'use strict';

angular.module('membership')
  .controller('ModifyAgentCtrl', [
    '$scope', 'modifyAgentViewModel', '$modalInstance', '$$selectedId', '$$selectedName',
    function ($scope, viewModel, modalInstance, $$selectedId, $$selectedName) {
      var vm = new viewModel(modalInstance, $$selectedId, $$selectedName);

      $scope.vm = vm;
      
    }
  ]);