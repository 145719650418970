/**
 * Created on 10/13/2015.
 */
angular.module('common')
  .run([
    '$rootScope',
    'common.config',
    'PageTitleService',
    '$state',
    'stateExtensions',
    function (rootScope, commonConfig, PageTitleService, $state, stateExtensions) {
      rootScope.commonConfig = commonConfig;
      rootScope.getFormattedDate = function (date, format) {
        if (!moment(date).isValid())
          return '';

        return moment(date).format(format || commonConfig.momentDateFormat);
      };

      rootScope.getChildrenState = stateExtensions.getChildrenState;

      rootScope.$on('$stateChangeStart', function (evt, to, params) {
        if (to.redirectTo) {
          evt.preventDefault();
          if (to.redirectTo === '::FIRSTCHILD::') {
            stateExtensions.goToFirstSubchild(to.name, params);
            return;
          }
          $state.go(to.redirectTo, params);
        }
      });

      rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        PageTitleService.setPageTitle('');

        if (toState.data && toState.data.title) {
          PageTitleService.setPageTitle(toState.data.title);
        }

        if (toState.data && toState.data.pageTitle) {
          PageTitleService.setPageTitle(toState.data.pageTitle);
        }
      });
    }
  ]);
