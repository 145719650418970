angular.module('common')
  .directive('loadingIndicator', [
    function () {
      return {
        restrict: "A",
        scope: {
          isLoading: "=loadingIndicator"
        },
        link: function (scope, element, attr) {
          $(element).prepend('<div class="loader"><div class="spinner"></div></div>');

          scope.$watch('isLoading', function () {
            if (scope.isLoading) {
              $(element).addClass('panel-loading');
            } else {
              $(element).removeClass('panel-loading');
            }
          }, true);
        }
      }
    }
  ]);
