

angular.module('membership')
  .controller('UserListCtrl', [
    '$scope', 'userCreationViewModel', 'modalSvc', 'ManageCompanies', 'membership.config', 'AjaxService', 'userUiService', 'authorizeService', 'BizUsers', '$location',
    function ($scope, creationModel, modalSvc, ManageCompanies, config, AjaxService, userUiService, authorizeService, BizUsers, $location) {

      /*$scope.dtColumns = [{
        "bSearchable": false,
        "bVisible": false,
        "aTargets": [0]
      }, {
        "bSearchable": false,
        "bSortable": false,
        "aTargets": [2]
      },
      {
        "sType": "html",
        "fnRender": function(o, val) {
          return $("<div class='actions'>")
            .append($("<a>")
              .attr('class', 'btn btn-success')
              .attr('href', '/#/admin/account/' + o.aData[0] + '/personalInfo')
              .append("<i class='fa fa-edit'></i>"))
            .append($("<a>")
              .attr('class', 'btn btn-success')
              .attr('href', '/account/impersonate/' + o.aData[0])
              .append("<i class='fa fa-exchange'></i>"))
            .html();
        },
        "aTargets": [-1],
        "sClass": 'actions',
        "bSortable": false,
        "bSearchable": false,
        "mData": null
      }
    ];*/


      $scope.filter = {
        roles: [
          {
            Id: "Owner",
            Name: "Owner"
          }, {
            Id: "Staff",
            Name: "Staff"
          }, {
            Id: "Client",
            Name: "Client"
          }
        ]
      };
      ManageCompanies.getAllCompanies(function (companies) {
        $scope.filter.companies = companies;
      });

      $scope.datatablesConfig = {
        datasourceUrl: config.apiUrls.base + '/datasource/user',
        dtColumns: [
          {
            "bSearchable": false,
            "bSortable": false,
            "aTargets": [2]
          },
          {
            "aTargets": -1,
            "bSearchable": false,
            "bSortable": false,
            "data": null,
            "sWidth": 90,
            "sClass": "actions-col dynamic-html",
            "render": function (data, type, full, meta) {
              var buttons = [
                ];
              
              var editBtn = "<a class='btn btn-success' ui-sref='membership_user_detail({id:" + data[0] + ", tab:\"personalInfo\"})' title='Edit' ><i class='fa fa-edit'></i></a>";
              var impersonateBtn = "<a class='btn btn-success' ng-click='model.impersonate(" + data[0] + ")' title='Impersonate' ><i class='fa fa-exchange'></i></a>";

              buttons.push(editBtn);
              buttons.push(impersonateBtn);

              if (authorizeService.isFeatureAllowed(config.permissions.activateDeactivateUsers)) {
                if (data[6] === "True") {
                  buttons.push("<a class='btn btn-danger' ng-click='model.deactivateUser(" + data[0] + ")' title='Deactivate User' ><i class='fa fa-ban'></i></a>");
                } else {
                  buttons.push("<a class='btn btn-info' ng-click='model.reactivateUser(" + data[0] + ")' title='Reactivate User' ><i class='fa fa-check'></i></a>");
                }
              }
              

              return buttons.join('&nbsp;');
            }
          }
        ],
        hiddenColumns: [0, 1, 6]/*,
        customFilter: {}*/
      };

      $scope.addUser = function () {
        userUiService.proceedUserCreation().finally(function () {
          $scope.datatablesConfig.reload();
        });
      };

      $scope.model = {
        impersonate: function (userId) {
          AjaxService.get(config.apiUrls.base + "/auth/impersonate/" + userId).then(function () { 
            window.location = '/';
          });
        },

        reactivateUser: function (userId) {
          BizUsers.reactivateUser(userId).finally($scope.datatablesConfig.reload);
        },

        deactivateUser: function (userId) {
          BizUsers.deactivateUser(userId).finally($scope.datatablesConfig.reload);
        }
      };
    }
  ]);
