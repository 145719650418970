'use strict';

angular.module('magtac')
  .service('MagTacLibraries', ['AjaxService', 'magtac.config',
    function (ajaxService, config) {
      var baseUrl = config.apiUrls.base + '/libraries';
      var domain = {
        getAll: function () {
          return ajaxService.get(baseUrl);
        },

        getForType: function (type) {
          return ajaxService.get(baseUrl + '/getForType?type=' + type);
        },

        getUpdates: function (params) {
          return ajaxService.post(baseUrl + '/getUpdates', params);
        },

        create: function (model, file) {
          return ajaxService.upload(baseUrl + '/upload', file, { data: model });
        },

        createWorkflow: function (model, file) {
          return ajaxService.upload(baseUrl + '/UploadWorkflow', file, { data: model });
        },

        update: function (model) {
          return ajaxService.put(baseUrl + '/', model);
        },

        updateWorkflow: function (model) {
          return ajaxService.put(baseUrl + '/updatedworkflow', model);
        },

        delete: function (id) {
          return ajaxService.delete(baseUrl + '/' + id);
        },

        deleteWorkflow: function (id) {
          return ajaxService.delete(baseUrl + '/deleteworkflowlibrary/'+ id);
        },

        updateCustomers: function (lib) {
          return ajaxService.post(baseUrl + '/updateCustomers', lib);
        },

        workflowGetAll: function() {
          return ajaxService.get(baseUrl + '/getAllWorkflowLibrary');
        },

        getForWorkflowType: function(type){
          return ajaxService.get(baseUrl + '/GetForWorkflowType?type=' + type);
        }
      };

      return domain;
    }
  ]);
