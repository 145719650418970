'use strict';

angular.module('membership', [
    'membership-config',
    'navigation',
    'ui.select2',
    'common',
    'backend',
    'siteSettings'
  ])
  .run([
    '$rootScope', 'authenticationService', '$location', '$state', '$timeout', 'authorizeService',
    function ($rootScope, authenticationService, $location, $state, $timeout, authorizeService) {

      $rootScope.isPageAvailable = authorizeService.isPageAvailable;
      $rootScope.isStateAvailable = authorizeService.isStateAvailable;
      $rootScope.isFeatureAllowed = authorizeService.isFeatureAllowed;

      //authenticationService.authCheck();
      //authenticationService.authServerCheck();

      $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        if (!authenticationService.authCheck(toState, toParams)) {
          event.preventDefault();
          $timeout(function () {
            if (toState.data.redirect) {
              $rootScope.returnState = {
                state: toState,
                params: toParams
              };
              $location.path(toState.data.redirect);
            } else {
              $location.path('/access-denied');
            }
          });
        }
      });
    }
  ]);
