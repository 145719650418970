'use strict';

angular.module('membership')
  .factory('contactsViewModel', [
    'AjaxService', 'membership.config',
    function (ajaxService, config) {
    var viewModel = function() {
      var self = this;

      self.mode = 1;
      self.selectedUser = null;
      self.selectedGroup = null;
      self.selectedUsers = [];


      self.promises = {
        users: ajaxService.get(config.apiUrls.base + '/api/user/getall').then(function (data) {
          self.users = data;
        }),

        groups: ajaxService.get(config.apiUrls.base + '/api/userGroup/getAll').then(function (data) {
          self.groups = data;
        })
      };


      self.getUserById = function(id) {
        var user = _.find(self.users, function(u) {
          return u.Id == id;
        });

        return user;
      }

      self.add = function () {

        if (!self.selectedUser) {
          return;
        }

        if (self.mode == 1) {
            var existingUser = _.find(self.selectedUsers, function(u) {
              return u.Id == self.selectedUser.Id;
            });

            if (!existingUser) {
              //self.selectedUsers.push(self.getUserById(self.selectedUser));
              self.selectedUsers.push(self.selectedUser);
            }
        } else {
          ajaxService.get(config.apiUrls.base + '/api/userGroup/getUsers/' + self.selectedGroup).then(function (data) {
            _.each(data, function(u) {

              var existingUser = _.find(self.selectedUsers, function(su) {
                return su.Id == u.Id;
              });

              if (!existingUser) {
                self.selectedUsers.push(u);
              }
            });
          });
        }
      }

      self.remove = function(user){
        self.selectedUsers = _.filter(self.selectedUsers, function(u){
          return u.Id != user.Id;
        })
      }

      var showPerPage = 10;
      self.autocompleteOptions = {
        width: 'element',
        placeholder: "Search for a user",
        minimumInputLength: 1,
        ajax: {
          url: config.apiUrls.base + "/user/autocomplete",
          quietMillis: 100,
          data: function (term, page) {
            return {
              q: term,
              page_limit: showPerPage + 1,
              page: page
            };
          },
          results: function (data, page) {
            var more = false;
            if (data.length > showPerPage) {
              data.pop();
              more = true;
            }
            return { results: data, more: more };
          }
        },
        initSelection: function (element, callback) {
          /*if (element.select2('data') === null) {
            callback(self.event.customer);
          }*/
        },
        formatResult: function (item) {
          return item.LastName + ' ' + item.FirstName + ' / ' + item.Email + ' / ' + item.CellPhoneNumber;
        },
        formatSelection: function (item) {
          return item.LastName + ' ' + item.FirstName + ' / ' + item.Email + ' / ' + item.CellPhoneNumber;
        },
        id: function (item) { return item.Id; },
        escapeMarkup: function (m) { return m; }
      };
    }

    return viewModel;
  }]);
