/**
 * Created on 10/8/2015.
 */
angular.module('membership')
  .service('ClientCompanyUiService', [
    'modalDialogService',
    function (modalDialogService) {
      var membershipClientCompanyUiService = {};
      membershipClientCompanyUiService.newClientCompany = function () {
        return modalDialogService.showWindow('components/membership/clientCompany/create/clientCompanyCreation.tpl.html', 'ClientCompanyCreationController');
      };

      membershipClientCompanyUiService.editClientCompany = function (clientCompanyId) {
        return modalDialogService.showWindow('components/membership/clientCompany/edit/clientCompanyEdit.tpl.html', 'ClientCompanyEditController', {
          clientCompanyId: clientCompanyId
        });
      };

      return membershipClientCompanyUiService;
    }
  ]);
