/**
 * Created on 9/28/2015.
 */
angular.module('siteSettings')
  .config([
    'appStateConfigProvider',
    function (appStateConfigProvider) {

      var states = [// site setting management
        {
          name: 'backend.site_setting_management',
          url: '/siteSettings',
          template: '<ui-view></ui-view>',
          controller: ['$state', function ($state) {
            if ($state.current.name == 'backend.site_setting_management') {
              $state.go('backend.site_setting_management.list');
            }
          }],
          data: {
            title: 'siteSettings.lbl.siteSettings',
            pageTitle: 'siteSettings.lbl.siteSettings',
            pageDesc: 'siteSettings.lbl.siteSettings',
            access: "/sysadmin"
          }
        },
        {
          name: 'backend.site_setting_management.list',
          url: '/',
          templateUrl: 'components/siteSettings/list/siteSettingsList.html',
          controller: 'SiteSettingsListController',
          data: {
            title: 'siteSettings.lbl.siteSettings',
            pageTitle: 'siteSettings.lbl.siteSettings',
            pageDesc: 'siteSettings.lbl.siteSettings',
            access: "/sysadmin"
          }
        },
        {
          name: 'backend.site_setting_management.editSetting',
          url: '/{settingKey:.*}',
          templateUrl: 'components/siteSettings/settingPage/setting.html',
          controller: 'SettingPageController',
          data: {
            access: "/sysadmin"
          }
        }
      ];

      states.forEach(appStateConfigProvider.addState);
    }]);
