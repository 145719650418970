'use strict';

angular.module('magtac')
    .service('WorkflowRoutes', ['AjaxService', 'magtac.config',
        function (ajaxService, config) {
            var baseUrl = config.apiUrls.base + '/workflow';
            var domain = {

                getCustomerWorkflows: function (searchTerm, searchLevel, searchCategory, partialMatchSearch, numericSearch) {
                    return ajaxService.get(baseUrl + '/getCustomerWorkflows/' + searchTerm + '/' + searchLevel + '/' + searchCategory + '/' + partialMatchSearch + '/'+ numericSearch);
                },
                getDropdownOptions: function () {
                    return ajaxService.get(baseUrl + '/getDropdownOptions/');
                },
            };

            return domain;
        }
    ]);
