'use strict';

angular.module('membership')
  .service('Role', [
    'membership.config', 'AjaxService',
    function (config, AjaxService) {

      var base = config.apiUrls.base + '/role';

      var Role = {
        list: [],
        getPermissions: function (roleId) {
          return _.find(this.list, function (x) {
            return x.Id == roleId;
          }).Permissions;
        },

        getPermissionsFromServer: function (id, callback) {
          return AjaxService.get(base + '/' + id + '/permission').then(callback);
        },

        get: function (id, callback) {
          return AjaxService.get(base + '/' + id).then(callback);
        },
        insert: function (role, callback) {
          return AjaxService.post(base + '/post', role).then(callback);
        },
        update: function (id, role, callback) {
          return AjaxService.put(base + '/' + id, role).then(callback);
          //Restangular.one(base, id).customPUT(role).then(callback);
        },
        assignPermission: function (role, permission, callback) {
          return AjaxService.post(base + '/' + role.Id + '/permission/' + permission.Id).then(callback);
          //Restangular.one(base, role.Id).one('permission', permission.Id).post().then(callback);
        },
        unassignPermission: function (role, permission, callback) {
          return AjaxService.delete(base + '/' + role.Id + '/permission/' + permission.Id).then(callback);
          //Restangular.one(base, role.Id).one('permission', permission.Id).remove().then(callback);
        },

        reload: function () {
          var self = this;

          return AjaxService.get(base).then(function (roles) {
            self.list = roles;
            return self.list;
          });

          //Restangular.all(base).getList()
          //  .then(function (roles) {
          //    self.list = roles;
          //  });
        },

        remove: function (roleId, callback) {
          return AjaxService.delete(base + "/" + roleId).then(callback);
        },

        getSystemRoles: function (callback) {
          return AjaxService.get(base + "/systemRoles").then(callback);
        },
      };

      Role.reload();

      return Role;
    }
  ]);
