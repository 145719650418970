'use strict';

angular.module('projectsManagement')
  .directive('projectsTree', [
    'Projects', 'modalDialogService', '$state',
    function (Projects, modalDialogService, $state) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          masterId: '='
        },
        templateUrl: function(element, attrs){
          return attrs.templateUrl || 'components/projectsManagement/interfaces/projects-tree/projectsTree.html';
        },
        link: function (scope, elm, attrs) {
          scope.addProject = function() {
            modalDialogService
              .showWindow('components/projectsManagement/newProjectDialog/newProjectDialog.tpl.html', 'NewProjectCtrl', { $$parent: null, $$companyId: scope.masterId, $$project: null })
              .then(function(resp){
                scope.init();
                if (resp.ParentId) {                  
                  $state.go('project_details.child.general', {childId: resp.Id, key: resp.ParentId});
                } else {
                  $state.go('project_details.general', {key: resp.Id});
                }
              });
          }

          scope.init = function(){
            Projects.byCustomer(scope.masterId, true).then(function(projectsList) {
              scope.projectsList = projectsList;
            });
          }

          scope.init();
        }
      };
    }
  ]);
