'use strict';

angular.module('magtac')
  .controller('MagTacCustomersCtrl', [
    '$scope', 'MagTacCustomersViewModel',
    function ($scope, viewModel) {
      $scope.model = new viewModel();
      $scope.toggleOpen = function () {
        $scope.model.filterOpen = !$scope.model.filterOpen;
      };


    }
  ]);