'use strict';

angular.module('membership')
  .controller('NewCustomerCtrl', [
    '$scope', 'newCustomerViewModel', '$modalInstance',
    function ($scope, viewModel, modalInstance) {
      var vm = new viewModel(modalInstance);
      $scope.vm = vm;
    }
  ]);
