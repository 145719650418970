'use strict';

angular.module('magview')
    .controller('prefDetailsControlModel', [
        '$scope', '$stateParams', 'prefDetailsViewModel',
        function ($scope, $stateParams, viewModel) {
            $scope.model = new viewModel($stateParams.name);
            $scope.pref = { Name: $stateParams.name};
        }
    ]);
