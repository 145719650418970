'use strict';
angular.module('ticketAssignment')
    .service('supportMetricsViewModel', ['ticketAssignment.config', 'ticketAdmin', '$state',
        function (config, Tickets, $stateParams) {
            var viewModel = function (agentId) {
                console.log('\n------------- Loaded: -------------');
                console.log('Loaded support metrics view model');

                var self = this;

                var todayDateObj = new Date();

                self.today = {
                    date: todayDateObj.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' }),
                    time: todayDateObj.toLocaleDateString("en-US", { weekday: 'long', hour: 'numeric', minute: 'numeric' })
                };

                self.headers = [
                    {
                        name: 'Backlog',
                        display: 'Backlog',
                        width: '10vw',
                        subheaders: [
                            {
                                name: "Total",
                                display: "Total",
                                properties: {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 0, isTimeValue: false },
                                },
                                totalWeight: "bold"
                            },
                            {
                                name: "Age",
                                display: "Age",
                                properties: {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 0 , isTimeValue: false },
                                },
                                show: "none"

                            },
                            {
                                name: "Score",
                                display: "Score",
                                properties:{
                                    agent: { type: 'number', precision: 1, isTimeValue: false },
                                    team: { type: 'number', precision: 1 , isTimeValue: false },
                                },
                                show: "none"
                            },
                            {
                                name: "OneWeek",
                                display: "7d%",
                                properties: {
                                    agent: { type: 'percent', precision: 0, isTimeValue: false },
                                    team: { type: 'percent', precision: 0, isTimeValue: false  },
                                },
                                show: "none",
                                borderRight: "yes"
                            },
                        ]
                    },
                    {
                        name: 'Closes',
                        display: 'Closes',
                        width: '9vw',
                        subheaders: [
                            {
                                name: "Today",
                                display: "T",
                                properties:
                                {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 1 , isTimeValue: false },
                                }
                            },
                            {
                                name: "OneDay",
                                display: "1d",
                                properties:
                                {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 1 , isTimeValue: false },
                                },
                                totalWeight: "bold",
                                averageWeight: "bold"
                            },
                            {
                                name: "SevenDay", display: "7d",
                                properties:
                                {
                                    agent: { type: 'number', precision: 1, isTimeValue: false },
                                    team: { type: 'number', precision: 1 , isTimeValue: false },
                                }
                            },
                            {
                                name: "ThirtyDays",
                                display: "30d",
                                properties:
                                {
                                    agent: { type: 'number', precision: 1, isTimeValue: false },
                                    team: { type: 'number', precision: 1 , isTimeValue: false },
                                }
                            },
                            {
                                name: "NinetyDays",
                                display: "90d",
                                properties:
                                {
                                    agent: { type: 'number', precision: 1, isTimeValue: false },
                                    team: { type: 'number', precision: 1, isTimeValue: false },
                                }
                            },
                        ]
                    },
                    {
                        name: 'Touches',
                        display: 'Touches',
                        width: '8vw',
                        subheaders: [
                            {
                                name: "Today",
                                display: "T",
                                properties:
                                {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 1 , isTimeValue: false },
                                }
                            },
                            {
                                name: "OneDay",
                                display: "1d",
                                properties:
                                {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 1 , isTimeValue: false },
                                }
                            },
                            {
                                name: "SevenDay",
                                display: "7d",
                                properties:
                                {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 0 , isTimeValue: false },
                                }
                            },
                            {
                                name: "ThirtyDays",
                                display: "30d",
                                properties:
                                {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 0 , isTimeValue: false },
                                }
                            },
                            {
                                name: "NinetyDays",
                                display: "90d",
                                properties:
                                {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 1, isTimeValue: false },
                                }
                            },
                        ]
                    },
                    {
                        name: 'TicketTouches',
                        display: 'Tickets Touched',
                        width: '8vw',
                        subheaders: [
                            {
                                name: "Today", display: "T", 
                                properties:
                                {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 1 , isTimeValue: false },
                                }
                            },
                            {
                                name: "OneDay", display: "1d", 
                                properties:
                                {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 1 , isTimeValue: false },
                                },
                                totalWeight: "bold"
                            },
                            {
                                name: "SevenDay", display: "7d", 
                                properties:
                                {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 0 , isTimeValue: false },
                                }
                            },
                            {
                                name: "ThirtyDays", display: "30d", 
                                properties:
                                {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 0 , isTimeValue: false },
                                }
                            },
                            {
                                name: "NinetyDays",
                                display: "90d",
                                properties:
                                {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 1, isTimeValue: false },
                                }
                            },
                        ]
                    },
                    {
                        name: 'CallHours',
                        display: 'Call Hours',
                        width: '12vw',
                        align: 'right',
                        subheaders: [
                            {
                                name: "Today", display: "T",
                                properties:
                                {
                                    agent: { type: 'string', isTimeValue: false },
                                    team: { type: 'string', isTimeValue: false },
                                }
                            },
                            {
                                name: "OneDay", display: "1d",
                                properties:
                                {
                                    agent: { type: 'string', isTimeValue: false },
                                    team: { type: 'string', isTimeValue: false },
                                }
                            },
                            {
                                name: "SevenDay", display: "7d",
                                properties:
                                {
                                    agent: { type: 'string', isTimeValue: false },
                                    team: { type: 'string', isTimeValue: false },
                                }
                            },
                            {
                                name: "ThirtyDays", display: "30d",
                                properties:
                                {
                                    agent: { type: 'string', isTimeValue: false },
                                    team: { type: 'string', isTimeValue: false },
                                }
                            },
                        ]
                    },
                    {
                        name: 'Response',
                        display: 'Response',
                        width: '7vw',
                        subheaders: [
                            {
                                name: "First", display: "First",
                                properties:
                                {
                                    agent: { type: 'number', precision: 1, isTimeValue: false },
                                    team:  { type: 'number', precision: 2, isTimeValue: false },
                                },
                                show: "none",
                                borderLeft: "yes"
                            },
                            {
                                name: "Average", display: "Average",
                                properties:
                                {
                                    agent: { type: 'number', precision: 1, isTimeValue: false },
                                    team:  { type: 'number', precision: 2, isTimeValue: false },
                                },
                                show: "none",
                                borderRight: "yes"
                            },
                        ]
                    },
                    {
                        name: 'FeedbackPositive',
                        display: 'Positive',
                        width: '4.5vw',
                        subheaders: [
                            {
                                name: "SevenDay", display: "7d",
                                properties: {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 1, isTimeValue: false },
                                }
                            },
                            {
                                name: "ThirtyDays", display: "30d",
                                properties: {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 1, isTimeValue: false },
                                }
                            },
                            {
                                name: "NinetyDays",
                                display: "90d",
                                properties:
                                {
                                    agent: { type: 'number', precision: 1, isTimeValue: false },
                                    team: { type: 'number', precision: 1, isTimeValue: false },
                                }
                            },
                        ]
                    },
                    {
                        name: 'FeedbackNegative',
                        display: 'Negative',
                        width: '4.5vw',
                        subheaders: [
                            {
                                name: "SevenDay", display: "7d",
                                properties: {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 1, isTimeValue: false },
                                }
                            },
                            {
                                name: "ThirtyDays", display: "30d",
                                properties: {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 1, isTimeValue: false },
                                }
                            },
                            {
                                name: "NinetyDays",
                                display: "90d",
                                properties:
                                {
                                    agent: { type: 'number', precision: 0, isTimeValue: false },
                                    team: { type: 'number', precision: 1, isTimeValue: false },
                                }
                            },
                        ]
                    },
                    {
                        name: 'FirstAndLastTouches',
                        display: 'First,Last Touch',
                        width: '22vw',
                        subheaders: [
                            {
                                name: "Today",
                                display: "T",
                                properties:
                                {
                                    agent: { type: 'string', precision: 0, isTimeValue: false },
                                    team: { type: 'string', precision: 0, isTimeValue: false },
                                }
                            },
                            {
                                name: "OneDay",
                                display: "1d",
                                properties:
                                {
                                    agent: { type: 'string', precision: 0, isTimeValue: false },
                                    team: { type: 'string', precision: 0, isTimeValue: false },
                                }
                            },
                            {
                                name: "SevenDay",
                                display: "7d",
                                properties:
                                {
                                    agent: { type: 'string', precision: 0, isTimeValue: false },
                                    team: { type: 'string', precision: 0, isTimeValue: false },
                                }
                            },
                            {
                                name: "ThirtyDays",
                                display: "30d",
                                properties:
                                {
                                    agent: { type: 'string', precision: 0, isTimeValue: false },
                                    team: { type: 'string', precision: 0, isTimeValue: false },
                                }
                            },
                        ]
                    },

                ];

                self.sortDirection = {
                    'Backlog': { 'Total': 'asc', 'Age': 'desc', 'Score': 'desc', 'OneWeek': 'desc' },
                    'Closes': { 'Today': 'desc', 'OneDay': 'desc', 'SevenDay': 'desc', 'ThirtyDays': 'desc' },
                    'Touches': { 'Today': 'desc', 'OneDay': 'desc', 'SevenDay': 'desc', 'ThirtyDays': 'desc' },
                    'TicketTouches': { 'Today': 'desc', 'OneDay': 'desc', 'SevenDay': 'desc', 'ThirtyDays': 'desc' },
                    'CallHours': { 'Today': 'desc', 'OneDay': 'desc', 'SevenDay': 'desc', 'ThirtyDays': 'desc' },
                    'Response': { 'First': 'asc', 'Average': 'asc' },
                    'FirstAndLastTouches': { 'Today': 'asc', 'OneDay': 'asc', 'SevenDay': 'asc', 'ThirtyDays': 'asc' },
                    'FeedbackPositive': { 'SevenDay': 'desc', 'ThirtyDays': 'desc' },
                    'FeedbackNegative': { 'SevenDay': 'asc', 'ThirtyDays': 'asc' },
                };

                self.nHeaders = self.headers.length + 1;

                self.warning = {
                    display: false,
                    message: '',
                }

                Tickets.getSupportMetrics().then(
                    function (resp) {
                        console.log('Calling Tickets.getSupportMetrics()...');
                        console.log(resp);

                        self.TeamNames = resp.GroupNames;
                        self.Teams = resp.Teams;
                        self.GroupMetrics = resp.GroupMetrics;
                        self.TotalMetrics = resp.TotalMetrics;
                        self.ErrorMessages = resp.ErrorMessages;
                        self.HasErrorMessages = resp.ErrorMessages.length > 0;
                        self.Members = Object.values(self.Teams)
                            .map(function f(team) { return Object.values(team.Members) })
                            .flat();

                        if (self.Members.filter(function (m) { return !m.HasPhoneExtension }).length > 0)
                        {
                            self.warning = {
                                message: 'No phone extension for: ' + self.Members.filter(function (m) { return !m.HasPhoneExtension }).map(function (m) { return m.Name; }),
                                display: true
                            };
                        }
                        
                        console.log('Done.\n\nself.Teams:'); console.log(self.Teams);
                        console.log('self.GroupMetrics:'); console.log(self.GroupMetrics);
                        console.log('\nself.Members:'); console.log(self.Members);
                        console.log(self.ErrorMessages);
                    }
                );

            }
            return viewModel;
        }
    ]);
