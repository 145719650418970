'use strict';

angular.module('projectsManagement')
  .config([
    'appStateConfigProvider', 'projectsManagement.configProvider',
    function (appStateConfigProvider, configProvider) {

      var states = [
        {
          name: 'projects_list',
          url: "/projects?customerId",
          templateUrl: "components/projectsManagement/list/list.html",
          controller: 'ProjectsListCtrl',
          data: {
            access: configProvider.config.permissions.projectView
          }
        },
        {
          name: 'project_details',
          redirectTo: 'project_details.general',
          url: "/projects/:key",
          templateUrl: "components/projectsManagement/details/details.html",
          controller: 'ProjectDetailsCtrl',
          //abstract: true,
          data: {
            access: configProvider.config.permissions.projectView
          }
        },
        // region project detail general
        {
          name: 'project_details.general',
          url: "",
          templateUrl: "components/projectsManagement/details/general/general.html",
          data: {
            access: configProvider.config.permissions.projectView
          }
        },        
        {
          name: 'project_details.notes',
          url: "/notes",
          templateUrl: "components/projectsManagement/details/notes/notes.html",
          controller: 'NotesListCtrl',
          resolve: {
            masterId: ['$stateParams', function($stateParams) { return $stateParams.key; }]
          },
          data: {
            access: configProvider.config.permissions.projectNotesView
          }
        },          
        {
          name: 'project_details.documents',
          url: "/documents",
          templateUrl: "components/documentsManagement/list/documents.html",
          controller: 'DocumentsListCtrl',
          data: {
            access: configProvider.config.permissions.customerDocumentsView
          },
          resolve: {
              masterId: ['$stateParams', function ($stateParams) {
                return $stateParams.key;
              }],
              documentsApiUrl: ['projectsManagement.config', function (config) {
                return config.apiUrls.base + '/ProjectDocuments';
              }],
              editPermission: ['projectsManagement.config', function (config) {
                return config.permissions.projectDocumentsEdit;
              }]
          }/*,
          resolve2: {
            masterId: ['$stateParams', function($stateParams) { return $stateParams.key; }],
            documentsApiUrl: ['projectsManagement.config', function(config){ return config.apiUrls.base + '/ProjectDocuments'; }]
          }*/
        },        
        
        {
          name: 'project_details.child',
          url: "/child/:childId",
          template: "<div ui-view></div>",
          redirectTo: 'project_details.child.general',
          //abstract: true,
          controller: 'ProjectChildDetailsCtrl'
          //templateUrl: "components/projectsManagement/details/child/child.html"
        },
        {
          name: 'project_details.child.general',
          url: "",
          controller: 'ProjectDetailsGeneralCtrl',
          templateUrl: "components/projectsManagement/details/general/general.html"
        },        
        {
          name: 'project_details.child.notes',
          url: "/notes",
          templateUrl: "components/projectsManagement/details/notes/notes.html",
          controller: 'NotesListCtrl',
          resolve: {
            masterId: ['$stateParams', function($stateParams) { return $stateParams.childId; }],
          }
        },

        // function registerDocument(stateName, MasteId , docu) {}
        {
          name: 'project_details.child.documents',
          url: "/documents",
          templateUrl: "components/documentsManagement/list/documents.html",
          controller: 'DocumentsListCtrl',
          resolve: {
            masterId: ['$stateParams', function($stateParams) { return $stateParams.childId; }],
            documentsApiUrl: ['projectsManagement.config', function(config){ return config.apiUrls.base + '/ProjectDocuments'; }]
          }
        },  
        // endregion

        // region project detail expenses

        {
          name: 'project_details.expenses',
          url: "/expenses",
          templateUrl: "components/projectsManagement/details/expenses/expenses.html",
        },
        // endregion
      ];

      states.forEach(appStateConfigProvider.addState);
    }
  ]);
