/**
 * Created on 11/24/2015.
 */

'use strict';

angular.module('common')
  .service('CountryStateDomain', [
    'AjaxService',
    'CacheStorageService',
    'PromiseService',
    function (AjaxService,
              CacheStorageService,
              PromiseService) {
      var countriesJsonUrl = 'https://gist.githubusercontent.com/JustinChasez/e9df228731667b6d4e6f/raw/fb097a60427717b262d5058633590749f366bd80/gistfile1.json';

      var countryListCacheKey = "Lean35:Countries:List",
      statesListCacheKey = "Lean35:States::List";

      var CountryStateDomain = {
        getCountriesList: function () {
          var countries = CacheStorageService.retrieve(countryListCacheKey);
          if (countries)
            return PromiseService.resolve(countries);

          return AjaxService.singleGet(countriesJsonUrl).then(function(data){
            var countriesList = _.pluck(data.countries, 'country');
          });
        },
        getStatesList: function (country) {

        }
      };

      return CountryStateDomain;
    }
  ]);
