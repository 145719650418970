'use strict';

angular.module('magtac')
  .service('bulkTasksDetailsViewModel', [
    'BulkTasksConfig', 'timerFormatService',
    function (BulkTasksConfig, timerFormatService) {

      var viewModel = function (item, defaultConfigurations, observerNames, timers, adHocQueries) {
        var self = this;

        if (item) {
          self.Id = item.Id;
          self.ConfigName = item.Name;
          self.item = angular.copy(item.TaskConfigurations[0]);

          _.each([self.item].concat(self.item.SubTasks), function (x) {
            x.RunNow = !!x.RunNowCronConfiguration;
          });
        }
        else {
          self.item = {SubTasks: []};
        }

        self.listeners = {};
        self.observerNames = observerNames;
        self.adHocQueries = adHocQueries;
        self.timers = timers;
        self.adHocDetailsExpanded = false;
        self.RUN_NOW_DELAY_MINUTES = 5;

        BulkTasksConfig.getAvailableTasks().then(function (resp) {

          self.availableTasks = resp;

          /*var filtered = _.filter(self.availableTasks, function (x) {
            if (x.Category == 1) {
              return true;
            }
            if (self.item && self.item.TaskName == x.Name) {
              return true;
            }

            return !_.findWhere(self.timers, {TaskName: x.Name});
          });

          self.taskNames = _.uniq(_.pluck(filtered, 'Name'));*/

          self.taskNames = _.uniq(_.pluck(self.availableTasks, 'TaskName'));

          if (!!self.item.TaskName) {
            self.taskChanged(true);
          }
        });

        self.toggleAdvanced = function () {
          self.advancedVisible = !self.advancedVisible;
          if (self.advancedVisible) {
            self.CronInput = self.item.CronConfiguration;
            self.CronOutput = self.item.CronConfiguration;
          }
        }

        self.availableTimeValues = timerFormatService.getValues();

        self.cronConfig = {
          options: {
            allowYear: false
          }
        };

        self.on = function (action, callBack) {
          self.listeners[action] = callBack;
        };

        self.notify = function (action, params) {
          if (self.listeners[action]) {
            self.listeners[action](params);
          }
        };

        self.taskChanged = function (isInitializing) {

          if (!self.item.TaskName) {
            self.item.TaskName = null;
            return;
          }

          self.selectedTask = _.findWhere(self.availableTasks, {TaskName: self.item.TaskName});
          self.onSelectedTaskChanged();

          self.isGroup = !!self.selectedTask.SubTasks && self.selectedTask.SubTasks.length > 0;
        };

        self.onSelectedTaskChanged = function () {

          // apply default settings

          if (!self.item.CronConfiguration) {
            self.item.CronConfiguration = self.selectedTask.CronConfiguration;
            self.item.CronEnabled = true;
            self.item.RunNow = false;
            self.item.RunNowCronConfiguration = null;
          }

          if (self.selectedTask.Parameters && !self.item.Parameters) {
            self.item.Parameters = self.selectedTask.Parameters;
            /*self.item.Parameters = {
             isSingleResult: self.selectedTask.Parameters.isSingleResult
             };*/
          }

          _.each(self.selectedTask.SubTasks, function (x) {
            if (!self.getSubTaskByName(x.TaskName)) {
              self.item.SubTasks.push({
                TaskName: x.TaskName,
                CronConfiguration: x.CronConfiguration,
                CronEnabled: true,
                RunNow: false,
                RunNowCronConfiguration: null,
                Parameters: x.Parameters
              });
            }
          });

        };

        self.getSubTaskByName = function (name) {
          return _.findWhere(self.item.SubTasks, {TaskName: name});
        };

        self.save = function () {
          _.each([self.item].concat(self.item.SubTasks), function (x) {
            if (!x.RunNow) {
              x.RunNowCronConfiguration = null;
            } else if (!x.RunNowCronConfiguration) {
              var minute = (new Date().getMinutes() + self.RUN_NOW_DELAY_MINUTES) % 60;
              x.RunNowCronConfiguration = "0 " + minute + " * * * ?";
            }
          });

          if (self.item.SubTasks && self.item.SubTasks.length > 0) {
            self.item.CronConfiguration = self.item.SubTasks[0].CronConfiguration;
          }

          if (self.Id) {
            BulkTasksConfig.update({
              Id: self.Id,
              Name: self.ConfigName,
              TaskConfigurations: [self.item]
            }).then(function () {
              self.notify('save');
              //angular.copy(self.item, item);
            });
          } else {
            BulkTasksConfig.create({Name: self.ConfigName, TaskConfigurations: [self.item]}).then(function () {
              self.notify('save');
              //angular.copy(self.item, item);
            });
          }
        };

        self.delete = function () {
          BulkTasksConfig.delete(self.item.Id).then(function () {
            self.notify('delete', {Id: self.item.Id});
          });
        };
      };

      return viewModel;

    }
  ]);
