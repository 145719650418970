  angular.module('membership')
    .controller('SecurityCtrl', [
      '$scope', 'securityViewModel',
      function($scope, model) {

        $scope.viewModel = model;
        $scope.$watch('changePasswordForm.$valid', function() {
          $scope.viewModel.isValid = $scope.changePasswordForm.$valid;
        });

        $scope.validate = function() {
          for (var field in $scope.changePasswordForm) {
            if (field[0] != '$' && $scope.changePasswordForm[field].$pristine) {
              $scope.changePasswordForm[field].$setViewValue(
                $scope.changePasswordForm[field].$modelValue
              );
            }

          }
        };
        $scope.viewModel.init($scope.userId);
      }
    ])