/**
 * Created on 11/14/2015.
 */

'use strict';

angular.module('backend')
  .factory('backendDashboardViewModel', [
    function () {
      function backendDashboardViewModel() {

      }

      return backendDashboardViewModel;
    }
  ]);
