'use strict';

angular.module('common')
    .directive('timeFilter', [
        'enums',
        function (enums) {
          return {
            restrict: 'E',
            templateUrl: 'components/common/templates/timeFilterTemplate.html',
            scope: {
              selectedDateRange: '=',
              selectedDateFrom: '=',
              selectedDateTo: '=',
              isPopoverMode: '=',
              historyMode: '='
            },
            link: function (scope, elm, attrs) {

              scope.timeRanges = enums.filterValues.timeRanges;

              function getOption(group, index) {
                return {
                  text: enums.filterValues.timeRanges[index].text,
                  click: 'select(group, ' + index + ')'
                };
              }

              function getGroup(name, options) {
                var g = {
                  placeholder: name,
                  selected: {},
                  options: _.map(options, function (o) { return getOption(g, o); })
                };

                return g;
              }


              scope.timeGroups = [
                  /*getGroup('Weekly', [1, 2, 3]),
                  getGroup('Monthly', [4, 5, 6]),
                  getGroup('Quarterly', [7, 8, 9]),
                  getGroup('Yearly', [10, 11, 12]),*/
                  getGroup('Weekly', [14]),
                  getGroup('Monthly', [15]),
                  getGroup('Quarterly', [16]),
                  getGroup('Yearly', [17])
              ];

              scope.select = function (group, option) {
                var range = enums.filterValues.timeRanges[option];
                if (group) {
                  group.selected = range.value;
                  group.selectedName = range.text;
                }

                scope.selectedDateFrom = scope.selectedDateTo = null;
                scope.selectedDateRange = range.value;
              };

              scope.change = function () {
                //console.log(scope.selectedDateRange);
                scope.selectedDateFrom = scope.selectedDateTo = null;
              };

              scope.clearSelect = function () {
                scope.selectedDateFrom = scope.selectedDateTo = null;
                scope.selectedDateRange = 'Today';
              };

              scope.isActive = function (value) {
                return scope.selectedDateRange && scope.selectedDateRange === value;
              };

              if (!scope.selectedDateFrom && !scope.selectedDateTo) {
                _.each(scope.timeGroups, function (group) {
                  _.each(group.options, function (option) {
                    if (scope.selectedDateRange === option.value) {
                      group.selected = option;
                    }
                  });
                });
              }

              scope.$watch('selectedDateFrom', function (val) {
                if (val) {
                  //Set custom date range
                  scope.selectedDateRange = enums.filterValues.timeRanges[13].value;
                }
              });

              scope.$watch('selectedDateTo', function (val) {
                if (val) {
                  //Set custom date range
                  scope.selectedDateRange = enums.filterValues.timeRanges[13].value;
                }
              });
            }
          };
        }
    ]);