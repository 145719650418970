'use strict';

angular.module('membership')
  .controller('UserManagerListCtrl', [
    '$scope', '$compile', 'manageUsersListViewModel', '$stateParams', 'membership.config',
    function ($scope, $compile, viewModel, $stateParams, config) {

      $scope.vm = new viewModel($stateParams.filter);

      /*$scope.isClients = $stateParams.filter == 'clients';
      $scope.isEmployees = $stateParams.filter == 'employees';

      $scope.datatablesConfig = {
        datasourceUrl: config.apiUrls.base + '/datasource/bizUsers',
        dtColumns: [
          {
            "aTargets": -1,
            "bSearchable": false,
            "bSortable": false,
            "data": null,
            "sWidth": 90,
            "sClass": "actions-col dynamic-html",
            "render": function (data, type, full, meta) {

              var editBtn = "<a class='btn btn-success' ui-sref='membership_usermanager_details({filter:\"" + $stateParams.filter + "\", action:\"update\", userId: " + data[0] + "})' title='Edit' ><i class='fa fa-edit'></i></a>";
              var addApptBtn = "<a class='btn btn-danger' confirmation='Are you sure you want to delete this user?' ng-click='viewModel.remove(" + data[0] + ")' itle='Delete'><i class='fa fa-times'></i></a>";

              var actionButtons = editBtn + "&nbsp;" + addApptBtn;

              return actionButtons;
            }
          }
        ],
        hiddenColumns: [0, 6]
      };

      if ($scope.isClients) {
        $scope.datatablesConfig.customFilter = { role: $stateParams.filter };
      }
      else if ($scope.isEmployees) {
        $scope.datatablesConfig.hiddenColumns = [0, 1, 5, 6];
        $scope.datatablesConfig.customFilter = { role: $stateParams.filter };
      }*/

    }
  ]);