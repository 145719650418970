'use strict';

angular.module('membership')
  .service('smsViewModel', [
    'Customer',
    function (Customer) {

      var viewModel = function (company) {

        var self = this;

        self.SMS = [{name : 'Test'}, {name: 'Test2'}];

      };

      return viewModel;

    }
  ]);
