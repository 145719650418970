
angular.module('common')
  .constant('enums', {

    filterValues: {
      timeRanges: [
        { value: 'Yesterday', text: 'Yesterday' },
        { value: 'ThisWeek', text: 'This Week' },
        { value: 'LastWeek', text: 'Last Week' },
        { value: 'Last7Days', text: 'Last 7 Days' },
        { value: 'ThisMonth', text: 'This Month' },
        { value: 'Last30Days', text: 'Last 30 Days' },
        { value: 'LastMonth', text: 'Last Month' },
        { value: 'ThisQuarter', text: 'This Quarter' },
        { value: 'Last3Months', text: 'Last 3 Months' },
        { value: 'LastQuarter', text: 'Last Quarter' },
        { value: 'ThisYear', text: 'Year to Date' },
        { value: 'Last12Months', text: 'Last 12 Months' },
        { value: 'LastYear', text: 'Last Year' },
        { value: 'Custom', text: 'Custom...' },

        { value: 'Today', text: 'Today' },
        { value: 'Tomorrow', text: 'Tomorrow' },
        { value: 'NextWeek', text: 'Next Week' },
        { value: 'NextMonth', text: 'Next Month' },
        { value: 'Upcoming', text: 'All Upcoming' }
      ],
      customTimeRange: 'Custom'
    },

    months: {
      '1': 'January',
      '2': 'February',
      '3': 'March',
      '4': 'April',
      '5': 'May',
      '6': 'June',
      '7': 'July',
      '8': 'August',
      '9': 'September',
      '10': 'October',
      '11': 'November',
      '12': 'December'
    },
  });