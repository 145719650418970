'use strict';

angular.module('membership')
  .service('customerUserListViewModel', [
    'membership.config', 'authorizeService', 'BizUsers', 'AjaxService',
    function (config,
              authorizeService,
              BizUsers,
              AjaxService) {

      var customerUserListViewModel = function (companyId) {

        var self = this;

        self.filterOpen = true;
        self.filter = {companyId: companyId};

        self.datatablesConfig = {
          datasourceUrl: config.apiUrls.base + '/datasource/BizUsers',
          defaultSortingColumn: "2",
          defaultSortingOrder: "asc",
          dtColumns: [
            {
              "bSearchable": false,
              "bVisible": false,
              "aTargets": [0]
            },
            {
              "bSearchable": false,
              "bVisible": false,
              "aTargets": [1]
            },
            {
              aTargets: [2],
              "sClass": "dynamic-html no-wrap",
              render: function (data, type, full, meta) {
                return "<a ui-sref='membership_customerUser.userProfile.general({key: " + full[0] + "})' >" + data + "</a>";
              }
            },
            {
              aTargets: [3],
              "sClass": "dynamic-html no-wrap",
              render: function (data, type, full, meta) {
                return "<a ui-sref='membership_customerUser.userProfile.general({key: " + full[0] + "})' >" + data + "</a>";
              }
            },
            {
              "bSearchable": false,
              "bVisible": false,
              "aTargets": [6]
            },
            {
              "bSearchable": false,
              "bVisible": false,
              "aTargets": [7]
            },
            {
              "bSearchable": false,
              "bVisible": false,
              "aTargets": [8]
            },
            {
              "aTargets": -1,
              "bSearchable": false,
              "bSortable": false,
              "data": null,
              "sWidth": 50,
              "sClass": "actions-col dynamic-html no-wrap",
              "render": function (data, type, full, meta) {
                var buttons = [
                  //"<a class='btn btn-success' ui-sref='membership_customerUser.userProfile.general({key: " + data[0] + "})' title='Details' ><i class='fa fa-eye'></i></a>"
                ];

                // if (authorizeService.isFeatureAllowed(config.permissions.activateDeactivateUsers)) {
                //   if (data[8] === "True") {
                //     buttons.push("<a class='btn btn-danger' ng-click='model.deactivateUser(" + data[0] + ")' title='Deactivate User' ><i class='fa fa-ban'></i></a>");
                //   } else {
                //     buttons.push("<a class='btn btn-info' ng-click='model.reactivateUser(" + data[0] + ")' title='Reactivate User' ><i class='fa fa-check'></i></a>");
                //   }
                // }
                // if (authorizeService.isFeatureAllowed('/sysadmin') || authorizeService.isFeatureAllowed(config.permissions.impersonateUsers)) {
                //   buttons.push("<a class='btn btn-danger' ng-click='$root.impersonateUser(" + data[0] + ")' title='Impersonate User' ><i class='fa fa-exchange'></i></a>");
                // }

                return buttons.join('&nbsp;');
              }
            }
          ],
          customFilter: self.filter,
          dom: "<'row'<'col-xs-12 col-sm-12'r>>" +
          "t" +
          "<'row'<'col-sm-2 col-xs-12 hidden-xs'l><'col-sm-4 col-xs-12 hidden-xs'<'pull-right'i>><'col-xs-12 col-sm-6'p>>"
        };

        self.reactivateUser = function (userId) {
          BizUsers.reactivateUser(userId).finally(self.datatablesConfig.reload);
        };

        self.deactivateUser = function (userId) {
          BizUsers.deactivateUser(userId).finally(self.datatablesConfig.reload);
        };
      };

      return customerUserListViewModel;

    }
  ]);
