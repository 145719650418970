'use strict';

angular.module('magview')
  .service('AttendanceTrackingViewModel', [
    '$state', 'magview.config', 'modalService', 'TimeManagement', 'BizUsers', 'attendanceDetailsViewModel',
    function ($state, config, modalService, TimeManagement, BizUsers, attendanceDetailsViewModel) {

      var viewModel = function (userId) {

        var self = this;

        self.filter = { dateFrom: new Date(new Date().getFullYear(), 0, 1), dateTo: new Date(new Date().getFullYear() + 1, 0, 1) /*(new Date()).setDate((new Date()).getDate() + 1)*/ };

        if (userId) {
          self.userId = userId;
          self.filter.userId = userId;
        }

        self.datatablesConfig = {
          datasourceUrl: config.apiUrls.base + '/datasource/attendanceTracking',
          defaultSortingColumn: "1",
          defaultSortingOrder: "desc",
          dtColumns: [
            {
              "aTargets": [0],
              "bSearchable": false,
              "bVisible": false
            },
            {
              "aTargets": [1],
              "sClass": "dynamic-html",
              "render": function (val, param1, data) {
                if (!self.filter.userId) {
                  return '<a class="callback-link" ng-click="model.redirectToUserDetails(' + data[0] + ')">' + val + '</a>';
                } else {
                  return '<span>' + val + '</span>';
                }
              }
            },
            {
              "aTargets": -1,
              "bSearchable": false,
              "bSortable": false,
              "data": null,
              "sClass": "actions-col dynamic-html no-wrap",
              "render": function (data) {
                return "<button class='btn btn-success btn-xs' ng-click=\"model.edit(" + data[0] + ")\" title=\"Edit\"><i class=\"fa fa-edit\"></i></button>" +
                  "&nbsp;<button class='btn btn-danger btn-xs' ng-click=\"model.delete(" + data[0] + ")\" title=\"Edit\"><i class=\"fa fa-times\"></i></button>";
              }
            }
          ],
          customFilter: self.filter,
          dom: "<'fixed-filter'f><'row'<'col-xs-12 col-sm-12'r>>" +
            "t" +
            "<'row'<'col-sm-2 col-xs-12 hidden-xs'l><'col-sm-4 col-xs-12 hidden-xs'<'pull-right'i>><'col-xs-12 col-sm-6'p>>"
        };

        self.datatablesConfig2 = {
          datasourceUrl: config.apiUrls.base + '/datasource/attendanceAnalytic',
          dtColumns: [
            {
              "aTargets": [0],
              "bSearchable": false,
              "bVisible": false
            },
            {
              "aTargets": [1],
              "sClass": "dynamic-html",
              "render": function (val, param1, data) {
                if (!self.filter.userId) {
                  return '<a class="callback-link" ng-click="model.redirectToUserDetails(' + data[0] + ')">' + val + '</a>';
                } else {
                  return '<span>' + val + '</span>';;
                }
              }
            }
          ],
          customFilter: self.filter,
          dom: "<'fixed-filter'f><'row'<'col-xs-12 col-sm-12'r>>" +
            "t" +
            "<'row'<'col-sm-2 col-xs-12 hidden-xs'l><'col-sm-4 col-xs-12 hidden-xs'<'pull-right'i>><'col-xs-12 col-sm-6'p>>"
        };

        self.redirectToUserDetails = function (attendanceId) {
          TimeManagement.getUserIdByAttendanceId(attendanceId).then(function (id) {
            $state.go('membership_customerUser.timeManagement.timeOff', { key: id });
          });
        };

        self.edit = function (id) {
          if (self.userId && !self.user) {
            BizUsers.get(userId).then(function (user) {
              self.user = user;
              self.openEditDialog(id);
            });
          } else {
            self.openEditDialog(id);
          }
        };

        self.openEditDialog = function (attendanceId) {
          var detailsViewModel = new attendanceDetailsViewModel(attendanceId, self.user);
          detailsViewModel.on('save', function () {
            self.datatablesConfig.reload();
          });
          detailsViewModel.on('delete', function (params) {
            self.datatablesConfig.reload();
          });
          modalService.showWindow('components/magview/timeManagement/attendanceTracking/attendance-details.html', detailsViewModel);
        };

        self.delete = function (id) {
          TimeManagement.deleteAttendance({ Id: id }).then(function () {
            self.datatablesConfig.reload();
          });
        };

      };

      return viewModel;

    }
  ]);
