import './wrapper';

import './moduleConfig';
import './modules';
import './stateConfig';

import 'backend/app';
import 'backend/stateConfig';
import 'projectsManagement/app';
import 'projectsManagement/config';
//import 'projectsManagement/navigationConfig';
import 'projectsManagement/stateConfig';
import 'documentsManagement/app';
import 'common/_module';
import 'common/config';
import 'common/translationConfig';
import 'magtac/_magtac';
import 'magtac/config';
import 'magtac/stateConfig';
import 'membership/app';
import 'membership/config';
import 'membership/navRegister';
import 'membership/siteSettingConfig';
import 'membership/stateConfig';
import 'ticketAssignment/_tickets';
import 'ticketAssignment/config';
import 'ticketAssignment/stateConfig';
import 'siteSettings/app.js';
import 'siteSettings/siteSettings.provider';
import 'siteSettings/siteSettings.svc';
import 'siteSettings/stateConfig';
import 'magview/app';
import 'magview/config';
import 'magview/navigationConfig';
import 'magview/stateConfig';
import 'navigation/_navigation';
import 'navigation/navbar.controller';


import 'backend/dashboard/backendDashboard.vm';
import 'backend/dashboard/backendDashboardController';
import 'projectsManagement/embeddedList/embeddedList.ctrl';
import 'projectsManagement/embeddedList/embeddedList.vm';
import 'projectsManagement/domains/projects.dmn';
import 'projectsManagement/details/details.ctrl';
import 'projectsManagement/details/details.vm';
import 'projectsManagement/list/list.ctrl';
import 'projectsManagement/list/list.vm';
import 'projectsManagement/settings/siteSettingConfig';
import 'projectsManagement/newProjectDialog/newProjectDialog.ctrl';
import 'projectsManagement/newProjectDialog/newProjectDialog.vm';
import 'documentsManagement/list/documents.ctrl';
import 'documentsManagement/list/documents.vm';
import 'documentsManagement/editDialog/documentEditDialog.ctrl';
import 'documentsManagement/editDialog/documentEditDialog.vm';
import 'common/directives/announcement.dir';
import 'common/directives/ckEditor.dir';
import 'common/directives/clickOutSide.dir';
import 'common/directives/convertToNumber';
import 'common/directives/datatables.dir';
import 'common/directives/disableAnimation';
import 'common/directives/focusOn.dir';
import 'common/directives/imageCarousel';
import 'common/directives/lean.datatable';
import 'common/directives/listPage.dir';
import 'common/directives/loadingIndicator.dir';
import 'common/directives/onBlur.dir';
import 'common/directives/pageTitle.dir';
import 'common/directives/select2Events.dir';
import 'common/directives/selectElementValue.dir';
import 'common/directives/timeFilter.dir';
import 'common/extensions/rootScopeExtensions';
import 'common/filters/bytesFilter';
import 'common/filters/repeatRangeFilter';
import 'common/layoutZones/layoutZone.dir';
import 'common/layoutZones/layoutZone.svc';
import 'common/services/AjaxService';
import 'common/services/PromiseService';
import 'common/services/announcement.svc';
import 'common/services/appStateConfigProvider';
import 'common/services/atlassianIssueCollector.svc';
import 'common/services/awaitService';
import 'common/services/cacheStorage.svc';
import 'common/services/enums';
import 'common/services/eventBroadcast.svc';
import 'common/services/googleMapLoader';
import 'common/services/loadingSpinner.svc';
import 'common/services/modal.svc';
import 'common/services/modalDialog.svc';
import 'common/services/notifySvc';
import 'common/services/pageTitleService';
import 'common/services/stateExtensions';
import 'common/services/storage.svc';
import 'common/services/translationSvc';
import 'common/services/validation';
import 'common/services/version';

import 'magtac/bulkTasksManagement/bulkTasksDetails.vm';
import 'magtac/bulkTasksManagement/list.ctrl';
import 'magtac/bulkTasksManagement/list.vm';
import 'magtac/customerStatus/customerStatus.ctrl';
import 'magtac/customerStatus/customerStatus.vm';
import 'magtac/sockets/signalr';
import 'magtac/sockets/signalrHubs';
import 'magtac/librariesManagement/libraries.ctrl';
import 'magtac/librariesManagement/libraries.vm';
import 'magtac/dashboard/dashboard.ctrl';
import 'magtac/dashboard/dashboard.vm';
import 'magtac/domains/bulkTasksConfig.dmn';
import 'magtac/domains/dashboard.dmn';
import 'magtac/domains/libraries.dmn';
import 'magtac/domains/lookup.dmn';
import 'magtac/domains/status.dmn';
import 'magtac/domains/taskHistory.dmn';
import 'magtac/domains/timerConfig.dmn';
import 'magtac/domains/server.dmn';
import 'magtac/domains/workflows.dmn';

import 'membership/clientCompany/clientCompany.list.controller';
import 'membership/clientCompany/clientCompany.list.vm';
import 'membership/clientCompany/clientCompany.ui.svc';
import 'membership/customer/customer.ctrl';
import 'membership/customer/customer.vm';
import 'membership/customer/customersList.ctrl';
import 'membership/customer/customersList.vm';
import 'membership/company/detail.ctrl';
import 'membership/company/details.vm';
import 'membership/company/list.ctrl';
import 'membership/extensions/rootScopeExtensions';
import 'membership/domains/bizUsers.dmn';
import 'membership/domains/bizUsersSync.dmn';
import 'membership/domains/clientCompany.dmn';
import 'membership/domains/customer.dmn';
import 'membership/domains/lookup.dmn';
import 'membership/domains/manageCompanies';
import 'membership/domains/ownership.dmn';
import 'membership/domains/permission.dmn';
import 'membership/domains/profile.dmn';
import 'membership/domains/profileProperty.dmn';
import 'membership/domains/role.dmn';
import 'membership/domains/subscription.dmn';
import 'membership/domains/user.dmn';
import 'membership/domains/userGroup.dmn';
import 'membership/domains/userRegister.dmn';
import 'membership/login/login.ctrl';
import 'membership/login/login.vm';
import 'membership/role/list.ctrl';
import 'membership/myaccount/myAccount.ctrl';
import 'membership/register/userRegister.ctrl';
import 'membership/register/userRegister.vm';
import 'membership/services/authentication.svc';
import 'membership/services/authorizeService';
import 'membership/services/getUserInfo.ui.svc';
import 'membership/services/invoice.svc';
import 'membership/services/permissions.svc';
import 'membership/services/subscription.svc';
import 'membership/services/userUi.svc';
import 'membership/search/search.ctrl';
import 'membership/search/search.vm';
import 'membership/user/list.ctrl';
import 'membership/userActivation/userActivation.ctrl';
import 'membership/userActivation/userActivation.vm';
import 'membership/userProfile/userProfile.ctrl';
import 'membership/userProfile/userProfile.vm';
import 'membership/userProfileProperty/propertyUi.svc';
import 'ticketAssignment/activityReportDetails/activityReportDetails.ctrl';
import 'ticketAssignment/activityReportDetails/activityReportDetails.vm';
import 'ticketAssignment/activityReport/activityReport.ctrl';
import 'ticketAssignment/activityReport/activityReport.vm';
import 'ticketAssignment/alertConfiguration/alertConfiguration.ctrl';
import 'ticketAssignment/alertConfiguration/alertConfiguration.vm';
import 'ticketAssignment/admin/admin.ctrl';
import 'ticketAssignment/admin/admin.vm';
import 'ticketAssignment/agentTickets/agentTickets.ctrl';
import 'ticketAssignment/agentTickets/agentTickets.vm';
import 'ticketAssignment/companyTickets/companyTickets.ctrl';
import 'ticketAssignment/companyTickets/companyTickets.vm';
import 'ticketAssignment/assignmentList/assignmentList.ctrl';
import 'ticketAssignment/assignmentList/assignmentList.vm';
import 'ticketAssignment/scoreboard/scoreboard.ctrl';
import 'ticketAssignment/scoreboard/scoreboard.vm';
import 'ticketAssignment/audit/audit.ctrl';
import 'ticketAssignment/audit/audit.vm';
import 'ticketAssignment/customerBoardOld/customerBoardOld.ctrl';
import 'ticketAssignment/customerBoardOld/customerBoardOld.vm';
import 'ticketAssignment/customerBoard/customerBoard.ctrl';
import 'ticketAssignment/customerBoard/customerBoard.vm';
import 'ticketAssignment/domains/ticketAdmin.dmn';
import 'ticketAssignment/supportMetrics/supportMetrics.ctrl';
import 'ticketAssignment/supportMetrics/supportMetrics.vm';
import 'ticketAssignment/supportMetrics/metricsSettings/metricsSettings.ctrl';
import 'ticketAssignment/supportMetrics/metricsSettings/metricsSettings.vm';

import 'ticketAssignment/supportMetricsOld/supportMetricsOld.ctrl';
import 'ticketAssignment/supportMetricsOld/supportMetricsOld.vm';

import 'ticketAssignment/ticketSearch/ticketSearch.ctrl';
import 'ticketAssignment/ticketSearch/ticketSearch.vm';
import 'ticketAssignment/teamTickets/teamTickets.ctrl';
import 'ticketAssignment/teamTickets/teamTickets.vm';
import 'siteSettings/settingPage/settingPage.ctrl';
import 'siteSettings/settingPage/settingPage.vm';
import 'siteSettings/list/siteSettingsList.ctrl';
import 'siteSettings/list/siteSettingsList.vm';
import 'magview/buildLog/buildLog.ctrl';
import 'magview/buildLog/buildLog.vm';
import 'magview/prefs/prefs.ctrl';
import 'magview/prefs/prefs.vm';
import 'magview/domains/buildLog.dmn';
import 'magview/domains/lookup.dmn';
import 'magview/domains/prefs.dmn';
import 'magview/domains/timeManagement.dmn';
import 'magview/timeManagement/timeManagement.ctrl';
import 'navigation/directives/activeUrl.dir';
import 'projectsManagement/interfaces/projects-tree/projectsTree.dir';
import 'projectsManagement/details/child/childDetails.ctrl';
import 'projectsManagement/details/child/childDetails.vm';
import 'projectsManagement/details/general/general.ctrl';
import 'projectsManagement/details/general/general.vm';
import 'common/directives/addressControlInputs/country.dmn';
import 'common/directives/addressControlInputs/countrySelection';
import 'common/directives/addressSearchControl/addressSearchControl.dir';
import 'common/directives/pagination/lean.pagination.dir';
import 'common/directives/quartz-cron/cronSelection';
import 'common/directives/quartz-cron/cronService';
import 'projectsManagement/details/notes/notes.ctrl';
import 'projectsManagement/details/notes/notes.vm';
import 'magtac/customers/taskslist/magtac-customers-tasks-list.ctrl';
import 'magtac/customers/taskslist/magtac-customers-tasks-list.vm';

import 'magtac/customers/workflow/magtac-workflows.ctrl';
import 'magtac/customers/workflow/magtac-customers-workflows-list.vm';

import 'magtac/customerStatus/currentStatus/currentStatus.ctrl';
import 'magtac/customerStatus/services/timerFormat.svc';
import 'magtac/customerStatus/general/statusGeneral.ctrl';
import 'magtac/customerStatus/general/statusGeneral.vm';
import 'magtac/customerStatus/statusConfig/statusConfig.ctrl';
import 'magtac/customerStatus/ignoreRuleDialog/ignoreRuleDialog.ctrl';
import 'magtac/customerStatus/statusHistory/statusHistory.ctrl';
import 'magtac/customerStatus/statusHistory/statusHistory.vm';
import 'magtac/customerStatus/timersConfig/timerConfigDetails.vm';
import 'magtac/customerStatus/timersConfig/timersConfig.vm';
import 'magtac/customerStatus/observers/observers.ctrl';

import 'magtac/customers/list/magtac-customers-list.vm';
import 'magtac/customers/list/magtac-customers-list.ctrl';

import 'magtac/customerStatus/serverDelete/serverDelete.vm';
import 'magtac/customerStatus/serverDelete/serverDelete.ctrl';

import 'magtac/customerStatus/serverDelete/serverDeleteDetails/serverDeleteDetails.vm';
import 'magtac/customerStatus/serverDelete/serverDeleteDetails/serverDeleteDetails.ctrl';

import 'magtac/librariesManagement/uploadLibrary/uploadLibrary.vm';
import 'membership/clientCompany/detail/clientCompanyDetail.ctrl';
import 'membership/clientCompany/detail/clientCompanyDetail.vm';
import 'membership/clientCompany/edit/clientCompanyEdit.controller';
import 'membership/clientCompany/edit/clientCompanyEdit.vm';
import 'membership/clientCompany/create/clientCompanyCreation.controller';
import 'membership/clientCompany/create/clientCompanyCreation.vm';
import 'membership/clientCompany/directives/ClientCompanyInfo.ctrl';
import 'membership/clientCompany/directives/ClientCompanyInfo.vm';
import 'membership/clientCompany/directives/clientCompanyInfo.directive';
import 'membership/customer/SMS/SMS.ctrl';
import 'membership/customer/SMS/SMS.vm';
import 'membership/customer/connectionInfo/connectionInfo.dmn';
import 'membership/customer/connectionInfo/connectionInfo.vm';
import 'membership/customer/contacts/contactDetails.vm';
import 'membership/customer/contacts/contacts.ctrl';
import 'membership/customer/contacts/contacts.vm';
import 'membership/customer/contacts/contactsSearch.ctrl';
import 'membership/customer/contacts/contactsSearch.vm';
import 'membership/customer/documents/documents.ctrl';
import 'membership/customer/documents/documents.vm';
import 'membership/customer/newCustomerDialog/newCustomerDialog.ctrl';
import 'membership/customer/newCustomerDialog/newCustomerDialog.vm';
import 'membership/customer/modifyOwnership/modifyOwnership.ctrl';
import 'membership/customer/modifyOwnership/modifyOwnership.vm';
import 'membership/customer/ownership/ownership.ctrl';
import 'membership/customer/ownership/ownership.vm';
import 'membership/customer/modify/modify.ctrl';
import 'membership/customer/modify/modify.vm';
import 'membership/customer/users/customerUser.ctrl';
import 'membership/customer/users/customerUser.vm';
import 'membership/customer/users/customerUserList.ctrl';
import 'membership/customer/users/customerUserList.vm';
import 'membership/customer/users/customerUserNav.svc';
import 'membership/customer/sites/siteDetails.vm';
import 'membership/customer/sites/sites.ctrl';
import 'membership/customer/sites/sites.vm';
import 'membership/customer/workflow/workflow.ctrl';
import 'membership/customer/workflow/workflow.vm';
import 'membership/customer/vendors/vendorDetails.vm';
import 'membership/customer/vendors/vendors.ctrl';
import 'membership/customer/vendors/vendors.vm';
import 'membership/customer/partnerships/partnershipDetails.vm';
import 'membership/customer/partnerships/partnerships.ctrl';
import 'membership/customer/partnerships/partnerships.vm';
import 'membership/interfaces/authorized_only/authorizedOnly.dir';
import 'membership/interfaces/contact-user-widget/contactUserWidget.dir';
import 'membership/interfaces/logout_menu/logoutMenu.dir';
import 'membership/interfaces/selectUsers/selectRoleDirective';
import 'membership/interfaces/selectUsers/selectUserDirective';
import 'membership/interfaces/selectUsers/selectUsersDirective';
import 'membership/interfaces/user_contacts_select/contacts.dir';
import 'membership/interfaces/user_contacts_select/contacts.vm';
import 'membership/interfaces/permission/permission.dir';
import 'membership/interfaces/selectClientCompany/selectClientCompany.dir';
import 'membership/role/creationModal/roleCreation.vm';
import 'membership/role/details/details.base.ctrl';
import 'membership/myaccount/personalInfo/accountInfo.ctrl';
import 'membership/myaccount/personalInfo/accountInfo.vm';
import 'membership/myaccount/changePassword/changePassword.ctrl';
import 'membership/myaccount/changePassword/changePassword.vm';
import 'membership/myaccount/profile/accountProfile.ctrl';
import 'membership/myaccount/profile/accountProfile.vm';
import 'membership/user/creationModal/userCreation.controller';
import 'membership/user/creationModal/userCreation.vm';
import 'membership/user/details/details.base.ctrl';
import 'membership/user/details/permissions.ctrl';
import 'membership/user/details/permissions.vm';
import 'membership/user/details/personalInfo.ctrl';
import 'membership/user/details/personalInfo.vm';
import 'membership/user/details/security.ctrl';
import 'membership/user/details/security.vm';
import 'membership/userManager/details/details.ctrl';
import 'membership/userManager/details/details.vm';
import 'membership/userManager/list/list.ctrl';
import 'membership/userManager/list/list.vm';
import 'membership/userManager/list/userManagerList.renderDefaultUserActions';
import 'membership/userManager/filterableList/filterable.list.controller';
import 'membership/userManager/filterableList/filterable.list.vm';
import 'membership/userGroup/details/details.ctrl';
import 'membership/userGroup/details/details.vm';
import 'membership/userGroup/list/list.ctrl';
import 'membership/userGroup/list/list.vm';
import 'membership/userManagerSync/list/list.ctrl';
import 'membership/userManagerSync/list/list.vm';
import 'membership/userManagerSync/details/details.ctrl';
import 'membership/userManagerSync/details/details.vm';
import 'membership/userProfileProperty/create/profilePropertyCreate.ctrl';
import 'membership/userProfileProperty/create/profilePropertyCreate.vm';
import 'magview/prefs/prefDetails/prefDetail.ctrl';
import 'magview/prefs/prefDetails/prefDetails.vm';
import 'magview/timeManagement/attendanceTracking/attendanceDetails.vm';
import 'magview/timeManagement/attendanceTracking/attendanceTracking.ctrl';
import 'magview/timeManagement/attendanceTracking/attendanceTracking.vm';
import 'magview/timeManagement/timeTracking/timeTracking.ctrl';
import 'magview/timeManagement/timeTracking/timeTracking.vm';
import 'membership/clientCompany/managers/filterableList/filterable.list.controller';
import 'projectsManagement/details/notes/editDialog/noteEditDialog.ctrl';
import 'projectsManagement/details/notes/editDialog/noteEditDialog.vm';
import 'membership/role/details/profileProperties/profileProp.ctrl';
import 'membership/role/details/profileProperties/profileProp.vm';
import 'membership/role/details/permission/permissions.ctrl';
import 'membership/role/details/permission/permissions.vm';
import 'membership/role/details/roleInfo/roleInfo.ctrl';
import 'membership/role/details/roleInfo/roleInfo.vm';

import 'magview/prefs/prefDetails/prefDetail.ctrl';
import 'magview/prefs/prefDetails/notes/prefNotes.ctrl';
import 'magview/prefs/prefDetails/notes/prefNotes.vm';
import 'magview/prefs/prefDetails/notes/prefNotesDetails.vm';
import 'magview/prefs/prefDetails/general/general.ctrl';
import 'magview/prefs/prefDetails/general/general.vm';
import 'magview/prefs/prefDetails/general/generalEdit.vm';
import 'magview/prefs/prefDetails/prefDetails.vm';



import 'format-as-currency';
