'use strict';

angular.module('projectsManagement')
  .service('Projects', ['AjaxService', 'projectsManagement.config',
    function (ajaxService, config) {
      var baseUrl = config.apiUrls.base;
      var domain = {
        list: function () {
          return ajaxService.get(baseUrl + '/projects');
        },
        details: function (id) {
          return ajaxService.get(baseUrl + '/projects/' + id);
        },
        update: function (project) {
          return ajaxService.put(baseUrl + '/projects/', project);
        },
        create: function (project) {
          return ajaxService.post(baseUrl + '/projects/', project);
        },
        delete: function (id) {
          return ajaxService.delete(baseUrl + '/projects/' + id);
        },
        descriptions: function (id) {
          return ajaxService.get(baseUrl + '/projects/descriptions');
        },
        deleteNote: function (noteId) {
          return ajaxService.delete(baseUrl + '/projectNotes/' + noteId);
        },
        createNote: function (projectId, noteModel) {
          return ajaxService.post(baseUrl + '/projectNotes/?projectId=' + projectId, noteModel);
        },
        childs: function (id) {
          return ajaxService.get(baseUrl + '/projects/' + id + '/childs');
        }  ,
        byCustomer: function (customerCode, includeChilds, fullDetails) {
          if(!fullDetails){
            fullDetails = false;
          }
          return ajaxService.get(baseUrl + '/projects/customer/' + customerCode + '?includeChilds=' + includeChilds + '&fullDetails=' + fullDetails);
        }  ,
        getExpenses: function (id) {
          return ajaxService.get('/expense.api/expenses/' + id);
        }        
      };

      return domain;
    }
  ]);
