'use strict';

angular.module('membership')
  .service('authenticationService', [
    '$rootScope', '$location', 'User', '$cookies',
    'permissionsService', 'membership.config', '$state', '$timeout',
    'AjaxService', 'appStateConfig',
    function ($rootScope, $location, user, $cookies,
              permissionsService, config, $state, $timeout,
              ajaxService, appStateConfig) {
      return {
        //isAuthenticated: false,
        validationFailed: false,
        authCheck: function (toState, toParams) {

          if ($location.path().indexOf('login') != -1) {
            return true;
          }

          var tokenCookieValue = $.cookie(config.defaultTokenKey);
          if (tokenCookieValue) {
            $rootScope.isAuthenticated = true;
            ajaxService.setAuthenticateHeader(config.defaultTokenKey, tokenCookieValue);
          } else if (config.alwaysRequireLogin) {
            $rootScope.isAuthenticated = false;
            $location.path('/login');
            //return false;
          }

          if (permissionsService.initialized) {
            if (toState && toState.url != "/access-denied") {
              return $rootScope.isStateAvailable(toState.name);
            }
            return true;
          }

          if (!permissionsService.initializing) {

            var initializaingCallBack = function() {
              if (!toState) return;
              if (!$rootScope.isStateAvailable(toState.name)) {
                $timeout(function() {
                  if (toState.data.redirect) {
                    $rootScope.returnState = {
                      state: toState,
                      params: toParams
                    };
                    $location.path(toState.data.redirect);
                  } else {
                    $location.path('/access-denied');
                  }
                });
              }
            };

            permissionsService.init(initializaingCallBack);
            this.authServerCheck();
          }

          return true;
        },
        authServerCheck: function () {
          var self = this;
          if ($location.path().indexOf('login') != -1) {
            return;
          }

          if ($rootScope.isAuthenticated == true) {
            user.init(function(resp) {
              if (resp.error == true) {
                $rootScope.safeApply(function() {
                  $rootScope.isAuthenticated = false;
                  if (config.alwaysRequireLogin) {
                    $location.path('/login');
                  }
                });
              } else {
                $rootScope.userName = user.userName;
                $rootScope.userId = user.userId;
              }
            });
          } else {
            permissionsService.init().then(function () {
              user.init(function () {
                $rootScope.userName = user.userName;
                $rootScope.userId = user.userId;
              });
            });
          }
        },
        signOut: function () {
          $cookies.remove(config.defaultTokenKey);
          ajaxService.removeAuthenticateHeader();
          $rootScope.isAuthenticated = false;
          this.validationFailed = false;
          permissionsService.permissions = [];
          $state.go('login');
        },
        changePassword: function (data) {
          return user.changePassword(data).then(function (response) {
            $.cookie(response.key, response.value);
            ajaxService.setAuthenticateHeader(response.key, response.value);
            return response;
          });
        },
        signInWithToken: function(token, successCallback) {
            $.cookie(config.defaultTokenKey, token);
            ajaxService.setAuthenticateHeader(config.defaultTokenKey, token);
            $rootScope.safeApply(function () {
              permissionsService.init(function () {
                user.init(function () {
                  $rootScope.userName = user.userName;
                  $rootScope.userId = user.userId;
                });
                $rootScope.isAuthenticated = true;

                if (successCallback) {
                  successCallback();
                  return;
                }

                $location.search({});
                // redirect to saved state
                if ($rootScope.returnState) {
                  $state.go($rootScope.returnState.state, $rootScope.returnState.params);
                  $rootScope.returnState = undefined;
                } else {
                  $location.path(appStateConfig.defaultPath);
                }
              }, true);
            });
        },

        signIn: function (login, pass, errorCallback, successCallback) {
          var self = this;

          ajaxService.post(config.apiUrls.base + '/auth', {
            Login: login,
            Password: pass
          }).then(function (data) {
            $.cookie(data.key, data.value);
            ajaxService.setAuthenticateHeader(data.key, data.value);
            $rootScope.safeApply(function () {
              permissionsService.init(function () {
                user.init(function () {
                  $rootScope.userName = user.userName;
                  $rootScope.userId = user.userId;
                });
                $rootScope.isAuthenticated = true;

                if (successCallback) {
                  successCallback();
                  return;
                }

                $location.search({});
                // redirect to saved state
                if ($rootScope.returnState) {
                  $state.go($rootScope.returnState.state, $rootScope.returnState.params);
                  $rootScope.returnState = undefined;
                } else {
                  $location.path(appStateConfig.defaultPath);
                }
              }, true);
            });
          }, function (error) {
            self.validationFailed = true;
            $rootScope.isAuthenticated = false;
            if (errorCallback) {
              errorCallback(error);
            }
          });
        }
      };
    }
  ]);
