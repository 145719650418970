/**
 * Created on 9/29/2015.
 */
angular.module('common')
  .provider('translationFilePath', function () {
    var filePaths = [
      "components/__module__/locales/__language__.json"
    ];

    this.addFilePath = function (path) {
      if (path.indexOf('__module__') == -1 || path.indexOf('__language__') == -1)
        throw new Error("Path must specify the module and language position using __module__ and __language__");
      filePaths.push(path);
    };

    this.$get = function () {
      return filePaths;
    };

    return this;
  })
  .factory('translationCustomLoader', [
    'AjaxService', '$q', 'CacheStorageService', 'translationFilePath',
    function (ajaxService, $q, cacheStorageService, translationFilePath) {

      var getLanguageFilePath = function (module, language) {
        return translationFilePath.map(function (path) {
          return path.replace('__module__', module).replace('__language__', language);
        });
      };

      var getOverrideLanguagePath = function (language) {
        return "app/locales/" + language + ".json";
      };

      var dictionaryKey = "App_Translation_Dictionary_";
      return function (options) {
        var defer = $q.defer();
        var dictionary = {};
        var promises = [];

        var languageKey = options.key;

        var cacheKey = dictionaryKey + languageKey;
        var dic = cacheStorageService.retrieve(cacheKey);

        if (dic) {
          defer.resolve(dic);
          return defer.promise;
        }

        options.modules.forEach(function (module) {
          var ___defer = $q.defer();

          var urls = getLanguageFilePath(module, options.key);

          runAjax(urls[0], 0);

          promises.push(___defer.promise);

          function runAjax(url, currentIndex) {
            ajaxService.get(url)
              .then(getLanguageSuccess, getLanguageError.bind(null, currentIndex + 1));
          }

          function getLanguageSuccess(results) {
            dictionary[module] = results;
            ___defer.resolve();
          }

          function getLanguageError(nextUrlIndex, error) {
            if (error.status == 404) {
              if (urls.length > nextUrlIndex) {
                runAjax(urls[nextUrlIndex], nextUrlIndex);
                return;
              }
            }
            ___defer.reject("Could not load language file");
          }
        });

        $q.all(promises).finally(function () {
          ajaxService.get(getOverrideLanguagePath(options.key))
            .then(function (results) {
              dictionary = _.merge(dictionary, results);
            }, function () {

            }).finally(function () {
            cacheStorageService.store(cacheKey, dictionary, 0.1);
            defer.resolve(dictionary);
          });
        });

        return defer.promise;
      };
    }])
  .config([
    '$translateProvider',
    function ($translateProvider) {
      var module;

      try {
        module = angular.module('modules')
      } catch (e) {
        return;
      }

      //$translateProvider.useSanitizeValueStrategy('sanitize');

      $translateProvider.useLoader('translationCustomLoader', {
        modules: module.requires
      });
      $translateProvider.preferredLanguage('en');
      $translateProvider.fallbackLanguage('en');
    }
  ]);
