/**
 * Created on 10/6/2015.
 */
angular.module('membership')
  .directive('selectUsers', [
    'AjaxService', 'User', 'membership.config', '$q', 'translationSvc', 'AwaitService',
    function (AjaxService, User, config, $q, translationSvc, AwaitService) {
      return {
        restrict: "EA",
        templateUrl: function (element, attrs) {
          return attrs.templateUrl || "components/common/templates/commonMultiSelectionWithAutoComplete.html";
        },
        require: 'ngModel',
        scope: {
          bindingProperty: "@bindingProperty",
          roleFilter: "@roleFilter",
          optionalDisplayText: "@optionalDisplayText",
          showOptionalValue: "=",
          clientCompanyId: "="
        },
        link: function (scope, element, attr, ngModel) {

          var self = scope;
          self.optionsList = [];
          var defer = $q.defer();
          self.ensureDataLoaded = defer.promise;

          self.selectedValue = {};

          self.getDisplayValue = function (item) {
            return item ? item.displayName : "";
          };

          if (!scope.optionalDisplayText)
            scope.optionalDisplayText = translationSvc.t('membership.lbl.typeToSearchUser');

          self.reload = function (filter) {
            AwaitService.await(reloadExecute.bind(null, filter), 500);
            return defer.promise;
          };

          function reloadExecute(filter) {
            var url = config.apiUrls.base + "/user/autocomplete?q=" + filter + "&roleFilter=" + scope.roleFilter + "&page_limit=1100&page=1";

            if (scope.clientCompanyId) {
              url += "&clientCompanyId=" + scope.clientCompanyId;
            }

            return AjaxService.get(url)
              .then(function (response) {

                self.optionsList = response.map(function (r) {
                  r.displayName = r.FirstName + " " + r.LastName;
                  return r;
                }).filter(function (item) {
                  console.log(item);
                  return !_.contains(scope.selectedValue.selected, item[scope.bindingProperty]);
                });

                if (scope.showOptionalValue) {
                  self.optionsList.splice(0, 0, {
                    Id: '',
                    displayName: scope.optionalDisplayText || translationSvc.t('membership.lbl.typeToSearchUser')
                  });
                }
              })
              .finally(function () {
                defer.resolve();
              });
          }

          scope.$watch('selectedValue.selected', function () {
            if (scope.selectedValue.selected)
              ngModel.$setViewValue(_.pluck(scope.selectedValue.selected, scope.bindingProperty));
          }, true);


          scope.$watch(function () {
            return ngModel.$modelValue;
          }, function (newValue) {
            scope.ensureDataLoaded.then(function () {
              scope.selectedValue.selected = _.filter(self.optionsList, function (option) {
                return _.contains(newValue, option[scope.bindingProperty]);
              });

              if (!scope.showOptionalValue) {
                scope.optionalDisplayText = self.getDisplayValue(scope.selectedValue.selected);
              }
            });
          });

          scope.$watch('roleFilter', function () {
            scope.reload('');
          }, true);

          scope.$watch('clientCompanyId', function () {
            scope.reload('');
          }, true);
        }
      };
    }
  ]);
