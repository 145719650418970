'use strict';

angular.module('membership')
  .controller('CustomerUserListCtrl', [
    '$scope', 'customerUserListViewModel',
    function ($scope, viewModel) {
      var id = null;
      if ($scope.company) {
        id = $scope.company.Id;
      }
      $scope.model = new viewModel(id);
      $scope.toggleOpen = function () {
        $scope.model.filterOpen = !$scope.model.filterOpen;
      };
    }
  ]);
