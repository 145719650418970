/**
 * Created on 12/1/2015.
 */

'use strict';

angular.module('membership')
  .service('SubscriptionDomain', [
    'AjaxService',
    'membership.config',
    function (ajaxService, config) {
      var baseUrl = config.apiUrls.base + '/api/subscriptionplans';

      var SubscriptionDomain = {
        getById: function (id) {
          return ajaxService.get(baseUrl + '/' + id);
        },

        update: function (id, data) {
          return ajaxService.put(baseUrl + '/' + id, data);
        },

        delete: function (id) {
          return ajaxService.delete(baseUrl + '/' + id);
        },

        create: function (data) {
          return ajaxService.post(baseUrl, data);
        }
      };

      return SubscriptionDomain;
    }
  ]);
