'use strict';

angular.module('magtac')
  .controller('MagTacCustomersTasksCtrl', [
    '$scope', 'MagTacCustomersTasksViewModel',
    function ($scope, viewModel) {
      $scope.model = new viewModel();
      $scope.toggleOpen = function () {
        $scope.model.filterOpen = !$scope.model.filterOpen;
      };
    }
  ]);