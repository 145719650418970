angular.module('membership')
  .factory('clientCompanyCreationViewModel', [
    'ClientCompanyDomain', 'validation', 'notifySvc', '$translate',
    function (ClientCompanyDomain, validation, notifySvc, $translate) {

      function userCreationViewModel(modalInstance) {
        var self = this;

        self.modalInstance = modalInstance;
        self.clientCompanyData = {};
        self.clientCompanyData.Name = '';
        self.clientCompanyData.Address = '';
        self.clientCompanyData.PhoneNumber = '';
        self.clientCompanyData.MainContactUser = {
          FirstName: "",
          LastName: "",
          Email: "",
          CellPhoneNumber: "",
          Password: "",
          RepeatPassword: "",
          Address: ""
        };

        this.showValidationMessages = false;
        this.isLoading = false;
      }

      userCreationViewModel.prototype = {

        showValidation: function (field) {
          return validation.validate(field, 'required') && this.showValidationMessages;
        },

        isModelValid: function () {
          var self = this;
          return validation.validate(self.clientCompanyData.Name, 'required')
            && validation.validate(self.clientCompanyData.Address, 'required')
            && validation.validate(self.clientCompanyData.PhoneNumber, 'required')
            && validation.validate(self.clientCompanyData.MainContactUser.FirstName, 'required')
            && validation.validate(self.clientCompanyData.MainContactUser.LastName, 'required')
            && validation.validate(self.clientCompanyData.MainContactUser.Email, 'required');
        },

        save: function () {
          this.showValidationMessages = true;
          var self = this;
          if (self.isModelValid()) {
            self.showValidationMessages = false;
            self.isLoading = true;
            ClientCompanyDomain.create(self.clientCompanyData).then(function (data) {
              notifySvc.success({
                i18msg: 'membership.msg.clientCompanyCreatedSuccess',
                i18title: 'membership.lbl.addClientCompany'
              });
              self.modalInstance.close();
            }, function (data) {
              notifySvc.error({
                msg: data.data || $translate.instant('membership.msg.clientCompanyCreateError'),
                i18title: 'membership.lbl.addClientCompany'
              });
            }).finally(function () {
              self.isLoading = false;
            });
          }
        }
      };

      return userCreationViewModel;
    }]);
