/**
 * Created on 10/26/2015.
 */

'use strict';

angular.module('common')
  .service('CacheStorageService', [
    'StorageService',
    function (StorageService) {
      var key = "CACHED::::_";

      var CachedStorageService = {
        lifetime: 1, // minute
        retrieve: function (storageName) {
          var value = StorageService.retrieve(key + storageName);

          if (value == null)
            return null;

          if (moment(value.expireAt) < moment()) {
            this.remove(storageName);
            return null;
          }

          return value.obj;
        },
        remove: function (storageName) {
          StorageService.remove(key + storageName);
        },
        store: function (storageName, storageValue, time) {
          StorageService.store(key + storageName, {
            expireAt: moment().add(time || this.lifetime, 'minutes'),
            obj: storageValue
          });
        }
      };

      return CachedStorageService;
    }

  ]);
