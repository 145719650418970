'use strict';

angular.module('membership')
  .factory('manageUsersDetailsViewModel', ['BizUsers', 'validation', '$location', '$state', function (BizUsers, validation, $location, $state) {

    var viewModel = function (filter, mode, userId) {
      var self = this;
      self.role = filter;
      self.isAddClientForm =  self.role === 'BizClient';
      self.action = mode;
      self.userId = userId;
      self.user = null;
      self.showValidationMessages = false;
      self.emailExists = false;
      self.emailChecked = false;

      switch (mode) {
        case 'create':
          self.user = {
            roles: [self.role == 'BizClient' ? 'BC' : 'BS']
          };
          break;
        case 'invite':
          self.user = {
            roles: ['BC'],
            IsNeedInvite: true
          };
          break;
        case 'update':
          BizUsers.get(self.userId, function (user) {
            self.user = user;
            if (user.Email) {
              self.userOriginalEmail = user.Email.toLowerCase();
            }
            self.user.isBizClient = _.some(user.Roles, function (r) {
              return r == 'BizClient';
            });
          });
          break;
      }

      self.checkEmail = function () {
        if (!self.user) {
          self.emailExists = false;
          return;
        }

        if (self.user.Id && self.user.Email) {
          if (self.user.Email.toLowerCase() == self.userOriginalEmail) {
            self.emailExists = false;
            return;
          }
        }

        if ((!self.user.Email && !self.user.email)) {
          self.emailExists = false;
          return;
        }
        BizUsers.checkEmail(self.user.Email || self.user.email, function (resp) {
          self.emailExists = resp.exists;
          self.emailChecked = true;
        });
      };

      self.insert = function () {
        this.showValidationMessages = true;
        if (!this.isModelValid()) {
          return;
        }

        this.showValidationMessages = false;

        BizUsers.insert(self.user, function (u) {
          $state.transitionTo('membership_usermanager', { filter: self.role });
        });
      };

      self.update = function () {
        self.showValidationMessages = true;
        if (!self.isUpdateModelValid()) {
          return;
        }

        self.showValidationMessages = false;

        BizUsers.update(self.userId, self.user, function (u) {
          $state.transitionTo('membership_usermanager', { filter: self.role });
        });
      };

      self.save = function () {
        if (self.userId) {
          self.update();
        } else {
          self.insert();
        }
      };

      self.inviteClient = function () {
        $location.path('/manageUsers/clients/invite');
      };

      self.returnToList = function () {
        $location.path('/manageUsers/' + this.role);
      };

      self.showValidation = function (field, fieldName) {
        if (fieldName == 'email' && this.emailExists) {
          return false;
        }

        return validation.validate(field, 'required') && this.showValidationMessages;
      };

      self.isModelValid = function() {
        // password and email are not required for add client form
        if (self.isAddClientForm) {
              return validation.validate(this.user.FirstName, 'required')
                && validation.validate(this.user.LastName, 'required')
                && !this.emailExists;
        } else {
             return (this.user.IsNeedInvite || validation.validate(this.user.Password, 'required'))
                && validation.validate(this.user.FirstName, 'required')
                && validation.validate(this.user.LastName, 'required')
                && validation.validate(this.user.Email, 'required')
                && !this.emailExists;
        }
      };

      self.isUpdateModelValid = function () {
        // password and email are not required for add client form
        if (self.isAddClientForm) {
          return validation.validate(this.user.FirstName, 'required')
                && validation.validate(this.user.LastName, 'required')
                && !this.emailExists;
        } else {
          return validation.validate(this.user.FirstName, 'required')
                && validation.validate(this.user.LastName, 'required')
                && validation.validate(this.user.Email, 'required')
                && !this.emailExists;
        }
      };

    };

    return viewModel;
  }]);
