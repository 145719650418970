/**
 * Created on 9/28/2015.
 */
angular.module('membership')
  .factory('UserActivationViewModel', [
    'UserRegisterDomain', 'appStateConfig', '$location', 'notifySvc', 'modalDialogService', 'translationSvc',

    'SiteSettingService',
    'membership.config',
    function (UserRegisterDomain, appStateConfig, $location, notifySvc, modalDialogService, translationSvc,
              SiteSettingService,
              config) {
      function UserActivationViewModel(initialToken) {
        var self = this;

        self.setting = SiteSettingService.getInstance(config.membershipSettingKey);

        self.token = initialToken;
        self.data = {
          Email: "",
          ValidationCode: ""
        };

        self.activate = function () {
          if (self.token) {
            UserRegisterDomain.activateToken(self.token).then(self.activateSuccess, self.activateError);
          }
          else {
            UserRegisterDomain.activateUser(self.data).then(self.activateSuccess, self.activateError);
          }
        };

        self.activateSuccess = function () {
          var message = '';
          if (self.setting.data.RegistrationApprovalMustBeDoneByAdmin) {
            message = translationSvc.t('membership.msg.activationSuccessAdminApprovalNeeded');
          } else {
            message = translationSvc.t('membership.msg.activationSuccess');
          }

          modalDialogService.showAlert(message, 'Congratulation!', 'dialog-success');
          $location.path(appStateConfig.defaultPath);
        };

        self.activateError = function (error) {
          notifySvc.error({
            msg: error.data && error.data.ExceptionMessage ? error.data.ExceptionMessage : error.data || error,
            title: "Error while activating user"
          });
        };
      }

      return UserActivationViewModel;
    }]);
