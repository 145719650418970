/**
 * Created on 11/28/2015.
 */

'use strict';

angular.module('membership')
  .service('UserRegisterDomain', [
    'AjaxService', 'membership.config', '$location',
    function (AjaxService, config, $location) {
      var baseUrl = config.apiUrls.base + '/api/';


      var UserRegisterDomain = {
        register: function (user) {
          var activationBaseUrl = $location.absUrl().replace($location.url(), "/userActivation");
          return AjaxService.post(baseUrl + 'registerUser', user, {
            activationBaseUrl: activationBaseUrl
          });
        },
        approveUser: function (userId) {
          return AjaxService.post(baseUrl + 'approveUser/' + userId);
        },
        activateToken: function (token) {
          return AjaxService.post(baseUrl + 'activateUserViaToken?token=' + encodeURIComponent(token));
        },
        activateUser: function (data) {
          return AjaxService.post(baseUrl + 'activateUserViaCode', data);
        }
      };

      return UserRegisterDomain;
    }
  ]);
