'use strict';
angular.module('ticketAssignment')
	.service('companyTicketsViewModel', ['ticketAssignment.config', 'ticketAdmin', '$state',

		function (config, Tickets, $stateParams) {
			var viewModel = function (companyId) {
				console.log('\n------------- Loaded: -------------');
				console.log('Loaded company tickets view model');

                var self = this;
                self.version = "new";

				self.headers = [
					{
						name: 'Opened',
						style: 'width: 5%',
						display: 'Opened'
                    },
                    {
                        name: 'TicketId',
                        style: 'width: 4%',
                        display: 'Ticket'
                    },
					{
						name: 'Subject',
						style: 'width: 45%',
						display: 'Subject'
					},
					{
						name: 'Contact',
						style: 'width: 10%',
						display: 'Contact'
					},
					{
						name: 'Agent',
						style: 'width: 10%',
						display: 'Agent'
					},
					{
						name: 'Priority',
						style: 'width: 5%',
						display: 'Priority'
					},
					{
						name: 'Status',
						style: 'width: 10%',
						display: 'Status'
					},
					{
						name: 'DaysOld',
						style: 'width: 5%',
						display: 'Days Old'
					},
					{
						name: 'LastContact',
						style: 'width: 5%',
						display: 'Last Contact'
                    },
                    {
						name: 'LastTouchedBy',
						style: 'width: 9%',
						display: 'Last Touch'
					},
					{
						name: 'TicketScore',
						style: 'width: 5%',
						display: 'Ticket Score'
					}
				];

				self.priority = {
					Low: 1,
					Medium: 2,
					High: 3,
					Urgent: 4
				};

                self.url = new URL(window.location.href);

				self.filter = JSON.parse(localStorage.getItem('CustomerboardFilters'));
				self.filter['Id'] = companyId;
				self.filter['FilterWithShow'] = self.url.searchParams.get("show");

                self.getData = function () {
                    if (self.version == "new") {
                        Tickets.getCompanyTickets(self.filter).then(
                            function (response) {
                                console.log('Getting tickets');
                                self.tickets = response;
                                console.log(self.tickets);
                            }
                        );
                    } else {
                        Tickets.getCompanyTicketsOld(self.filter).then(
                            function (response) {
                                console.log('Getting tickets');
                                self.tickets = response;
                                console.log(self.tickets);
                            }
                        );
                    }
                }
				
                self.currentOpenSort = '';
                self.sortOpenTicketsBy = function (sortBy) {       // Sort open ticket list by attribute, 'sortBy' ie: "DaysOld"
                    if (self.currentOpenSort == sortBy) {
                        self.tickets.OpenedTickets.sort(function (a, b) { return a[sortBy] < b[sortBy] ? -1 : 1; });
                    }
                    else {
                        self.tickets.OpenedTickets.sort(function (a, b) { return a[sortBy] > b[sortBy] ? -1 : 1; });
                    }
                    self.currentOpenSort = (self.currentOpenSort == sortBy) ? '' : sortBy;
                };

                self.currentClosedSort = '';
                self.sortClosedTicketsBy = function (sortBy) {      // Sort recently closed ticket list by attribute, 'sortBy'
                    if (self.currentClosedSort == sortBy) {
                        self.tickets.RecentlyClosedTickets.sort(function (a, b) { return a[sortBy] < b[sortBy] ? -1 : 1; });
                    }
                    else {
                        self.tickets.RecentlyClosedTickets.sort(function (a, b) { return a[sortBy] > b[sortBy] ? -1 : 1; });
                    }
                    self.currentClosedSort = (self.currentClosedSort == sortBy) ? '' : sortBy;
                };


                self.toggleVersion = function () {
                    if (self.version == "new") {
                        self.version = "old";
                        console.log("Switching to old version");
                    } else {
                        self.version = "new";
                        console.log("Switching to new version");
                    }

                    self.getData();
                }

                self.getData();


            }

            return viewModel;
		}
	]);
