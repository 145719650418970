'use strict';

angular.module('membership')
  .service('MembershipLookup', [
    '$q', 'AjaxService', 'membership.config', function ($q, ajaxService, config) {

      var domain = function() {

        var cache = {};

        var loadLookupInternal = function(url) {

          var deferred = $q.defer();

          if (cache[url]) {
            deferred.resolve(cache[url]);
          } else {
            ajaxService.get(config.apiUrls.base + url).then(function(data) {
              cache[url] = data;
              deferred.resolve(data);
            });
          }

          return deferred.promise;
        };

        this.cleanerCache = function() {
          cache = {};
        };

        this.attendanceReasons = function() {
          return loadLookupInternal('/attendanceTracking/getAttendanceReasons');
        };

        this.partners = function() {
          return loadLookupInternal('/customers/getPartners');
        };

        this.vendors = function () {
          return loadLookupInternal('/customers/getVendors');
        };

        this.vendorTypes = function () {
          return loadLookupInternal('/customers/getVendorTypes');
        };

        this.projectDescriptions = function () {
          return loadLookupInternal('/customers/getProjectDescriptions');
        };

        this.observerNames = function() {
          return loadLookupInternal('/lookup/observerNames');
        };

      };

      return new domain();
    }
  ]);
