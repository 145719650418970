'use strict';

angular.module('magtac')
  .service('Status', ['AjaxService', 'magtac.config',
    function (ajaxService, config) {
      var baseUrl = config.apiUrls.base;
      var domain = {
        latest: function (customerKey) {
          return ajaxService.get(baseUrl + '/taskHistory?key=' + customerKey, null, { 'IsBackground': true });
        },

        magtacSettings: function(customerKey) {
          return ajaxService.get(baseUrl + '/CustomerSettings/GetInfo/' + customerKey);
        },

        getStatuses: function(customers) {
          return ajaxService.post(baseUrl + '/MagTacClient/getStatuses', { customers: customers });
        },

        setCustomerStatus: function (customerId, isDisabled) {
          return ajaxService.post(baseUrl + '/CustomerSettings/SetCustomerStatus', { customerId: customerId, isDisabled: isDisabled });
        },

        addIgnoreRule: function(ignoreRule) {
          return ajaxService.post(baseUrl + '/TaskHistory/addIgnoreRule', ignoreRule);
        },

        disableIgnoreRule: function(ignoreRule) {
          return ajaxService.post(baseUrl + '/TaskHistory/disableIgnoreRule', ignoreRule);
        },

        updateLibrary: function(customerKey, lib) {
          return ajaxService.post(baseUrl + '/CustomerSettings/updateLibrary?customerId=' + customerKey, lib);
        },

        setCustomerImportantFlag: function (customerId, isImportant) {
          return ajaxService.post(baseUrl + '/CustomerSettings/SetCustomerImportantFlag', { customerId: customerId, isImportant: isImportant });
        },

        saveStatusEmailRecipient: function(customerId, statusEmailREcipient) {
         return ajaxService.post(baseUrl + '/CustomerSettings/SaveStatusEmailREcipient', { customerId: customerId, statusEmailREcipient: statusEmailREcipient });
        },

        addTicket: function(ticket) {
          return ajaxService.post(baseUrl + '/SupportTicket/AddTicket', ticket);
         },
      };

      return domain;
    }
  ]);
