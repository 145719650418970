'use strict';

angular.module('magtac', [
  'magtac-config',
  'ngCookies',
  'ngSanitize',
  'ngAnimate',
  'mgcrea.ngStrap',
  'navigation',
  'ui.router',
  'common',
  'mgo-angular-wizard',
  'angularjs-dropdown-multiselect'
]);
