'use strict';

angular.module('projectsManagement')
  .service('projectChildDetailsViewModel', [
    'Projects', 'modalDialogService', '$stateParams',
    function(Projects, modalDialogService, $stateParams) {

      var viewModel = function(projectId) {

        var self = this;

        self.projectId = projectId;
        self.project = {};
        self.statuses = [
          'New', 'Assigned', 'In Progress', 'Live', 'On Hold', 'Canceled'
        ];
        self.projectsTypes = [{id: 'IMP', label: 'Improvement'}, {id: 'ADDON', label: 'Add-on'}];

        Projects.details(projectId).then(function(project) {
          angular.copy(project, self.project);
        });
      };

      return viewModel;

    }
  ]);
