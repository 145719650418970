'use strict';

angular.module('membership')
  .service('Permission', ['AjaxService', 'membership.config',
    function (AjaxService, config) {

      var base = config.apiUrls.base + '/permission';

      var permission = {
        list: []
      };

      AjaxService.get(base).then(function (permissions) {
        permission.list = permissions;
      });

      return permission;
    }]);
