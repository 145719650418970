'use strict';

angular.module('magtac')
  .service('customerStatusViewModel', [
    'Status', 'StatusPageLookup', '$q', '$filter', '$rootScope', 'magtac.config', 'modalDialogService',
    function (Status, lookup, $q, $filter, $rootScope, magtacConfig, modalDialogService) {

      var viewModel = function (companyId, timerViewModel) {

        var self = this;

        self.timerConfig = timerViewModel;

        self.ignoreRuleTypes = {
          ActiveXHours: 0,
          ActiveXTimes: 1,
          ActiveUntilDate: 2,
          ActiveUntilStatusChange: 3
        }

        self.loaded = false;
        self.mode = 'Monitor';
        self.magTacClientIsInstalled = false;
        self.localTimezone = /\((.*)\)/.exec(new Date().toString())[1];
        self.browserTimeZone = moment.tz(new Date(), moment.tz.guess()).format('Z z');

        var observersPromise = lookup.observerNames().then(function (data) {
          self.observerNames = data;
        });

        self.ignoreResult = function (observer, mode, value, userName) {
          modalDialogService
            .showDialog('components/magtac/customerStatus/ignoreRuleDialog/ignoreRuleDialog.html', 'IgnoreRuleDialogCtrl', {})
            .then(function (notes) {
              var ignoreRule = {
                RuleType: mode,
                Value: value,
                TaskConfigurationId: observer.ConfigurationId,
                ActiveUntil: mode == 'ActiveUntilDate' ? moment().add(value, 'weeks') : null,
                User: userName,
                Notes: notes,
                CompanyId: companyId
              };

              Status.addIgnoreRule(ignoreRule).then(self.reloadData);
            });
        };

        self.cancelIgnore = function (observer) {
          var ignoreRule = {
            TaskConfigurationId: observer.ConfigurationId,
            CompanyId: companyId
          };

          Status.disableIgnoreRule(ignoreRule).then(self.reloadData);
        };

        self.addSupportTicket = function(observer, userName){
          try {
            var configurationId = (observer==null||observer.ConfigurationId==null)?"No Id":observer.ConfigurationId;
            var description = (self==null || self.observerNames==null || self.observerNames[observer.Type]==null || self.observerNames[observer.Type].Description==null)?"No Description":self.observerNames[observer.Type].Description;
            var shortDetails = (observer==null||observer.ShortDetails==null)?"No Details":observer.ShortDetails;
            var userName = userName==null?"No Username":userName;
            var ticket = {
              CompanyId: companyId,
              TaskConfigurationId: configurationId,
              Subject: description,
              Detail: shortDetails,
              UserName: userName
            };
            Status.addTicket(ticket).then(function(response) {
              
              var jsonResponse = JSON.parse(response);

              if (!("id" in jsonResponse)) {
                // Failure
                var code = jsonResponse["code"];
                var message = jsonResponse["message"];
                modalDialogService.showAlert(code, message);
              } else {
                // Success
                var id = jsonResponse["id"];
                var linkStr = "https://magview.freshdesk.com/a/tickets/" + id;
                var message = "Ticket number: " + id + ".\n" + "Go to ticket?";
                
                var memo = jsonResponse["subject"];
                modalDialogService.showConfirmation(message, memo).then(function(resp) {
                  if (resp) {
                    window.open(linkStr);
                  }
                });
              }
              
            })
          } catch(err) {
            modalDialogService.showAlert("Could not create ticket", "Error - " + err.message);
          }
          
        };

        self.formattedDate = function(date) {
          if (!date)
          {
            return 'No previous success.';
          }
          var time = moment.duration(moment().diff(moment(date))).humanize();
          return time == 'a few seconds' ? 'a minute ago' : time + ' ago';
        }

        self.mergeWithTimer = function() {
          // Merge the data from the timer config with that from the status page.
          for (var timerType in self.data) {
            for (var key in self.timerConfig.timers) {
              for (var observer in self.data[timerType]) {
                if (self.data[timerType][observer].ConfigurationId == (self.timerConfig.timers)[key].Id) {
                  self.data[timerType][observer].timerDetails = (self.timerConfig.timers)[key];
                }
              }
            }
          }
        }

        self.reloadAllData = function() {
          self.timerConfig.reload(self);
        }

        self.reloadData = function () {         

          $rootScope.$broadcast(magtacConfig.events.statusReloading, {});

          var statusPromise = Status.latest(companyId).then(function (resp) {

            self.magTacClientIsInstalled = true;
            self.hasHistoryRecords = resp.length > 0;

            self.statusInfo = resp;

            if (self.hasHistoryRecords && self.statusInfo[0].Status == 'Disabled') {
              self.overallStatus = 'Disabled';
              self.statusInfoUpdateTime = self.statusInfo[0].ExecutionDateTime;
              self.statusInfo = [];
              self.loaded = true;
              return;
            }

            _.each(resp, function (t) {
              if(!self.serverTimeZone && t.ServerTimeZoneOffset){
                var zoneAbbrs = [];
                var serverOffset = moment(new Date()).utcOffset(t.ServerTimeZoneOffset)._offset * -1;
                var zoneNames = moment.tz.names().filter(function(zoneName){
                  return zoneName.indexOf("America") > -1;
                });  

                zoneNames.forEach(function(zoneName){
                  var zone = moment.tz(zoneName)._z;
                  if(zone.offsets.some(function(offset){
                    return offset == serverOffset;
                  })){
                    var offsetindex = zone.offsets.indexOf(serverOffset);
                    if(zoneAbbrs.indexOf(zone.abbrs[offsetindex]) == -1){
                      zoneAbbrs.push(zone.abbrs[offsetindex]);              
                    }              
                    return;
                  }
                });
                var output = [t.ServerTimeZoneOffset.slice(0, t.ServerTimeZoneOffset.length-2), ':', t.ServerTimeZoneOffset.slice(t.ServerTimeZoneOffset.length -2)].join('');
                self.serverTimeZone = output + " " + zoneAbbrs.join('/');
              }

              t.DetailsDateTime = new Date(t.DetailsDateTime);

              t.ExecutionDateTime = new Date(t.ExecutionDateTime);

              if(t.ServerTimeZoneOffset){
                var currentOffset = moment.tz(new Date(), moment.tz.guess())._offset;
                var serverOffset = moment(t.ExecutionDateTime).utcOffset(t.ServerTimeZoneOffset)._offset;
                var resultOffset = -1 * (currentOffset - serverOffset);
                if(resultOffset == 0){
                  t.ExecutionDateTimeOnServer = moment(t.ExecutionDateTime).format('MMMM, DD YYYY hh:mm:ss A');
                }else {
                  var serverDate = moment(t.ExecutionDateTime).utcOffset(resultOffset)._d;
                  t.ExecutionDateTimeOnServer = serverDate;
                }               
              }
              
              if (t.IgnoreRule) {
                t.IgnoreRule.DateCreated = new Date(t.IgnoreRule.DateCreated);
                if (t.IgnoredUntil) {
                  t.IgnoredUntil = new Date(t.IgnoredUntil);
                }
              }
              if (t.NextPlannedRun) {
                t.NextPlannedRun = new Date(t.NextPlannedRun);
              }
              if (t.ShortDetails) {
                // date is separated from text to show in correct time zone
                t.ShortDetails = t.ShortDetails.replace('{0}', $filter('date')(t.DetailsDateTime, 'medium'));
              }
            });

            self.statusInfoUpdateTime = _.max(resp, function (t) {
              return t.ExecutionDateTime.getTime();
            }).ExecutionDateTime;

            self.overallStatusExpired = _.some(resp, function (t) {
              return t.IsExpired && !t.IsIgnored && t.Status != 'Error';
            });

            //else{
            var isNotDefined = !_.some(resp, function (t) {
              return t.Status != 'Waiting';
            })
            if (isNotDefined) {
              self.overallStatus = "Waiting";
            }
            else {
              var overallStatusError = _.some(resp, function (t) {
                return t.Status == 'Error' && !t.IsIgnored;
              });
              var overallStatusWarning = _.some(resp, function (t) {
                return t.Status == 'Warning' && !t.IsIgnored;
              });

              if (self.overallStatusExpired){
                self.overallStatus = 'Expired';
              } else if (overallStatusError) {
                self.overallStatus = 'Danger';
              } else if (overallStatusWarning) {
                self.overallStatus = 'Warning';
              }
               else {
                self.overallStatus = 'Success';
              }
            }
            //}
            self.loaded = true;
          }, function () {
            self.loaded = true;
          });

          $q.all([statusPromise, observersPromise]).then(function () {
            self.data = _.groupBy(self.statusInfo, function (t) {
              return t.Category;
            })
            
            self.mergeWithTimer();

            if (self.data.Task && self.data.Task.length) {

              self.data.Task = _.groupBy(self.data.Task, function (t) {
                var appName = ((self.observerNames[t.Type] || {}).ApplicationName || "General");
                return appName;
              });
            }

            if (self.data.Monitor && self.data.Monitor.length) {
              
              self.data.Monitor = _.groupBy(self.data.Monitor, function (t) {
                var appName = ((self.observerNames[t.Type] || {}).ApplicationName || "General");
                return appName;
              });

            }
          });

        };

        self.reloadData();
      };

      return viewModel;

    }
  ]);
