'use strict';

angular.module('projectsManagement')
  .factory('notesListViewModel', [
    'modalDialogService',
    'common.config',
    'AjaxService',
    'notifySvc',
    'projectsManagement.config',
    function (modalDialogService,
              commonConfig,
              ajaxService,
              notifySvc,
              config) {      

      function notesListViewModel(masterId) {
        var baseUrl = config.apiUrls.base + '/projectNotes';
        var self = this;
        self.masterId = masterId;

        self.datatablesConfig = {
          datasourceUrl: baseUrl + '/' + masterId + '/DataTables',// + '/datatables',
          columns: [
            {
              data: "Details",
              sortable: false,
            },

            {
              "data": "Id",
              "width": "100px",
              title: '',
              searchable: false,
              sortable: false,
              "render": function (data, type, row) {
                return '<button ng-click="vm.editNote(' + data + '); $event.stopPropagation();" class="btn btn-info btn-xs" permission="Projects.Notes.NotesEdit">' +
                  '<i class="fa fa-edit"></i>' +
                  '</button>&nbsp;' +
                  '<button ng-click="vm.deleteNote(' + data + '); $event.stopPropagation();" class="btn btn-danger btn-xs" permission="Projects.Notes.NotesEdit">' +
                  '<i class="fa fa-times"></i>' +
                  '</button>';
              }
            }
          ],
          aaSorting: [[-2, 'desc']]
        };

        self.createNote = function () {
          showWindow(null);
        };

        self.editNote = function (noteId) {
          showWindow(noteId);
        };

        self.deleteNote = function (noteId) {
          modalDialogService.showConfirmation('Are you sure want to delete this note?', 'Delete note confirmation', 'dialog-danger').then(function (result) {
            if (result) {
              ajaxService.delete(baseUrl + '/' + noteId).then(function () {
                 notifySvc.success({
                  msg: "Note deleted successfully"
                });
              }).finally(self.reloadData);
            }
          });
        };

        self.reloadData = function () {
          self.datatablesConfig.table.reload();
        };

        function showWindow(noteId) {
          modalDialogService.showWindow('components/projectsManagement/details/notes/editDialog/noteEditDialog.html',
            'NoteEditCtrl', {
              $$masterId: self.masterId,
              $$noteId: noteId
            }).finally(self.reloadData);
        }
      }

      return notesListViewModel;
    }
  ]);
