'use strict';

angular.module('common')
.directive('quartzCronSelection', [
    'cronService',
    function(cronService) {
        return {
            restrict: 'EA',
            replace: true,
            transclude: true,
            scope: {
                config   : '=',
                output   : '=?',
                init     : '=?',
                timezone : '=?',
                mode     : "=?"
            },
            templateUrl: function(element, attributes) {
              return attributes.template || 'components/common/templates/cron-selection.tpl.html';
            },
            link: function($scope) {

                var originalInit = undefined;
                var initChanged = false;

                $scope.frequency = [
                    {
                      value : 1,
                      label : 'Minutely '  
                    },
                    {
                      value : 2,
                      label : 'Hourly'  
                    },
                    {
                      value : 3,
                      label : 'Daily'  
                    },
                    {
                      value : 4,
                      label : 'Weekly'  
                    },
                    {
                      value : 5,
                      label : 'Monthly'  
                    },
                    {
                      value : 6,
                      label : 'Yearly'  
                    }
                ];
                $scope.frequencyLabels = {
                  1 : 'minute(s)',
                  2 : 'hour(s)' ,
                  3 : 'day(s)' ,
                  4 : 'week(s)',
                  5 : 'month(s)',
                  6 : 'year(s)'  ,
                };

                $scope.timezone = typeof $scope.timezone == 'undefined' 
                    ? (new Date().getTimezoneOffset() / 60)
                    : parseInt($scope.timezone);
                $scope.getRepeatingInverval = function() { 
                    switch ($scope.myFrequency.base) {
                        case 1: return _.union([1], _.rest($scope.minuteValue));                            
                        case 2: return _.rest($scope.hourValue);
                        case 3: return $scope.dayOfMonthValue;
                        //case 4: return [];
                        case 5: return $scope.monthValue;
                        //case 6: return [1, 2, 3, 4, 5];
                        default: return [];
                    }
                }

                if (angular.isDefined($scope.init)) {
                    //console.log('init value found: ', $scope.init);
                    originalInit = angular.copy($scope.init);
                    originalInit = originalInit || "";
                    $scope.myFrequency = cronService.fromCron(angular.copy(originalInit), $scope.timezone);
                }

                $scope.$watch('init', function(newValue){
                    //console.log('watch on init fired!', newValue, originalInit);
                    if(angular.isDefined(newValue) && newValue && (newValue !== originalInit)){
                        initChanged = true;
                        $scope.myFrequency = cronService.fromCron(newValue, $scope.timezone);
                    }
                });

                if(typeof $scope.config === 'object' && !$scope.config.length){
                    var optionsKeyArray = Object.keys($scope.config.options);
                    for (var i in optionsKeyArray) {
                        var currentKey = optionsKeyArray[i].replace(/^allow/, '');
                        var originalKey = optionsKeyArray[i];
                        if(!$scope.config.options[originalKey]){
                            for(var b in $scope.frequency){
                                if($scope.frequency[b].label === currentKey){
                                    $scope.frequency.splice(b, 1);
                                }
                            }
                        }
                    }
                }

                $scope.minuteValue = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
                $scope.hourValue = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
                $scope.dayOfMonthValue = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
                $scope.dayValue = [1, 2, 3, 4, 5, 6, 7];
                $scope.monthValue = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

                $scope.$watch('myFrequency', function(n, o){
                    //console.log('myFrequency changed: ', n, initChanged);
                    if(n && (!o || n.base !== o.base) && !initChanged){
                        //console.log('base changed!', n, o);
                        if(n && n.base){
                            n.base = parseInt(n.base);
                        }

                        n.repeating = 1;
                        if(n && n.base && n.base >= 2) {
                            n.minuteValue = $scope.minuteValue[0];
                        }

                        if(n && n.base && n.base >= 3) {
                            n.hourValue = $scope.hourValue[0];
                        }

                        if(n && n.base && n.base === 4) {
                            n.dayValue = $scope.dayValue[0];
                        }

                        if(n && n.base && n.base >= 5) {
                            n.dayOfMonthValue = $scope.dayOfMonthValue[0];
                        }

                        if(n && n.base && n.base === 6) {
                            n.monthValue = $scope.monthValue[0];
                        }
                    } else if(n && n.base && o && o.base){
                        initChanged = false;
                    }
                    $scope.output = cronService.setCron(n, false, $scope.timezone);
                }, true);
            }
        };
    }
])
.filter('aqc_numeral', function() {
    return function(input) {
        switch (input) {
            case 1:
                return '1st';
            case 2:
                return '2nd';
            case 3:
                return '3rd';
            case 21:
                return '21st';
            case 22:
                return '22nd';
            case 23:
                return '23rd';
            case 31:
                return '31st';
            case null:
                return null;
            default:
                return input + 'th';
        }
    };
}).filter('aqc_monthName', function() {
    return function(input) {
        var months = {
            1: 'January',
            2: 'February',
            3: 'March',
            4: 'April',
            5: 'May',
            6: 'June',
            7: 'July',
            8: 'August',
            9: 'September',
            10: 'October',
            11: 'November',
            12: 'December'
        };

        if (input !== null && angular.isDefined(months[input])) {
            return months[input];
        } else {
            return null;
        }
    };
}).filter('aqc_dayName', function() {
    return function(input) {
        var days = {
            1: 'Sunday',
            2: 'Monday',
            3: 'Tuesday',
            4: 'Wednesday',
            5: 'Thursday',
            6: 'Friday',
            7: 'Saturday',
        };

        if (input !== null && angular.isDefined(days[input])) {
            return days[input];
        } else {
            return null;
        }
    };
});