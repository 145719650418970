// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `announcement {
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  -moz-animation-name: dropHeader;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: ease-in;
  -moz-animation-duration: 2s;
  -webkit-animation-name: dropHeader;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
  -webkit-animation-duration: 2s;
  animation-name: dropHeader;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}
announcement div {
  background: red;
  border-radius: 6px;
  margin: 6px;
  padding: 8px 40px 8px 20px;
  font-size: 20px;
  opacity: 0.8;
  color: #fff;
  text-align: center;
}
announcement div a,
announcement div a:hover {
  color: #fff;
}
announcement div a:hover {
  text-decoration: underline;
}
announcement button {
  color: #FFF;
  background: transparent;
  border: none;
  position: absolute;
  top: 12px;
  right: 12px;
}
announcement button i.fa {
  font-size: 20px;
}
announcement button:hover i.fa {
  font-size: 22px;
}
@keyframes dropHeader {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/common/styles/announcement.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,UAAU;EACV,MAAM;EACN,OAAO;EACP,+BAA+B;EAC/B,iCAAiC;EACjC,uCAAuC;EACvC,2BAA2B;EAC3B,kCAAkC;EAClC,oCAAoC;EACpC,0CAA0C;EAC1C,8BAA8B;EAC9B,0BAA0B;EAC1B,4BAA4B;EAC5B,kCAAkC;EAClC,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,WAAW;EACX,0BAA0B;EAC1B,eAAe;EACf,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB;AACA;;EAEE,WAAW;AACb;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,WAAW;EACX,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,wBAAwB;EAC1B;AACF","sourcesContent":["announcement {\n  width: 100%;\n  position: fixed;\n  z-index: 1;\n  top: 0;\n  left: 0;\n  -moz-animation-name: dropHeader;\n  -moz-animation-iteration-count: 1;\n  -moz-animation-timing-function: ease-in;\n  -moz-animation-duration: 2s;\n  -webkit-animation-name: dropHeader;\n  -webkit-animation-iteration-count: 1;\n  -webkit-animation-timing-function: ease-in;\n  -webkit-animation-duration: 2s;\n  animation-name: dropHeader;\n  animation-iteration-count: 1;\n  animation-timing-function: ease-in;\n  animation-duration: 2s;\n}\nannouncement div {\n  background: red;\n  border-radius: 6px;\n  margin: 6px;\n  padding: 8px 40px 8px 20px;\n  font-size: 20px;\n  opacity: 0.8;\n  color: #fff;\n  text-align: center;\n}\nannouncement div a,\nannouncement div a:hover {\n  color: #fff;\n}\nannouncement div a:hover {\n  text-decoration: underline;\n}\nannouncement button {\n  color: #FFF;\n  background: transparent;\n  border: none;\n  position: absolute;\n  top: 12px;\n  right: 12px;\n}\nannouncement button i.fa {\n  font-size: 20px;\n}\nannouncement button:hover i.fa {\n  font-size: 22px;\n}\n@keyframes dropHeader {\n  0% {\n    transform: translateY(-100%);\n  }\n  100% {\n    transform: translateY(0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
