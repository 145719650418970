'use strict';

angular.module('ticketAssignment')
    .controller('agentTicketsControlModel', ['$scope', '$stateParams', 'agentTicketsViewModel',

        function ($scope, $stateParams, viewModel) {
            $scope.model = new viewModel($stateParams.id);


            $scope.get_ticket_link = function (TicketId) {
                return 'https://magview.freshdesk.com/a/tickets/' + TicketId;
            }

            $scope.get_team_link = function (teamName) {
                return '/team/' + teamName + '/tickets';
            }

            $scope.get_company_link = function (CompanyId) {
                if (CompanyId !== undefined && CompanyId !== null) {
                    return "/customer/" + CompanyId + '/general';
                } else {
                    return "/";
                }
            }

            $scope.get_freshdesk_link = function (agentId) {
                if (agentId !== undefined && agentId !== null) {
                    return "https://magview.freshdesk.com/a/admin/agents/" + agentId;
                } else {
                    return "/";
                }
            }

            $scope.showDetails = function (agentId) {
                toggleDisplay(document.querySelectorAll(".note"));
            }

            $scope.shorten_note = function (noteText) {
                if (noteText != null) {
                    return noteText.substring(0, 120);
                }
                return noteText;
            }

            $scope.getColorClassForTicket = function (ticket, headerName) {
                // console.log("getClassForTicket called with", ticket, headerName);
                // if ticket is from another team
                if (!ticket.IsAgentInTeam && headerName == 'CompanyName') {
                    return ticket.Priority == 'Urgent' ? 'urgentNotFromTeam' : 'notFromTeam';
                }
                return 'normal';
            };

            $scope.getTicketUrgencyClass = function (ticket) {
                if (ticket.Priority == "Urgent") {
                    return 'urgent';
                } else {
                    return 'normal';
                }
            };

            function toggleDisplay(notes) {

                for (var i = 0; i < notes.length; i++) {
                    if (notes[i].style.display === "none" || notes[i].style.display == null || notes[i].style.display == "") {
                        notes[i].style.display = "table-row";
                    } else {
                        notes[i].style.display = "none";
                    }
                }
            }
        }
    ]);