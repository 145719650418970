/**
 * Created on 10/24/2015.
 */

'use strict';

angular.module('membership')
  .factory('accountProfileViewModel', [
    'ProfileDomain', 'ProfilePropertiesDomain', 'AjaxService', 'notifySvc', 'membership.config',
    function (profileDomain, profilePropertiesDomain, AjaxService, notifySvc, membershipConfig) {
      function accountProfileViewModel(userId) {
        var self = this;
        var baseUrl = membershipConfig.apiUrls.base;

        self.userId = userId;
        self.data = null;
        self.BirthMonth = null;
        self.BirthDate = null;
        self.BirthYear = moment().subtract(80, 'years').toDate().getFullYear();
        self.birthStartYear = new Date().getFullYear() - 70;
        self.birthEndYear = new Date().getFullYear() - 10;
        self.extendedProperties = {};
        self.uploading = false;

        self.syncBirthday = function () {
          self.data.Birthday = moment(self.BirthYear + '/' + self.BirthMonth + '/' + self.BirthDate, 'YYYY/MM/DD').toDate();
        };

        self.loadProfile = function () {
          var getProfilePromise = self.userId ? profileDomain.getProfile(userId) : profileDomain.getMyProfile();
          var getExtendedPropertyForUser = self.userId ? profilePropertiesDomain.getForUser(self.userId) : profilePropertiesDomain.getForCurrentUser();

          AjaxService.all([getProfilePromise.then(function (data) {
            self.data = data;
            self.BirthYear = moment(self.data.Birthday).format('YYYY');
            self.BirthMonth = moment(self.data.Birthday).format('M');
            self.BirthDate = moment(self.data.Birthday).format('DD');
          }),
            getExtendedPropertyForUser.then(function (data) {
              self.extendedProperties = data;
            })]);
        };

        self.onFileSelected = function () {
          self.uploadFile(self.file);
        };

        self.uploadFile = function ($file) {
          self.uploading = true;

          AjaxService.upload(baseUrl + '/api/uploadphoto', $file).then(function (data) {
            self.data.ProfilePhotoUrl = data.FileUrl;
          }).finally(function () {
            self.uploading = false;
          });
        };

        self.save = function () {
          var updateMyProfile = self.userId ? profileDomain.updateProfile(self.userId, self.data) : profileDomain.updateMyProfile(self.data);

          updateMyProfile.then(function () {
            notifySvc.success({
              i18msg: 'membership.msg.saveProfileSuccess'
            });
          }, function () {
            notifySvc.error({
              msg: "Save profile error"
            });
          });
        };

        self.loadProfile();
      }

      return accountProfileViewModel;
    }
  ]);
