/**
 * Created on 10/24/2015.
 */

'use strict';

angular.module('membership')
  .service('ProfilePropertiesDomain', [
    'membership.config', 'AjaxService', 'StorageService', '$q',
    function (config, AjaxService, StorageService, $q) {
      var baseUrl = config.apiUrls.base + '/api/userProfileProperty';

      function createPropertyTypeStorageValue(propertyTypes) {
        return {
          value: propertyTypes,
          validTo: moment().add(60, 'minutes')
        }
      }

      var ProfilePropertiesDomain = {
        getAll: function () {
          return AjaxService.get(baseUrl);
        },
        getPropertiesForRole: function (roleId) {
          return AjaxService.get(baseUrl + '?roleId=' + roleId);
        },
        getAllPropertyType: function () {
          var propertyStorageName = "PROFILE_PROPERTIES";
          var defer = $q.defer();
          var propertyTypesValue = StorageService.retrieve(propertyStorageName);

          if (propertyTypesValue && new Date(propertyTypesValue.validTo) > new Date()) {
            defer.resolve(propertyTypesValue.value);
          }
          else {
            AjaxService.get(baseUrl + '/propertyTypes').then(function (response) {
              StorageService.store(propertyStorageName, createPropertyTypeStorageValue(response));
              defer.resolve(response);
            }, function (error) {
              defer.reject(error);
            });
          }
          return defer.promise;
        },
        getForCurrentUser: function () {
          return AjaxService.get(baseUrl + '/myprofile');
        },
        getForUser: function (userId) {
          return AjaxService.get(baseUrl + '/' + userId + '/profile');
        },
        create: function (propertyData) {
          return AjaxService.post(baseUrl, propertyData);
        },
        assignToRole: function (propertyId, roleId) {
          return AjaxService.post(baseUrl + '/' + propertyId + '/assignToRole/' + roleId);
        },
        unassignFromRole: function (propertyId, roleId) {
          return AjaxService.delete(baseUrl + '/' + propertyId + '/assignToRole/' + roleId);
        }
      };

      return ProfilePropertiesDomain;
    }
  ]);
