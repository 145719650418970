'use strict';

angular.module('ticketAssignment-config', [])
    .provider('ticketAssignment.config', function () {
        this.config = {
            apiUrls: {
                base: '/ticketAssignment.api'
            }
        };

        var self = this;

        this.$get = function () {
            return self.config;
        };
        return this;
    });