'use strict';

angular.module('magtac')
  .service('TaskHistory', ['AjaxService', 'magtac.config',
    function (ajaxService, config) {
      var baseUrl = config.apiUrls.base + '/taskHistory';
      var domain = {
        get: function (id) {
          return ajaxService.get(baseUrl + '/' + id);
        }
      };

      return domain;
    }
  ]);
