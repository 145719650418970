/**
 * Created on 10/1/2015.
 */

angular.module('membership')
  .controller('ClientCompanyEditController', [
    '$scope', 'clientCompanyEditViewModel', '$modalInstance', 'clientCompanyId',
    function($scope, clientCompanyEditViewModel, modalInstance, clientCompanyId) {

      $scope.model = new clientCompanyEditViewModel(modalInstance, clientCompanyId);
    }
  ]);
