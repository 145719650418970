angular.module('common')
  .directive('announcement', [
    '$rootScope', '$sce', 'AnnouncementService',
    function ($rootScope, $sce, announcementsSvc) {
      return {
        restrict: 'E',
        templateUrl: function(element, attributes) {
          return attributes.templateUrl || 'components/common/templates/announcement.tpl.html';
        },
        link: function(scope, element, attrs) {
          scope.vm = {
            close: function() {
              scope.vm.isClosed = true;
              announcementsSvc.logViewed(scope.vm.item.id);
            }
          };

          var replaceUrlWithHtmlLinks = function(text) {
            var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;
            return text.replace(exp, "<a href='$1' target='_blank'>$1</a>");
          };

          // announcementsSvc.getLatestCard().then(function (data) {
          //   if (data) {
          //     scope.vm.item = data;
          //     scope.vm.message = $sce.trustAsResourceUrl(replaceUrlWithHtmlLinks(data.name + " " + data.desc));
          //   }
          // });
        }
      };
    }
  ]);