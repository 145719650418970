'use strict';

angular.module('common')
.factory('cronService', function() {
    var service = {};

    service.setCron = function(n, keepLocalTime, timezone) {
        //  console.log('set cron called: ', n);
        var cron = ['0', '*', '*',  '*',  '*', '?'];

        n = angular.copy(n);
        if (typeof n.hourValue !== 'undefined' && !keepLocalTime) {
          service.handleTimezone(n, false, timezone);
        }

        if (n && n.base && n.base == 1 && n.repeating > 1) {
          cron[1] = n.repeating > 1 ? '0/' + n.repeating : '*';
        }

        if(n && n.base && n.base >= 2) {
            cron[1] = typeof n.minuteValue !== 'undefined' ? n.minuteValue : '0';
            if(n.base == 2 && n.repeating > 1) {
              cron[2] = '0/' + n.repeating;
            }
        }

        if(n && n.base && n.base >= 3) {
            cron[2] = typeof n.hourValue !== 'undefined' ? n.hourValue  : '*';
            if(n.base == 3 && n.repeating > 1) {
              cron[3] = '1/' + n.repeating;
            }
        }

        if(n && n.base && n.base === 4) {
            cron[3] = "?";
            cron[5] = n.dayValue;
        }

        if(n && n.base && n.base >= 5) {
            cron[3] = typeof n.dayOfMonthValue !== 'undefined' ? n.dayOfMonthValue : '?';
            if(n.base == 5 && n.repeating > 1) {
              cron[4] = '1/' + n.repeating;
            }
        }

        if(n && n.base && n.base === 6) {
            cron[4] = typeof n.monthValue !== 'undefined' ? n.monthValue : '*';
        }
        //  console.log('cron after setCron ', cron.join(' '));
        return cron.join(' ');
    };

    service.fromCron = function(value, timezone) { 
        //  console.log('set cron fired!');
       var cron = value.replace(/\s+/g, ' ').split(' ');
       var frequency = {base: '1', repeating: 1}; // default: every minute
       if((cron[1] === '*' || cron[1].indexOf('/') >= 0) && cron[2] === '*' && cron[3] === '*'  && cron[4] === '*' && cron[5] === '?') {
           frequency.base = 1; // every minute           
       } else if((cron[2] === '*' || cron[2].indexOf('/') >= 0) && cron[3] === '*'  && cron[4] === '*' && cron[5] === '?') {
           frequency.base = 2; // every hour
       } else if((cron[3] === '*' || cron[3].indexOf('/') >= 0)  && cron[4] === '*' && cron[5] === '?') {
           frequency.base = 3; // every day
       } else if(cron[3] === '?') {
           frequency.base = 4; // every week
       } else if((cron[4] === '*' || cron[4].indexOf('/') >= 0) && cron[5] === '?') {
           frequency.base = 5; // every month
       } else if(cron[5] === '?') {
           frequency.base = 6; // every year
       }

       // console.log('frequency should be 5: ', frequency, cron);
       if (cron[1] !== '*') {
          if (cron[1].indexOf('/') >= 0) {
            frequency.repeating =  parseInt(cron[1].split('/')[1]);
          } else {
           frequency.minuteValue = parseInt(cron[1]);
          }
       }
       if (cron[2] !== '*') {
          if (cron[2].indexOf('/') >= 0) {
            frequency.repeating =  parseInt(cron[2].split('/')[1]);
          } else {
            frequency.hourValue = parseInt(cron[2]);
          }
       }
       if (cron[3] !== '*' && cron[3] !== '?') {
          if (cron[3].indexOf('/') >= 0) {
            frequency.repeating =  parseInt(cron[3].split('/')[1]);
          } else {
           frequency.dayOfMonthValue = parseInt(cron[3]);
          }
       }
       if (cron[4] !== '*') {
          if (cron[4].indexOf('/') >= 0) {
            frequency.repeating =  parseInt(cron[4].split('/')[1]);
          } else {
           frequency.monthValue = parseInt(cron[4]);
          }
       }
       if (cron[5] !== '*' && cron[5] !== '?') {
          if (cron[5].indexOf('/') >= 0) {
            frequency.repeating =  parseInt(cron[5].split('/')[1]);
          } else {
            frequency.dayValue = parseInt(cron[5]);
          }
       }

       if (typeof frequency.hourValue !== 'undefined') {
          service.handleTimezone(frequency, true, timezone);
        }
       //frequency.base += ''; // 'cast' to string in order to set proper value on "every" modal

       // console.log('freq ', frequency);
       return frequency;
    };

    service.handleTimezone = function(n, backToLocal, timezone) {
      var direction = backToLocal ? 1 : -1;

          n.hourValue = n.hourValue + direction * timezone;

          // remove day 
          if (n.hourValue < 0) {
            n.hourValue += 24;

            // handle day of week
            if (typeof n.dayValue !== 'undefined') {
              n.dayValue--;
              if (n.dayValue < 1) {
                n.dayValue = 7;
              }
            } 
            // handle day of month
            else if (typeof n.dayOfMonthValue !== 'undefined') {
              n.dayOfMonthValue--;
              if (n.dayOfMonthValue < 1) {
                n.dayOfMonthValue = 31;

                if (typeof n.monthValue !== 'undefined') {
                  n.monthValue--;
                  if (n.monthValue < 1) {
                    n.monthValue = 12;
                  }
                }
              }
            }
          }

          // add day
          if (n.hourValue > 23) {
            n.hourValue -= 24;

            // handle day of week
            if (typeof n.dayValue !== 'undefined') {
              n.dayValue += 1;
              if (n.dayValue > 7) {
                n.dayValue = 1;
              }
            }
            // handle day of month
            else if (typeof n.dayOfMonthValue !== 'undefined') {
              n.dayOfMonthValue++;
              if (n.dayOfMonthValue > 31) {
                n.dayOfMonthValue = 1;

                if (typeof n.monthValue !== 'undefined') {
                  n.monthValue++;
                  if (n.monthValue > 12) {
                    n.monthValue = 1;
                  }
                }
              }
            }
          }
    };

    service.convertCronToLocalTime = function(cron) {
        var timezone = new Date().getTimezoneOffset() / 60;
        var localCronObject = service.fromCron(cron, timezone);
        if (localCronObject.dayValue) {
          localCronObject.dayValue--;
        }

        
        var localCronString = service.setCron(localCronObject, true, timezone);
        return localCronString;
    }

    service.convertCronToServerTime = function(cron, timezone) {
        var localCronObject = service.fromCron(cron, timezone);
        if (localCronObject.dayValue) {
          localCronObject.dayValue--;
        }
        var localCronString = service.setCron(localCronObject, true, timezone);
        return localCronString;
    }

   
   return service;
});
