angular.module('membership')
  .factory('clientCompanyEditViewModel', [
    'ClientCompanyDomain', 'validation', 'toastr', '$translate',
    function (ClientCompanyDomain, validation, toastr, $translate) {

      function clientCompanyEditViewModel(modalInstance, clientCompanyId) {
        var self = this;

        self.clientCompanyId = clientCompanyId;
        self.modalInstance = modalInstance;
        self.clientCompanyData = {};
        self.clientCompanyData.Name = '';
        self.clientCompanyData.Address = '';
        self.clientCompanyData.PhoneNumber = '';
        self.clientCompanyData.MainContactUser = {
          FirstName: "",
          LastName: "",
          Email: "",
          CellPhoneNumber: "",
          Password: "",
          RepeatPassword: "",
          Address: ""
        };

        self.showValidationMessages = false;
        self.isLoading = false;
        self.loadClientCompanyData = function () {
          self.isLoading = true;
          ClientCompanyDomain.getById(self.clientCompanyId).then(function (data) {
            self.clientCompanyData = data;
            if (!self.clientCompanyData.MainContactUser) {
              self.clientCompanyData.MainContactUser = self.clientCompanyData.Owner;
            }
          }).finally(function () {
            self.isLoading = false;
          });
        };


        this.loadClientCompanyData();
      }

      clientCompanyEditViewModel.prototype = {

        showValidation: function (field) {
          return validation.validate(field, 'required') && this.showValidationMessages;
        },

        isModelValid: function () {
          var self = this;
          return validation.validate(self.clientCompanyData.Name, 'required')
            && validation.validate(self.clientCompanyData.Address, 'required')
            && validation.validate(self.clientCompanyData.PhoneNumber, 'required')
            && validation.validate(self.clientCompanyData.MainContactUser.FirstName, 'required')
            && validation.validate(self.clientCompanyData.MainContactUser.LastName, 'required')
            && validation.validate(self.clientCompanyData.MainContactUser.Email, 'required');
        },

        save: function () {
          this.showValidationMessages = true;
          var self = this;
          if (self.isModelValid()) {
            self.showValidationMessages = false;
            self.isLoading = true;
            ClientCompanyDomain.update(self.clientCompanyId, self.clientCompanyData).then(function (data) {
              toastr.success($translate.instant('membership.msg.clientCompanyUpdatedSuccess'), $translate.instant('membership.lbl.editClientCompany'));
              self.modalInstance.close();
            }, function (data) {
              toastr.error($translate.instant('membership.msg.clientCompanyUpdateError'), $translate.instant('membership.lbl.editClientCompany'));
            }).finally(function () {
              self.isLoading = false;
            });
          }
        }
      };

      return clientCompanyEditViewModel;
    }]);
