'use strict';

angular.module('membership')
  .service('newCustomerViewModel', [
    'ManageCompanies', 'modalDialogService', '$stateParams', '$state',
    function(manageCompanies, modalDialogService, $stateParams, $state) {

      var viewModel = function(modalInstance) {

        var self = this;

        self.company = {};
        
        self.createCompany = function () {
          manageCompanies.createCompany(self.company, function (data) {
            modalInstance.close();
            $state.go('membership_customer.general', {key: data.Id});
          });
        }
      };

      return viewModel;

    }
  ]);
