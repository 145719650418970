/**
 * Created on 10/26/2015.
 */

'use strict';

angular.module('common')
  .service('StorageService', [
    'store',
    function (store) {
      var StorageService = {
        retrieve: function (storageName) {
          return store.get(storageName);
        },
        remove: function (storageName) {
          return store.remove(storageName);
        },
        store: function (storageName, storageValue) {
          store.set(storageName, storageValue);
        }
      };

      return StorageService;
    }

  ]);
