'use strict';

angular.module('projectsManagement-config', [])
  .provider('projectsManagement.config', function () {
    this.config = {
      apiUrls: {
        base: '/projects.api'
      },
      settings: {
        projectManagementSetting: 'projectManagement.settings'
      },
      permissions: {
        projectView: 'Projects.ProjectsView',
        projectEdit: 'Projects.ProjectsEdit',
        projectNotesView: 'Projects.Notes.NotesView',
        projectNotesEdit: 'Projects.Notes.NotesEdit',
        projectExpensesView: 'Projects.Expenses.ExpensesView',
        projectExpensesEdit: 'Projects.Expenses.ExpensesEdit',
        projectDocumentsView: 'Projects.Documents.DocumentsView',
        projectDocumentsEdit: 'Projects.Documents.DocumentsEdit'
      }
    };

    var self = this;

    this.$get = function () {
      return self.config;
    };
    return this;
  });
