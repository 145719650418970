/**
 * Created on 8/20/2015.
 */

angular.module('common')
  .service('modalDialogService', [
    '$modal', '$rootScope', '$controller', '$q',
    function ($modal, $rootScope, $controller, $q) {

      return {
        showAlert: function (message, title, headerClass) {
          var defer = $q.defer();

          var scope = $rootScope.$new();

          scope.model = {
            headerText: title,
            bodyText: message,
            headerClasses: headerClass
          };

          var modalConfiguration = {
            template: 'components/common/templates/alert.tpl.html',
            persist: true,
            show: true,
            backdrop: 'static',
            modalClass: 'modal-window',
            scope: scope
          };
          var modalInstance = $modal(modalConfiguration);

          scope.modalInstance = modalInstance;

          scope.close = function () {
            modalInstance.hide();
            defer.resolve(true);
          };

          return defer.promise;
        },
        showConfirmation: function (message, title, headerClass) {
          var defer = $q.defer();

          var scope = $rootScope.$new();

          scope.title = title;
          scope.message = message;
          scope.headerClass = headerClass;

          var modalConfiguration = {
            template: 'components/common/templates/confirmation.tpl.html',
            persist: true,
            show: true,
            backdrop: 'static',
            modalClass: 'modal-window',
            scope: scope
          };
          var modalInstance = $modal(modalConfiguration);

          scope.ok = function () {
            modalInstance.hide();
            defer.resolve(true);
          };

          scope.cancel = function () {
            modalInstance.hide();
            defer.resolve(false);
          };

          return defer.promise;
        },
        showDialog: function (templatePath, controller, resolutions) {
          var defer = $q.defer();

          var scope = $rootScope.$new();
          resolutions.$scope = scope;

          var modalConfiguration = {
            template: templatePath,
            persist: true,
            show: true,
            backdrop: 'static',
            modalClass: 'modal-window'
          };

          modalConfiguration.scope = scope;
          var modalInstance = $modal(modalConfiguration);
          resolutions.$modalInstance = modalInstance;
          $controller(controller, resolutions);

          modalInstance.destroy = function () {
            modalInstance.hide();
            defer.reject();
          };

          modalInstance.close = function (result) {
            modalInstance.hide();
            defer.resolve(result);
          };
          scope.modalInstance = modalInstance;
          return defer.promise;
        },
        showWindow: function (templatePath, controller, resolutions, windowSize) {
          var defer = $q.defer();

          var scope = $rootScope.$new();
          resolutions = resolutions || {};
          resolutions.$scope = scope;

          var modalConfiguration = {
            template: templatePath,
            persist: true,
            show: true,
            backdrop: 'static',
            modalClass: 'modal-window ' + (windowSize || '')
          };

          modalConfiguration.scope = scope;
          scope.modalClass = modalConfiguration.modalClass;
          var modalInstance = $modal(modalConfiguration);
          scope.modalInstance = modalInstance;
          resolutions.$modalInstance = modalInstance;
          $controller(controller, resolutions);

          modalInstance.__destroy = modalInstance.hide;
          modalInstance.destroy = function () {
            modalInstance.hide();
            defer.reject();
          };

          modalInstance.close = function (result) {
            modalInstance.hide();
            defer.resolve(result);
          };

          scope.modalInstance = modalInstance;

          return defer.promise;
        }
      };
    }]);
