'use strict';

angular.module('membership')
  .service('User', [
    'membership.config', 'AjaxService',
    function (config, ajaxService) {

      var base = config.apiUrls.base + '/user';

      var user = {
        init: function (callback) {
          var self = this;
          callback = callback || function () {
            };

          ajaxService.get(config.apiUrls.base + '/auth/user').then(function (data) {
            self.userName = data.userName;
            self.userId = data.userId;
            callback(data);
          }, function () {
            callback({error: true});
          });
        },
        getProfile: function (userId) {
          return ajaxService.get(config.apiUrls.base + "/api/user/" + userId + "/profile");
        },
        changePassword: function (data) {
          return ajaxService.post(config.apiUrls.base + '/auth/changePassword', data);
        },
        changePasswordForUser: function (userId, data) {
          return ajaxService.post(config.apiUrls.base + "/api/user/" + userId + '/changePassword', data);
        },
        getAll: function (callback) {
          ajaxService.get(base).then(callback);
          //Restangular.all(base).getList().then(callback);
        },
        get: function (id, callback) {
          ajaxService.get(base + '/' + id).then(callback);
        },
        getPermissions: function (id, callback) {
          ajaxService.get(base + '/' + id + '/permission').then(callback);
        },
        getRoles: function (id, callback) {
          ajaxService.get(base + '/' + id + '/role').then(callback);
          //Restangular.one(base, id).all('role').getList().then(callback);
        },
        assignPermission: function (user, permission, callback) {
          ajaxService.post(base + '/' + user.Id + '/permission/' + permission.Id).then(callback);
          //Restangular.one(base, user.Id).one('permission', permission.Id).post().then(callback);
        },
        insert: function (user, callback) {
          return ajaxService.post(base + '/createUser', user).then(callback);
          //Restangular.all(base).all('post').post(user).then(callback);
        },
        unassignPermission: function (user, permission, callback) {
          ajaxService.delete(base + '/' + user.Id + '/permission/' + permission.Id).then(callback);
          //Restangular.one(base, user.Id).one('permission', permission.Id).remove().then(callback);
        },
        assignRole: function (user, role, callback) {
          ajaxService.post(base + '/' + user.Id + '/role/' + role.Id).then(callback);
          //Restangular.one(base, user.Id).one('role', role.Id).post().then(callback);
        },
        unassignRole: function (user, role, callback) {
          ajaxService.delete(base + '/' + user.Id + '/role/' + role.Id).then(callback);
          //Restangular.one(base, user.Id).one('role', role.Id).remove().then(callback);
        }
      };

      return user;
    }
  ]
);
