angular.module('membership')
  .controller('RoleListCtrl', ['$scope', 'modalSvc', 'roleCreationViewModel', 'membership.config', function ($scope, modalSvc, creationModel, config) {
    /*$scope.dtColumns = [
      {
        "bSearchable": false,
        "bVisible": false,
        "aTargets": [0]
      },
      {
        "sType": "html",
        "fnRender": function (o, val) {
          return $('<div>').append($("<a>").html(o.aData[1]).attr('href', '/#/admin/role/' + o.aData[0] + '/details')).html();
        },
        "aTargets": [1]
      }];*/

    $scope.datatablesConfig = {
      datasourceUrl: config.apiUrls.base + '/datasource/role',
      dtColumns: [
      /*{
        "sType": "html",
        "fnRender": function (o, val) {
          return $('<div>').append($("<a>").html(o.aData[1]).attr('href', '/#/admin/role/' + o.aData[0] + '/details')).html();
        },
        "aTargets": [1]
      },*/
      //,
          {
            "aTargets": -1,
            "bSearchable": false,
            "bSortable": false,
            "data": null,
            "sWidth": 90,
            "sClass": "actions-col dynamic-html",
            "render": function (data, type, full, meta) {

              return "<a class='btn btn-success' ui-sref='membership_role_detail({id:" + data[0] + ", tab:\"roleInfo\"})' title='Edit' ><i class='fa fa-edit'></i></a>";
            }
          }],
      hiddenColumns: [0]
    };

    $scope.addRole = function () {
      creationModel.init();
      modalSvc.showWindow('components/membership/role/creationModal/roleCreation.tpl.html', creationModel);
    };
  }])