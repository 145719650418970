'use strict';

angular.module('membership')
  .service('Ownership', [
    'AjaxService', 'membership.config',
    function (AjaxService, config) {

    var apiUrl = config.apiUrls.base + "/ownership";

    var domain = {

      modifyOwnership: function (company, callback) {
        AjaxService.post(apiUrl + '/modifyownership', company).then(function (data) {
          callback(data);
        });
      }
    };

    return domain;
  }]);