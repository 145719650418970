'use strict';

angular.module('magtac')
  .service('bulkTasksManagementViewModel', [
    'BulkTasksConfig', 'bulkTasksDetailsViewModel', 'modalService', 'timerFormatService', 'StatusPageLookup',
    function (BulkTasksConfig, bulkTasksDetailsViewModel, modalService, timerFormatService, lookup) {

      var viewModel = function () {

        var self = this;

        self.mode = "Monitor";
        
        BulkTasksConfig.getAvailableTasks().then(function(resp){
          self.availableTasks = resp;
        });

        BulkTasksConfig.getAdHocQueries().then(function (resp) {
          self.adHocQueries = resp;
        });

        lookup.observerNames().then(function (data) {
          self.observerNames = data;
        });

        self.reload = function () {
          BulkTasksConfig.get().then(function (resp) {
            self.tasks = resp;
          });
        };

        self.getTime = function(timer) {
          var timeZone = timer.Server ? timer.Server.TimeZoneOffset : undefined;
          return timerFormatService.formatTime(timer.CronConfiguration, timeZone) || timer.CronConfiguration;
        }

        self.editOrCreate = function (item) {
          var detailsViewModel = new bulkTasksDetailsViewModel(item, angular.copy(self.defaultConfigurations), self.observerNames, null, self.adHocQueries);
          detailsViewModel.on('save', function () {
            self.reload();
          });
          detailsViewModel.on('delete', function (params) {
            self.reload();
          });
          modalService.showWindow('components/magtac/bulkTasksManagement/bulkTasks-details.html', detailsViewModel);
        };

        self.delete = function(item) {
          BulkTasksConfig.delete(item.Id).then(function() {
            self.reload();
          });
        };

        self.reload();
      };

      return viewModel;

    }
  ]);
