/**
 * Created on 10/12/2015.
 */
angular.module('membership')
  .config([
    'navigationServiceProvider', 'membership.configProvider',
    function (navigationServiceProvider, membershipConfig) {

      navigationServiceProvider.register({
        priority: 4,
        state: 'membership_user_my_account.personalInfo',
        name: 'membership.lbl.myAccount'
      });

      navigationServiceProvider.register({
        priority: 5,
        state: 'membership_usermanager',
        name: 'Admin',
        children: [
          {
            state: 'membership_usermanager({filter:"' + membershipConfig.config.userRoles.clients + '"})',
            name: 'membership.menu.manageClient'
          },
          {
            state: 'membership_usermanager({filter:"' + membershipConfig.config.userRoles.staff + '"})',
            name: 'membership.menu.manageEmployees'
          },
          {
            state: 'membership_usermanager({filter:""})',
            name: 'membership.menu.manageAllUsers'
          },
          {
            state: 'membership_usergroup',
            name: 'membership.menu.manageUserGroups'
          },
          {
            state: 'clientCompany.list',
            name: 'membership.menu.manageClientCompany'
          }
        ]
      });

      navigationServiceProvider.register({
        priority: 6,
        state: 'membership_company',
        name: 'membership.sysAdminMenu.sysAdmin',
        children: [
          {
            state: 'membership_user',
            name: 'membership.sysAdminMenu.users',
          },
          {
            state: 'membership_role',
            name: 'membership.sysAdminMenu.roles',
          },
          {
            state: 'backend.site_setting_management.list',
            name: 'membership.sysAdminMenu.siteSettings',
          },
          {
            state: 'membership_company',
            name: 'membership.sysAdminMenu.company',
          }
        ]
      });
    }
  ]);
