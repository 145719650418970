'use strict';

angular.module('membership')
  .factory('manageUsersDetailsSyncViewModel', ['BizUsersSync', 'validation', '$location', '$state', '$http', 'membership.config',
    function (BizUsers, validation, $location, $state, $http, config) {

      var viewModel = function (filter, mode, userId) {
        var self = this;
        self.role = filter;
        self.isAddPatientMode = self.role === 'newPatients';
        self.action = mode;
        self.userId = userId;
        self.user = null;
        self.showValidationMessages = false;
        self.emailExists = false;
        self.emailChecked = false;

        self.loadPatientExternalData = function (id) {
          $http.get("/scheduler.api/externalData/PatientDetails/" + id).success(function (res) {
            self.patientDetails = res;
          });
        };

        switch (mode) {
          case 'create':
            self.user = {
              roles: [self.role == 'employees' ? 'BS' : self.role == 'users' ? 'PA' : 'BC']
            };
            break;
          case 'invite':
            self.user = {
              roles: ['BC'],
              IsNeedInvite: true
            };
            break;
          case 'update':
          case 'reconcile':
            BizUsers.get(self.userId, function (user) {
              self.user = user;
              //self.userOriginalEmail = '';
              if (user.Email) {
                self.userOriginalEmail = user.Email.toLowerCase();
              }
              self.user.isBizClient = _.some(user.Roles, function (r) {
                return r == 'BizClient';
              });
            });
            break;
          case 'view':
            self.loadPatientExternalData(self.userId);
            break;
        };

        self.checkEmail = function () {

          if (!self.user) {
            self.emailExists = false;
            return;
          }

          if (self.user.Id && self.user.Email) {
            if (self.user.Email.toLowerCase() == self.userOriginalEmail) {
              self.emailExists = false;
              return;
            }
          }

          if ((!self.user.Email && !self.user.email)) {
            self.emailExists = false;
            return;
          }
          BizUsers.checkEmail(self.user.Email || self.user.email, function (resp) {
            self.emailExists = resp.exists;
            self.emailChecked = true;
          });
        };

        self.insert = function () {

          self.showValidationMessages = true;
          if (!self.isModelValid()) {
            return;
          }

          self.showValidationMessages = false;

          BizUsers.insert/*Patient*/(self.user, function (u) {
            $state.transitionTo('membership_usermanager', { filter: self.role });
          });
        };

        self.update = function () {
          self.showValidationMessages = true;
          if (!self.isUpdateModelValid()) {
            return;
          }

          self.showValidationMessages = false;

          BizUsers.update(self.userId, self.user, function (u) {
            $state.transitionTo('membership_usermanager', { filter: self.role });
          });
        };

        self.save = function () {
          if (self.userId) {
            self.update();
          } else {
            self.insert();
          }
        };

        self.merge = function () {

          BizUsers.mergePatient(self.user.Id, self.mergeWith.Id, function (u) {
            $state.transitionTo('membership_usermanager', { filter: self.role });
          });
        };

        self.inviteClient = function () {
          $location.path('/manageUsers/clients/invite');
        };

        self.returnToList = function () {
          $location.path('/manageUsers/' + self.role);
        };

        self.showValidation = function (field, fieldName) {
          if (fieldName == 'email' && self.emailExists) {
            return false;
          }

          return validation.validate(field, 'required') && self.showValidationMessages;
        };

        self.isModelValid = function () {
          if (self.isAddPatientMode) {
            // email is not required
            return validation.validate(self.user.FirstName, 'required')
              && validation.validate(self.user.LastName, 'required')
              && !self.emailExists;
          } else {
            return validation.validate(self.user.FirstName, 'required')
            && validation.validate(self.user.LastName, 'required')
            && validation.validate(self.user.Email, 'required')
            && !self.emailExists;
          }
        };

        self.isUpdateModelValid = function () {
          if (self.isAddPatientMode) {
            // email is not required
            return validation.validate(self.user.FirstName, 'required')
              && validation.validate(self.user.LastName, 'required')
              && !self.emailExists;
          } else {
            return validation.validate(self.user.FirstName, 'required')
            && validation.validate(self.user.LastName, 'required')
            && validation.validate(self.user.Email, 'required')
            && !self.emailExists;
          }
        };

        var showPerPage = 10;

        self.autocompleteOptions = {
          width: 'element',
          placeholder: "Search for patient to merge with",
          minimumInputLength: 1,
          ajax: {
            url: config.apiUrls.base + "/importUsers/mergeAutocomplete",
            quietMillis: 100,
            data: function (term, page) {
              return {
                q: term,
                page_limit: showPerPage + 1,
                page: page
              };
            },
            results: function (data, page) {
              var more = false;
              if (data.length > showPerPage) {
                data.pop();
                more = true;
              }
              return { results: data, more: more };
            }
          },
          initSelection: function (element, callback) {
            if (self.mergeWith) {
              self.loadPatientExternalData(self.mergeWith);
            }
          },
          formatResult: function (item) {
            return "(" + item.ExternalKey + ") " + item.FirstName + ' ' + item.LastName;
          },
          formatSelection: function (item) {
            return "(" + item.ExternalKey + ") " + item.FirstName + ' ' + item.LastName;
          },
          id: function (item) { return item.Id; },
          escapeMarkup: function (m) { return m; }
        };

      };

      return viewModel;
    }]);