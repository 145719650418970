'use strict';

angular.module('ticketAssignment')
    .service('ticketAuditViewModel', ['ticketAssignment.config', 'modalDialogService', 'ticketAdmin', '$state',
        function (config, modalDialogService, Tickets, $state) {

            var viewModel = function (eventId) {

                var self = this;

                Tickets.getAssignmentEventDetails(eventId).then(function (resp) {
                    self.assignmentEvent = JSON.stringify(resp, null, 3);
                });
            }

            return viewModel;
        }
    ]);