'use strict';

angular.module('magview-config', [])
  .provider('magview.config', function () {
    this.config = {
      apiUrls: {
        base: '/magview.api'
      }
    };

    var self = this;

    this.$get = function () {
      return self.config;
    };
    return this;
  });
