/**
 * Created on 10/24/2015.
 */

'use strict';

angular.module('membership')
  .factory('RoleProfilePropertyViewModel', [
    'Role', 'AjaxService', 'ProfilePropertiesDomain', 'ProfilePropertyUiService',
    function (roleDomain, ajaxService, profilePropertiesDomain, profilePropertyUiService) {
      function RoleProfilePropertyViewModel(roleId) {
        var self = this;
        self.roleId = roleId;
        self.propertySearch = '';
        self.propertiesList = [];
        self.selectingPropertyIds = [];
        self.selectingUnassignedPropertyIds = [];
        self.roleProfileProperties = [];

        self.init = function () {
          ajaxService.all([self.loadData(), self.loadPropertiesList()]);
        };

        self.loadData = function () {
          return roleDomain.get(roleId, function (role) {
            self.role = role;
          });
        };

        self.loadPropertiesList = function () {
          profilePropertiesDomain.getAll().then(function (data) {
            self.propertiesList = data;
            self.getAssignedProperties();
          }).finally(function () {
            self.selectingPropertyIds = [];
            self.selectingUnassignedPropertyIds = [];
          });
        };

        self.getUnassignedProperties = function () {
          var assignedIds = _.map(self.roleProfileProperties, 'Id');
          return self.propertiesList.filter(function (r) {
            return !_.contains(assignedIds, r.Id);
          });
        };

        self.getAssignedProperties = function () {
          profilePropertiesDomain.getPropertiesForRole(self.roleId).then(function (data) {
            self.roleProfileProperties = data;
          }).finally(function () {
            self.selectingPropertyIds = [];
            self.selectingUnassignedPropertyIds = [];
          });
        };

        self.assignProperties = function () {
          var promises = self.selectingPropertyIds.map(function (id) {
            return profilePropertiesDomain.assignToRole(id, self.roleId);
          });

          ajaxService.all(promises).then(self.loadPropertiesList);
        };

        self.unassignProperties = function () {
          var promises = self.selectingUnassignedPropertyIds.map(function (id) {
            return profilePropertiesDomain.unassignFromRole(id, self.roleId);
          });

          ajaxService.all(promises).then(self.loadPropertiesList);
        };

        self.addNewProperty = function () {
          profilePropertyUiService.create().finally(self.loadPropertiesList);
        };

        self.init();
      }

      return RoleProfilePropertyViewModel;
    }
  ]);
