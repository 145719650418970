/**
 * Created on 9/28/2015.
 */

angular.module('membership')
  .controller('ClientCompanyController', [
    '$scope', 'clientCompanyListModel',
    function ($scope, ClientCompanyListModel) {
      $scope.vm = new ClientCompanyListModel();
    }
  ]);
