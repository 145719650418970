/**
 * Created on 9/30/2015.
 */

angular.module('membership')
  .controller('FilterableListController', [
    '$scope', 'FilterableUserListModel', 'membership.config', '$state',
    function ($scope, FilterableUserListModel, config, $state) {
      $scope.vm = new FilterableUserListModel($state.current.data.filter);
    }
  ]);
