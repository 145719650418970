/**
 * Created on 11/14/2015.
 */

'use strict';

angular.module('backend')
  .controller('backendDashboardController', [
    '$scope', 'backendDashboardViewModel',
    function (scope, backendDashboardViewModel) {
      scope.vm = new backendDashboardViewModel();
    }
  ]);
