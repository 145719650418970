/**
 * Created on 11/27/2015.
 */

'use strict';

angular.module('membership')
  .controller('UserRegistrationController', [
    '$scope', 'UserRegistrationViewModel',
    function (scope, UserRegistrationViewModel) {
      scope.vm = new UserRegistrationViewModel();
    }
  ]);
