'use strict';

angular.module('ticketAssignment')
    .controller('supportMetricsCtrl', [
        '$scope', 'supportMetricsViewModel',
        function ($scope, viewModel) {

            $scope.model = new viewModel();


            $scope.currentHoverExternalId = -1;
            $scope.setHover = function (externalUserId) {
                $scope.currentHoverExternalId = externalUserId;
            }
            $scope.unsetHover = function () {
                $scope.currentHoverExternalId = -1;
            };
            $scope.isCurrentlyHovered = function (externalUserId) {
                return $scope.currentHoverExternalId == externalUserId;
            };

            $scope.hideWarningMessage = function () { $scope.model.warning = { display: false, message: '' }; };

            $scope.sorted = false;
            $scope.sortedBy = {
                header: '',
                subheader: '',
                members: $scope.model.Members,
            };
            $scope.SetSortBy = function (_header, _subheader) {
                $scope.sorted = true;
                $scope.sortedBy = {
                    header: _header,
                    subheader: _subheader,
                };
                $scope.sortedBy.members = Sort($scope.model.Members);
            };
            $scope.UnsetSortBy = function () {
                $scope.sorted = false;
                $scope.sortedBy = { header: '', subheader: '' };
                $scope.sortedBy.members = $scope.model.Members;
            };

            $scope.Export = function () {
               
                var table = exportToCsv();

                var rows = [];

                for (var col = 0; col < table[0].length; col++) {
                    rows[col] = [];
                }

                var col_num = 0;
                for (var row = 0; row < rows.length; row++) {
                    while (col_num < table.length) {
                        rows[row][col_num] = table[col_num][row];
                        col_num++;
                    }
                    col_num = 0;
                }

                rows = rows.join("\n");
                var csv = new Blob([rows], {
                    type: "text/csv"
                });
                
                var a = document.createElement('a');
                a.download = "metrics.csv";
                var link = window.URL.createObjectURL(csv);
                a.href = link;
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
                a.removeChild(a);
            };


            $scope.openSettings = function () {
                location.href = 'metrics/settings';
            };


            $scope.openOldMetrics = function () {
                location.href = 'metrics/old';
            };

            function Sort(members)
            {
                var header = $scope.sortedBy.header;
                var subheader = $scope.sortedBy.subheader;
                
                if (header === 'CallHours') {
                    return members.sort(
                        function (a, b) {
                            return timespanToMinutes(a.Metrics[header][subheader]) < timespanToMinutes(b.Metrics[header][subheader]) ? 1 : -1;
                        });
                }
                else if (header === 'FirstAndLastTouches') {
                    return members.sort(
                        function (a, b) {
                            var firstTouchA = '';
                            var firstTouchB = '';

                            if (a.Metrics[header][subheader].length === 0) {
                                firstTouchA = '23:59';
                            }
                            else if (a.Metrics[header][subheader].indexOf(' - ') === -1) {
                                firstTouchA = '23:59';
                            }
                            else {
                                firstTouchA = a.Metrics[header][subheader].split(' - ')[0];
                            }

                            if (b.Metrics[header][subheader].length == 0) {
                                firstTouchB = '23:59';
                            }
                            else if (b.Metrics[header][subheader].indexOf(' - ') === -1) {
                                firstTouchB = '23:59';
                            }
                            else {
                                firstTouchB = b.Metrics[header][subheader].split(' - ')[0];
                            }

                            return postMeridiemTimeToMinutes(firstTouchA) > postMeridiemTimeToMinutes(firstTouchB) ? 1 : -1;
                        }
                    );
                }

                var isAscendingSort = $scope.model.sortDirection[header][subheader] === 'asc';

                return isAscendingSort
                    ?
                        members.sort(function (a, b) {
                            return a.Metrics[header][subheader] > b.Metrics[header][subheader] ? 1 : -1;
                        })
                    :
                        members.sort(function (a, b) {
                            return a.Metrics[header][subheader] < b.Metrics[header][subheader] ? 1 : -1;
                        });
            };

            $scope.getTeamName = function (teamName) {
                if (teamName.startsWith('Team')) {
                    return 'Team ' + teamName.substring(4);
                }
                else if (teamName === 'First Responder') {
                    return 'FRT';
                }
                return teamName;
            }

            $scope.valueDisplay = function (value, properties) {
                if (value == "-99") {
                    return " ";
                } else if (value === undefined || value === '' || value == -99 || ( ! value && properties.type === 'string'))
                {
                    return ' - ';
                }
                if (properties.type === 'string')
                {
                    if (properties.isTimeValue) {
                        return convertFromMillitaryTime(value);
                    }

                    return value;
                }
                else {
                    if (value > 100) {
                        return round(value, 0);
                    } else {
                        var v = round(value, properties.precision);
                        if (properties.precision > 0 && v.toString() === round(v, 0).toString()) {
                            v =  v+ ".0";
                        }
                        return v;
                    }
                }
            }

            var round = function (n, digits) { return Math.round(n * Math.pow(10, digits)) / Math.pow(10, digits); };

            function exportToCsv() {
                var file = '';
                var sections = [];
                var chunks = document.getElementsByTagName('table');
                for (var index = 0; index < chunks.length; index++) {
                    var tables = [];
                    var sect = chunks[index].getElementsByTagName('tr');
                    var headerName = chunks[index].getElementsByTagName('caption')[0].innerText;
                    var header = [];
                    if (headerName === "First,Last Touch") {
                        headerName = "First/Last Touch";
                    }
                    header.push(headerName);
                    for (var i = 0; i < sect[0].querySelectorAll('td,th').length - 1; i++) {
                        header.push("  ");
                    }
                    tables.push(header.join(","));
                    for (var linkElement = 0; linkElement < sect.length; linkElement++) {
                        var column = sect[linkElement].querySelectorAll('td,th');
                        var row = [];
                        for (var textIndex = 0; textIndex < column.length; textIndex++) {
                            var text = column[textIndex].innerText;
                            row.push(text);
                        }
                        tables.push(row.join(","));
                    }
                    tables = tables.join('\n');
                    var subsections = tables.split('\n');
                    sections.push(subsections);
                }

                return sections;
            }


            /* Converts a string, time, in millitary time format (eg. "15:23") into a string in standard time format (eg. "3:23p").
             * Leading zeros are removed.
             */
            function convertFromMillitaryTime(time) {
                var breakIndex = time.indexOf(":");
                var hourValue = parseInt(time.substring(0, breakIndex));
                if (hourValue >= 12) {
                    hourValue -= 12;
                    time = time.concat("p");
                } else {
                    time = time.concat("a");
                }

                if (hourValue == 0) {
                    hourValue = 12;
                }

                return hourValue.toString() + time.slice(breakIndex);
            }

            function timespanToMinutes(timespanString)                  // Converts string representation of timespan (i.e: "3:14") to its total duration in minutes (194)
            {
                if (timespanString.length == 0)
                {
                    return 1;
                }

                var split = timespanString.split(':');

                var hour = parseInt(split[0].padStart(1, '0'));
                var minutes = parseInt(split[1]);

                return hour * 60 + minutes;
            }


            function postMeridiemTimeToMinutes(pmTimeString) {
                if (pmTimeString.length === 0 || pmTimeString.indexOf(':') === -1) {
                    return 1;
                }

                var split = pmTimeString.split(':');
                var hour = parseInt(split[0], 10);
                var minutes = parseInt(split[1].substring(0, 2), 10);

                if (hour === 12) {
                    hour -= 12;
                }
                if (pmTimeString.indexOf('a') === -1) {
                    hour += 12;
                }
                return hour * 60 + minutes;
            }
        }
    ]);