'use strict';

angular.module('magview')
  .service('Lookup', [
    '$q', 'AjaxService', 'magview.config', function ($q, ajaxService, config) {

      var domain = function() {

        var cache = {};

        var loadLookupInternal = function(url) {

          var deferred = $q.defer();

          if (cache[url]) {
            deferred.resolve(cache[url]);
          } else {
            ajaxService.get(config.apiUrls.base + url).then(function(data) {
              cache[url] = data;
              deferred.resolve(data);
            });
          }

          return deferred.promise;
        };

        this.cleanerCache = function() {
          cache = {};
        };

        this.attendanceReasons = function() {
          return loadLookupInternal('/attendanceTracking/getAttendanceReasons');
        };

      };

      return new domain();
    }
  ]);
