'use strict';

angular.module('magtac')
  .service('magtacSignalrService', ['magtac.config', 'membership.config', '$rootScope', function (config, membershipConfig, $rootScope) {
    
    
    var isLoadingShown = false;

    var instance = {
        dashboard: $.connection.dashboard,
        connect: firstConnect,
        disconnect: disconnect,
        isConnected: true
    };


    function reconnect() {
      $.connection.hub.start({
        jsonp: true,
      });
    }


    function firstConnect() {
      $.connection.hub.url = config.apiUrls.base + '/signalr';
      $.connection.dashboard.client.start = function () { };
    
      $.connection.hub.start({
        jsonp: true,
      }).done(function () {
        var tokenCookieValue = $.cookie(membershipConfig.defaultTokenKey);
        $.connection.dashboard.server.connect(tokenCookieValue);
      })
      .fail(function() {
        console.log('Connection failed. Retrying...');
        setTimeout(function() { reconnect(); }, 3000);
      });

      $.connection.hub.error(function (error) { console.log('SignalR error: ' + error); });
      $.connection.hub.disconnected(function() {
         console.log('Connection closed. Retrying...');
         if (!$.cookie(membershipConfig.defaultTokenKey)) {
          return;
         }
         setTimeout(function() { reconnect(); }, 3000);
      });
    } 

    function disconnect() {
      $.connection.hub.stop();
    }

    return instance;
}]);