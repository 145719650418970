/**
 * Created on 10/13/2015.
 */
angular.module('membership')
  .run([
    '$rootScope',
    'common.config',
    'SiteSettingService',
    'translationSvc',
    'membership.config',
    'AjaxService',
    function (rootScope,
              commonConfig,
              SiteSettingService,
              translationSvc,
              config,
              AjaxService) {
      rootScope.companyName = "";
      rootScope.defaultTitle = "";

      var generalSetting = SiteSettingService.getInstance(commonConfig.coreSettingKey);

      function loadSetting() {
        generalSetting.getSetting().then(function (data) {
          rootScope.companyName = rootScope.defaultTitle = data.SiteName;
          translationSvc.useLanguage(data.SiteCulture);
        });
      }

      rootScope.impersonateUser = function (userId) {
        if (!rootScope.isFeatureAllowed('/sysadmin') && !rootScope.isFeatureAllowed(config.permissions.impersonateUsers))
          return;

        AjaxService.get(config.apiUrls.base + "/auth/impersonate/" + userId).then(function () {
          window.location.reload();
        });
      };

      rootScope.$on('siteSettingUpdated', function (scope, settingKey) {
        if (settingKey == commonConfig.coreSettingKey) {
          loadSetting();
        }
      });

      loadSetting();
    }
  ]);
