'use strict';

angular.module('membership')
  .service('loginViewModel', [
    'authenticationService', 'membership.config', '$location', function (authenticationService, config, $location) {

      return function () {

        var viewModel = {
          login: '',
          password: '',
          enableJiraLogin: config.enableJiraLogin,
          signIn: function (successCallback) {
            viewModel.showLoading = true;
            viewModel.showError = false;

            authenticationService.signIn(this.login, this.password, function () {
              viewModel.showLoading = false;
              viewModel.showError = true;
            }, successCallback);
          },
          signInWithJira: function () {
            if (config.enableJiraLogin)
              window.location = config.apiUrls.base + '/oauth/jira';
          },
          handlePwdKeyUpEvent: function (event) {
            if (event.keyCode == 13) {
              viewModel.signIn();
            }
          }
        };

        if ($location.search().token) {
          authenticationService.signInWithToken($location.search().token);
        }
        return viewModel;
      };
    }
  ]);
