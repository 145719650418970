'use strict';

angular.module('documentsManagement')
  .factory('documentsListViewModel', [
    'modalDialogService',
    'common.config',
    'AjaxService',
    'notifySvc',
    function (modalDialogService,
              commonConfig,
              ajaxService,
              notifySvc) {      

      function documentsListViewModel(masterId, documentsApiUrl, editPermission) {
        //var baseUrl = config.apiUrls.base + '/api/projectDocuments';
        var baseUrl = documentsApiUrl;
        var self = this;
        self.masterId = masterId;
        self.editPermission = editPermission;

        self.datatablesConfig = {
          datasourceUrl: baseUrl + '/?masterId=' + masterId,// + '/datatables',
          columns: [
            {
              data: "FileName",
              searchable: false,
              sortable: false,
              render: function (data, type, row) {
                return "<a href='" + baseUrl + '/download/' + row.Id + "' target='_blank'>" + data + "</a>";
              }
            },
            {
              data: "Comment",
              searchable: false,
              sortable: false,
            },

            {
              "data": "CreatedBy",
              "width": "120px",
              "searchable": false
            },
            {
              "data": "CreatedDate",
              "width": "160px",
              "render": function (data, type, row) {
                if (!data) return "";
                return moment(data).format(commonConfig.momentDateFormat);
              },
              "searchable": false
            },
            {
              "data": "Id",
              "width": "100px",
              searchable: false,
              sortable: false,
              "render": function (data, type, row) {
                return '<button ng-click="vm.editDocument(' + data + '); $event.stopPropagation();" class="btn btn-info btn-xs">' +
                  '<i class="fa fa-edit"></i>' +
                  '</button>&nbsp;' +
                  '<button ng-click="vm.deleteDocument(' + data + '); $event.stopPropagation();" class="btn btn-danger btn-xs">' +
                  '<i class="fa fa-times"></i>' +
                  '</button>';
              }
            }
          ],
          aaSorting: [[-2, 'desc']]
        };

        self.createDocument = function () {
          showWindow(null);
        };

        self.editDocument = function (docId) {
          showWindow(docId);
        };

        self.deleteDocument = function (docId) {
          modalDialogService.showConfirmation('Are you sure want to delete this document?', 'Delete document confirmation', 'dialog-danger').then(function (result) {
            if (result) {
              ajaxService.delete(baseUrl + '/' + docId).then(function () {
                 notifySvc.success({
                  msg: "Document deleted successfully"
                });
              }).finally(self.reloadData);
            }
          });
        };

        self.reloadData = function () {
          self.datatablesConfig.table.reload();
        };

        function showWindow(docId) {
          modalDialogService.showWindow('components/documentsManagement/editDialog/documentEditDialog.html',
            'DocumentEditCtrl', {
              $$masterId: self.masterId,
              $$documentId: docId,
              $$documentsApiUrl: documentsApiUrl
            }).finally(self.reloadData);
        }
      }

      return documentsListViewModel;
    }
  ]);
