'use strict';

angular.module('ticketAssignment')
    .config([
        'appStateConfigProvider',
        function (appStateConfigProvider) {

            var states = [
                {
                    name: 'tickets_admin',
                    url: "/assignment-list",
                    templateUrl: "components/ticketAssignment/assignmentList/assignmentList.html",
                    controller: "AssignmentListCtrl"
                },
                {
                    name: 'tickets_audit_details',
                    url: "/ticket-audit/ticket/{id}",
                    templateUrl: "components/ticketAssignment/audit/audit.html",
                    controller: "TicketAuditCtrl",
                    data: {

                    }
                },
				{
                    name: 'activity_report',
                    url: "/activity",
                    templateUrl: "components/ticketAssignment/activityReport/activityReport.html",
                    controller: "ActivityReportCtrl"
                },
                {
                    name: 'activity_report_details',
                    url: "/activity/details/{params}",
                    templateUrl: "components/ticketAssignment/activityReportDetails/activityReportDetails.html",
                    controller: "ActivityReportDetailsCtrl"
                },
                {
                    name: 'scoreboard_metrics',
                    url: "/scoreboard",
                    templateUrl: "components/ticketAssignment/scoreboard/scoreboard.html",
                    controller: "ScoreboardCtrl"
                },
				{
					name: 'customerBoardOld',
					url: "/customers/board/old",
					templateUrl: "components/ticketAssignment/customerBoardOld/customerBoardOld.html",
					controller: "customerBoardControlModelOld"
                },
                {
                    name: 'customerBoard',
                    url: "/customers/board",
                    templateUrl: "components/ticketAssignment/customerBoard/customerBoard.html",
                    controller: "customerBoardControlModel"
                },
                {
                    name: 'companyTickets',
                    url: "/company/{id}/tickets",
                    templateUrl: "components/ticketAssignment/companyTickets/companyTickets.html",
                    controller: "companyTicketsControlModel"
                },
                {
                    name: 'agentTickets',
                    url: "/agent/{id}/tickets",
                    templateUrl: "components/ticketAssignment/agentTickets/agentTickets.html",
                    controller: "agentTicketsControlModel"
                },
                {
                    name: 'teamTickets',
                    url: "/team/{teamName}/tickets",
                    templateUrl: "components/ticketAssignment/teamTickets/teamTickets.html",
                    controller: "teamTicketsControlModel"
                },
				{
                    name: 'alert_config',
                    url: "/alerts",
                    templateUrl: "components/ticketAssignment/alertConfiguration/alertConfiguration.html",
                    controller: 'alertConfigurationCtrl'
                },
                {
                    name: 'supportMetrics',
                    url: "/metrics",
                    templateUrl: "components/ticketAssignment/supportMetrics/supportMetrics.html",
                    controller: 'supportMetricsCtrl'
                },
                {
                    name: 'supportMetricsOld',
                    url: "/metrics/old",
                    templateUrl: "components/ticketAssignment/supportMetricsOld/supportMetricsOld.html",
                    controller: 'supportMetricsOldCtrl'
                },
                {
                    name: 'ticketSearch',
                    url: "/search/{searchFilter}/{pageNo}/{perPage}/{query}",
                    templateUrl: "components/ticketAssignment/ticketSearch/ticketSearch.html",
                    controller: 'ticketSearchCtrl'
                }
                // ,
                // {
                //     name: 'tickets_audit',
                //     url: "/ticket-audit",
                //     templateUrl: "components/ticketAssignment/audit/audit.html",
                //     controller: "TicketAuditCtrl",
                //     data: {

                //     }
                // }
            ];

            states.forEach(appStateConfigProvider.addState);
        }]);