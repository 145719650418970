'use strict';

angular.module('magtac')
  .service('MagTacCustomersTasksViewModel', [
    'magtac.config', 'modalDialogService', 'Customer', 'StatusPageLookup',
    function (config, modalDialogService, Customer, lookup) {

      var viewModel = function() {

        var self = this;

        self.filterOpen = false;
        self.filter = {};
        self.magTacTasks = [];

        self.init = function() {
          lookup.observerNames().then(function(data) {
            self.observerNames = data;

            for(var key in self.observerNames)
            {
              self.magTacTasks.push({
                id: key,
                label: self.observerNames[key].Description
              });
            }

          });
        }

        self.datatablesConfig = {
          datasourceUrl: config.apiUrls.base + '/CustomersTasks/datasource',
          dtColumns: [
            {
              "bSearchable": false,
              "bVisible": false,
              "aTargets": [0]
            },
            {
              aTargets: [1],
              "sClass": "dynamic-html no-wrap",
              render: function (data, type, full, meta) {
                if (full[5] === 'True') {
                  return "<span>" + full[1] + "</span>";
                }

                return "<a ui-sref='membership_customer.general({key: " + full[0] + "})' >" + full[1] + "</a>";
              }
            },
            {
              "aTargets": -1,
              "bSearchable": false,
              "bSortable": false,
              "data": null,
              "sWidth": 50,
              "sClass": "actions-col dynamic-html no-wrap",
              "render": function (data, type, full, meta) {

                var buttons = [                  
                ];

                if (data[5] === 'True') {
                  buttons.push("<a class='btn btn-info' ng-click='model.revertCustomer(" + data[0] + ")' title='Restore' ><i class='fa fa-undo'></i></a>")
                }

                return buttons.join('&nbsp;');
              }
            }
          ],
          customFilter: self.filter,
          dom: "<'row'<'col-xs-12 col-sm-12'r>>" +
          "t" +
          "<'row'<'col-sm-2 col-xs-12 hidden-xs'l><'col-sm-4 col-xs-12 hidden-xs'<'pull-right'i>><'col-xs-12 col-sm-6'p>>"
        };

        self.createCustomer = function () {
          modalDialogService
            .showWindow('components/membership/customer/newCustomerDialog/newCustomerDialog.tpl.html', 'NewCustomerCtrl');
        };

        self.revertCustomer = function (id) {
           modalDialogService.showConfirmation('Are you sure you want to revert this customer?', 'Customers Module')
            .then(function(res) {
              if (res) {
                Customer.revertCustomer(id).then(function() {
                  self.datatablesConfig.reload();
                });  
              }
            });
        };

        self.clearVersionFilters = function(){
          self.filter.versionValue = '';
          self.filter.subversionValue = '';
          self.versionValue = '';
          self.subversionValue = '';
        }
        self.init();

      };

      return viewModel;
    }
  ]);