'use strict';

angular.module('membership')
  .service('userPermissionsViewModel', [
    'Permission', 'Role', 'User', 'membership.config', '$q',
    function (permission, role, userDomain, config, $q) {

      return function (userId) {

        var viewModel = {
          //datatables config
          /*dtColumns: [
           {
           "bSearchable": false,
           "bVisible": false,
           "aTargets": [0]
           },
           {
           "bSearchable": false,
           "aTargets": [2]
           }
           ],*/
          datatablesConfig: {
            datasourceUrl: config.apiUrls.base + "/user/" + userId + "/permissions/datasource",
            dtColumns: [
              {
                "bSearchable": false,
                "bVisible": false,
                "aTargets": [0]
              },
              {
                "bSearchable": false,
                "aTargets": [2]
              }
            ],
            hiddenColumns: [0]
          },

          //advanced show hide
          advanced: false,
          toggleAdvanced: function () {
            this.advanced = !this.advanced;
          },

          userId: 0,
          user: null,
          userPermissions: [],
          userRoles: [],
          permissionsSearch: '',

          init: function () {
            var self = this;
            role.reload();
            self.userId = userId;
            userDomain.get(userId, function (user) {
              self.user = user;
            });
            loadUserPermissions(userId, self);
            userDomain.getRoles(userId, function (roles) {
              self.userRoles = roles;
            });
          },
          mode: {
            list: {
              permission: 0,
              role: 1
            },
            current: 1,
            set: function (mode) {
              this.current = mode;
            },
            isPermission: function () {
              return this.current == this.list.permission;
            },
            isRole: function () {
              return this.current == this.list.role;
            }
          },

          isPermissionAssigned: function (permissionId) {
            var permission = _.find(this.userPermissions, function (x) {
              return x.Id == permissionId
            });
            return permission !== undefined;
          },
          isPermissionAssignedByRole: function (permissionId) {
            var permission = _.find(this.userPermissions, function (x) {
              return x.Id == permissionId && x.roleId != null;
            });
            return permission !== undefined;
          },
          getPermissions: function () {
            return permission.list;
          },
          assignPermission: function (permission) {
            var self = this;
            if (this.isPermissionAssigned(permission.Id)) {
              userDomain.unassignPermission(this.user, permission, function () {
                self.userPermissions = _.filter(self.userPermissions, function (x) {
                  return x.Id !== permission.Id;
                });
                self.datatablesConfig.reload();
                //datatablesHelper.refresh();
              });
            } else {
              userDomain.assignPermission(this.user, permission, function () {
                self.userPermissions.push(permission);
                self.datatablesConfig.reload();
                //datatablesHelper.refresh();
              });
            }
          },

          selectedRole: null,
          rolePermissions: [],
          selectingPermissionIds: [],
          selectingUnassignedPermissionIds: [],

          isRoleAssigned: function (roleId) {
            var role = _.find(this.userRoles, function (x) {
              return x.Id == roleId
            });
            return role !== undefined;
          },
          assignRole: function (role) {
            var self = this;
            if (this.isRoleAssigned(role.Id)) {
              userDomain.unassignRole(self.user, role, function () {
                self.userRoles = _.filter(self.userRoles, function (x) {
                  return x.Id !== role.Id;
                });
                self.datatablesConfig.reload();
                loadUserPermissions(self.userId, self);
              });
            } else {
              userDomain.assignRole(self.user, role, function () {
                self.userRoles.push(role);
                self.datatablesConfig.reload();
                loadUserPermissions(self.userId, self);
              });
            }
          },
          getRoles: function () {
            return role.list;
          },
          getRole: function () {
            var self = this;
            return _.find(role.list, function (x) {
              return x.Id == self.selectedRole;
            });
          },
          selectRole: function (id) {
            this.selectedRole = id;
            this.updateRolePermissions();
          },
          updateRolePermissions: function () {
            this.rolePermissions = role.getPermissions(this.selectedRole);
          },
          getUnassignedPermissions: function () {
            var self = this;
            return _.filter(permission.list, function (permission) {
              return !self.isPermissionAssigned(permission.Id);
            });
          },

          assignPermissions: function () {
            var self = this;

            var selectedPermissions = _.filter(permission.list, function (permission) {
              return _.contains(self.selectingPermissionIds, permission.Id.toString());
            });

            self.isLoading = true;
            var allPromises = _.map(selectedPermissions, function (permission) {
              return assignPermission(self.user, permission);
            });

            $q.all(allPromises).then(function (results) {
              self.selectingPermissionIds = [];
              loadUserPermissions(self.userId, self);
              self.datatablesConfig.reload();
              self.isLoading = false;
            })
          },
          unassignPermissions: function () {
            var self = this;

            var selectedPermissions = _.filter(self.userPermissions, function (permission) {
              return _.contains(self.selectingUnassignedPermissionIds, permission.Id.toString());
            });
            self.isLoading = true;
            var allPromises = _.map(selectedPermissions, function (permission) {
              return unassignPermission(self.user, permission);
            });

            $q.all(allPromises).then(function (results) {
              self.selectingUnassignedPermissionIds = [];
              loadUserPermissions(self.userId, self);
              self.datatablesConfig.reload();
              self.isLoading = false;
            })
          }
        };

        viewModel.init();

        function loadUserPermissions(userId, self) {
          userDomain.getPermissions(userId, function (permissions) {
            self.userPermissions = permissions;
          });
        }

        function assignPermission(user, permission) {
          var defer = $q.defer();

          userDomain.assignPermission(user, permission, function () {
            defer.resolve(permission);
          });

          return defer.promise;
        }

        function unassignPermission(user, permission) {
          var defer = $q.defer();

          userDomain.unassignPermission(user, permission, function () {
            defer.resolve(permission);
          });

          return defer.promise;
        }

        return viewModel;
      };
    }
  ]);
