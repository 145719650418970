'use strict';

angular.module('magview')
  .service('BuildLog', [
    'AjaxService', 'magview.config',
    function (ajaxService, config) {
      var baseUrl = config.apiUrls.base;
      var domain = {
        updateBuildLogs: function (activeProject) {
          return ajaxService.get(baseUrl + "/buildLog/UpdateBuildLogs/" + activeProject);
        },
        reloadBuildLog: function(externalLogId, activeProject){
          return ajaxService.get(baseUrl + "/buildLog/ReloadBuildLog/" + externalLogId + "/" + activeProject);
        }
      };

      return domain;
    }
  ]);