'use strict';

angular.module('ticketAssignment')
    .controller('ActivityReportCtrl', [
        '$scope', 'activityReportViewModel',
        function ($scope, viewModel) {

            $scope.model = new viewModel();
            
            var today = new Date();
            today.setHours(0, 0, 0, 0);
            $scope.startDate = today;
            $scope.endDate = today;

            $scope.AVERAGE_ID = '10000000001';
            $scope.activitiesInRange = 0;

            $scope.displayGroupReport = false;

            $scope.currentUserId = '10000000001';
            $scope.averageUserIsSelected = function () {
                return $scope.currentUserId == $scope.AVERAGE_ID;
            }
            
            $scope.updateStartDate = function (newDate) {
                $scope.startDate = newDate;
                $scope.activitiesInRange = 0;
                console.log("updated $scope.startDate to: " + $scope.startDate.getTime() + " ie:" + $scope.startDate.toDateString());
            }
            $scope.updateEndDate = function (newDate) {
                $scope.endDate = newDate;
                console.log("updated $scope.endDate to: " + $scope.endDate.getTime()+" ie:"+$scope.endDate.toDateString());
                $scope.activitiesInRange = 0;
            }
            $scope.AddToStartDate = function(nDays) {
                var newDate = document.getElementById('start').valueAsDate;
                newDate.setDate(newDate.getDate() + nDays);
                document.getElementById('start').valueAsDate = newDate;
                $scope.updateStartDate(newDate);
            }
            $scope.AddToEndDate = function (nDays) {
                var newDate = document.getElementById('end').valueAsDate;
                newDate.setDate(newDate.getDate() + nDays);
                document.getElementById('end').valueAsDate = newDate;
                $scope.updateStartDate(newDate);
            }


            $scope.ColorMap = {
                'RED': 'rgb(231, 172, 165)',
                'YELLOW': 'rgb(243, 241, 171)',
                'WHITE': 'rgba(255, 255, 255, 0)'
            }
            $scope.scoreStyle = function (color) {
                if (color == null) {
                    color = 'WHITE';
                }

                var styleObj = {
                    "background-color": $scope.ColorMap[color],
                    "text-align": "center"
                };
                return styleObj;
            }
            

            $scope.ActivityStyle = function (activity) {
                var backgroundColor = 'rgba(0,0,0,0.0)';
                var aDate = new Date(activity['ActivityTime']); 

                var display = 'table';
                if ($scope.activitiesInRange % 2 == 0) {
                    backgroundColor = 'rgba(255, 255, 255, 1.0)';
                } else {
                    backgroundColor = 'rgba(240, 240, 240, 1.0)';
                }
                $scope.activitiesInRange += 1;

                var styleObj = {
                    "display": display,
                    "background-color": backgroundColor
                };
                return styleObj;
            }

            $scope.cellStyle = function (header) {
                var textoverflow = "unset";
                if (header.name == 'ActivityContent') {
                    textoverflow = "ellipsis";
                }

                var styleObj = {
                    "width": header.width,
                    "min-width": header.width,
                    "max-width": header.width,
                    "text-overflow": textoverflow
                };
                return styleObj;
            }

            $scope.SortBy = 'TotalScore';
            $scope.SetSortBy = function (sortByType) {
                $scope.SortBy = sortByType;
            }
            $scope.sortDropdownStyle = function (sortName) {
                var backgroundColor = 'unset';
                var fontWeight = 'unset';

                if (sortName == $scope.SortBy) {
                    backgroundColor = 'rgba(75, 25, 140, 0.35)';
                    fontWeight = 'bold';
                }

                var styleObj = {
                    "background-color": backgroundColor,
                    "font-weight": fontWeight
                };
                return styleObj;
            }

            $scope.generate = async function () {
				timeout.style = "display: none;";
				
				var _start = $scope.startDate.getTime();
				var _end = $scope.endDate.getTime();
				var _id = $scope.currentUserId;

                await $scope.model.getActivityReport(_start, _end, _id);

                if ($scope.currentUserId == null) {
                    $scope.currentUserId = '10000000001';
                }

                $scope.displayGroupReport = $scope.averageUserIsSelected();
                console.log('displayGroupReport: ' + $scope.displayGroupReport);
                $scope.$apply();
            }


            $scope.activityDetailsLink = function (externalUserId) {
                var _start = $scope.startDate.getTime();
                var _end = $scope.endDate.getTime();

                var parameters = _start + ' ' + _end + ' ' + externalUserId;

                var href = 'activity/details/' + parameters;
                return href;
            }
            
            $scope.ticketURL = function (ticketId) {
                if (ticketId == null) {
                    return "";
                }
                var baseUrl = 'https://magview.freshdesk.com/a/tickets/';
                return baseUrl + ticketId.substring(0, 6);
            }
            $scope.formatMinutes = function (mins) {
                var h = '';
                if (mins >= 60) {
                    h += Math.floor(mins / 60) + "h:"
                }
                return h + Math.floor(mins % 60) + "m";
            }

            $scope.adjustToLocalTime = function dt(UTCTimeOrTimeRange) {
                var rangeSplitIndex = UTCTimeOrTimeRange.indexOf("-");
                if (rangeSplitIndex == -1) {
                    return getLocalTime(UTCTimeOrTimeRange);  // Not a time range
                } else {
                    var UTCTimeStart = UTCTimeOrTimeRange.substring(0, rangeSplitIndex - 1);
                    var UTCTimeEnd = UTCTimeOrTimeRange.substring(rangeSplitIndex + 1);
                    return getLocalTime(UTCTimeStart) + " - " + getLocalTime(UTCTimeEnd);
                }
            }

            function getLocalTime(UTCTime) {
                var currentDate = getCurrentDate();
                var UTCDateTime = new Date(currentDate + ' ' + UTCTime + ' UTC');
                var localTime = generateLocalTime(UTCDateTime);

                return localTime;
            }

            function getCurrentDate() {
	            var fullCurrentDate = new Date(Date());
	            var dd = fullCurrentDate.getUTCDate();
	            var mm = fullCurrentDate.getMonth() + 1;
	            var yyyy = fullCurrentDate.getFullYear();
	
	            return mm + '/' + dd + '/' + yyyy;
            }

            function generateLocalTime(UTCDateTime) {
	            var hours = UTCDateTime .getHours();
	            var minutes = UTCDateTime .getMinutes();
                var leadingZeroForMinutes = '';
	            var amOrPm = "AM";

	            if(hours >= 12) {
		            amOrPm = "PM";
	            }

	            if(hours > 12) {
		            hours -= 12;
	            }

                if(minutes < 10){
                    leadingZeroForMinutes = '0'
                }

	            return hours + ':' + leadingZeroForMinutes+  minutes + " " + amOrPm;
            }



        }
    ]);