'use strict';

angular.module('membership')
  .factory('userGroupListViewModel', ['membership.config', 'UserGroup', function (config, UserGroup) {

    var viewModel = function () {

      var self = this;

      self.datatablesConfig = {
        datasourceUrl: config.apiUrls.base + '/datasource/userGroup',
        dtColumns: [
          {
            "aTargets": -1,
            "bSearchable": false,
            "bSortable": false,
            "data": null,
            "sWidth": 90,
            "sClass": "actions-col dynamic-html",
            "render": function (data, type, full, meta) {

              var editBtn = "<a class='btn btn-success' ui-sref='membership_usergroup_details({action:\"update\", groupId: " + data[0] + "})' title='Edit' ><i class='fa fa-edit'></i></a>";
              var addApptBtn = "<a class='btn btn-danger' confirmation='Are you sure you want to delete this group?' ng-click='vm.remove(" + data[0] + ")' itle='Delete'><i class='fa fa-times'></i></a>";

              var actionButtons = editBtn + "&nbsp;" + addApptBtn;

              return actionButtons;
            }
          }
        ],
        hiddenColumns: [0]
      };

      self.remove = function (id) {
        UserGroup.remove(id, function () {
          self.datatablesConfig.reload();
        });
      };

    };

    return viewModel;
  }]);