/**
 * Created on 10/30/2015.
 */



angular.module('membership')
  .factory('ClientCompanyDetailViewModel', [
    '$state',
    function ($state) {

      function ClientCompanyDetailViewModel() {
        var self = this;

        var stateList = _.filter($state.get(), function (state) {
          if (_.startsWith)
            return _.startsWith(state.name, 'clientCompany.detail.');
          else {
            return state.name.indexOf('clientCompany.detail.') > -1;
          }
        });

        self.tabsList = [];

        _.each(stateList, function (state) {
          if (!state.data.order) {
            state.data.order = 100;
          }
          self.tabsList.push(state);
        });

        self.tabsList = _.sortBy(self.tabsList, 'data.order');
      }

      return ClientCompanyDetailViewModel;
    }
  ]);
