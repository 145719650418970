'use strict';

angular.module('ticketAssignment')
    .controller('teamTicketsControlModel', ['$scope', '$stateParams', 'teamTicketsViewModel',

        function ($scope, $stateParams, viewModel) {
            $scope.model = new viewModel($stateParams.teamName);

            $scope.get_ticket_link = function (TicketId) {
                return 'https://magview.freshdesk.com/a/tickets/' + TicketId;
            }

            $scope.get_agent_link = function (agentId) {
                return '/agent/' + agentId + '/tickets';
            }

            $scope.get_company_link = function (CompanyId) {
                if (CompanyId !== undefined && CompanyId !== null) {
                    return "/customer/" + CompanyId + '/general';
                } else {
                    return "/";
                }
            }

            $scope.showDetails = function (agentId) {
                toggleDisplay(document.querySelectorAll(".note"));
            }

            $scope.shorten_note = function (noteText) {
                if (noteText != null) {
                    return noteText.substring(0, 120);
                }
                return noteText;
            }

            function toggleDisplay(notes) {

                for (var i = 0; i < notes.length; i++) {
                    if (notes[i].style.display === "none" || notes[i].style.display == null || notes[i].style.display == "") {
                        notes[i].style.display = "table-row";
                    } else {
                        notes[i].style.display = "none";
                    }
                }
            }
        }
    ]);
