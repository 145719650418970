'use strict';

angular.module('membership')
  .service('BizUsers', [
    'membership.config', 'AjaxService',
    function (config, ajaxService) {

      var base = config.apiUrls.base + '/bizUsers';

      var user = {
        getAll: function (callback) {
          return ajaxService.get(base).then(callback);
          //Restangular.all(base).getList({}, {
          //  transparent: true
          //}).then(callback);
        },
        getAllWithLoading: function (callback) {
          return ajaxService.get(base).then(callback);
          //Restangular.all(base).getList().then(callback);
        },
        reactivateUser: function (id) {
          return ajaxService.post(base + '/' + id + '/reactivate');
        },
        deactivateUser: function (id) {
          return ajaxService.post(base + '/' + id + '/deactivate');
        },
        insert: function (user, callback) {
          return ajaxService.post(base + '/post', user).then(callback);
          //Restangular.all(base).all('post').post(user).then(callback);
        },
        update: function (id, user, callback) {
          return ajaxService.put(base + '/' + id, user).then(callback);
          //Restangular.one(base, id).customPUT(user).then(callback);
        },
        /*remove: function(user, callback) {
         user.remove().then(callback);
         },*/
        remove: function (userId, callback) {
          return ajaxService.delete(base + "/" + userId).then(callback);
        },
        get: function (userId, callback) {
          return ajaxService.get(base + "/" + userId).then(callback);
          //Restangular.one(base, userId).get().then(callback);
        },

        checkEmail: function (email, callback) {
          return ajaxService.get(base + "/checkEmail/" + email).then(callback);
          //Restangular.all(base).one('checkEmail', email).customPOST({}, null, {}, {
          //  transparent: true
          //}).then(callback);
        },

        getClients: function (callback) {
          this.getAll(function (allUsers) {
            var clients = _.filter(allUsers, function (u) {
              return _.some(u.Roles, function (r) {
                return r == 'BizClient';
              });
            });
            callback(clients);
          });
        },

        getClientsWithLoading: function (callback) {
          this.getAllWithLoading(function (allUsers) {
            var clients = _.filter(allUsers, function (u) {
              return _.some(u.Roles, function (r) {
                return r == 'BizClient';
              });
            });
            callback(clients);
          });
        }
      };

      return user;
    }]);
