/**
 * Created on 9/25/2015.
 */
angular.module('common')
  .service('AjaxService', [
    '$http', '$q', 'Upload', '$timeout',
    function ($http, $q, Upload, $timeout) {

      var cacheSingleGetRequest = {};

      function cacheRequest(url, promiseResult, lifeTime) {
        var key = buildRequestKey(url);

        cacheSingleGetRequest[key] = promiseResult;

        $timeout(removeCache.bind(null, url), (lifeTime || 1.5) * 1000);
      }

      function buildRequestKey(url) {
        return url + ":::CACHED_PROMISE";
      }

      function getCacheRequest(url) {
        return cacheSingleGetRequest[buildRequestKey(url)];
      }

      function removeCache(url) {
        delete cacheSingleGetRequest[buildRequestKey(url)];
      }

      var ajaxObj = {
        tokenHeaderValue: {},
        makeAjax: function (options) {
          var defer = $q.defer();

          var headers = options.headers || {};
          headers = _.extend(_.cloneDeep(this.tokenHeaderValue), headers);

          options.headers = headers;
          options.contentType = options.contentType || "application/json";

          $http(options).then(function (response) {
            defer.resolve(response.data);
          }, function (error) {
            defer.reject(error);
          });

          return defer.promise;
        },
        upload: function (url, file, options, processHandler) {
          if (!options) options = {};
          var headers = options.headers || {};
          headers = _.extend(_.cloneDeep(this.tokenHeaderValue), headers);

          var data = options.data || {};

          var defer = $q.defer();
          Upload.upload({
            url: url,
            headers: headers,
            file: file,
            data: data
          }).then(function (response) {
            defer.resolve(response.data);
          }, function (error) {
            defer.reject(error);
          }, function (event) {
            if (processHandler) {
              processHandler(event);
            }
          });
          return defer.promise;
        },
        get: function (url, data, headers) {
          return this.makeAjax({
            url: url,
            method: "GET",
            data: data,
            headers: headers
          });
        },
        /**
         * process GET request only once if the URL are same
         * @param url
         * @param data
         * @returns {*}
         */
        singleGet: function (url, data, cacheTime, headers) {
          var cachedRequest = getCacheRequest(url);
          if (cachedRequest) {
            return cachedRequest;
          }

          var promise = this.makeAjax({
            url: url,
            method: "GET",
            data: data,
            headers: headers
          });
          cacheRequest(url, promise, cacheTime);

          return promise;
        },
        post: function (url, data, headers) {
          return this.makeAjax({
            url: url,
            method: "POST",
            data: data,
            headers: headers
          });
        },
        put: function (url, data, headers) {
          return this.makeAjax({
            url: url,
            method: "PUT",
            data: data,
            headers: headers
          });
        },
        delete: function (url, data, headers) {
          return this.makeAjax({
            url: url,
            method: "DELETE",
            headers: headers
          });
        },
        setAuthenticateHeader: function (authTokenKey, authTokenValue) {
          this.tokenHeaderValue[authTokenKey] = authTokenValue;
        },
        removeAuthenticateHeader: function () {
          this.tokenHeaderValue = {};
        },
        all: function (promises) {
          return $q.all(promises);
        }
      };

      return ajaxObj;
    }

  ]);
