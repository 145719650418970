'use strict';

angular.module('common')
  .service('validationRules', [function() {
    var rules = {
      required: function(x) {
        return x !== '' && !_.isNull(x) && !_.isUndefined(x);
      }
    };

    return rules;
  }])
  .service('validation', ['validationRules', function(validationRules) {
    return {
      validate: function(field, rule) {
        if (!_.isUndefined(rule) && !_.isUndefined(field)) {
          return validationRules[rule](field);
        }
      }
    };
  }]);