'use strict';

angular.module('membership')
  .service('contactsSearchViewModel', [
    'membership.config', '$state', 'modalDialogService', 'Customer',
    function (config, $state, modalDialogService, Customer) {

      var viewModel = function () {

        var self = this;

        self.filterOpen = false;
        self.filter = {};

        self.datatablesConfig = {
          datasourceUrl: config.apiUrls.base + '/customersContacts/datasource',
          dtColumns: [
            {
              "bSearchable": false,
              "bVisible": false,
              "aTargets": [0]
            },
            {
              aTargets: [1],
              "sClass": "dynamic-html no-wrap",
              render: function (data, type, full, meta) {
                return "<a ui-sref='membership_customer.general({key: " + full[0] + "})' >" + data + "</a>";
              }
            },
          ],
          customFilter: self.filter,
          dom: "<'row'<'col-xs-12 col-sm-12'r>>" +
          "t" +
          "<'row'<'col-sm-2 col-xs-12 hidden-xs'l><'col-sm-4 col-xs-12 hidden-xs'<'pull-right'i>><'col-xs-12 col-sm-6'p>>"

        };

        self.createCustomer = function () {
          modalDialogService
            .showWindow('components/membership/customer/newCustomerDialog/newCustomerDialog.tpl.html', 'NewCustomerCtrl');
        };

        self.revertCustomer = function (id) {
           modalDialogService.showConfirmation('Are you sure you want to revert this customer?', 'Customers Module')
            .then(function(res) {
              if (res) {
                Customer.revertCustomer(id).then(function() {
                  self.datatablesConfig.reload();
                });  
              }
            });
        };

      };


      return viewModel;

    }
  ]);
