'use strict';

angular.module('magtac')
  .service('uploadLibraryViewModel', [
    'MagTacLibraries', 'modalDialogService',
    function (MagTacLibraries, modalDialogService) {

      var viewModel = function (lib) {

        var self = this;

        self.item = lib || {
          Name: '',
          Version: '',
          Description: ''
        };

        self.isEdit = !!lib;

        self.listeners = {};
        
        self.isWorkflow = false;
        self.setLibType = function(selectedType){
          if (selectedType == 'Workflow')
          {
            self.isWorkflow = true;
          }else{
            self.isWorkflow = false;
          }
        };

        self.onFileSelected = function () {
          if (self.file) {
            var name = (/(?!\.)[a-zA-Z]+\.\d/g.exec(self.file.name) || [])[0];
            if (!!name) {
              name = name.substr(0, name.length - 2);
            }

            self.item.Name = name || self.file.name.substr(0, self.file.name.length - 4);
            if (self.item.Name != 'Workflow'){
              self.item.Version = (/\d+\.\d+\.\d+\.\d+/ig.exec(self.file.name) || [])[0] || '';
            }else{
              self.item.Version = (/\d+\.\d+\.\d+/ig.exec(self.file.name) || [])[0] || '';
            }
            
          }
        };

        self.removeFile = function() {
          self.item.Name = '';          
          self.item.Version = '';          
          
          self.file = null;
        };

        self.on = function (action, callBack) {
          self.listeners[action] = callBack;
        };

        self.notify = function (action, params) {
          if (self.listeners[action]) {
            self.listeners[action](params);
          }
        };
          
        self.save = function (cb) {
          self.validationMessage = '';

          if (!self.file && !self.isEdit) {
            self.validatonMessage = 'File is required';
            return;
          }

          if (!self.item.Name) {
            self.validatonMessage = 'Name is required';
            return;
          }

          if (!self.item.Version) {
            self.validatonMessage = 'Version is required';
            return;
          }

          if (self.isEdit) {
            if (self.isWorkflow){
              //workflow library require differnet api call
              MagTacLibraries.updateWorkflow(self.item).then(function () {
                self.notify('save');
              });
            }else{
              MagTacLibraries.update(self.item).then(function () {
                self.notify('save');
              });
            }
          } else {
            //workflow library require differnet api call
            if (self.isWorkflow){

              MagTacLibraries.createWorkflow(self.item, self.file).then(function(){
                self.notify('save');
              });

            }else {
              MagTacLibraries.create(self.item, self.file).then(function () {
                self.notify('save');
              });
            } 
          }
          cb();
        };
      };

      return viewModel;

    }
  ]);
