angular.module('common')
    .directive('ckEditor', [
        function () { 
            return {
                require: '?ngModel',
                link: function (scope, elm, attr, ngModel) {
                    var defaultConfig = {
                        skin: 'moono,/build/ckeditor/skins/moono/'
                    };

                    // Get height from html
                    var configFromHTML = scope.$eval(attr.config) || {};
                    var editorConfig = angular.extend({}, defaultConfig, configFromHTML);
                    const ck = CKEDITOR.replace(elm[0], editorConfig);


                    if (!ngModel) return;

                    ck.on('instanceReady', function () {
                        ck.setData(ngModel.$viewValue);
                    });

                    function updateModel() {
                        scope.$apply(function () {
                            ngModel.$setViewValue(ck.getData());
                        });
                    }

                    ck.on('change', updateModel);
                    ck.on('key', updateModel);
                    ck.on('dataReady', updateModel);

                    ngModel.$render = function (value) {
                        ck.setData(ngModel.$viewValue);
                    };
                }
            };
        }
    ]);
