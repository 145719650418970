'use strict';
angular.module('ticketAssignment')
    .service('supportMetricsSettingsViewModel', ['ticketAssignment.config', 'ticketAdmin', '$state',
        function (config, Routes, $stateParams) {
            var viewModel = function () {
                console.log('view model for metrics/settings');

                var self = this;
                self.selectedDate = new Date();

                self.ignoredHolidays = ["No Holidays Selected"];

                self.getHolidays = function () {
                    Routes.getSupportMetricsHolidays().then(
                        function (response) {
                            console.log('Getting holidays');
                            self.ignoredHolidays = response;
                            console.log("ignored: " + self.ignoredHolidays);
                        }
                    );
                }


                self.addHoliday = function () {
                    let formattedDate = self.formatDate(self.selectedDate);
                    Routes.addHoliday(formattedDate).then(() => {
                        console.log('holiday added ' + formattedDate);
                        self.getHolidays();
                    });
                };

                self.deleteHoliday = function (holiday) {
                    let formattedDate = holiday.replaceAll("/", "-");
                    console.log("Deleting holiday " + formattedDate);

                    Routes.deleteHoliday(formattedDate).then(() => {
                        console.log('holiday deleted');
                        self.getHolidays();
                    });
                };

                self.formatDate = function (dateToFormat) {
                    let month = '' + (dateToFormat.getMonth() + 1);
                    let day = '' + dateToFormat.getDate();
                    let year = dateToFormat.getFullYear();

                    if (month.length < 2)
                        month = '0' + month;
                    if (day.length < 2)
                        day = '0' + day;

                    return [month, day, year].join('-');
                }


                self.getHolidays();

                // todos. 
                // sort dates before displaying (can be front end or back end)

            }
            return viewModel;
        }
    ]);
