/**
 * Created on 2/25/2016.
 */

'use strict';

angular.module('membership')
  .provider('customerUserNav', [
    function () {
      var customerUserNav =this;

      customerUserNav.navigations = [];

      customerUserNav.registerNav = function (name, state) {
        customerUserNav.navigations.push({
          name: name,
          state: state
        });
      };

      customerUserNav.$get = function() {
        return {
          navigations: customerUserNav.navigations
        }
      };

      return this;
    }
  ])
  .config(['customerUserNavProvider',
    function (customerUserNavProvider) {
      customerUserNavProvider.registerNav('User Profile', 'membership_customerUser.userProfile');
      customerUserNavProvider.registerNav('Time Management', 'membership_customerUser.timeManagement.workTime');
    }
  ]);
