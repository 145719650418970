'use strict';

angular.module('membership')
  .service('documentsViewModel', [
    'Customer',
    function (Customer) {

      var viewModel = function (company) {

        var self = this;

        if (!company.documents) {
          Customer.documents(company.Id).then(function (resp) {
            company.documents = resp;
            self.documents = resp;
          });
        } else {
          self.documents = company.documents;
        }

      };

      return viewModel;

    }
  ]);
