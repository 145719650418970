'use strict';

angular.module('ticketAssignment')
    .controller('ScoreboardCtrl', [
        '$scope', 'scoreboardViewModel',
        function ($scope, viewModel) {

            $scope.model = new viewModel();



            $scope.AVERAGE_ID = '10000000001';
            $scope.currentUserId = '10000000001';
            $scope.averageUserIsSelected = function () {
                return $scope.currentUserId == $scope.AVERAGE_ID;
            }
            $scope.updateUser = function (newUserId) {
                console.log("updating user id from " + $scope.currentUserId + " to " + newUserId);
                console.log((newUserId == $scope.AVERAGE_ID));
                $scope.averageUserIsSelected();
                $scope.currentUserId = newUserId;
            }




            $scope.currentRefreshRate = 60;
            $scope.autoRefreshEnabled = true;
            $scope.refreshPage = function () {
                window.location.reload();
            }
            $scope.updateRefreshRate = function (newRefreshRate) {
                console.log('refresh rate updated to ' + newRefreshRate);
                $scope.currentRefreshRate = newRefreshRate;
                setInterval(function () {
                    window.location.reload(1);
                }, $scope.currentRefreshRate * 60 * 1000);

                console.log('interval set at ' + ($scope.currentRefreshRate * 60 * 1000) + 'ms = ' + ($scope.currentRefreshRate) + ' minutes')
            }



            // styles
            $scope.primaryColor = 'rgba(51, 0, 115, 0.89)';     // Purple - for labels 
            $scope.secondaryColor = 'rgba(45, 11, 87, 0.9)';    // Darker purple for buttons, backgrounds etc.

            $scope.style = [
                {
                    'dividingLine': 'linear-gradient(90deg, rgb(55, 83, 197) 0%, rgb(21, 182, 246) 90%)',
                },
                {
                    'dividingLine': 'linear-gradient(90deg, rgba(81,0,136,1) 0%, rgba(163,0,200,1) 90%)',
                },
                {
                    'dividingLine': 'linear-gradient(90deg, rgba(119,0,0,1) 0%, rgba(200,0,0,1) 90%)',
                },
                {
                    'dividingLine': 'linear-gradient(90deg, rgb(200, 197, 0) 0%, rgb(247, 255, 0)90%)',
                },
                {
                    'dividingLine': 'linear-gradient(90deg, rgb(10, 121, 0) 0%, rgb(129, 231, 130) 90%) ',
                }
            ];

            $scope.opacity = function (tag) {
                if ( tag == 'PLACEHOLDER') {
                    return '0.0';
                } else {
                    return '1.0';
                }
            };
            
            $scope.currentHistogramStep = {
                "MFR3d": 2,
                "AFR3d": 20,
                "ESCR": 25
            }
            $scope.currentHistogramxMin= {
                "MFR3d": 0,
                "AFR3d": 0,
                "ESCR": 0
            }
            
            $scope.updateHistogramStep = function (tag, newHistogramStep) {
                $scope.currentHistogramStep[tag] = newHistogramStep;
            }
            $scope.updateHistogramxMin = function (tag, newHistogramxMin) {
                $scope.currentHistogramxMin[tag] = newHistogramxMin;
            }

            $scope.d = '';

            $scope.histogramBarStyle = function (tag, histogramObj, hVal, hFreq, distribution, histogram_component) {
                var invisibleStyle = {
                    "color": "rgba(0,0,0,0)", "background-color": "rgba(0,0,0,0)", "height": "0px", "width": "0px"
                }

                if (hVal.toString(10).startsWith('0') && hVal != 0) {
                    return invisibleStyle;
                }



                var distributionValues = Object.values(distribution);
                var xMin = histogramObj.xMin;
                var xMax = histogramObj.xMax;
                var yMin = histogramObj.yMin;
                var yMax = Math.max.apply(null, distributionValues);    // TODO store $scope.yMaxes = {"tag": <ymax>, "tag": <ymax>, ... ,} controller value
                // Redundant to calculate yMax for every single bar

                var dx = (xMax - xMin);
                var dy = (yMax - yMin);


                var step = $scope.currentHistogramStep[tag];
                var steps_shown = histogramObj.steps_shown;

                var histogramBarColor = histogramObj.color;


                var barToSpaceRatio = 1.0;
                var width = barToSpaceRatio * (100.0 / steps_shown);
                width = Math.ceil(width) + "%";

                var left = (hVal - xMin) / (step * steps_shown);
                left = (left * 100.0) + "%";

                var top = 1.0 - 0.85 * ((hFreq - yMin) / dy);
                top = (top * 100.0) + "%";

                if (tag == 'AFR3d') {
                    $scope.d = distributionValues.join(', ');
                    //$scope.msg += 'BAR[' + tag + '] = { {hval:' + hVal + ', hfrq:' + hFreq + '},  yMax: ' + yMax + ', left:' + left + '%, top:' + top + '%, width:' + width + '% }';
                }

                var xLabelTextColor = "rgba(0,0,0,1.0)";

                var should_display_xlabel = true;//hVal % (1 * step) == 0;

                if (hVal.toString(10).startsWith('0') && hVal != 0) {
                    should_display_xlabel = false;
                }

                if (histogram_component == 1 && should_display_xlabel) {     // && !should_display_xlabel 
                    xLabelTextColor = "rgba(0,0,0,1.0)";
                } else {
                    xLabelTextColor = "rgba(0,0,0,0)";
                }

                var hFreqTextColor = "white";
                if (hFreq == 0) {
                    hFreqTextColor = "rgba(0,0,0,0.0)";
                }


                //console.log('{hFreq:' + hFreq + ', xMin:' + xMin + ', xMax: ' + xMax + ', yMin: ' + yMin + ', yMax: ' + yMax + 'left: ' + left + '%, top:' + top + '% }');


                var histogramBarStyleObj = {
                    "left": left,
                    "top":  top,
                    "width": width,
                    "background-color": histogramBarColor,
                    "color": hFreqTextColor,

                    "font-size": "12px",
                    "font-weight": "bold",
                    "position": "absolute",
                    "height": "100%",
                    "border-radius": "2px",
                    "border": "1px solid #00000014"
                }


                var histogramLabelStyleObj = {
                    "left": left,
                    "width": width,
                    "color": xLabelTextColor,

                    "top": "0%",
                    "background-color": "rgba(0, 0, 0, 0)",
                    "position": "absolute",
                    "height": "100%",
                    "text-align": "left",
                    "font-size": "8px",
                    "font-weight": "600"
                }

                

                

                if (histogram_component == 0) {
                    return histogramBarStyleObj;
                }
                else if (histogram_component == 1) {
                    return histogramLabelStyleObj;
                }

                return histogramBarStyleObj;
            }

            $scope.generateHistogramDistribution = function (tag, dataAll, histogram) {
                //console.log('generateHistogramDistribution() called ');

                var d = {}


                var histogramConfig = histogram[tag];
                var data = dataAll[tag];
                var step = $scope.currentHistogramStep[tag];

                var xMin = $scope.currentHistogramxMin[tag]
                var xMax = histogramConfig.xMax;
                var yMin = histogramConfig.yMin;
                var yMax = histogramConfig.yMax;

                //console.log('step:' + step + ', xMin: ' + xMin + ', xMax:' + xMax);


                for (var id in data) {
                    var uValue = data[id];

                    if (uValue == null) {
                        continue;
                    }
                    

                    var fit = function (uValue, hRange) {
                        return hRange * Math.floor(uValue / hRange, 10);
                    }
                    var uFit = fit(uValue, step);
                    var uFitStr = uFit.toString(10);

                    if (uFitStr.startsWith('0') && uFitStr.length > 1) {
                        continue;
                    }

                    if ( ! d.hasOwnProperty(uFit)) {
                        d[uFit] = 0;
                    } 
                    d[uFit]++;
                }


                // populate zeros for complete histogram distribution:
                for (var j = xMin; j <= xMax; j += step) {
                    if (! d.hasOwnProperty(j)) {
                        d[j] = 0;
                    }
                }
                

                var printDistribution = false;

                if (tag == 'AFR3d' || tag == 'ESCR') {
                    // print distribution:
                    if (printDistribution) {
                        console.log('\n d ['+tag+']= {');
                        var dataStr = '';
                        for (var hVal in d) {
                            var hFreq = d[hVal];
                            dataStr += hVal + ':' + hFreq + ',\t';

                        }
                        console.log(dataStr + '}\n\n');
                    }
                }


                return d;
            }


            $scope.tileColor = function (userValue, centralTendencyValue, threshold) {
                // Background of user value portion 
                var green = 'rgb(37, 104, 44)';
                var yellow = '#C6BA02';
                var red = 'rgb(122, 0, 0)';
                var black = 'rgba(0, 0, 0, 0.87)';

                var tileColor = '';

                if ($scope.averageUserIsSelected()) {
                    //var previousAvg; if (previousAvg - centralTendencyValue > threshold) ...
                    tileColor = green;
                } else {

                    if (userValue == null) {
                        tileColor = green;
                    }
                    else if (userValue <= centralTendencyValue) {
                        tileColor = green;
                    }
                    else if (userValue <= centralTendencyValue + threshold) {
                        tileColor = green;
                    }
                    else if (userValue <= centralTendencyValue + 1.2 * threshold) {
                        tileColor = yellow;
                    }
                    else if (userValue <= centralTendencyValue + 3 * threshold) {
                        tileColor = red;
                    }

                    if (tileColor == '') {
                        //console.log('tileColor(userVal:' + userValue + ', centralTendVal:' + centralTendencyValue + ', threshold:' + threshold + ') ');
                        tileColor = deep_red;
                    }
                }

                console.log('tile color: ' + tileColor);

                var tileStyleObj = {
                    "background-color": tileColor
                }
                return tileStyleObj;
            }
            
           
            $scope.performanceCode = function (userValue, centralTendencyValue, threshold) {
                if (userValue == null) {
                    return 0;
                }
                if (userValue <= centralTendencyValue) {
                    return 1;
                }
                if (userValue <= centralTendencyValue + threshold) {
                    return 2;
                }
                if (userValue <= centralTendencyValue + 1.2 * threshold) {
                    return 3;
                }
                if (userValue <= centralTendencyValue + 3 * threshold) {
                    return 4;
                }
                return 4;
            };

            $scope.colorMode = 0;
            $scope.colorModes = {
                0: 'Dark Tiles',
                1: 'Light Tiles',
                2: 'Inverted Colors'
            }
            $scope.updateColorMode = function (newColorMode) {
                $scope.colorMode = newColorMode;
            }


            $scope.percentageColor = function (change, tag) {
                if (change == 0) {
                    return 'lightgray';
                } else if ((change < 0 && tag != 'ADCChange') || (change > 0 && tag == 'ADCChange')) {
                    return 'green';
                } else {
                    return 'red';
                }
            }

            $scope.getPercentage = function (value) {
                return Math.abs(value);
            }

            $scope.backlog_value = function (value) {
                if (value < 1) {
                    return "<1"
                } else {
                    return Math.round(value);
                }
            }
        }
    ]);