'use strict';

angular.module('magtac')
  .service('Dashboard', ['AjaxService', 'magtac.config', 'magtacSignalrService',
    function (ajaxService, config, signalrService) {
      var baseUrl = config.apiUrls.base;
      var domain = {
        getData: function () {
          return ajaxService.get(baseUrl + '/dashboard');
        },

        getForCustomer: function(customerId) {
          return ajaxService.get(baseUrl + '/dashboard/' + customerId);
        },

        onEvent: function (name, fun) {
          signalrService.dashboard.on(name, fun);
          return {
            unsubscribe: function () { signalrService.dashboard.off(name, fun); }
          };
        },
      };

      return domain;
    }
  ]);
