'use strict';

angular.module('projectsManagement')
  .controller('ProjectDetailsGeneralCtrl', [
    '$scope', '$state', 'projectDetailsGeneralViewModel', '$stateParams',
    function($scope, $state, viewModel, $stateParams) {
      $scope.model = new viewModel($scope.$parent.model.project);
      $scope.$watch('$parent.model.project', function(proj){
        if (proj.CompanyId) {
          $scope.model.init();
        }
      })
    }
  ]);
