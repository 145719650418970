/**
 * Created on 10/30/2015.
 */



angular.module('membership')
  .controller('ClientCompanyDetailController', [
    '$scope', 'ClientCompanyDetailViewModel', '$stateParams',
    function (scope, ClientCompanyDetailViewModel, $stateParams) {
      scope.clientCompanyId = $stateParams.clientCompanyId;
      scope.vm = new ClientCompanyDetailViewModel();
    }
  ]);
