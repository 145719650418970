'use strict';

angular.module('membership')
  .service('UserGroup', [
    'AjaxService', 'membership.config', function (AjaxService, config) {

      var base = config.apiUrls.base + '/userGroup';

      var domain = {
        get: function (id, callback) {
          AjaxService.get(base + '/' + id).then(callback);
        },
        insert: function (group, callback) {
          AjaxService.post(base + '/post', group).then(callback);
        },
        update: function (id, group, callback) {
          AjaxService.put(base + '/' + id, group).then(callback);
        },
        remove: function (id, callback) {
          AjaxService.delete(base + "/" + id).success(callback);
        }
      };

      return domain;
    }
  ]);
