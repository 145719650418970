'use strict';

angular.module('magtac')
  .service('statusHistoryViewModel', [
    'magtac.config', 'StatusPageLookup', 'TimerConfig', '$filter', '$popover',
    function (config, lookup, TimerConfig, $filter, $popover) {

      var viewModel = function (companyId) {

        var self = this;

        lookup.observerNames().then(function(data) {
          self.observerNames = data;
          var applications = _.uniq(_.map(self.observerNames, function(o) { return o.ApplicationName; }));
          var typeApps = _.map(self.observerNames, function(o, i){ return {type: i, app: o.ApplicationName} });
          self.applications = _.map(applications, function (app) {
            return { app: app, types: _.pluck(_.where(typeApps, { app: app}), 'type') };
          })
        });

        TimerConfig.getServers(companyId).then(function(resp){
          self.servers = resp;
        });

        TimerConfig.get(companyId).then(function (resp) {
          self.timers = resp;
        });

        self.toggleMode = function(mode){
          self.filter.mode = mode;
          self.filter.task = null;
        };

        self.getTaskFullName = function(t){
          if(!self.observerNames[t.TaskName]){
            return '';
          }
          var name = self.observerNames[t.TaskName].Description;
          if (t.Parameters.instances) {
            name += " (" + t.Parameters.instances + ")";
          }
          return name;
        }

        self.filter = { companyId: companyId, types: '', mode: 'Monitor', dateMode: 'Today', dateFrom: '', dateTo: '' };
        self.datatablesConfig = {
          dom: "<'row'<'col-xs-12 col-sm-12'r>>" +
          "t" +
          "<'row'<'col-sm-2 col-xs-12 hidden-xs'l><'col-sm-4 col-xs-12 hidden-xs'<'pull-left'>><'col-xs-12 col-sm-6 short-paging'p>>",
          datasourceUrl: config.apiUrls.base + '/datasource/taskHistory',
          defaultSortingColumn: "3",
          defaultSortingOrder: "desc",
          dtColumns: [
            {
              "bSearchable": false,
              "bVisible": false,
              "aTargets": [0]
            },
            {
              "aTargets": [1],
              "render": function (data, type, full, meta) {
                if (!self.observerNames[data]) {
                  return '';
                }
                return self.observerNames[data].ApplicationName;
              }
            },
            {
              "aTargets": [2],
              "render": function (data, type, full, meta) {
                var task = _.findWhere(self.timers, { Id: parseInt(full[8]) });
                if(!task) {
                  if (!self.observerNames[data]) {
                    return '';
                  }
                  return self.observerNames[data].Description;
                }
                return self.getTaskFullName(task);
              }
            },
            {
              "aTargets": [3],
              "render": function (data, type, full, meta) {
                var date = new Date(data + ' UTC');
                if(!date){
                  return data;
                }
                return $filter('date')(date, 'M/d/yyyy h:mm:ss a');
              }
            },
            {
              "aTargets": [4],
            }, {
              "aTargets": [5],
              "render": function (data, type, full, meta) {
                var date = new Date(full[6] + ' UTC');
                if(!!date){
                  data = data.replace('{0}', $filter('date')(date, 'M/d/yyyy h:mm:ss a'));
                }
                
                if (full[9]) {
                  var link = $("<a href='javascript:void(0)' popover>Show more...</a>");
                  link.attr('data-content', full[9]);

                  return $('<span>').text(data).append($('<br>')).append(link).html();
                }
                return data;
              }
            }, {
              "aTargets": [6],
              "bVisible": false
              //"mData": 4,
              // "iDataSort": 4
            },
            {
              "bSearchable": false,
              "bVisible": false,
              "aTargets": [8]
            },
            {
              "bSearchable": false,
              "bVisible": false,
              "aTargets": [9]
            } /*,
            {
              "aTargets": -1,
              "bSearchable": false,
              "bSortable": false,
              "data": null,
              "sWidth": 50,
              "sClass": "actions-col dynamic-html no-wrap",
              "render": function (data, type, full, meta) {

                return "<a class='btn btn-success' ui-sref='statusPage_statusHistoryDetails({id: " + data[0] + "})' title='View Details' ><i class='fa fa-eye'></i></a>";
              }
            }*/
          ],
          fnDrawCallback: function() {
            $('.status-history-page table [popover]').each(function() {
              $popover($(this), { placement: 'top',content: $(this).attr('data-content'), trigger: 'click', autoClose: true});
            });
          },
          customFilter: self.filter,
          skipCounting: true,
          sPaginationType: "simple_numbers"
        };
      };

      return viewModel;

    }
  ]);
