'use strict';

angular.module('wrapper')
  .config([
    '$stateProvider',
    'appStateConfigProvider',
    '$urlRouterProvider',
    'membership.configProvider',
    '$locationProvider',
    function ($stateProvider,
              appStateConfigProvider,
              $urlRouterProvider,
              ) {

      // override modules configuration at app level
      appStateConfigProvider.deleteState('membership_user_my_account.changePassword');
      appStateConfigProvider.deleteState('membership_customerUser.userProfile.changePassword');
      appStateConfigProvider.deleteState('membership_userAccount.changePassword');

      // override some states here

      var overrideDefinition = [{
        name: 'membership_company',
        url: "/membership/customers/list"
      }, {
        name: 'membership_company_details',
        url: "/membership/customers/details/:id",
        templateUrl: 'app/templates/customerDetail/details.html'
      }];

      appStateConfigProvider.overrideStates(overrideDefinition);

      // register all states
      appStateConfigProvider.statesList.forEach($stateProvider.state);

      // default path


    }]);

