/**
 * Created on 10/6/2015.
 */
angular.module('membership')
  .directive('selectClientCompany', [
    'ClientCompanyDomain', '$q', 'translationSvc',
    function (ClientCompanyDomain, $q, translationSvc) {
      return {
        restrict: "EA",
        templateUrl: function (element, attrs) {
          return attrs.templateUrl || "components/common/templates/commonSelectionWithAutoComplete.html";
        },
        require: 'ngModel',
        scope: {
          bindingProperty: "@bindingProperty"
        },
        link: function (scope, element, attr, ngModel) {
          var self = scope;
          self.optionsList = [];
          var defer = $q.defer();
          self.ensureDataLoaded = defer.promise;

          self.selectedValue = {};

          self.getDisplayValue = function (item) {
            return item ? item.displayName : "";
          };

          if (!scope.optionalDisplayText) {
            translationSvc.t_async('membership.lbl.selectClientCompany').then(function (value) {
              scope.optionalDisplayText = value;
            });
          }

          self.reload = function (filter) {
            return ClientCompanyDomain.getAll().then(function (response) {
              self.optionsList = response.map(function (r) {
                r.displayName = r.Name;
                return r;
              });
              self.optionsList.splice(0, 0, {
                Id: '',
                displayName: scope.optionalDisplayText || translationSvc.t('membership.lbl.selectClientCompany')
              });
            })
              .finally(function () {
                defer.resolve();
              });
          };

          scope.$watch('selectedValue.selected', function () {
            if (scope.selectedValue.selected)
              ngModel.$setViewValue(scope.selectedValue.selected[scope.bindingProperty]);
          }, true);


          scope.$watch(function () {
            return ngModel.$modelValue;
          }, function (newValue) {
            scope.ensureDataLoaded.then(function () {
              scope.selectedValue.selected = _.find(self.optionsList, function (user) {
                return user[scope.bindingProperty] == newValue;
              });

              if (!scope.showOptionalValue) {
                scope.optionalDisplayText = self.getDisplayValue(scope.selectedValue.selected);
              }
            });
          });
        }
      };
    }
  ]);
