'use strict';

angular.module('magview')
  .service('BuildLogViewModel', ['magview.config', 'BuildLog', 'modalDialogService', '$window',
    function (config, BuildLog, modalDialogService, $window) {

      var viewModel = function () {
        var self = this;
        self.projectsEnum = {
          Portal: 1,
          Techpad: 2,
          Portal2: 3,
          Techpad3: 4,
          Portal3: 5,
          Techpad4: 6
        };
        self.projectArtifacts = {
          PortalWeb: 0,
          PortalCustom: 1,
          Techpad: 2,
          Portal: 3,
          MigrationTool: 4,
          TechpadDiagramX86: 5,
          TechpadDiagramX64: 6,
          Portal2: 7,
          MigrationTool2: 8,
          Techpad3: 9,
          TechpadDiagramX64_3: 10,
          TechpadDiagramX86_3: 11,
          Portal3: 12,
          MigrationTool3: 13,
          Techpad4: 14,
          TechpadDiagramX64_4: 15,
          TechpadDiagramX86_4: 16,
          TechpadElectron: 17
        };
        self.activeProject = self.projectsEnum.Portal;
        self.filter = { project: self.activeProject };

        self.datatablesConfig = {
          datasourceUrl: config.apiUrls.base + '/buildLog/datasource',
          defaultSortingColumn: "1",
          defaultSortingOrder: "desc",
          dtColumns: [
            {
              "bSearchable": false,
              "bSortable": false,
              "aTargets": [0]
            }
            , {
              "bSearchable": false,
              "bSortable": false,
              "aTargets": [1],
              "sClass": "dynamic-html",
              "render": function (val, param1, data) {
                //Portal v1
                //after bamboo build #207 custom folder was included to portal archive
                //all artifacts before build #263 for Portal v1 were lost
                if (self.activeProject == self.projectsEnum.Portal) {
                  if (data[1] < 207) {
                    return '<span><b>#' + data[1] + '</b></span><br/><br/>'
                      + '<span>Artifacts for this build are not avaliable.</span>';
                  }
                  else if (data[1] <= 263) {
                    return '<span><b>#' + data[1] + '</b></span><br/><br/>'
                      + '<span>Artifacts for this build are not avaliable.</span>';
                  }
                  else if (data[1] > 263) {
                    return '<span><b>#' + data[1] + '</b></span><ul><div class="bambooVersionBuild"><li><a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.Portal + ',' + data[1] + ')">Portal</a></li> ' +
                      '<li><a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.MigrationTool + ',' + data[1] + ')">MigrationTool</a></li></div></ul>';
                  }
                }
                else if (self.activeProject == self.projectsEnum.Portal2) {
                  //Portal v2
                  //all artifacts before Portal v2 build #45 were lost
                  if (data[1] < 45) {
                    return '<span><b>#' + data[1] + '</b></span><br/>'
                      + '<span>Artifacts for this build are not avaliable.</span>';
                  }
                  else if (data[1] >= 45 && self.activeProject == self.projectsEnum.Portal2) {
                    return '<span><b>#' + data[1] + '</b></span><ul><div class="bambooVersionBuild"><li><a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.Portal2 + ',' + data[1] + ')">Portal 2.0</a></li> ' +
                      '<li><a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.MigrationTool2 + ',' + data[1] + ')">MigrationTool</a></li></div></ul>';
                  }
                }

                else if (self.activeProject == self.projectsEnum.Portal3) {
                  //Portal v3
                  //all artifacts before Portal v3 build #207 were lost
                  if (data[1] < 207) {
                    return '<span><b>#' + data[1] + '</b></span></b></span><br/>'
                      + '<span>Artifacts for this build are not avaliable.</span>'
                  }
                  else if (data[1] >= 207) {
                    return '<span><b>#' + data[1] + '</b></span><ul><div class="bambooVersionBuild"><li><a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.Portal3 + ',' + data[1] + ')">Portal 3.0</a></li> ' +
                      '<li><a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.MigrationTool3 + ',' + data[1] + ')">MigrationTool</a></li></div></ul>';
                  }
                }

                else if (self.activeProject == self.projectsEnum.Techpad3) {
                  //Techpad v3
                  //all artifacts before Techpad v3 build #49 were lost
                  if (data[1] < 49) {
                    return '<span><b>#' + data[1] + '</b></span><br/>'
                      + '<span>Artifacts for this build are not avaliable.</span>';
                  }
                  else if (data[1] >= 49 && self.activeProject) {
                    return '<span><b>#' + data[1] + '</b></span><ul><div class="bambooVersionBuild"><li><a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.Techpad3 + ',' + data[1] + ')">Techpad</a></li>'
                      + '<li><span></span> <a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.TechpadDiagramX86_3 + ',' + data[1] + ')">TechpadDiagram_x86</a></li>'
                      + '<li><span></span> <a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.TechpadDiagramX64_3 + ',' + data[1] + ')">TechpadDiagram_x64</a></li></div></ul>';
                  }
                  //Techpad V3
                  else if (data[1] >= 49) {
                    return '<span><b>#' + data[1] + '</b></span><ul><div class="bambooVersionBuild"><li><a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.Techpad3 + ',' + data[1] + ')">Techpad</a></li>'
                      + '<li><span></span> <a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.TechpadDiagramX86_3 + ',' + data[1] + ')">TechpadDiagram_x86</a></li>'
                      + '<li><span></span> <a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.TechpadDiagramX64_3 + ',' + data[1] + ')">TechpadDiagram_x64</a></li></div></ul>';
                  }
                }

                else if (self.activeProject == self.projectsEnum.Techpad4) {
                  //Techpad v4 with WPF app
                  if (data[1] < 54) {
                    return '<span><b>#' + data[1] + '</b></span><ul><div class="bambooVersionBuild"><li><a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.Techpad4 + ',' + data[1] + ')">Techpad</a></li>';
                  }
                  //Techpad v4 with electron app
                  else if (data[1] >= 54 && self.activeProject == self.projectsEnum.Techpad4) {
                    return '<span><b>#' + data[1] + '</b></span><ul><div class="bambooVersionBuild"><li><a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.Techpad4 + ',' + data[1] + ')">Techpad</a></li>'
                      + '<li><span></span> <a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.TechpadElectron + ',' + data[1] + ')">Techpad Electron</a></li>'
                  }
                }

                //Techpad v2
                else {
                  //all artifacts before Techpad v2 build #49 were lost
                  if (data[1] >= 107) {
                    return '<span><b>#' + data[1] + '</b></span><ul><div class="bambooVersionBuild"><li><a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.Techpad + ',' + data[1] + ')">Techpad</a></li>'
                      + '<li><span></span> <a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.TechpadDiagramX86 + ',' + data[1] + ')">TechpadDiagram_x86</a></li>'
                      + '<li><span></span> <a class="artifact-link" target="_blank" ng-click="model.getArtifact(' + self.projectArtifacts.TechpadDiagramX64 + ',' + data[1] + ')">TechpadDiagram_x64</a></li></div></ul>';
                  }
                  else {
                    return '<span><b>#' + data[1] + '</b></span><br/><span>Artifacts for this build are not avaliable.</span>';
                  }
                }
              }
            },
            {
              "bSearchable": false,
              "bSortable": false,
              "aTargets": [2]
            },
            {
              "bSearchable": false,
              "bSortable": false,
              "aTargets": [3]
            },
            {
              "bSearchable": false,
              "bSortable": false,
              "aTargets": [4],
              "sClass": "dynamic-html",
              "render": function (val, param1, data) {
                var content = "";
                data[4].split(";").forEach(function (ticket) {
                  if (ticket == "") return;
                  ticket = ticket.substring(1, ticket.length - 1);
                  var url = ticket.split("##")[3] ? ticket.split("##")[3] : "";
                  var number = ticket.split("##")[1].substring(0, ticket.split("##")[1].length - 6);
                  var title = ticket.split("##")[2].substring(0, ticket.split("##")[2].length - 4);
                  if (!url || !title) {
                    content += !url ? '<a class="anchor">' + number + '</a>' : '<a target="_blank" href=' + '"' + url + '"' + '>' + number + '</a>';
                    content += !title ? '<br/>' : ': ' + title + '<br/>';
                  } else {
                    content += '<a target="_blank" href=' + '"' + url + '"' + '>' + number + '</a>: ' + title + '<br/>';
                  }
                });
                return content;
              }
            },
            {
              "bSearchable": false,
              "bSortable": false,
              "aTargets": [5],
              "sClass": "dynamic-html",
              "render": function (val, param1, data) {
                if (self.activeProject == self.projectsEnum.Portal && data[1] <= 263) {
                  return '';
                }
                else if (self.activeProject == self.projectsEnum.Portal2 && data[1] < 45) {
                  return '';
                }
                else if (self.activeProject == self.projectsEnum.Portal3 && data[1] < 207) {
                  return '';
                }
                else if (self.activeProject == self.projectsEnum.Techpad && data[1] < 107) {
                  return '';
                }
                else if (self.activeProject == self.projectsEnum.Techpad3 && data[1] < 49) {
                  return '';
                }
                return '<button class="btn btn-primary m-b-10 btn-sm" ng-click="model.reloadBuildLog(' + data[1] + ',' + self.activeProject + ')"><i class="glyphicon glyphicon-refresh"></i></button>';
              }
            }
          ],
          customFilter: self.filter,
        };

        self.updateBuildLogs = function () {
          BuildLog.updateBuildLogs(self.activeProject).then(function (resp) {
            self.datatablesConfig.reload();
            console.log(resp);
          },
            function (error) {
              var message = error.data.ExceptionMessage ? error.data.ExceptionMessage : error.data.Message;
              modalDialogService.showAlert(message, "Error updating build logs");
            });
        };

        self.reloadBuildLog = function (externalLogId, activeProject) {
          BuildLog.reloadBuildLog(externalLogId, activeProject).then(function (resp) {
            self.datatablesConfig.table.draw(false);
            console.log(resp);
          },
            function (error) {
              var message = error.data.ExceptionMessage ? error.data.ExceptionMessage : error.data.Message;
              modalDialogService.showAlert(message, "Error updating build log: " + externalLogId);
            });
        };

        self.changeProject = function (activeProject) {
          self.activeProject = activeProject;
          self.datatablesConfig.customFilter = { project: activeProject };
          self.datatablesConfig.reload();
        };

        self.getActiveProjectName = function () {
          if (self.activeProject == 1) {
            return 'Portal';
          } else if (self.activeProject == 2) {
            return 'Techpad 2.0';
          } else if (self.activeProject == 3) {
            return 'Portal 2.0';
          } else if (self.activeProject == 4) {
            return 'Techpad 3.0';
          } else if (self.activeProject == 5) {
            return 'Portal 3.0';
          }
          else if (self.activeProject == 6) {
            return 'Techpad 4.0';
          }
        };

        self.getArtifact = function (artifact, externalLogId) {
          var url = '' + config.apiUrls.base + '/buildLog/LoadArtifact/' + artifact + '/' + externalLogId;
          $window.open(url, "_parent", '');
        };
      };

      return viewModel;

    }
  ]);
