'use strict';

angular.module('ticketAssignment')
    .service('ticketAdmin', ['AjaxService', 'ticketAssignment.config',
        function (ajaxService, config) {
            var baseUrl = config.apiUrls.base;
            var domain = {
                getAllTickets: function () {
                    return ajaxService.get(baseUrl + '/tickets');
                },
                getAllTicketEvents: function () {
                    return ajaxService.get(baseUrl + '/auditEvent')
                },
                getAssignmentEventDetails: function (eventId) {
                    return ajaxService.get(baseUrl + '/auditEvent/' + eventId);
                },
                getAllAgents: function () {
                    return ajaxService.get(baseUrl + '/agents/all');
                },
                getAssignedAgentLookups: function () {
                    return ajaxService.get(baseUrl + '/lookups/agents-assigned');
                },
                getAssignedCompanyLookups: function () {
                    return ajaxService.get(baseUrl + '/lookups/companies-assigned');
                },
                getActivityReport: function (parameters) {
                    return ajaxService.get(baseUrl + '/report/' + parameters);
                },
                getActivityReportAgents: function () {
                    return ajaxService.get(baseUrl + '/activity');
                },
				getScoreboardMetrics: function () {
                    return ajaxService.get(baseUrl + '/scoreboard/');
                },
                getTeamsTickets: function (data) {
                    console.log('getting teams tickets:');
                    console.log(data);
                    return ajaxService.post(baseUrl + '/team-tickets/', data);
                },
                getTeamsTicketsOld: function (data) {
                    return ajaxService.post(baseUrl + '/team-tickets-old/', data);
                },
                getLastUpdateTimestamp: function () {
                    console.log("getting last update timestamp");
                    return ajaxService.get(baseUrl + '/last-update-timestamp/');
                },
                getCompanyTicketsOld: function (filters) {
                    return ajaxService.post(baseUrl + '/company-tickets-old/', filters)
                },
                getCompanyTickets: function (filters) {
                    return ajaxService.post(baseUrl + '/company-tickets/', filters)
                },
                getAgentsInTeamOld: function (teamName, filters) {
                    return ajaxService.post(baseUrl + '/team-agents-old/' + teamName, filters)
                },
                getAgentsInTeam: function (teamName, filters) {
                    return ajaxService.post(baseUrl + '/team-agents/' + teamName, filters)
                },
                getAgentTickets: function (filters) {
                    return ajaxService.post(baseUrl + '/agent-tickets/', filters)
                },
                getAgentTicketsOld: function (filters) {
                    return ajaxService.post(baseUrl + '/agent-tickets-old/', filters)
                },
                getSupportMetrics: function () {
                    return ajaxService.get(baseUrl + '/support-metrics/');
                },
                getSupportMetricsOld: function () {
                    return ajaxService.get(baseUrl + '/support-metrics-old/');
                },
                getSupportMetricsHolidays: function () {
                    return ajaxService.get(baseUrl + '/support-metrics-holidays/');
                },
                addHoliday: function (holiday) {
                    console.log("in domains - adding holiday: " + holiday);
                    return ajaxService.post(baseUrl + '/add-metrics-holiday/' + holiday);
                },
                deleteHoliday: function (holiday) {
                    console.log("in domains - deleting holiday: " + holiday);
                    return ajaxService.delete(baseUrl + '/delete-metrics-holiday/' + holiday);
                },
                getTicketTypes: function () {
                    return ajaxService.get(baseUrl + '/ticket-types/');
                },
                getTicketStatuses: function () {
                    return ajaxService.get(baseUrl + '/ticket-statuses/');
                },
                getTicketTypesOld: function () {
                    return ajaxService.get(baseUrl + '/ticket-types-old/');
                },
                getTicketStatusesOld: function () {
                    return ajaxService.get(baseUrl + '/ticket-statuses-old/');
                },
				getAllAlerts: function () {
                    return ajaxService.get(baseUrl + '/alert-configuration')
                },
                modifyAlertConfiguration: function (request) {
                    return ajaxService.post(baseUrl + '/alert-configuration/update', request);
                },
                getSearchResults: function (searchFilter, pageNo, perPage, query) {
                    if (searchFilter == 0) {
                        console.log("No search filter specified. No results can be returned.")
                        return {"search result" : "No search filter specified. Please select at least one option and try again"}
                    }

                    return ajaxService.get(baseUrl + '/tickets-search/' + searchFilter + '/' + pageNo + '/' + perPage + '/' + query);
                },
            };

            return domain;
        }
    ]);