/**
 * Created on 11/14/2015.
 */

'use strict';

angular.module('common')
  .service('PageTitleService', [
    '$rootScope', 'translationSvc',
    function ($rootScope, translationSvc) {
      var PageTitleService = {
        setPageTitle: function (title) {
          $rootScope.contextTitle = translationSvc.t(title);
        }
      };

      return PageTitleService;
    }
  ]);
