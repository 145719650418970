/**
 * Created on 9/28/2015.
 */
angular.module('common-config', [])
  .provider('common.config', function () {
    this.config = {
      apiUrls: {
        base: 'common.api'
      },
      momentDateFormat: 'YYYY-MM-DD hh:mm:ss A',
      momentDateOnlyFormat: 'YYYY-MMM-DD',
      coreSettingKey: 'lean35.core.generalSettings'
    };

    this.$get = function () {
      return this.config;
    };

    return this;
  });
