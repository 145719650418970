'use strict';

angular.module('projectsManagement', [
  'projectsManagement-config',
  'ngCookies',
  'ngSanitize',
  'ngAnimate',
  'mgcrea.ngStrap',
  'navigation',
  'ui.router',
  'common',
  //'statusPage',
  'siteSettings',
  'membership',
  'bcherny/formatAsCurrency'
]);
