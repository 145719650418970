'use strict';

// styles from libraries
import 'bootstrap/dist/css/bootstrap.css';
import 'angular-toastr/dist/angular-toastr.css';
import 'animate.css/animate.css';
import 'ui-select/dist/select.css';
import 'angular-wizard/dist/angular-wizard.min.css';
import 'datatables/media/css/jquery.dataTables.css';
import 'font-awesome/css/font-awesome.css';
import 'select2/select2.css';
import 'bootstrap-select/dist/css/bootstrap-select.css';

// custom styles
import './index.css';
import 'common/styles/announcement.css';
import 'common/styles/listPage.css';
import 'common/styles/loadingSpinner.css';
import 'common/styles/quartz-cron.css';
import 'magtac/styles/magtac.css';
import 'documentsManagement/styles/documentsManagement.css';
import 'magview/styles/magtac.css';
import 'membership/styles/customer.css';
import 'membership/styles/membership.css';
import 'membership/styles/user-profile.css';
import 'projectsManagement/styles/projectsManagement.css';
import 'navigation/styles/navbar.css';
import 'ticketAssignment/styles/ticketAssignment.css';

// 3rd party scripts and dependencies
import './basePath.js'; // config for ckeditor4
import * as angular from 'angular';
import 'angular-animate';
import 'angular-cookies';
import 'angular-touch';
import 'angular-sanitize';
import 'angular-strap';
import 'angular-ui-router';
import 'angular-wizard';
import 'ui-select';
import 'angular-aria';
import 'angular-messages';
import 'angular-moment';
import 'angular-translate';
import 'angularjs-dropdown-multiselect';
import 'ng-file-upload';
import 'angular-storage';
import 'angular-ui-select2/src/select2';
import 'angular-toastr';
import 'jquery';
import 'signalr';
import 'lodash';
import 'moment';
import '@uirouter/angularjs';
import '@uirouter/core';
import 'bootstrap';
import 'bootstrap-select';
import 'datatables';
import 'format-as-currency';
import 'jquery.cookie';
import 'moment-timezone';
import 'ng-file-upload';
import 'select2';

import 'ckeditor4';
import 'cron-parser';


angular.module('wrapper',
  [
   'ngAnimate',
    'ngCookies',
    'ngTouch',
    'ngSanitize',
    'mgcrea.ngStrap',
    'ui.router',
    'mgo-angular-wizard',
    'ui.select',
    'ngAria',
    'ngMessages',
    'angularMoment',
    'pascalprecht.translate',
    'angularjs-dropdown-multiselect',
    'ngFileUpload',
    'angular-storage',
    'ui.select2',
    'modules'
  ])
  .config(['membership.configProvider', '$locationProvider', function(membershipConfigProvider, $locationProvider) {
    membershipConfigProvider.config.enableJiraLogin = true;
    $locationProvider.html5Mode(true).hashPrefix('!');

  }])
  .run([
    '$rootScope', '$state', 'authenticationService', 'atlassianIssueCollector',
    function($rootScope, $state, authenticationService, atlassianIssueCollector) {
      $rootScope.signOut = function () {
        authenticationService.signOut();
      };

      atlassianIssueCollector.init('https://magview.atlassian.net/s/522cd56aa857a31e7316402588eb2198-T/en_US-jvd4ec/72000/2b2a0a761d4daa2807107b2b08347772/2.0.13/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=6af6e686');

      authenticationService.authCheck();
      authenticationService.authServerCheck();

      $rootScope.isPageAvailable = $rootScope.isPageAvailable || function() { return true; };
      $rootScope.isFeatureAllowed = $rootScope.isFeatureAllowed || function() { return true; };
      $rootScope.isStateAvailable = $rootScope.isStateAvailable || function() { return true; };

      $rootScope.staticContentVersion = (new Date()).getTime();

      $rootScope.safeApply = function(fn) {
        var phase = this.$root.$$phase;
        if (phase == '$apply' || phase == '$digest') {
          if (fn && (typeof (fn) === 'function')) {
            fn();
          }
        } else {
          this.$apply(fn);
        }
      };
      $rootScope.state = $state;
    }
  ]);
