'use strict';

angular.module('ticketAssignment')
    .controller('ticketSearchCtrl', [
        '$scope', 'ticketSearchViewModel', '$state', '$stateParams',
        function ($scope, viewModel, $state, $stateParams) {
            $scope.model = new viewModel($stateParams.searchFilter, $stateParams.pageNo, $stateParams.perPage, $stateParams.query);

            $scope.get_ticket_link = function (TicketId) { return 'https://magview.freshdesk.com/a/tickets/' + TicketId; }

            $scope.GetSearchLink = function (code, query) { return '/search/' + code + '/0/100/' + query; }
            

            $scope.get_company_link = function (companyId) { return (companyId == undefined || companyId == null) ? '/' : '/customer/' + companyId + '/general'; }

            $scope.IsExpanded = function (note) { return $scope.model.textExpanded[note.NoteId]; };

            $scope.ExpandOrCollapse = function (note) { $scope.model.textExpanded[note.NoteId] = !$scope.model.textExpanded[note.NoteId]; };

            $scope.breakIntoSegments = function (note) {
                var parameter = $scope.IsExpanded(note) ? 'FullText' : 'PartialText';
                return emboldenSearchTerms(note[parameter]).split("<bold>");
            };

            function emboldenSearchTerms(paragraph) {
                var emboldenedString = paragraph;
                $scope.model.searchTerms.forEach(function (searchTerm) {
                    var re = new RegExp(searchTerm, "ig");
                    paragraph = paragraph.replace(re,"<bold>$&<bold>");
                });
                return paragraph;
            };


            $scope.page = {
                generated:
                {
                    url: $scope.model.link,
                    filterCode: 4,
                },
                elements:
                {
                    hidden : true,
                    openTickets : { value: true },
                    closedTickets : { value: true },
                    searchbar : { value: ''},
                    searchButton : {valid: true,  },
                },
            };

            $scope.ShowOrHidePageElements = function () { $scope.page.elements.hidden = ! $scope.page.elements.hidden; };

            $scope.checkForValidSearch = function () {
                var query = $scope.page.elements.searchbar.value;
                if (!query) {
                    query = $scope.model.query;
                }
                console.log($scope);
                var filters = ($scope.model.openFilter ? 1 : 0) + ($scope.model.closedFilter ? 3 : 0);
                $scope.page.generated = { url: $scope.GetSearchLink(filters, query), filterCode: filters };
                $scope.page.elements.searchButton.valid = (filters != 0 && query != undefined && query.length > 0);
            }

            $scope.Update = function (element, value) {
                $scope.page.elements[element].value = value;
                $scope.checkForValidSearch();
            }

            $scope.autocompletePlaceholder = function () {
                if (!$scope.searchbar) {
                    $scope.page.elements.searchbar.value = $scope.model.info.query;
                    $scope.searchbar = $scope.model.info.query;
                    $scope.page.elements.searchButton.valid = true;
                    $scope.checkForValidSearch();
                }
            };


            var appendedPunctuationList = ['.', ',', ';', '-'];

            $scope.IsSearchTerm = function (word) {

                word = word.trim();
                var word = word.toLowerCase();
                return $scope.model.searchTerms.includes(word);
               
            };

            $scope.paginatedLinkStyle = function (paginationObj) {
                var styleObj =
                {
                    'font-weight': (paginationObj.currentPage ? 'bolder' : 'normal'),
                    'background-color': (paginationObj.currentPage ? 'rgb(85, 110, 155)' : 'unset'),
                    'color': (paginationObj.currentPage ? 'white' : 'unset'),
                    'text-shadow': (paginationObj.currentPage ? '1px 1px 0px rgba(0, 0, 0, 0.35)' : 'unset'),
                    'cursor': (paginationObj.currentPage ? 'not-allowed' : 'pointer')
                };
                return styleObj;
            };
        }
    ]);
