/**
 * Created on 10/14/2015.
 */
angular.module('common')
  .service('notifySvc', [
    'toastr', 'translationSvc',
    function (toastr, translationSvc) {
      return {
        success: function (options) {
          toastr.success(options.msg || translationSvc.t(options.i18msg), options.title || translationSvc.t(options.i18title));
        },
        error: function (options) {
          toastr.error(options.msg || translationSvc.t(options.i18msg), options.title || translationSvc.t(options.i18title));
        },
        info: function (options) {
          toastr.info(options.msg || translationSvc.t(options.i18msg), options.title || translationSvc.t(options.i18title));
        }
      };
    }
  ]);
