/**
 * Created on 10/2/2015.
 */
angular.module('membership')
  .service('ClientCompanyDomain', [
    'AjaxService', 'membership.config',
    function (ajaxService, config) {

      var apiUrl = config.apiUrls.base + "/clientCompany";
      var services = {
        getAll: function () {
          return ajaxService.get(apiUrl);
        },
        getById: function (id) {
          return ajaxService.get(apiUrl + '/' + id);
        },
        create: function (companyData) {
          return ajaxService.post(apiUrl, companyData);
        },
        update: function (companyId, companyData) {
          return ajaxService.put(apiUrl + '/' + companyId, companyData);
        },
        delete: function (companyId) {
          return ajaxService.delete(apiUrl + '/' + companyId);
        }
      };

      return services;
    }]);
