/**
 * Created on 1/6/2016.
 */

angular.module('membership')
  .run([
    '$rootScope',
    'membership.config',
    'modalDialogService',
    'BizUsers',
    'UserRegisterDomain',
    'authorizeService',
    function ($rootScope,
              membershipConfig,
              modalDialogService,
              BizUsers,
              UserRegisterDomain,
              authorizeService) {

      $rootScope.$on(membershipConfig.events.renderingUserActions, function (scope, data) {
        if (!data.handler.remove)
          data.handler.remove = function (userId) {
            modalDialogService.showConfirmation("Are you sure want to delete this user ?", "User deletion confirmation", "dialog-warning").then(function (result) {
              if (result) {
                BizUsers.remove(userId).then(function () {
                  modalDialogService.showAlert("User deleted successfully", "Delete User Alert", "dialog-success");
                }, function () {
                  modalDialogService.showAlert("Error while deleting user", "Delete User Alert", "dialog-warning");
                }).finally(function () {
                  data.handler.triggerSearch();
                });
              }
            });
          };

        if (!data.handler.approveUser)
          data.handler.approveUser = function (userId) {
            UserRegisterDomain.approveUser(userId).then(function () {
              data.handler.triggerSearch();
            });
          };

        var editBtn = "<a class='btn btn-success btn-xs' ui-sref='membership_userAccount.personalInfo({userId: " + data.user.Id + "})' title='Edit' ><i class='fa fa-edit'></i></a>";

        var deleteBtn = "<a class='btn btn-danger btn-xs' ng-if='!vm.showDeletedOnly' ng-click='vm.remove(" + data.user.Id + ")' title='Delete'><i class='fa fa-times'></i></a>";

        data.actionButtons.push({
          template: editBtn,
          order: 0
        });

        data.actionButtons.push({
          template: deleteBtn,
          order: 1
        });

        if (!data.user.IsApproved && authorizeService.isFeatureAllowed(membershipConfig.permissions.approveUserRegistration)) {
          data.actionButtons.push({
            template: "<a class='btn btn-info' ng-click='vm.approveUser(" + data.user.Id + ")' title='Approve user' ><i class='fa fa-check'></i></a>",
            order: 10
          });
        }
      });
    }
  ]);
