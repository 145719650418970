angular.module('common')
  .directive('pageTitle', [
    'PageTitleService',
    function (PageTitleService) {
      return {
        restrict: "EA",
        scope: {
          pageTitle: "=pageTitle"
        },
        link: function (scope, element, attribute) {
          PageTitleService.setPageTitle(scope.pageTitle);

          scope.$watch('pageTitle', function () {
            PageTitleService.setPageTitle(scope.pageTitle);
          }, true);
        }
      }
    }
  ]);
