'use strict';

angular.module('membership')
  .controller('OwnershipCtrl', [
    '$scope', 'ownershipViewModel',
    function ($scope, viewModel) {
      $scope.model = new viewModel();
      $scope.toggleOpen = function () {
        $scope.model.filterOpen = !$scope.model.filterOpen;
      };
    }
  ]);
