/**
 * Created on 10/27/2015.
 */

'use strict';

angular.module('membership')
  .factory('AccountInfoViewModel', [
    'ProfileDomain', 'notifySvc',
    function (profileDomain, notifySvc) {
      function AccountInfoViewModel(userId) {
        var self = this;
        self.userId = userId;

        self.data = null;
        self.loadData = function () {
          var promise = self.userId ? profileDomain.getUserPersonalInfo(self.userId) : profileDomain.getMyPersonalInfo();
          promise.then(function (data) {
            self.data = data;
          });
        };

        self.saveData = function () {
          var updatePromise = self.userId ? profileDomain.updateUserPersonalInfo(self.userId, self.data) : profileDomain.updateMyPersonalInfo(self.data);
          updatePromise.then(function (data) {
            notifySvc.success({
              msg: "Save profile success"
            });
          }, function () {
            notifySvc.error({
              msg: "Save profile error"
            });
          });
        };

        self.loadData();
      }

      return AccountInfoViewModel;
    }
  ]);
