

angular.module('membership')
  .factory('FilterableUserListModel', [
    'AjaxService', 'userUiService', 'membership.config', 'BizUsers', 'manageUsersListViewModel', '$translate', 'modalDialogService',
    function (AjaxService, userUiService, config, BizUsers, manageUsersListViewModel, $translate, modalDialogService) {

      function FilterableUserListModel(filter) {
        var self = this;
        var translationId = 'membership.customRoleName.' + filter;
        self.filterSearchName = "";

        $translate(translationId).then(function (r) {
          self.translatedRoleName = r;
        });
        manageUsersListViewModel.call(this, filter);

        self.remove = function (userId) {
          modalDialogService.showConfirmation("Are you sure want to delete this user ?", "User deletion confirmation", "dialog-warning").then(function (result) {
            if (result) {
              BizUsers.remove(userId).then(function(){
                modalDialogService.showAlert("User deleted successfully", "Delete User Alert", "dialog-success");
              }, function() {
                modalDialogService.showAlert("Error while deleting user", "Delete User Alert", "dialog-warning");
              }).finally(function() {
                self.reloadTable();
              });
            }
          });
        };

        self.addNewUser = function () {
          userUiService.proceedUserCreation(filter).finally(function () {
            self.reloadTable();
          });
        };

        self.reloadTable = function () {
          self.datatablesConfig.reload();
        };

        self.triggerSearch = function () {
          self.datatablesConfig.table.search(self.filterSearchName).draw();
        };
      }

      return FilterableUserListModel;
    }
  ]);
