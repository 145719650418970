'use strict';

angular.module('magview')
  .service('TimeTrackingViewModel', [
    '$state', 'magview.config', 'TimeManagement',
    function ($state, config, TimeManagement) {

      var viewModel = function (userId) {

        var self = this;

        self.filter = { dateFrom: new Date(), dateTo: new Date() /*(new Date()).setDate((new Date()).getDate() + 1)*/ };

        if (userId) {
          self.filter.userId = userId;
        }

        self.datatablesConfig = {
          datasourceUrl: config.apiUrls.base + '/datasource/timeTracking',
          defaultSortingColumn: "1",
          defaultSortingOrder: "desc",
          dtColumns: [
            {
              "aTargets": [0],
              "bSearchable": false,
              "bVisible": false
            },
            {
              "aTargets": [1],
              "sClass": "dynamic-html",
              "render": function (val, param1, data) {
                if (!self.filter.userId) {
                  return '<a class="callback-link" ng-click="model.redirectToUserDetails(\'' + data[0] + '\')">' + val + '</a>';
                } else {
                  return '<span>' + val + '</span>';;
                }
              }
            }
          ],
          customFilter: self.filter,
          dom: "<'fixed-filter'f><'row'<'col-xs-12 col-sm-12'r>>" +
            "t" +
            "<'row'<'col-sm-2 col-xs-12 hidden-xs'l><'col-sm-4 col-xs-12 hidden-xs'<'pull-right'i>><'col-xs-12 col-sm-6'p>>"
        };

        self.datatablesConfig2 = {
          datasourceUrl: config.apiUrls.base + '/datasource/timeAnalytic',
          dtColumns: [
            {
              "aTargets": [0],
              "bSearchable": false,
              "bVisible": false
            },
            {
              "aTargets": [1],
              "sClass": "dynamic-html",
              "render": function (val, param1, data) {
                if (!self.filter.userId) {
                  return '<a class="callback-link" ng-click="model.redirectToUserDetails(\'' + data[0] + '\')">' + val + '</a>';
                } else {
                  return '<span>' + val + '</span>';;
                }
              }
            }
          ],
          customFilter: self.filter,
          dom: "<'fixed-filter'f><'row'<'col-xs-12 col-sm-12'r>>" +
            "t" +
            "<'row'<'col-sm-2 col-xs-12 hidden-xs'l><'col-sm-4 col-xs-12 hidden-xs'<'pull-right'i>><'col-xs-12 col-sm-6'p>>"
        };

        self.redirectToUserDetails = function (userInit) {
          TimeManagement.getUserIdUserInit(userInit).then(function (id) {
            $state.go('membership_customerUser.timeManagement.workTime', { key: id });
          });
        };

      };

      return viewModel;

    }
  ]);
