'use strict';

angular.module('membership')
  .service('connectionInfoViewModel', [
    'ConnectionInfo', 'membership.config', function (ConnectionInfo, config) {

      var viewModel = function (customerId) {

        var self = this;

        self.uploadUrl = config.apiUrls.base + '/image/upload';

        ConnectionInfo.getConnectionInfo(customerId).then(function (resp) {
          if (resp == null) {
            self.showPinRequest = true;
            return;
          }

          var prefix = "<p>";
          if (resp.Content.slice(0, prefix.length) != prefix) {
            resp.Content = resp.Content.replace(/(?:\r\n|\r|\n)/g, '<br>');
          }
          self.info = resp;
          self.infoOrigin = angular.copy(resp);
        });

        self.requestPin = function() {
          ConnectionInfo.requestPin(customerId).then(function(resp) {
            if(!resp.PhoneNumber) {
              self.message = 'You don\'t have phone number in your profile';
              return;
            }

            self.message = 'Pin number was sent to ' + resp.PhoneNumber;            
          });
        }

        self.submitPin = function() {
          ConnectionInfo.getConnectionInfoWithPin(customerId, self.pinNumber).then(function(resp) {
            if (resp == null) {
              self.message = 'Pin number incorrect';
              return;
            }

            self.showPinRequest = false;
            var prefix = "<p>";
            if (resp.Content.slice(0, prefix.length) != prefix) {
              resp.Content = resp.Content.replace(/(?:\r\n|\r|\n)/g, '<br>');
            }
            self.info = resp;
            self.infoOrigin = angular.copy(resp);

          });
        }

        self.edit = function () {
          self.isEdit = true;
        };
        self.save = function () {
          ConnectionInfo.saveConnectionInfo(self.info).then(function (resp) {
            self.isEdit = false;
            self.infoOrigin = angular.copy(self.info);
          });
        };
        self.cancel = function () {
          self.isEdit = false;
          self.info = angular.copy(self.infoOrigin);
        };
      };

      return viewModel;
    }
  ]);
