angular.module('membership')
  .controller('MyAccountController', [
    '$scope', '$stateParams', '$state',
    'stateExtensions',
    function ($scope, $stateParams, $state,
              stateExtensions) {
      $scope.previousState = null;


      var userAccountState = 'membership_user_my_account';

      if ($state.current.name.indexOf('membership_userAccount') > -1) {
        userAccountState = 'membership_userAccount';
        $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
          if ($scope.previousState)
            return;

          $scope.previousState = fromState;
          $scope.previousParams = fromParams;
        });
      }

      $scope.vm = {
        goBack: function () {
          if ($scope.previousState) {
            $state.go($scope.previousState, $scope.previousParams);
          }
        }
      };

      var childrenStates = stateExtensions.getChildrenState(userAccountState);

      $scope.vm.tabsList = _.sortBy(childrenStates, 'data.order');

      if ($state.current.controller !== 'MyAccountController')
        return;

      if (userAccountState == 'membership_userAccount')
        stateExtensions.goToFirstSubchild(userAccountState, {userId: $stateParams.userId});
      else
        stateExtensions.goToFirstSubchild(userAccountState);
    }
  ]);
