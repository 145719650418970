'use strict';

angular.module('membership')
  .service('sitesViewModel', [
    'Customer', 'modalService', 'siteDetailsViewModel',
    function (Customer, modalService, siteDetailsViewModel) {

      var viewModel = function (company) {

        var self = this;

        self.reload = function () {
          Customer.sites(company.Id).then(function (resp) {
            company.sites = resp;
            self.sites = resp;
          });
        };

        if (!company.sites) {
          self.reload();
        } else {
          self.sites = company.sites;
        }

        self.edit = function (item) {
          var detailsViewModel = new siteDetailsViewModel(item || { CompanyId: company.Id });
          detailsViewModel.on('save', function () {
            self.reload();
          });
          detailsViewModel.on('delete', function (params) {
            self.reload();
          });
          modalService.showWindow('components/membership/customer/sites/site-details.html', detailsViewModel);
        };

        self.delete = function (item) {
          Customer.deleteSite(item).then(function () {
            self.reload();
          });
        };

      };

      return viewModel;

    }
  ]);
