'use strict';

angular.module('ticketAssignment')
    .controller('ActivityReportDetailsCtrl', [
        '$scope', 'activityReportDetailsViewModel', '$state', '$stateParams',
        function ($scope, viewModel, $state, $stateParams) {
            console.log('controller loading');
            console.log('$stateParams.params: ' + $stateParams.params);

            $scope.model = new viewModel($stateParams.params);

            $scope.ActivityStyle = function (activity) {
                var display = 'table';

                var styleObj = {
                    'display': display,
                    'background-color': 'unset'
                };
                return styleObj;
            }
            $scope.cellStyle = function (header) {
                var textoverflow = 'unset';
                if (header.name == 'ActivityContent') {
                    textoverflow = "ellipsis";
                }

                var styleObj = {
                    "width": header.width,
                    "min-width": header.width,
                    "max-width": header.width,
                    "text-overflow": textoverflow
                };
                return styleObj;
            }

            $scope.ticketURL = function (ticketId) {
                if (ticketId == null) {
                    return 'null';
                }
                var baseUrl = 'https://magview.freshdesk.com/a/tickets/';
                return baseUrl + ticketId.substring(0, 6);
            }
            $scope.formatMinutes = function (mins) {
                var h = '';
                if (mins >= 60) {
                    h += Math.floor(mins / 60) + "h:"
                }
                return h + Math.floor(mins % 60) + "m";
            }

            $scope.adjustToLocalTime = function dt(UTCTimeOrTimeRange) {
                var rangeSplitIndex = UTCTimeOrTimeRange.indexOf("-");
                if (rangeSplitIndex == -1) {
                    return getLocalTime(UTCTimeOrTimeRange);  // Not a time range
                } else {
                    var UTCTimeStart = UTCTimeOrTimeRange.substring(0, rangeSplitIndex - 1);
                    var UTCTimeEnd = UTCTimeOrTimeRange.substring(rangeSplitIndex + 1);
                    return getLocalTime(UTCTimeStart) + " - " + getLocalTime(UTCTimeEnd);
                }
            }

            function getLocalTime(UTCTime) {
                var currentDate = getCurrentDate();
                var UTCDateTime = new Date(currentDate + ' ' + UTCTime + ' UTC');
                var localTime = generateLocalTime(UTCDateTime);

                return localTime;
            }

            function getCurrentDate() {
	            var fullCurrentDate = new Date(Date());
	            var dd = fullCurrentDate.getUTCDate();
	            var mm = fullCurrentDate.getMonth() + 1;
	            var yyyy = fullCurrentDate.getFullYear();
	
	            return mm + '/' + dd + '/' + yyyy;
            }

            function generateLocalTime(UTCDateTime) {
	            var hours = UTCDateTime .getHours();
	            var minutes = UTCDateTime .getMinutes();
                var leadingZeroForMinutes = '';
	            var amOrPm = "AM";

	            if(hours >= 12) {
		            amOrPm = "PM";
	            }

	            if(hours > 12) {
		            hours -= 12;
	            }

                if(minutes < 10){
                    leadingZeroForMinutes = '0'
                }

	            return hours + ':' + leadingZeroForMinutes+  minutes + " " + amOrPm;
            }


            console.log('controller loaded');

        }
    ]);