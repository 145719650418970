angular.module('projectsManagement')
  .config([
    'SiteSettingInstancesProvider',
    'projectsManagement.configProvider',
    function (SiteSettingInstancesProvider, configProvider) {

      SiteSettingInstancesProvider.registerSetting(configProvider.config.settings.projectManagementSetting, {
        name: 'projectsManagement.lbl.settings.projectManagementSetting',
        templateUrl: 'components/projectsManagement/settings/projectManagementSettings.html',
        apiSettingEndPoint: configProvider.config.apiUrls.base + '/api/projectManagementSettings',
        order: 20
      });
    }]);
