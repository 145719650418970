'use strict';

angular.module('membership')
  .service('customerUserViewModel', [
    'BizUsers',
    'customerUserNav',
    function(BizUsers, customerUserNav) {

      var customerUserViewModel = function(userId) {

        var self = this;

        self.navItems = customerUserNav.navigations;
        self.userId = userId;
        self.activeTab = 'General';

        BizUsers.get(userId).then(function(user) {
          self.user = user;
        });
      };

      return customerUserViewModel;

    }
  ]);
