/**
 * Created on 12/7/2015.
 */

'use strict';

angular.module('siteSettings')
  .controller('SettingPageController', [
    '$scope', 'SettingPageViewModel', '$stateParams',
    function (scope, SettingPageViewModel, $stateParams) {
      scope.vm = new SettingPageViewModel($stateParams.settingKey);
    }
  ]);
