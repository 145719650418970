/**
 * Created on 12/14/2015.
 */

'use strict';

angular.module('membership')
  .service('UserInformationUIService', [
    'authorizeService',
    'membership.config',
    'DefaultUserInformationUiService',
    function (authorizeService,
              config,
              DefaultUserInformationUiService) {

      var UserInformationUIService = {
        showUserInfo: function (userId) {
          if (!authorizeService.isFeatureAllowed(config.permissions.accessUserInformation)) {
            DefaultUserInformationUiService.onUnauthorizedToAccessUserInfo(userId);
          } else {
            DefaultUserInformationUiService.onAuthorizedToAccessUserInfo(userId);
          }
        }
      };

      return UserInformationUIService;
    }
  ])
  .service('DefaultUserInformationUiService', [
    '$state',
    function ($state) {

      var DefaultUserInformationUiService = {
        onUnauthorizedToAccessUserInfo: function (userId) {
          $state.go('access_denied');
        },
        onAuthorizedToAccessUserInfo: function (userId) {
          $state.go('userProfile', {userId: userId});
        }
      };

      return DefaultUserInformationUiService;
    }
  ]);
