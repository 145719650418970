'use strict';

angular.module('documentsManagement')
  .factory('documentEditingBaseModel', [
    'AjaxService',
    'notifySvc',
    function (AjaxService,
              notifySvc) {

      function documentEditingBaseModel(masterId, documentId, documentsApiUrl, $modalInstance) {
        var baseUrl = documentsApiUrl;

        var self = this;

        self.documentId = documentId;

        self.uploadable = true;
        self.multipleUpload = false;

        self.data = {
          MasterId: masterId,
          FileName: "",
          FileUrl: "",
          Comment: "",
          OtherProperties: {},
        };

        self.onFileSelected = function () {
          if (!self.multipleUpload && self.file) {
            if (!self.data.FileName)
              self.data.FileName = self.file.name;

            //self.uploadFile(self.file);
          }
        };

        self.showErrorMaxSize = function () {
          modalDialogService.showAlert('Max upload size exceeded the maximum allowed size is ' + self.validateObj.size.max, 'File size exceeded', 'dialog-warning').finally(function () {
            self.file = null;
          });
        };

        self.uploadFile = function ($file) {
          self.isLoading = true;

          AjaxService.upload(baseUrl + '/upload', $file, {
            data: {
              FileName: self.data.FileName,
              Identifier: self.data.MasterId
            }
          }).then(function (data) {
            self.data.FileRecordId = data.fileRecord.Id;
            self.data.FileUrl = data.fileRecord.FileUrl;
            self.isLoading = false;
            self.uploadable = false;
            self.canSave = true;
          });
        };

        self.loadData = function () {
          AjaxService.get(baseUrl + '/details/' + self.documentId).then(function (data) {
            self.data = data;
          });
        };

        self.removeFile = function() {
          if (self.data.FileName == self.file.name) {
            self.data.FileName = '';
          }
          
          self.file = null;
        };

        self.save = function () {
           if (self.documentId) {
            self.updateDocumentData();
            return;
          }

          if (!self.multipleUpload) {
            AjaxService.upload(baseUrl + '/upload', self.file, {
              data: {
                FileName: self.data.FileName,
                Identifier: self.data.MasterId
              }
            }).then(function (data) {
              var subDocument = _.cloneDeep(self.data);
              subDocument.FileRecordId = data.fileRecord.Id;
              subDocument.FileUrl = data.fileRecord.FileUrl;
              subDocument.FileName = data.fileRecord.FileName;
              AjaxService.post(baseUrl + '/create', subDocument).then(function (data) {
                self.onDataSavedSuccessfully();
              }, self.onError.bind(self, 'Error while saving document'));
            });
            return;
          }

          self.multiUploadExec();

        };

        self.multiUploadExec = function () {
          var promises = [];

          _.each(self.file, function (file) {

            var promise = AjaxService.upload(baseUrl + '/upload', file, {
              data: {
                FileName: file.name,
                Identifier: self.data.MasterId
              }
            }).then(function (data) {
              var subDocument = _.cloneDeep(self.data);
              subDocument.FileName = file.name;
              subDocument.FileRecordId = data.fileRecord.Id;
              subDocument.FileUrl = data.fileRecord.FileUrl;
              return subDocument;
            });

            promises.push(promise);
          });

          AjaxService.all(promises).then(function (result) {
            var pr = result.map(function (subDocument) {
              return AjaxService.post(baseUrl + '/create', subDocument).then(function (data) {
              }, self.onError.bind(self, 'Error while saving document'));
            });

            AjaxService.all(pr).then(self.onDataSavedSuccessfully, self.onError.bind(self, 'Error while saving document'));
          }, self.onError.bind(self, 'Error while saving document'));
        };

        self.updateDocumentData = function () {
          AjaxService.put(baseUrl + '/' + self.documentId, self.data).then(function (data) {
            self.onDataSavedSuccessfully();
          }, self.onError.bind(self, 'Error while saving document'));
        };

        self.onDataSavedSuccessfully = function () {
          notifySvc.success({
            msg: "Document saved successfully"
          });
          $modalInstance.close();
        };

        self.onError = function (title, error) {
          notifySvc.error({
            msg: error.data && error.data.ExceptionMessage ? error.data.ExceptionMessage : error.data || error,
            title: title
          });
        };

        if (self.documentId)
          self.loadData();
      }

      return documentEditingBaseModel;
    }
  ])
;