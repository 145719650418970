/**
 * Created on 12/7/2015.
 */

'use strict';

angular.module('siteSettings')
  .factory('SettingPageViewModel', [
    'SiteSettingInstances', 'SiteSettingService',
    'PageTitleService',
    'notifySvc',
    function (SiteSettingInstances, SiteSettingService, PageTitleService, notifySvc) {
      function SettingPageViewModel(settingKey) {
        var self = this;

        self.data = {};

        var service = new SiteSettingService(settingKey);

        self.settingConfig = service.instance;

        PageTitleService.setPageTitle(self.settingConfig.name);

        service.getSetting().then(function (data) {
          self.data = data;
        });

        self.saveSetting = function () {
          service.saveSetting(self.data).then(self.saveSuccess, self.saveError);
        };

        self.saveSuccess = function () {
          notifySvc.success({
            i18msg: 'siteSettings.msg.saveSettingSuccess'
          });
        };

        self.saveError = function (error) {

        };
      }

      return SettingPageViewModel;
    }
  ]);
