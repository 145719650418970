'use strict';

angular.module('magtac')
    .service('ServerDeleteViewModel', ['Server',
        function (Server,) {
            var viewModel = function () {

                var self = this;
                Server.getCustomersServerData().then(
                    function (response) {
                        self.dataSet = response;
                        console.log(response);
                    }
                ).then(
                    function () {
                        if (!$.fn.DataTable.isDataTable('#customerServers')) {
                            $('#customerServers').DataTable({
                                data: self.dataSet,
                                columns: [
                                    {
                                        title: "Customers",
                                        render: function (data, type, full, meta) {
                                            return "<a target='_blank' href='/serverDeletion/" + full.Code + "'>" + full.Name + "</div>";
                                        } 
                                    },
                                ],
                            });
                        }
                    }
                );

            };

            return viewModel;

        }
    ]);
