'use strict';

angular.module('magview')
  .config([
    'appStateConfigProvider',
    function(appStateConfigProvider) {

      var states = [
        {
          name: 'magview_TimeManagement',
          url: "/time-management",
          templateUrl: "components/magview/timeManagement/time-management-master.html",
          data: {
            access: 'Magview.TimeManagement'
          }
        },
        {
          name: 'magview_TimeManagement.workTime',
          url: "/work-time",
          templateUrl: "components/magview/timeManagement/timeTracking/time-tracking.html",
          data: {
            access: 'Magview.TimeManagement'
          }
        },
        {
          name: 'magview_TimeManagement.timeOff',
          url: "/time-off",
          templateUrl: "components/magview/timeManagement/attendanceTracking/attendance-tracking.html",
          data: {
            access: 'Magview.TimeManagement'
          }
        },
        {
          name: 'prefs',
          url: "/prefs",
          templateUrl: "components/magview/prefs/prefs.tpl.html"
          },
          {
              name: 'prefDetails',
              url: "/prefs/{name}",
              templateUrl: "components/magview/prefs/prefDetails/prefDetails.html",
          },
          {
              name: 'prefDetails.notes',
              url: "/notes",
              templateUrl: "components/magview/prefs/prefDetails/notes/pref-Notes.html",
              controller: "PrefNotesCtrl",
          },
          {
              name: 'prefDetails.general',
              url: "/general",
              templateUrl: "components/magview/prefs/prefDetails/general/general.html",
              controller: "generalCtrl",
          },
      ];

      states.forEach(appStateConfigProvider.addState);
    }
  ]);
