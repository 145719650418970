/**
 * Created on 12/7/2015.
 */

'use strict';

angular.module('siteSettings')
  .controller('SiteSettingsListController', [
    '$scope', 'SiteSettingsListViewModel',
    function (scope, SiteSettingsListViewModel) {
      scope.vm = new SiteSettingsListViewModel();
    }
  ]);
