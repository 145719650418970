/**
 * Created on 11/14/2015.
 */

'use strict';

angular.module('common')
  .service('PromiseService', [
    '$q',
    function ($q) {
      var PromiseService = {
        resolve: function(result, timeout){
          var defer = $q.defer();

          setTimeout(function(){
            defer.resolve(result);
          }, timeout || 0);

          return defer.promise;
        },
        reject: function(result){
          var defer = $q.defer();

          defer.reject(result);

          return defer.promise;
        }
      };

      return PromiseService;
    }
  ]);
