'use strict';

angular.module('projectsManagement')
  .controller('ProjectChildDetailsCtrl', [
    '$scope', '$state', 'projectChildDetailsViewModel', '$stateParams',
    function($scope, $state, viewModel, $stateParams) {
      $scope.projectId = parseInt($stateParams.childId);
      $scope.model = new viewModel($scope.projectId);
    }
  ]);
