'use strict';

angular.module('magtac')
  .service('librariesManagementViewModel', [
    '$q', '$filter', '$interval', '$timeout', 'MagTacLibraries', 'uploadLibraryViewModel', 'modalService', 'modalDialogService',
    function ($q, $filter, $interval, $timeout, MagTacLibraries, uploadLibraryViewModel, modalService, modalDialogService) {

      var viewModel = function () {

        var self = this;

        self.addLibrary = function() {

        }

        self.init = function() {
          MagTacLibraries.getAll().then(function(resp){
            self.libraries = resp;
            if (!!resp.length) {
              var selectedLib = _.find(self.libraries, { Name: self.selectedType }) || self.libraries[0];
              self.openDetails(selectedLib, true);
            } else {
              self.selectedType = null;
            }
          });
          
          MagTacLibraries.workflowGetAll().then(function(resp){
            self.wfLibraries = resp;
          });

        }

        self.openDetails = function(library, force) {
          if (self.selectedType == library && !force) {
            return;
          }

          self.selectedType = library.Name;
          if (library.Name == 'Workflow') {
            MagTacLibraries.getForWorkflowType(self.selectedType).then(function(resp){
              self.selectedTypeOptions = resp;
            });
          } else {
            MagTacLibraries.getForType(self.selectedType).then(function(resp){            
              self.selectedTypeOptions = resp;
            });
          }
        }

        self.uploadLibrary = function() {
            var uploadViewModel = new uploadLibraryViewModel();
            uploadViewModel.setLibType(self.selectedType);

            uploadViewModel.on('save', function () {
              self.init();
            });
            modalService.showWindow('components/magtac/librariesManagement/uploadLibrary/uploadLibrary.html', uploadViewModel);
        }

        self.updateLibrary = function(lib) {
            var uploadViewModel = new uploadLibraryViewModel(angular.copy(lib));
            uploadViewModel.setLibType(self.selectedType);
            uploadViewModel.on('save', function () {
              self.init();
            });
            modalService.showWindow('components/magtac/librariesManagement/uploadLibrary/uploadLibrary.html', uploadViewModel);
        }

        self.updateCustomers = function() {
          if (!self.selectedTypeOptions || !self.selectedTypeOptions.length) {
            return;
          }

          var newLib = self.selectedTypeOptions[0];

          /*modalDialogService.showConfirmation(
            'Are you sure you want to update [' + newLib.Name + '] library to version ' + newLib.Version + ' for every customer?', 'MagTac Libraries')*/
          modalDialogService.showConfirmation(
            'Are you sure you want to update [' + newLib.Name + '] library to the latest versions for every customer?', 'MagTac Libraries')
          .then(function(resp){
            if (resp) {
              MagTacLibraries.updateCustomers(newLib).then(function(resp) {
                if (resp.affected > 0) {
                  modalDialogService.showAlert(resp.affected + ' customer(s) updated', 'MagTac Libraries');
                } else {
                  modalDialogService.showAlert('All customers already up to date', 'MagTac Libraries');
                }
              });
            }
          });
        }

        self.deleteLibrary = function(library) {
          modalDialogService.showConfirmation('Are you sure you want to delete this library?', 'MagTac Libraries').then(function(resp){
            if (resp) {
              if (self.selectedType == 'Workflow'){
                MagTacLibraries.deleteWorkflow(library.Id).then(function() {
                  self.init();
                });
              }else{
                MagTacLibraries.delete(library.Id).then(function() {
                  self.init();
                });
              }
             
            }
          });
          
        }

        self.downloadLibrary = function(library) {
          MagTacLibraries.downloadLibrary(library.Id);
        }

        self.init();
      };

      return viewModel;
    }
  ]);
