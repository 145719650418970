'use strict';

angular.module('projectsManagement')
  .controller('ProjectDetailsCtrl', [
    '$scope', '$state', 'projectDetailsViewModel', '$stateParams',
    function($scope, $state, viewModel, $stateParams) {
      $scope.projectId = parseInt($stateParams.key);
      $scope.model = new viewModel($scope.projectId);
    }
  ]);
