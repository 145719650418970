'use strict';

angular.module('magview')
  .config([
    'navigationServiceProvider',
    function(navigationServiceProvider) {

      navigationServiceProvider.register({
        priority: 5,
        state: 'magview_TimeManagement.workTime',
        name: 'Time Management'
      });
    }
  ]);
