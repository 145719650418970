'use strict';

// const cronParser = require('cron-parser');

angular.module('magtac')
    .service('timerFormatService', ['cronService',
        function (cronService) {
            this.availableValues = [];


            function cronToReadable(cronExpr) {
                try {
                    console.log(cronExpr);
                    let interval = cronParser.parseExpression(cronExpr);
                    return `Every ${interval.fields.hour} hour(s), ${interval.fields.minute} minute(s) on the ${interval.fields.dayOfWeek} day(s) of the week.`;
                } catch (err) {
                    console.error('Error parsing cron expression', err);
                    return 'Invalid cron expression';
                }
            }

            this.formatTime = function (cron, timezone, checkForCron) {
                if (!cron) {
                    return cron;
                }

                var label = (_.find(this.availableValues, { cron: cron }) || {}).label;
                var readableCron = cronToReadable(cronService.convertCronToServerTime(cron, timezone));

                if (checkForCron) {
                    if (label) {
                        return { string: label, isCron: false };
                    } else {
                        return { string: readableCron, isCron: true };
                    }
                }

                return label || readableCron;
            }

            this.fillValues = function () {

                this.availableValues.push({
                    cron: '0 * * * * ?',
                    label: 'Every minute',
                });

                for (var i = 1; i <= 59; i++) {
                    this.availableValues.push({
                        cron: '0 0/' + i + ' * * * ?',
                        label: 'Every ' + i + ' minute' + ((i > 1) ? 's' : ''),
                    });
                }

                for (var i = 1; i <= 23; i++) {
                    this.availableValues.push({
                        cron: '0 0 0/' + i + ' * * ?',
                        label: 'Every ' + i + ' hour' + ((i > 1) ? 's' : ''),
                    });
                }

                for (var i = 1; i <= 7; i++) {
                    this.availableValues.push({
                        cron: '0 0 0 */' + i + ' * ?',
                        label: 'Every ' + i + ' day' + ((i > 1) ? 's' : ''),
                    });
                }
            }

            this.getValues = function () {
                return this.availableValues;
            }


            this.fillValues();
        }
    ]);
