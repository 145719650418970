'use strict';

angular.module('projectsManagement')
  .service('projectsListViewModel', ['projectsManagement.config', 'Customer', 'modalDialogService', '$state', 'BizUsers', '$q',
    function (config, Customer, modalDialogService, $state, BizUsers, $q) {

      var viewModel = function (customerId) {

        var self = this;

        self.filterOpen = true;
        self.filter = { status: 'active', customerId: customerId || '' };

        var usersPromise = BizUsers.getAll().then(function (resp) {
          self.users = resp;
        });

        var customersPromise = Customer.namesList().then(function (resp) {
          self.customers = _.sortBy(resp, function (item) {
            return item.id != self.filter.customerId;
          });
        });

        $q.all([usersPromise, customersPromise]).then(function () {
          self.isLookupsReady = true;
        });

        self.datatablesConfig = {
          datasourceUrl: config.apiUrls.base + '/projects/datasource',
          defaultSortingColumn: "0",
          defaultSortingOrder: "desc",
          iDisplayLength: 25,
          dtColumns: [
            {
              "aTargets": 1,
              "sWidth": 200,
              "sClass": "dynamic-html",
              "render": function (data, type, full, meta) {
                return "<a class='' ui-sref='project_details.general({key:" + full[0] + "})'>" + data +"</a>";                
              }
            },
            {
              "aTargets": 2,
              "render": function (data, type, full, meta) {
                return (_.find(self.customers, {id: parseInt(data)}) || { value: '-'}).value;
              }
            },
            {
              "aTargets": 3,
              "render": function (data, type, full, meta) {
                var user = _.find(self.users, { Id: parseInt(data) });
                if (!user) {
                  return '-';
                }
                return user.FirstName + ' ' + user.LastName;
              }
            },
            {
              "aTargets": 4,
              "render": function (data, type, full, meta) {
                var user = _.find(self.users, { Id: parseInt(data) });
                if (!user) {
                  return '-';
                }
                return user.FirstName + ' ' + user.LastName;
              }
            },
            {
              "aTargets": 5,
              "render": function (data, type, full, meta) {
                var labelType = 'default';
                switch (data) {
                  case 'Live': labelType = 'success';
                    break;
                  case 'New': labelType = 'info';
                    break;
                  case 'In Progress': labelType = 'warning';
                    break;
                  case 'On Hold': labelType = 'default';
                    break;
                  case 'Canceled': labelType = 'danger';
                    break;
                  case 'Assigned': labelType = 'primary';
                    break;
                }

                return "<span class='label label-" + labelType + "'>" + data + "</span>";
              }
            },
            {
              "aTargets": -1,
              "bSearchable": false,
              "bSortable": false,
              "data": null,
              "sWidth": 90,
              "sClass": "actions-col dynamic-html",
              "render": function (data, type, full, meta) {
                var editBtn = "<a class='btn btn-success' disabled='disabled' ui-sref='project_details.general({key:" + data[0] + "})' title='Details' ><i class='fa fa-usd'></i></a>";                
                return editBtn;
              }
            }
          ],
          customFilter: self.filter,
          dom: "<'row'<'col-xs-12 col-sm-12'r>>" + 
            "t" +
            "<'row'<'col-sm-2 col-xs-12 hidden-xs'l><'col-sm-4 col-xs-12 hidden-xs'<'pull-right'i>><'col-xs-12 col-sm-6'p>>"
            
        };

        self.create = function() {
          modalDialogService
            .showWindow('components/projectsManagement/newProjectDialog/newProjectDialog.tpl.html', 'NewProjectCtrl', { $$parent: null, $$companyId: null, $$project: null })
            .then(function(resp){
              if(resp.ParentId){
                //$location.path('projects/' + resp.ParentId + '/child/' + resp.Id);
                $state.go('project_details.child.general', {childId: resp.Id, key: resp.ParentId});
              }else{
                $state.go('project_details.general', {key: resp.Id}, {reload: true});
              }              
            });
        };
      };

      return viewModel;

    }
  ]);
