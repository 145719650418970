/**
 * Created on 11/6/2015.
 */

'use strict';

angular.module('membership')
  .service('authorizeService', [
    '$rootScope', 'permissionsService', '$state',
    'membership.config',
    function ($rootScope, permissionsService, $state, config) {

      var authorizeService = {};

      $rootScope.permissions = config.permissions;

      authorizeService.isPageAvailable = function (page) {
        if (_.contains(permissionsService.permissions, config.permissions.systemAdmin)
          && page != config.permissions.cancelImpersonation)
          return true;

        var context = {
          page: page,
          contextChanged: false,
          result: false
        };
        $rootScope.$broadcast('authorizePage', context);

        if (context.contextChanged)
          return context.result;

        return _.contains(permissionsService.permissions, page);
      };

      authorizeService.isStateAvailable = function (state) {
        if (_.contains(permissionsService.permissions, config.permissions.systemAdmin))
          return true;

        if (!state) {
          return true;
        }
        var context = {
          state: state,
          contextChanged: false,
          result: false
        };

        $rootScope.$broadcast('authorizeState', context);

        if (context.contextChanged)
          return context.result;

        if (state.indexOf('(') != -1) {
          state = state.substring(0, state.indexOf('('));
        }

        var routerState = _.find($state.get(), function (s) {
          return s.name == state;
        });

        if (!routerState || !routerState.data || !routerState.data.access) {
          return true;
        }

        var isAvailable = _.contains(permissionsService.permissions, routerState.data.access);

        return isAvailable;
      };

      authorizeService.isFeatureAllowed = function (feature) {

        if (_.contains(permissionsService.permissions, config.permissions.systemAdmin))
          return true;

        var context = {
          feature: feature,
          contextChanged: false,
          result: false
        };

        $rootScope.$broadcast('authorizeFeature', context);

        if (context.contextChanged)
          return context.result;

        return _.contains(permissionsService.permissions, feature);
      };

      return authorizeService;
    }
  ]);
