'use strict';

angular.module('membership')
  .controller('CustomerUserCtrl', [
    '$rootScope', '$scope', '$state', 'customerUserViewModel', '$stateParams',
    function ($rootScope, $scope, $state, viewModel, $stateParams) {

      if ($state.current.name.indexOf('.') == -1) {
        $state.go($state.current.name + '.general', $stateParams);
      }

      $scope.selectedUserId = parseInt($stateParams.key);

      if ($scope.selectedUserId == 0) {
        $state.go($state.current.name, { key: $rootScope.userId });
      }

      $scope.model = new viewModel($scope.selectedUserId);
    }
  ]);
