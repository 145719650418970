'use strict';
angular.module('ticketAssignment')
    .service('ticketSearchViewModel', ['ticketAssignment.config', 'ticketAdmin', '$state',
        function (config, Tickets, $state) {
            var viewModel = function (searchFilter, pageNo, perPage, query) {
                console.log('\n - ticketSearchViewModel - \n Fetching page:' + pageNo+' of results...');

                var self = this;

                self.searchFilter = searchFilter;
                self.pageNo = pageNo;
                self.perPage = perPage;
                self.query = query;
                self.closedFilter = true;
                self.openFilter = true;

                self.GetHref = function (_page, _filterCode, _query) { return '/search/' + _filterCode + '/' + _page + '/100/' + _query; }
                self.link = '/search/' + searchFilter + '/' + pageNo + '/' + perPage + '/' + query;

                self.setSearchFilters = function () {
                    console.log('setting search filters');
                    if (self.searchFilter == 4) {
                        self.closedFilter = true;
                        self.openFilter = true;
                    } else if (self.searchFilter == 3) {
                        self.closedFilter = true;
                        self.openFilter = false;
                    } else if (self.searchFilter == 1) {
                        self.closedFilter = false;
                        self.openFilter = true;
                    } else if (self.searchFilter == 0) {
                        // no filters. This should never be reached
                        self.closedFilter = false;
                        self.openFilter = false;
                    }
                }

                self.setSearchFilters();

                if (self.searchFilter != 0) {
                    Tickets.getSearchResults(self.searchFilter, self.pageNo, self.perPage, self.query).then(
                        function (response) {
                            self.results = response.Results;
                            self.count = response.Count;
                            self.searchTerms = response.SearchTerms;
                            self.textExpanded = self.results
                                .map(function f(r) { return r.NoteId })
                                .reduce(function (dict, noteId) { return (dict[noteId] = false, dict); }, {});

                            self.info = {
                                resultsCount: (self.count == 100 ? '100+' : self.count),
                                query: self.searchTerms.join(', '),
                                pagination: {
                                    previous:
                                        { href: self.GetHref(self.pageNo - 1, self.searchFilter, self.query), exists: self.pageNo > 0 },
                                    next:
                                        { href: self.GetHref(self.pageNo + 1, self.searchFilter, self.query), exists: self.count == self.perPage },
                                    pages: [],
                                }
                            };

                            if (self.pageNo > 0) {
                                self.info.resultsCount = (self.pageNo * self.perPage) + ' - ' + ((self.pageNo * self.perPage) + self.count);
                            }
                            for (var page = Math.max(self.pageNo - 3, 0); self.info.pagination.pages.length < 6; page++) {
                                self.info.pagination.pages.push({ pageNumberLogical: page, pageNumberDisplayed: page + 1, href: self.GetHref(page, self.searchFilter, self.query), currentPage: page == self.pageNo });
                                if (self.pageNo == 0 && self.count < self.perPage) {
                                    break;
                                }
                            }

                            self.setSearchFilters();


                            console.log('Done');
                        }
                    );
                } else {
                    self.results = {};
                    self.count = 0;
                    console.log("Invalid search parameters. Please select at least one filter");
                }

                self.headers = [
                    {
                        name: 'TicketId',
                        width: '5%',
                        display: 'Ticket',
                        info: {
                            enabled: true,
                            // If header.info.autoPopulated = false, then (in the .html source) we must use an if statement on the header's name to populate it. (ie "header.name == 'XYZ'")
                            // If header.info.autoPopulated = true, then we can automatically display this header's value <span ng-if="header.info.autoPopulated"> {{result[header.name]}} </span>
                            autoPopulated: false,
                        }
                    },
                    {
                        name: 'Status',
                        width: '10%',
                        display: 'Status',
                        info: {
                            enabled: true,
                            autoPopulated: false,
                        }
                    },
                    {
                        name: 'Text',
                        width: '50%',
                        display: 'Ticket Contents',
                        info: {
                            enabled: true,
                            autoPopulated: false,
                        }
                    },
                    {
                        name: 'Company',
                        width: '6%',
                        display: 'Company',
                        info: {
                            enabled: false,
                            autoPopulated: true,
                        }
                    },
                    {
                        name: 'CreatedAt',
                        width: '5%',
                        display: 'Date',
                        info: {
                            enabled: true,
                            autoPopulated: true,
                        }
                    }
                ];

            }
            return viewModel;
        }
    ]);
