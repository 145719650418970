'use strict';

angular.module('membership')
  .service('customerViewModel', [
    '$rootScope', 'Customer', 'modalService', '$state', 'connectionInfoViewModel', 'modalDialogService', 'BizUsers',
    function ($rootScope, Customer, modalService, $state, connectionInfoViewModel, modalDialogService, BizUsers) {

      var viewModel = function (customer) {
          
        var self = this;

        self.customer = customer;
        self.priorityLookup = ['1', '2', '3', 'None'];
          self.teamLookup = ['First Responder', 'Team1', 'Team2', 'Team3', 'Team4', 'Team5', 'Team6', 'VIP', 'None'];

        self.reloadDetails = function (callBack) {

          Customer.details(customer.Id).then(function (c) {
            if (c) {
              angular.extend(self.customer, c);
            }
            if (!self.customer.Status) {
              self.statusInfo = 'Skipped';
            }
            else if (self.customer.IsStatusExpired && self.customer.Status != 'Error') {
              self.statusInfo = 'Expired';
            }
            else {
              self.statusInfo = self.customer.Status;
            }

            if (callBack && typeof callBack === "function") {
              callBack();
            }
          });
        }

        self.reloadDetails(function () {
          self.loadLookupForRoleBasedFields();
        });
        $rootScope.$on('magtac.events.statusReloading', self.reloadDetails);

        Customer.namesList().then(function (resp) {
          self.customers = _.sortBy(resp, function (item) {
            return item.id != self.customer.Id;
          });
        });

        self.isCustomerDetailsSection = function () {
          if ($state.current.name.indexOf('membership_customer.status') >= 0) {
            return false;
          }

          switch ($state.current.name) {
            case "membership_customer.users":
              return false;
            default:

              return true;
          }
        }

        self.showConnectioninfo = function () {
          modalService.showWindow('components/membership/customer/connectionInfo/connection-info-window.html', new connectionInfoViewModel(customer.Id));
        };

        self.selectionChanged = function (data, container, escapeMarkup) {

            var id = parseInt(data.id, 10);

            if (!isNaN(id) && id != self.customer.Id) { // checks if id is a valid number and not equal to self.customer.Id
                console.log("Transitioning with key:", id);
                $state.go($state.current.name, { key: id });
            } else {
                console.log("Invalid or same ID:", data.id);
            }
          return data ? escapeMarkup(data.text) : undefined;
        };

        self.startEdit = function () {
          self.isEditMode = true;
          self.customerEditModel = angular.copy(self.customer);
        };

        self.save = function () {
          Customer.updateCustomer(self.customerEditModel).then(function () {
            self.isEditMode = false;
            angular.copy(self.customerEditModel, self.customer);
            self.loadLookupForRoleBasedFields();
          }, function () {
            modalDialogService.showAlert('Failed to update customer');
          });
        };

        self.cancel = function () {
          self.isEditMode = false;
        };

        self.deleteCustomer = function () {
          modalDialogService.showConfirmation('Are you sure you want to delete this customer?', 'Customers Module')
            .then(function (res) {
              if (res) {
                Customer.deleteCustomer(self.customer.Id).then(function () {
                  $state.go('membership_customerList');
                });
              }
            });
        };

        self.loadLookupForRoleBasedFields = function () {
          if (!self.customer.ExtendedPropertiesLookup) {
            self.customer.ExtendedPropertiesLookup = {};
          }
          _.each(self.customer.RoleBasedDynamicProperties, function (property) {
            if (self.customer.ExtendedProperties[property.PropertyName]) {
              BizUsers.get(self.customer.ExtendedProperties[property.PropertyName], function (resp) {
                self.customer.ExtendedPropertiesLookup[property.PropertyName] = resp.FirstName + ' ' + resp.LastName;
              });
            }
          });
        };
      };

      return viewModel;

    }
  ]);
//TODO: get rid from status and connectionInfoViewModel dependensies