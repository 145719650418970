'use strict';

angular.module('magview')
    .service('generalEditViewModel', ['Prefs',
        function (Prefs) {

            var viewModel = function (description, name) {

                var self = this;
                this.description = description.replace(/!br!/g, "\n");

                self.listeners = {};
                this.dto = {Name: name, Description: this.newDescription};

                self.on = function (action, callBack) {
                    self.listeners[action] = callBack;
                };

                self.notify = function (action, params) {
                    if (self.listeners[action]) {
                        self.listeners[action](params);
                    }
                };

                self.notify('save', self.description);
                self.save = function () {
                    this.dto.Description = this.description.replace(/\r?\n|\r/g, "!br!");
                    console.log(this.description);
                    Prefs.saveDescription(this.dto).then(function () {
                        self.notify('save');
                    });
                };

            };

            return viewModel;

        }
    ]);
