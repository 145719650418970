'use strict';

angular.module('common')
  .service('atlassianIssueCollector', ['$http', '$rootScope',
    function ($http, $rootScope) {
      var self = this;

      self.init = function(url) {
        self.url = url;

        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
          $('.atlwdg-blanket').remove();
          $('.atlwdg-trigger').remove();
          $('.atlwdg-popup').remove();
          //$.getScript(self.url);
        });
      }
    }
  ]);
