'use strict';

angular.module('membership')
  .service('ManageCompanies', [
    'AjaxService', 'membership.config',
    function (AjaxService, config) {

    var apiUrl = config.apiUrls.base + "/manageCompanies";

    var domain = {

      getCompanySettings: function (callback) {
        AjaxService.get(apiUrl + '/GetCompanySettings').then(function (data) {
          callback(data);
        });
      },

      setBackupLimit: function (limit, companyId, callback) {
        AjaxService.post(apiUrl + '/setBackupLimit?limit=' + limit + '&companyId=' + companyId).then(function (data) {
          callback(data);
        });
      },

      setDomain: function (domain, companyId, callback) {
        AjaxService.post(apiUrl + '/SetCompanyDomain?domain=' + domain + '&companyId=' + companyId).then(function (data) {
          callback(data);
        });
      },

      setCompanyName: function (name, companyId, callback) {
        AjaxService.post(apiUrl + '/setCompanyName?name=' + name + '&companyId=' + companyId).then(function (data) {
          callback(data);
        });
      },

      setCompanyLogo: function (logoUrl, companyId, callback) {
        AjaxService.post(apiUrl + '/setCompanyLogo?logoUrl=' + logoUrl + '&companyId=' + companyId).then(function (data) {
          callback(data);
        });
      },

      setCompanyTheme: function (theme, companyId, callback) {
        AjaxService.post(apiUrl + '/setCompanyTheme?theme=' + theme + '&companyId=' + companyId).then(function (data) {
          callback(data);
        });
      },

      setTmsAccount: function (tmsAccount, companyId, callback) {
        AjaxService.post(apiUrl + '/setTmsAccount?companyId=' + companyId, tmsAccount).then(function (data) {
          callback(data);
        });
      },

      setVonageAccount: function (vonageAccount, companyId, callback) {
        AjaxService.post(apiUrl + '/setVonageAccount?companyId=' + companyId, vonageAccount).then(function (data) {
          callback(data);
        });
      },

      getAllCompanies: function (callback) {
        AjaxService.get(apiUrl + '/GetAllCompanies').then(function (data) {
          callback(data);
        });
      },

      get: function (id, callback) {
        AjaxService.get(apiUrl + '/get/' + id).then(function (data) {
          callback(data);
        });
      },

      createCompany: function (company, callback) {
        AjaxService.post(apiUrl + '/postCompany/', company).then(function (data) {
          callback(data);
        });
      },

      saveCompany: function (company, callback) {
        AjaxService.put(apiUrl + '/putCompany/', company).then(function (data) {
          callback(data);
        });
      },

      unattachDropBox: function (company, callback) {
        AjaxService.post(apiUrl + '/UnattachDropBox/', company).then(function (data) {
          callback(data);
        });
      }
    };

    return domain;
  }]);
