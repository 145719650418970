/**
 * Created on 10/12/2015.
 */
angular.module('backend')
  .config([
    'appStateConfigProvider',
    function (appStateConfigProvider) {
      appStateConfigProvider.addState({
        name: "backend",
        url: "/admin",
        abstract: true,
        template: '<div class="container" ui-view></div>'
      });
      appStateConfigProvider.addState({
        name: "backend.home",
        url: "/",
        controller: 'backendDashboardController',
        templateUrl: 'components/backend/dashboard/dashboard.html'
      });
    }
  ]);
