// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cron-select {
  display: inline-block;
  width: 150px;
  height: 34px;
  padding: 6px 12px;
  margin: 0;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.cron-select.cron-select-repeating {
  width: 70px;
}
.select-options {
  display: block;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/styles/quartz-cron.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,SAAS;EACT,eAAe;EACf,uBAAuB;EACvB,WAAW;EACX,sBAAsB;EACtB,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,gDAAgD;EAChD,wEAAwE;AAC1E;AACA;EACE,WAAW;AACb;AACA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".cron-select {\n  display: inline-block;\n  width: 150px;\n  height: 34px;\n  padding: 6px 12px;\n  margin: 0;\n  font-size: 14px;\n  line-height: 1.42857143;\n  color: #555;\n  background-color: #fff;\n  background-image: none;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);\n  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;\n}\n.cron-select.cron-select-repeating {\n  width: 70px;\n}\n.select-options {\n  display: block;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
