/**
 * Created on 10/2/2015.
 */

angular.module('membership')
  .directive('clientCompanyInfo', [
    function () {
      return {
        templateUrl: function (element, attribute) {
          return attribute.templateUrl || "components/membership/clientCompany/directives/clientCompanyInfo.html";
        },
        restrict: "EA",
        controller: 'ClientCompanyInfoController',
        scope: {
          clientCompanyId: "="
        }
      }
    }
  ]);
