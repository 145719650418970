'use strict';

angular.module('projectsManagement')
  .controller('NewProjectCtrl', [
    '$scope', 'Projects', 'newProjectViewModel', '$modalInstance', '$$parent', '$$companyId', '$$project',
    function ($scope, Projects, viewModel, modalInstance, $$parent, $$companyId, $$project) {
      var vm = new viewModel(modalInstance, $$parent, $$companyId, $$project);
      $scope.model = vm;      

      $scope.$watch('model.projectEditModel.CompanyId', function(newValue, oldValue) {
        if (newValue != oldValue) {
          vm.companyUpdated();
        }
      });
    }
  ]);
