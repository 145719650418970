'use strict';

angular.module('magtac')
  .service('statusGeneralViewModel', [
    'magtac.config', 'Status', '$rootScope', 'MagTacLibraries',
    function (config, Status, $rootScope, MagTacLibraries) {

      var viewModel = function(companyId) {

        var self = this;

        self.onStatusDisableChange = function() {
          Status.setCustomerStatus(companyId, self.isStatusDisabled).then(function() {
            $rootScope.$broadcast(config.events.statusReloading, {});
          });
        };

        self.onIsImportantChange = function() {
          Status.setCustomerImportantFlag(companyId, self.isImportant);
        };  

        self.useLibrary = function(lib) {
          Status.updateLibrary(companyId, lib).then(self.init);
        }

        self.saveStatusEmailRecipient = function(){
          Status.saveStatusEmailRecipient(companyId, self.statusEmailRecipient);
        };

        self.librariesGroups = [];

        self.init = function() {
          Status.magtacSettings(companyId).then(function(resp) {
            self.isStatusDisabled = resp.IsDisabled;
            self.isImportant = resp.IsImportant; 
            self.statusEmailRecipient = resp.StatusEmailRecipient;    
            self.MagViewVersion = resp.MagViewVersion;
            self.OldestMagLinkVersion = resp.OldestMagLinkVersion;
            self.NewestMagLinkVersion = resp.NewestMagLinkVersion;
            self.PortalVersion = resp.PortalVersion;

            MagTacLibraries.getUpdates({MagTacVersion: resp.MagTacVersion, Libraries: resp.ExpectedLibraries}).then(function(updates) {
              self.librariesUpdates = updates;

              self.librariesGroups = _.map(resp.CurrentLibraries, function(l) {
                var next = _.find(updates, { Name: l.Name });
                var expected = _.find(resp.ExpectedLibraries, { Name: l.Name });
                var prev = _.find(resp.PreviousLibraries, { Name: l.Name });

                var group = { 
                  current: l,
                  next: next,
                  prev: prev,
                  expected: !expected || expected.Version == l.Version ? null : expected
                };

                return group;
              });
            });
          });
        };

        self.init();

      };

      return viewModel;

    }
  ]);