'use strict';

angular.module('membership')
  .controller('CustomerCtrl', [
    '$scope', '$state', '$sce', 'customerViewModel', '$stateParams',
    function ($scope, $state, $sce, viewModel, $stateParams) {
      if ($state.current.name.indexOf('.') == -1) {
        $state.go($state.current.name + '.general', $stateParams);
      }
      $scope.company = { Id: parseInt($stateParams.key) };
      $scope.model = new viewModel($scope.company);

      $scope.getGoogleMapUrl = function (name, address, isAddressAlreadyCombined) {
        if (isAddressAlreadyCombined) {
          return $sce.trustAsResourceUrl(
            "https://www.google.com/maps/embed/v1/place?q=" + address.replace(",", "").replace(" ", "") + "&key=AIzaSyBcAaO3YFFEbWPgvGgC1rXj9yAmGajPRVA"
          );
        } else {
          return $sce.trustAsResourceUrl(
            "https://www.google.com/maps/embed/v1/place?q=" + address.Street + address.City + address.ZipCode + "&key=AIzaSyBcAaO3YFFEbWPgvGgC1rXj9yAmGajPRVA"
          );
        }
      };
    }
  ]);
