'use strict';

angular.module('magtac')
    .service('ServerDeleteDetailsViewModel', ['magview.config', 'Server', 'modalDialogService', '$window', '$rootScope',
        function (config, Server, modalDialogService, $window, $rootScope) {
            var viewModel = function (companyCode) {

                var self = this;
                self.companyCode = companyCode;
                self.customerName = "Customer Name Not Found";
                self.timeout = 5;
                self.hasMagtac;


                Server.getCustomerName(companyCode).then((response) => {
                    if(response != null && response != "")
                    self.customerName = response;
                });


                Server.getServersByCompanyCode(companyCode).then(
                    function (response) {
                        console.log('in details call');
                        console.log(response);
                        self.serverInfo = response;

                        console.log(self.serverInfo[0].ServerName);
                        if (response[0]) {
                            self.customerName = response[0].CompanyName;
                        }else {
                            Server.getCustomerName(companyCode).then((response) => {
                                self.customerName = response;
                            });
                        }

                        for (let i = 0; i < self.serverInfo.length; i++) {
                            self.serverInfo[i].RecordsDeletedSoFar = 0;
                            self.serverInfo[i].DeletionStatus = 'incomplete'; 
                            self.serverInfo[i].PauseDeleteButtonDisplay = 'Delete'; 
                            self.serverInfo[i].RecordsRemaining = self.serverInfo[i].TaskHistoryCount;
                            self.serverInfo[i].frontEndServerIndex = i;
                        }

                        console.log(self.serverInfo);
                    }
                );


                self.getPercentComplete = function (serverInfo) {

                    return Math.round( (serverInfo.RecordsDeletedSoFar / serverInfo.TaskHistoryCount) * 100 );
                }


                self.startOrPauseServerDelete = async function (serverName, serverId) {
                    let currentServer = this.serverInfo.find(s => s.ServerId == serverId);

                    if (currentServer.PauseDeleteButtonDisplay == "Delete") {
                        self.confirmDeletion(serverName, serverId)
                    } else if (currentServer.PauseDeleteButtonDisplay == "Pause") {
                        currentServer.DeletionStatus = 'completed';
                        currentServer.PauseDeleteButtonDisplay = 'Resume';
                    } else if (currentServer.PauseDeleteButtonDisplay == "Resume") {
                        if (self.serverInfo.length <= 0) {
                            modalDialogService.showAlert(`Server cannot be deleted, as it is the only one. Please ensure there are at least two servers 
                            before deleting one`, "Delete Server Alert", "dialog - warning");
                        } else {
                            self.deleteServerIncremental(serverName, serverId)
                        }
                        currentServer.PauseDeleteButtonDisplay = 'Pause';
                    }
                }


                self.confirmDeletion = async function (serverName, serverId) {
                    console.log('reached delete incremental');
                    console.log(serverId);

                    const result = await modalDialogService.showConfirmation(`Are you sure want to delete the server ${serverName}, ID: ${serverId}? This action can take a long time, and taskhistory records will be irrevocably deleted.`, 'Delete document confirmation', 'dialog - danger');

                    if (result) {
                        console.log(result);
                        if (self.serverInfo.length <= 0) {
                            modalDialogService.showAlert(`Server cannot be deleted, as it is the only one. Please ensure there are at least two servers 
                            before deleting one`, "Delete Server Alert", "dialog - warning");
                        } else {
                            self.deleteServerIncremental(serverName, serverId);
                        }
                    } else {
                        console.log("Confirmation rejected");
                    }
                };


                self.deleteServerIncremental = async function (serverName, serverId, confirmationRequested = false) {
                    let currentServer = this.serverInfo.find(s => s.ServerId == serverId);
                    let recordsDeleted = currentServer.RecordsDeletedSoFar;

                    currentServer.DeletionStatus = 'incomplete';
                    currentServer.PauseDeleteButtonDisplay = 'Pause';

                    while (currentServer.DeletionStatus == 'incomplete') {
                        const response = await Server.deleteServerIncremental(serverName, serverId);
                        console.log(response);

                        if (response == 'completed') {
                            currentServer.DeletionStatus = response;
                            if (confirmationRequested) {
                                modalDialogService.showAlert('Server ' + serverName + ', ID:  ' + serverId + ' Deleted successfully', 'Delete Server Alert', 'dialog-success')
                                    .then(() => {
                                        $rootScope.$apply(() => {
                                            self.serverInfo.splice(currentServer.frontEndServerIndex, 1);
                                        });
                                    });
                            } else {
                                console.log("server deleted");
                                $rootScope.$apply(() => {
                                    self.serverInfo.splice(currentServer.frontEndServerIndex, 1);
                                });
                                console.log(self.serverInfo.length);
                            }

                        } else {
                            let intResponse = parseInt(response);
                            console.log('response: ' + intResponse + " type: " + typeof (intResponse));
                            recordsDeleted += intResponse;

                            currentServer.RecordsDeletedSoFar = recordsDeleted;
                            currentServer.RecordsRemaining = currentServer.TaskHistoryCount - currentServer.RecordsDeletedSoFar;
                        }

                        if (isNaN(response)) { break; }
                    }
                };


                self.deleteAllServers = async function () {
                    // Map each server deletion to a promise and collect them into an array
                    let deletionPromises = self.serverInfo.map(server =>
                        self.deleteServerIncremental(server.ServerName, server.ServerId, false)
                    );

                    // Use Promise.all to wait for all deletions to complete
                    await Promise.all(deletionPromises);
                };        
                    

                self.deleteAllMagTac = async function () {
                    modalDialogService.showConfirmation(`Are you sure want to delete MagTac? This will delete all servers, all folders, and all traces of MagTac in the database. If there is Task History associated with this instance, this action may take a VERY long time.`,
                        'Delete document confirmation', 'dialog - danger').then(function (result) {
                            if (result) {
                                // Use Promise.all to wait for all deleteServerIncremental operations to complete
                                self.deleteAllServers()
                                    .then(() => {
                                        console.log("This should appear after server deletion");
                                        console.log(self.serverInfo.length);
                                        if (self.serverInfo.length <= 0) {
                                            Server.deleteMagTac(companyCode).then((response) => {
                                                if (response == false) {
                                                    modalDialogService.showAlert(`MagTac has failed to completely delete.`, 'Delete MagTAC Alert', 'dialog - success')
                                                        .then(() => {
                                                            location.reload();
                                                        });
                                                } else {
                                                    modalDialogService.showAlert(`Magtac has been successfully deleted for ${companyCode}`, 'Delete Server Alert', 'dialog-success')
                                                        .then(() => {
                                                            location.reload();
                                                        });
                                                }
                                            });
                                        } else {
                                            modalDialogService.showAlert(`MagTac could not be deleted because at least one server failed to delete.
                                                                            If the problem persists, please delete individual servers first.`, 'Delete MagTAC Alert', 'dialog - success')
                                        }

                                    })
                                    .catch((error) => {
                                        console.error("Error deleting servers:", error);
                                        modalDialogService.showAlert(`Error occurred during deletion.`, 'Error Alert', 'dialog-error');
                                    });
                            } else {
                                console.log("Confirmation rejected");
                            }
                        });
                };




                self.detectMagtac = function () {
                    Server.detectMagtac(companyCode).then((response) => {
                        self.hasMagtac = response;
                    });
                };

                self.hasMagtac = self.detectMagtac();



                // not currently used (use incremental instead)
                self.deleteServer = function (serverName, serverId) {
                    console.log('reached delete logic');
                    console.log(serverId);

                    modalDialogService.showConfirmation('Are you sure want to delete the server ' + serverName + ', ID:  ' + serverId + `? This action can take a long time. Please see the timeout
                                                            option to set maximum time spend on this operation.`, 'Delete document confirmation', 'dialog - danger').then(function (result) {
                            if (result) {
                                console.log(result);

                            if (self.serverInfo.length <= 1) {
                                modalDialogService.showAlert(`Server cannot be deleted, as it is the only one. Please ensure there are at least two servers 
                                    before deleting one`, "Delete Server Alert", "dialog - warning");
                            } else {
                                Server.deleteServer(serverName, serverId, self.timeout).then((response) => {
                                    if (response != -1) {
                                        modalDialogService.showAlert(`The deletion operation for ${serverName} has timed out because it 
                                             took more than ${self.timeout} minutes. ${response} records have been deleted. `, 'Delete Server Alert', 'dialog - success')
                                        .then(() => {
                                            location.reload();
                                        });
                                    } else {
                                        modalDialogService.showAlert('Server ' + serverName + ', ID:  ' + serverId + ' Deleted successfully', 'Delete Server Alert', 'dialog-success')
                                        .then(() => {
                                            location.reload();
                                        });
                                    }
                                });
                            }
                        } else {
                            console.log("Confirmation rejected");
                        }
                    });
                };

                self.formatDate = function (dateStr) {
                    // dateStr = "2024-02-12T14:54:46.84";
                    var date = new Date(dateStr);

                    var formattedDate = date.toLocaleString('en-US', {
                        month: 'long', 
                        day: '2-digit', 
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit', 
                        hour12: true 
                    });

                    return formattedDate;
                }




            };
            return viewModel;
        }
    ]);
