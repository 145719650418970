/**
 * Created on 10/26/2015.
 */

'use strict';

angular.module('common')
  .service('AnnouncementService', [
    '$http', '$q', 'common.config',
    function($http, $q, config) {
      var service = {
        getLatestCard: function() {

          // var defer = $q.defer();

          // $http.get("https://api.trello.com/1/lists/" + config.AnnouncementsBoardId + "/cards").then(function(resp) {
          //   var item = resp.data[0];

          //   $http.get(config.apiUrls.base + "/AnnouncementAudit/IsAnnouncementViewed/" + item.id + "?lastUpdateDate=" + item.dateLastActivity).then(function (resp2) {
          //     if (resp2.data === false) {
          //       defer.resolve(item);
          //     } else {
          //       defer.resolve(null);
          //     }
          //   });
          // });

          return null;
        },
        logViewed: function(id) {
          $http.get(config.apiUrls.base + "/AnnouncementAudit/LogAnnouncementViewed/" + id);
        }
      };

      return service;
    }
  ]);
