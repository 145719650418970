/**
 * Created on 10/1/2015.
 */

angular.module('membership')
  .controller('UserCreationController', [
    '$scope', 'userCreationViewModel', '$modalInstance', 'userRole', '$$extendedData',
    function($scope, userCreationViewModel, modalInstance, userRole, $$extendedData) {

      $scope.model = new userCreationViewModel(modalInstance, userRole, $$extendedData);
    }
  ]);
