'use strict';

angular.module('magview')
    .service('generalViewModel', ['Prefs', 'modalService', 'generalEditViewModel',
        function (Prefs, modalService, generalEditViewModel) {

            var viewModel = function (pref) {


                var self = this;
                self.name = pref.Name;

                self.escapeHtml = function (unsafe) {
                    if (!unsafe) {
                        return "";
                    }
                    return unsafe
                        .replace(/&/g, "&amp;")
                        .replace(/</g, "&lt;")
                        .replace(/>/g, "&gt;")
                        .replace(/"/g, "&quot;")
                        .replace(/'/g, "&#039;");
                };


                Prefs.getPrefDetails(pref.Name).then(
                    function (response) {
                        response.DateAdded = response.DateAdded.split('T')[0];
                        self.prefDetails = response;
                        let descriptionSansSpecialChars = self.escapeHtml(response.Description);
                        self.prefDescription = descriptionSansSpecialChars.replace(/!br!/g, '<br>');
                        console.log(response);
                    }
                );


                self.reload = function () {
                    Prefs.getPrefDetails(pref.Name).then(function (response) {
                        response.DateAdded = response.DateAdded.split('T')[0];
                        self.prefDetails = response;
                        let descriptionSansSpecialChars = self.escapeHtml(response.Description);
                        self.prefDescription = descriptionSansSpecialChars.replace(/!br!/g, '<br>');
                    }).catch(function (error) {
                        console.error("Error fetching details:", error);
                    });
                };

                self.editDescription = function () {
                    var detailsViewModel = new generalEditViewModel(this.prefDetails.Description, this.prefDetails.Name);
                    detailsViewModel.on('save', function () {
                        self.reload();
                    }.bind(this));
                    modalService.showWindow('components/magview/prefs/prefDetails/general/generalEdit.html', detailsViewModel);
                };

            };

            return viewModel;

        }
    ]);
