'use strict';

angular.module('ticketAssignment', [
    'ticketAssignment-config',
    'ngCookies',
    'ngSanitize',
    'ngAnimate',
    'mgcrea.ngStrap',
    'navigation',
    'ui.router',
    'common',
    'mgo-angular-wizard',
    'angularjs-dropdown-multiselect'
]);
