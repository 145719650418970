'use strict';

angular.module('membership')
  .controller('CompanyDetailsCtrl', [
    '$scope', 'companyDetailsViewModel', '$stateParams',
    function ($scope, model, $stateParams) {
      $scope.vm = model;
      $scope.vm.init($stateParams.id);

      $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        if (fromState.name) {
          $scope.vm.previousPage = fromState;
          $scope.vm.previousParams = fromParams;
        }
      });
    }
  ]);
