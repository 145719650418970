// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#roleDetails .page-header,
#userDetails .page-header {
  margin-bottom: -1px;
  padding-bottom: 0px;
}
.pac-container {
  z-index: 2000 !important;
}
.global-warning {
  width: 500px;
  margin: auto;
  margin-top: 100px;
}
.global-warning h1 {
  background: red;
  color: #fff;
  margin: 0;
  font-size: 22px;
  padding: 10px 15px;
}
.global-warning p {
  background: #fff;
  padding: 30px 15px;
  font-size: 18px;
}
.space-after {
  margin-right: 5px;
}
#source-permissions,
#selected-permissions {
  height: 160px;
}
#source-permissions option,
#selected-permissions option {
  white-space: normal;
  margin-bottom: 3px;
}
.version-info {
  position: absolute;
  width: 100%;
  bottom: 10px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/membership/styles/membership.css"],"names":[],"mappings":"AAAA;;EAEE,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,WAAW;EACX,SAAS;EACT,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,iBAAiB;AACnB;AACA;;EAEE,aAAa;AACf;AACA;;EAEE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":["#roleDetails .page-header,\n#userDetails .page-header {\n  margin-bottom: -1px;\n  padding-bottom: 0px;\n}\n.pac-container {\n  z-index: 2000 !important;\n}\n.global-warning {\n  width: 500px;\n  margin: auto;\n  margin-top: 100px;\n}\n.global-warning h1 {\n  background: red;\n  color: #fff;\n  margin: 0;\n  font-size: 22px;\n  padding: 10px 15px;\n}\n.global-warning p {\n  background: #fff;\n  padding: 30px 15px;\n  font-size: 18px;\n}\n.space-after {\n  margin-right: 5px;\n}\n#source-permissions,\n#selected-permissions {\n  height: 160px;\n}\n#source-permissions option,\n#selected-permissions option {\n  white-space: normal;\n  margin-bottom: 3px;\n}\n.version-info {\n  position: absolute;\n  width: 100%;\n  bottom: 10px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
