'use strict';

angular.module('magtac')
  .service('TimerConfig', ['AjaxService', 'magtac.config',
    function (ajaxService, config) {
      var baseUrl = config.apiUrls.base + '/timers';
      var domain = {
        get: function (companyId) {
          return ajaxService.get(baseUrl + '?companyId=' + companyId);
        },

        update: function (timerModel) {
          return ajaxService.put(baseUrl, timerModel);
        },

        create: function (timerModel) {
          return ajaxService.post(baseUrl, timerModel);
        },

        delete: function (timerId) {
          return ajaxService.delete(baseUrl + '/' + timerId);
          },


        getAvailableTasks: function (companyId) {
          return ajaxService.get(baseUrl + '/getAvailableTasks?companyId=' + companyId);
        },

        getAdHocQueries: function () {
          return ajaxService.get(baseUrl + '/getAdHocQueries');
        },

        getServers: function (companyId) {
        return ajaxService.get(baseUrl + '/getServers?companyId=' + companyId);
        },

        getServersByCompanyCode: function (companyCode) {
            console.log("getting servers for: " + companyCode);
            return ajaxService.get(baseUrl + '/getServersByCompanyCode?companyCode=' + companyCode);
        },

        getDefaultTasksConfigurations: function () {
          return ajaxService.get(baseUrl + '/getDefaultTasksConfigurations');
        }
      };

      return domain;
    }
  ]);
