'use strict';

angular.module('membership')
  .service('Customer', [
    'AjaxService', 'membership.config',
    function (ajaxService, config) {
      var baseUrl = config.apiUrls.base + '/customers';
      var domain = {
        namesList: function () {
          return ajaxService.get(baseUrl + '/get');
        },
        details: function (id) {
          return ajaxService.get(baseUrl + '/getdetails/' + id);
        },
        contacts: function (id) {
          return ajaxService.get(baseUrl + '/contacts/' + id);
        },
        saveContact: function (item) {
          return ajaxService.post(baseUrl + '/saveContact', item);
        },
        deleteContact: function (item) {
          return ajaxService.post(baseUrl + '/deleteContact', item);
        },
        sites: function (id) {
          return ajaxService.get(baseUrl + '/sites/' + id);
        },
        saveSite: function (item) {
          return ajaxService.post(baseUrl + '/saveSite', item);
        },
        deleteSite: function (item) {
          return ajaxService.post(baseUrl + '/deleteSite', item);
        },
        documents: function (id) {
          return ajaxService.get(baseUrl + '/documents/' + id);
        },
        vendors: function (id) {
          return ajaxService.get(baseUrl + '/vendors/' + id);
        },
        partnerships: function (id) {
          return ajaxService.get(baseUrl + '/partnerships/' + id);
        },
        savePartnership: function (item) {
          return ajaxService.post(baseUrl + '/savePartnership', item);
        },
        deletePartnership: function (item) {
          return ajaxService.post(baseUrl + '/deletePartnership', item);
        },
        saveVendor: function (item) {
          return ajaxService.post(baseUrl + '/saveVendor', item);
        },
        deleteVendor: function (item) {
          return ajaxService.post(baseUrl + '/deleteVendor', item);
        },
        getPartners: function () {
          return ajaxService.get(baseUrl + '/getPartners');
        },
        getConnectionInfo: function (id) {
          return ajaxService.get(baseUrl + '/GetConnectionInfo/' + id);
        },
        saveConnectionInfo: function (data) {
          return ajaxService.post(baseUrl + '/SaveConnectionInfo', data);
        },
        updateCustomer: function (data) {
          return ajaxService.put(baseUrl + '/' + data.Id, data);
        },
        deleteCustomer: function (customerId) {
          return ajaxService.delete(baseUrl + '/' + customerId);
        },
        revertCustomer: function (customerId) {
          return ajaxService.post(baseUrl + '/revertCustomer/' + customerId);
        },
        getWorkflow: function(customerId){
          return  ajaxService.get(baseUrl + '/workflow/' + customerId );
        }
      };

      return domain;
    }
  ]);
