/**
 * Created on 10/24/2015.
 */

'use strict';

angular.module('membership')
    .service('ProfilePropertyUiService', [
    'modalDialogService',
        function (modalDialogService) {
            var ProfilePropertyUiService = {
              create: function(){
                return modalDialogService.showWindow('components/membership/userProfileProperty/create/create.html', 'profilePropertyCreateController');
              },
              edit: function(propertyId){

              }
            };

            return ProfilePropertyUiService;
        }
    ]);
