'use strict';

angular.module('magtac-config', [])
  .provider('magtac.config', function () {
    this.config = {
      apiUrls: {
        base: '/magtac.api'
      },
      events: {
        statusReloading: 'magtac.events.statusReloading'
      }
    };

    var self = this;

    this.$get = function () {
      return self.config;
    };
    return this;
  });