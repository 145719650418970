/**
 * Created on 10/12/2015.
 */
angular.module('common')
  .provider('layoutZoneService', [
    function () {
      var self = this;

      self.widgets = [];

      self.$get = ['$compile', '$rootScope', function ($compile, $rootScope) {
        return {
          registerWidget: function (name, widget) {
            widget.zoneName = name;
            widget.render = function () {
              return widget.template;
            };
            if (!widget.position) {
              widget.position = (_.max(_.filter(self.widgets, function (wid) {
                  return wid.zoneName == name;
                }), 'position').position || 0) + 1;
            }
            self.widgets.push(widget);
          },
          getWidgets: function (zoneName) {
            return _.sortBy(_.filter(self.widgets, function (w) {
              return w.zoneName == zoneName;
            }), 'position');
          },
          widgets: self.widgets
        }
      }];

      return self;
    }
  ]);
