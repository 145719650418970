'use strict';

angular.module('membership')
  .factory('manageUsersListSyncViewModel', ['$http', 'membership.config', 'BizUsersSync', 'scheduler.config', function ($http, config, BizUsers, schedulerConfig) {

    var viewModel = function (filter) {
      var self = this;
      self.role = filter;

      self.isClients = self.role == 'clients';
      self.isEmployees = self.role == 'employees';
      self.isNewPatients = self.role == 'newPatients';
      self.isUsers = self.role == 'users';

      self.datatablesConfig = {
        datasourceUrl: config.apiUrls.base + '/datasource/syncBizUsers',
        dtColumns: [
          /*{
            "aTargets": 6,
            "bSearchable": false,
            "bSortable": false
          },*/
          {
            "aTargets": -1,
            "bSearchable": false,
            "bSortable": false,
            "data": null,
            "sWidth": 150,
            "sClass": "actions-col dynamic-html",
            "render": function (data, type, full, meta) {

              if (self.isClients) {
                
                var editBtn = "<a minifyme class='btn btn-success' href='#/membership/usermanager/clients/view/" + data[0] + "' rel='tooltip' title='View Patient Detail' ><i class='fa fa-eye'></i></a>";
                var addApptBtn = "<a class='btn btn-success' href='#/scheduler/appointments/customer/" + data[0] + "' rel='tooltip' title='Add Appointment'><i class='fa fa-calendar'></i></a>";

                return editBtn + "&nbsp;" + addApptBtn;

              }

              else if (self.isNewPatients) {

                var editBtn = "<a minifyme class='btn btn-success' href='#/membership/usermanager/newPatients/update/" + data[0] + "' rel='tooltip' title='Edit Patient' ><i class='fa fa-edit'></i></a>";
                var addApptBtn = "<a class='btn btn-success' href='#/scheduler/appointments/customer/" + data[0] + "' rel='tooltip' title='Add Appointment'><i class='fa fa-calendar'></i></a>";
                var reconcileBtn = "<a minifyme class='btn btn-success' href='#/membership/usermanager/newPatients/reconcile/" + data[0] + "' rel='tooltip' title='Reconcile Patient' ><i class='fa fa-compress'></i></a>";
                var deleteBtn = "<a class='btn btn-danger' confirmation='Are you sure you want to delete this user?' ng-click='vm.remove(" + data[0] + ")' itle='Delete'><i class='fa fa-times'></i></a>";

                return editBtn + "&nbsp;" + addApptBtn + "&nbsp;" + reconcileBtn + "&nbsp;" + deleteBtn;

              } else {
                
                var editBtn = "<a class='btn btn-success' ui-sref='membership_usermanager_details({filter:\"" + self.role + "\", action:\"update\", userId: " + data[0] + "})' title='Edit' ><i class='fa fa-edit'></i></a>";
                var deleteBtn = "<a class='btn btn-danger' confirmation='Are you sure you want to delete this user?' ng-click='vm.remove(" + data[0] + ")' itle='Delete'><i class='fa fa-times'></i></a>";

                return editBtn + "&nbsp;" + deleteBtn;

              }
            }
          }
        ],
        hiddenColumns: [0]
      };

      if (self.isClients) {
        self.datatablesConfig.hiddenColumns = [0, 4];
        self.datatablesConfig.customFilter = { role: self.role };
      }
      else if (self.isEmployees) {
        self.datatablesConfig.hiddenColumns = [0, 1, 5, 6];
        self.datatablesConfig.customFilter = { role: self.role };
      }
      else if (self.isUsers) {
        self.datatablesConfig.hiddenColumns = [0, 1, 5, 6];
        self.datatablesConfig.customFilter = { role: self.role };
      }
      else if (self.isNewPatients) {
        self.datatablesConfig.hiddenColumns = [0, 1];
        self.datatablesConfig.customFilter = { role: self.role };
        /*self.datatablesConfig.dtColumns[1].render = function(data, type, full, meta) {

          var editBtn = "<a minifyme class='btn btn-success' href='#/membership/usermanager/newPatients/update/" + data[0] + "' rel='tooltip' title='Edit Patient' ><i class='fa fa-edit'></i></a>";
          var addApptBtn = "<a class='btn btn-success' href='#/scheduler/appointments/customer/" + data[0] + "' rel='tooltip' title='Add Appointment'><i class='fa fa-calendar'></i></a>";
          var reconcileBtn = "<a minifyme class='btn btn-success' href='#/membership/usermanager/newPatients/reconcile/" + data[0] + "' rel='tooltip' title='Reconcile Patient' ><i class='fa fa-compress'></i></a>";

          return editBtn + "&nbsp;" + addApptBtn + "&nbsp;" + reconcileBtn;
        };*/
      }

      self.remove = function(userId) {
        BizUsers.remove(userId, function () {
          self.datatablesConfig.reload();
        });
      };

      self.refreshUsers = function () {
        self.isRefreshingUsers = true;
        $http.get(schedulerConfig.apiUrls.base + "/import/refreshUsers").then(function (response) {
          if (!response.data.success) {
            alert(response.data.Message);
            alert(response.data.Exception);
          }
          self.isRefreshingUsers = false;
        });
      };

    };

    return viewModel;
  }]);