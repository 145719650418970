'use strict';

angular.module('magtac')
  .config([
    'appStateConfigProvider',
    function (appStateConfigProvider) {

      var states = [
        {
          name: 'membership_customer.status',
          url: "/status",
          redirectTo: 'membership_customer.status.currentStatus',
          templateUrl: "components/magtac/customerStatus/customerStatus.html",
          controller: "CustomerStatusCtrl"
        },

        {
          name: 'membership_customer.status.currentStatus',
          url: "",
          templateUrl: "components/magtac/customerStatus/currentStatus/currentStatus.html",
          controller: "CurrentStatusCtrl"
        },
        {
          name: 'membership_customer.status.statusHistory',
          url: "/history",
          templateUrl: "components/magtac/customerStatus/statusHistory/statusHistory.html",
          controller: "StatusHistoryCtrl"
        },
        {
          name: 'membership_customer.status.observers',
          url: "/observers",
          templateUrl: "components/magtac/customerStatus/observers/observers.html",
          controller: "ObserversCtrl"
        },
        {
          name: 'membership_customer.status.statusConfig',
          url: "/statusConfig",
          templateUrl: "components/magtac/customerStatus/statusConfig/statusConfig.html",
          controller: "StatusConfigCtrl"
        },
        {
          name: 'membership_customer.status.timersConfig',
          url: "/timers",
          templateUrl: "components/magtac/customerStatus/timersConfig/timersConfig.html",
          controller: "TimersConfigCtrl"
        },
        {
          name: 'membership_customer.status.general',
          url: "/general",
          templateUrl: "components/magtac/customerStatus/general/general.html",
          controller: "StatusGeneralCtrl"
          },
          {
              name: 'server_deletion',
              url: "/serverDeletion",
              templateUrl: "components/magtac/customerStatus/serverDelete/serverDelete.html",
              controller: 'ServerDeleteCtrl',
              data: {
                  access: 'authorizedUser'
              }
          }, {
              name: 'server_deletion_details',
              url: "/serverDeletion/{companyCode}",
              templateUrl: "components/magtac/customerStatus/serverDelete/serverDeleteDetails/serverDeleteDetails.html",
          },

        {
          name: 'magtacDashboard',
          url: "/dashboard",
          templateUrl: "components/magtac/dashboard/dashboard.html",
          controller: "DashboardCtrl",
          data: {
            access: '/dashboard'
          }
        },

        {
          name: 'magtacLibraries',
          url: "/libraries",
          templateUrl: "components/magtac/librariesManagement/libraries.html",
          controller: "LibrariesManagementCtrl",
          data: {
            access: '/libraries'
          }
        },

        {
          name: 'magTacBulkTasksManagement',
          url: "/magtacTasks",
          templateUrl: "components/magtac/bulkTasksManagement/list.html",
          controller: "BulkTasksManagementCtrl",
          data: {
            access: '/libraries'
          }
        },

        {
          name: 'magtacCustomersVersions',
          url: "/magtac-customers-versions",
          templateUrl: "components/magtac/customers/magtac-customers.html",
        },
        {
          name: 'magtacCustomersVersions.List',
          url: "/list",
          templateUrl: "components/magtac/customers/list/magtac-customers-list.html",
          controller: "MagTacCustomersCtrl"
        },
        ,
        {
            name: 'magtacCustomersWorkflows',
            url: "/workflow",
            templateUrl: "components/magtac/customers/workflow/magtac-customers-workflows-list.html",
            controller: "MagTacCustomersWorkflowsCtrl"
        }, //todo why are there two for each?
        {
          name: "magtacCustomersTasks",
          url: "/magtac-customers-tasks",
          templateUrl: "components/magtac/customers/magtac-customers.html"
        },
        {
          name: 'magtacCustomersTasks.List',
          url: "/taskslist",
          templateUrl: "components/magtac/customers/taskslist/magtac-customers-tasks-list.html",
          controller: "MagTacCustomersTasksCtrl"
        }

      ];

      states.forEach(appStateConfigProvider.addState);
    }]);
