'use strict';

angular.module('membership')
  .directive('permission', [
    '$rootScope', 'authorizeService', 'membership.config',
    function ($rootScope, authorizeService, config) {
      return {
        scope: {
          permission: '@permission'
        },
        link: function (scope, elm, attrs) {

          var permissionKeys;
          var logivalOperation;

          if (scope.permission.indexOf("|") > -1) {
            permissionKeys = scope.permission.split("|");
            logivalOperation = 'OR';
          }
          else if (scope.permission.indexOf("&") > -1) {
            permissionKeys = scope.permission.split("&");
            logivalOperation = 'AND';
          } else {
            permissionKeys = [scope.permission];
          }

          var isAllowed = function (key) {
            var permissionKey = config.permissions[key] || key;

            //console.log(permissionKey);

            return authorizeService.isPageAvailable(permissionKey)
              || authorizeService.isFeatureAllowed(permissionKey);
          };

          var checkIsAllowed = function() {
            if (!logivalOperation) {
              return isAllowed(permissionKeys[0]);
            } else if (logivalOperation === 'OR') {
              return !!_.find(permissionKeys, function(x) {
                return isAllowed(x);
              });
            }
            else if (logivalOperation === 'AND') {
              return !_.find(permissionKeys, function (x) {
                return !isAllowed(x);
              });
            }
            return false;
          };

          scope.$watch(function () {
            return checkIsAllowed();
          }, function (newValue) {

            if (!newValue) {
              elm.hide();
            } else {
              elm.show();
            }
          });
        }
      };
    }
  ]);