'use strict';

angular.module('magtac')
  .service('BulkTasksConfig', ['AjaxService', 'magtac.config',
    function (ajaxService, config) {
      var baseUrl = config.apiUrls.base + '/BulkTaskConfiguration';
      var domain = {
        get: function () {
          return ajaxService.get(baseUrl);
        },
        create: function (timerModel) {
          return ajaxService.post(baseUrl, timerModel);
        },
        update: function (timerModel) {
          return ajaxService.put(baseUrl, timerModel);
        },
        delete: function (configId) {
          return ajaxService.delete(baseUrl + '/' + configId);
        },
        getAvailableTasks: function (companyId) {
          return ajaxService.get(baseUrl + '/getAvailableTasks');
        },
        getAdHocQueries: function () {
          return ajaxService.get(baseUrl + '/getAdHocQueries');
        }
      };

      return domain;
    }
  ]);
