'use strict';

angular.module('magview')
  .service('attendanceDetailsViewModel', [
    '$http', 'TimeManagement', 'Lookup', 'magview.config', 'membership.config',
    function ($http, TimeManagement, Lookup, config, membershipConfig) {

      var viewModel = function (id, surroundingPageUser) {

        var self = this;

        self.listeners = {};
        self.item = { User: {} };
        if (surroundingPageUser) {
          self.surroundingPageUser = surroundingPageUser;
          if (!id) {
            self.item.User = { Id: surroundingPageUser.Id };
          }
        }

        self.on = function (action, callBack) {
          self.listeners[action] = callBack;
        };

        self.notify = function (action, params) {
          if (self.listeners[action]) {
            self.listeners[action](params);
          }
        };

        Lookup.attendanceReasons().then(function (data) {
          self.attendanceReasons = data;
        });

        if (id) {
          self.isDataReady = false;
          TimeManagement.getAttendance(id).then(function(resp) {
            self.item = resp;
            self.isDataReady = true;
          });
        } else {
          self.isDataReady = true;
        }

        self.save = function (cb) {
          self.validationMessage = '';
          if (!self.item.User.Id) {
            self.validatonMessage = 'User is required';
            return;
          }

          if (!self.item.StartDate) {
            self.validatonMessage = 'Start Date is required';
            return;
          }

          if (!self.item.Length) {
            self.validatonMessage = 'Length is required';
            return;
          }

          if (!self.item.ReasonId) {
            self.validatonMessage = 'Reason is required';
            return;
          }

          TimeManagement.saveAttendance(self.item).then(function () {
            self.notify('save');
          });
          cb();
        };

        self.delete = function () {
          TimeManagement.deleteAttendance(self.item).then(function () {
            self.notify('delete');
          });
        };
      };

      return viewModel;

    }
  ]);
