'use strict';

angular.module('ticketAssignment')
    .service('ticketsAdminViewModel', ['ticketAssignment.config', 'modalDialogService', 'ticketAdmin', '$state',
        function (config, modalDialogService, Tickets, $state) {

            var viewModel = function () {

                var self = this;

                Tickets.getAll().then(function (resp) {
                    self.tickets = resp;
                });
            }

            return viewModel;
        }
    ]);
