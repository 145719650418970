'use strict';
angular.module('ticketAssignment')
    .service('activityReportDetailsViewModel', ['ticketAssignment.config', 'ticketAdmin', '$state',
        function (config, Tickets, $state) {
            var viewModel = function (parameters) {

                var self = this;

                self.parameters = parameters.split(' ');

                self.startDate = new Date(parseInt(self.parameters[0])).toLocaleString().split(',')[0];
                self.endDate = new Date(parseInt(self.parameters[1])).toLocaleString().split(',')[0];
                self.externalUserId = self.parameters[2];
                self.dateRangeString = '';
                if (self.startDate == self.endDate) {
                    self.dateRangeString = 'For ' + self.startDate;
                } else {
                    self.dateRangeString = 'From ' + self.startDate + ' to ' + self.endDate;
                }

                self.headers = [
                    {
                        name: 'Date',
                        width: '8vw',
                        display: 'Date'
                    },
                    {
                        name: 'Time',
                        width: '8vw',
                        display: 'Time'
                    },
                    {
                        name: 'Duration',
                        width: '7vw',
                        display: 'Time Between'
                    },
                    {
                        name: 'ActivityType',
                        width: '8vw',
                        display: 'Type'
                    },
                    {
                        name: 'ActivityContent',
                        width: '47vw',
                        display: 'Content'
                    },
                    {
                        name: 'TicketLogicalId',
                        width: '11vw',
                        display: 'Ticket ID'
                    }
                ];
				
                self.reports = [];

                Tickets.getActivityReport(parameters).then(
                    function (response) {
                        self.reports = response;
                        self.name = self.reports[self.externalUserId].AgentName;
                        console.log(self.reports);
                        console.log(self.reports[self.externalUserId]);
                    }
                );
            }

            return viewModel;
        }
    ]);