/**
 * Created on 11/27/2015.
 */

'use strict';

angular.module('membership')
  .factory('UserRegistrationViewModel', [
    'UserRegisterDomain',
    'modalDialogService',
    'notifySvc',
    'translationSvc',
    '$location',
    'appStateConfig',
    'SiteSettingService',
    'membership.config',
    function (UserRegisterDomain,
              modalDialogService,
              notifySvc,
              translationSvc,
              $location,
              appStateConfig,
              SiteSettingService,
              config) {

      function UserRegistrationViewModel() {
        var self = this;

        self.setting = SiteSettingService.getInstance(config.membershipSettingKey);

        self.data = {
          Email: "",
          Password: "",
          CellPhoneNumber: "",
          FirstName: "",
          LastName: "",
          ConfirmPassword: ""
        };

        self.registerSuccess = function () {
          var message = '';
          if (self.setting.data.UserMustActivateRegistration) {
            message = translationSvc.t('membership.msg.registerSuccessActivationNeeded');
          }
          else if (self.setting.data.RegistrationApprovalMustBeDoneByAdmin) {
            message = translationSvc.t('membership.msg.registerSuccessAdminApprovalNeeded');
          } else {
            message = translationSvc.t('membership.msg.registerSuccessNoActivationNeeded');
          }

          modalDialogService.showAlert(message, 'Registration successful', 'dialog-success').finally(function () {
            $location.path(appStateConfig.defaultPath);
          });
        };

        self.registerError = function (error) {
          notifySvc.error({
            msg: error.data && error.data.ExceptionMessage ? error.data.ExceptionMessage : error.data || error,
            title: "Error while registering"
          });
        };

        self.register = function () {
          var valid = true;
          if (!self.data.Email) {
            valid = false;
            modalDialogService.showAlert('Please enter your email', 'Registration form invalid', 'dialog-warning');
            return;
          }
          if (self.data.Password !== self.data.ConfirmPassword) {
            valid = false;
            modalDialogService.showAlert('Password and Confirm password must match', 'Registration form invalid', 'dialog-warning');
            return;
          }

          if (valid) {
            UserRegisterDomain.register(self.data).then(self.registerSuccess, self.registerError);
          }
        };
      }

      return UserRegistrationViewModel;
    }
  ]);
