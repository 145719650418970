angular.module('common')
  .service('modalService', ['$modal', '$rootScope', function ($modal, $rootScope) {
    return {
      showConfirmation: function (args) {
        this.showWindow('confirmationTemplate', args);
      },

      showAlert: function (args) {
        this.showWindow('alertTemplate', args);
      },

      showWindow: function (templatePath, args) {
        var scope = $rootScope.$new();
        scope.model = args;
        return $modal({
          templateUrl: templatePath,
          persist: true,
          show: true,
          backdrop: 'static',
          scope: scope,
          modalClass: 'modal-window'
        });
      },

      showApptWindow: function (templatePath, args, backdrop) {
        var scope = $rootScope.$new();
        scope.model = args;
        var modal = $modal({
          templateUrl: templatePath,
          show: true,
          backdrop: backdrop || false,
          keyboard: false,
          scope: scope,
          container: false,
          id: "appt-window"
        });
        return modal;
      }
    };
  }])
  .service('modalSvc', [
    '$modal', '$rootScope', '$controller',
    function ($modal, $rootScope, $controller) {
    return {
      showConfirmation: function (args) {
        this.showWindow('confirmationTemplate', args);
      },

      showAlert: function (args) {
        this.showWindow('/components/common/templates/alert.tpl.html', args);
      },

      showWindow: function (templatePath, args, options) {
        var scope = $rootScope.$new();
        scope.model = args;

        var modalConfiguration = {
          templateUrl: templatePath,
          persist: true,
          show: true,
          backdrop: 'static',
          modalClass: 'modal-window'
        };

        if (options)
          modalConfiguration = _.assign(modalConfiguration, options);

        modalConfiguration.scope = scope;

        return $modal(modalConfiguration);
      },

        showDialog: function (templatePath, controller, resolutions) {
          var scope = $rootScope.$new();

          resolutions.$scope = scope;

          var modalConfiguration = {
            templateUrl: templatePath,
            persist: true,
            show: true,
            backdrop: 'static',
            modalClass: 'modal-window'
          };

          modalConfiguration.scope = scope;
          var modalInstance = $modal(modalConfiguration);
          resolutions.$modalInstance = modalInstance;
          $controller(controller, resolutions);
          return modalInstance;
        },

      showApptWindow: function (templatePath, args, position) {
        var scope = $rootScope.$new();
        scope.model = args;
        var modal = $modal({
          templateUrl: templatePath,
          show: true,
          backdrop: false,
          keyboard: false,
          scope: scope,
          container: false,
          id: "appt-window"
        });
        return modal;
      }
    };
  }]);
