'use strict';

angular.module('magview')
    .service('prefDetailsViewModel', ['magview.config', 'Prefs', 'modalDialogService', '$window', 'generalEditViewModel',
        function (config, Details, modalDialogService, $window, generalEditViewModel) {
                  var viewModel = function (prefName) {

                var self = this;


                Details.getPrefDetails(prefName).then(
                    function (response) {
                        response.DateAdded = response.DateAdded.split('T')[0];
                        self.prefDetails = response;
                    }
                );

                      this.name = prefName;


                self.editDescription = function (prefName) {
                    console.log("edit description");
                    var detailsViewModel = new generalEditViewModel(prefName);
                    detailsViewModel.on('save', function () {
                        self.reload();
                    });
                    modalDialogService.showWindow('components/magview/prefs/prefDetails/general/generalDdit.html', detailsViewModel);
                };

            };

            return viewModel;
        }
    ]);
