angular.module('common')
  .directive('addressSearchControl', [
    'googleMapLoader',
    function (googleMapLoader) {
      return {
        restrict: "EA",
        scope: {
          bindingProperty: '@'
        },
        require: 'ngModel',
        link: function (scope, element, attribute, ngModel) {

          ngModel.$formatters.push(function (val) {
            if (!val) return '';
            if (typeof val == 'string') {
              return val;
            }

            return [val.streetAddress, val.city, (val.state + ' ' + val.zipCode).trim(), val.country]
              .filter(function (x) {
                return !!x;
              }).join(', ');
          });

          function initialControl() {
            var autocomplete = new google.maps.places.Autocomplete($(element)[0]);

            autocomplete.addListener('place_changed', function () {
              var place = autocomplete.getPlace();

              if (scope.bindingProperty == 'bindingProperty') {
                ngModel.$setViewValue(place.formatted_address);
                return;
              }

              var addressComponents = []; //place.formatted_address.split(', ');

              var locationObject = {};

              _.each(place.address_components, function (addressComponent) {

                if (_.includes(addressComponent.types, 'country')) {
                  locationObject.country = addressComponent.long_name;
                  addressComponents.push(addressComponent.long_name);
                  return;
                }

                if (_.includes(addressComponent.types, "street_number")) {
                  locationObject.streetNumber = addressComponent.long_name;
                  addressComponents.push(addressComponent.long_name);
                  return;
                }

                if (_.includes(addressComponent.types, "route")) {
                  locationObject.streetName = addressComponent.long_name;
                  addressComponents.push(addressComponent.long_name);
                  return;
                }

                if (_.includes(addressComponent.types, "administrative_area_level_1")) {
                  locationObject.state = addressComponent.long_name;
                  addressComponents.push(addressComponent.long_name);
                  return;
                }

                if (_.includes(addressComponent.types, "locality")) {
                  locationObject.city = addressComponent.long_name;
                  addressComponents.push(addressComponent.long_name);
                  return;
                }

                if (_.includes(addressComponent.types, "postal_code")) {
                  locationObject.zipCode = addressComponent.long_name;
                  addressComponents.push(addressComponent.long_name);
                  return;
                }
              });

              locationObject.streetAddress = [locationObject.streetNumber, locationObject.streetName].join(' ').trim();

              ngModel.$setViewValue(locationObject);
            });
          }

          googleMapLoader.then(initialControl);
        }
      }
    }
  ]);
