/**
 * Created on 9/30/2015.
 */

angular.module('membership')
  .controller('ClientCompanyManagersFilterableListController', [
    '$scope', 'FilterableUserListModel', 'membership.config', '$state', 'userUiService',
    function ($scope, FilterableUserListModel, config, $state, userUiService) {
      $scope.vm = new FilterableUserListModel();

      $scope.vm.datatablesConfig.customFilter = $scope.vm.datatablesConfig.customFilter || {};
      $scope.vm.datatablesConfig.customFilter.clientCompanyId = $state.params.clientCompanyId;

      $scope.vm.addNewUser = function () {
        userUiService.proceedUserCreation($state.current.data.filter, {
          ClientCompanyId: $scope.vm.datatablesConfig.customFilter.clientCompanyId
        }).finally(function () {
          $scope.vm.reloadTable();
        });
      };
    }
  ]);
