// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-inverse .navbar-brand {
  color: #fff;
  font-weight: bold;
}
.navbar-inverse .navbar-nav > li > a {
  color: #fff;
  padding: 15px 20px;
  text-transform: uppercase;
  font-size: 16px;
}
.navbar-inverse {
  background-color: #222e2e;
  border-radius: 0;
  margin-bottom: 40px;
}
.navbar-brand {
  padding: 0;
}
.navbar-brand img {
  height: 50px;
}
.navbar .container {
  background: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/components/navigation/styles/navbar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,UAAU;AACZ;AACA;EACE,YAAY;AACd;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".navbar-inverse .navbar-brand {\n  color: #fff;\n  font-weight: bold;\n}\n.navbar-inverse .navbar-nav > li > a {\n  color: #fff;\n  padding: 15px 20px;\n  text-transform: uppercase;\n  font-size: 16px;\n}\n.navbar-inverse {\n  background-color: #222e2e;\n  border-radius: 0;\n  margin-bottom: 40px;\n}\n.navbar-brand {\n  padding: 0;\n}\n.navbar-brand img {\n  height: 50px;\n}\n.navbar .container {\n  background: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
