/**
 * Created on 10/29/2015.
 */

'use strict';

angular.module('membership')
  .controller('UserProfileController', [
    '$scope', 'UserProfileViewModel', '$stateParams',
    function (scope, UserProfileViewModel, $stateParams) {
      scope.vm = new UserProfileViewModel($stateParams.userId);
    }
  ]);
