/**
 * Created on 11/10/2015.
 */

'use strict';

angular.module('common')
  .filter('repeatRange', [
    function () {
      return function (input, min, max) {
        min = parseInt(min); //Make string input int
        max = parseInt(max);
        for (var i = min; i < max; i++)
          input.push(i);
        return input;
      };
    }
  ]);
