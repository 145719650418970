'use strict';

angular.module('magtac')
    .service('Server', ['AjaxService', 'magtac.config',
        function (ajaxService, config) {
            var baseUrl = config.apiUrls.base + '/server';
            var domain = {

                deleteServer: function (serverName, serverId, timeout) {
                    console.log('deleting server: ' + serverName + ", timeout: " + timeout);
                    return ajaxService.post(baseUrl + '/deleteServer/' + serverName + '/' + serverId + "/" + timeout);
                },

                deleteServerIncremental: function (serverName, serverId) {
                    console.log('deleting server incrementally: ' + serverName);
                    var headers = {
                        'IsBackground': true
                    };
                    return ajaxService.post(baseUrl + '/deleteServerIncremental/' + serverName + '/' + serverId, null, headers);
                },

                deleteMagTac: function (companyId) {
                    console.log('deleting all MagTac. Calling back end.');
                    return ajaxService.post(baseUrl + '/deleteAllMagTac/' + companyId);
                },

                getCustomersServerData: function () {
                    console.log('trying to reach backend - get customer server data');
                    return ajaxService.get(baseUrl + '/getFormattedCustomersServerData');
                },

                getServers: function (companyId) {
                    return ajaxService.get(baseUrl + '/getServers?companyId=' + companyId);
                },

                getServersByCompanyCode: function (companyCode) {
                    console.log("getting servers for: " + companyCode);
                    return ajaxService.get(baseUrl + '/getServersByCompanyCode?companyCode=' + companyCode);
                },

                getCustomerName: function (companyCode) {
                    console.log('trying to reach backend - get customer name from company code: ' + companyCode);
                    return ajaxService.get(baseUrl + '/getCustomerName/' + companyCode);
                },

                detectMagtac: function (companyCode) {
                    console.log('trying to reach backend - detecting magTac for: ' + companyCode);
                    return ajaxService.get(baseUrl + '/detectMagtac/' + companyCode);
                },


            };

            return domain;
        }
    ]);
