'use strict';

angular.module('membership')
  .service('vendorsViewModel', [
    'Customer', 'modalService', 'vendorDetailsViewModel',
    function (Customer, modalService, vendorDetailsViewModel) {

      var viewModel = function (company) {

        var self = this;

        self.reload = function() {
          Customer.vendors(company.Id).then(function (resp) {
            company.vendors = resp;
            self.vendors = resp;
          });
        };

        if (!company.vendors) {
          self.reload();
        } else {
          self.vendors = company.vendors;
        }

        self.edit = function(item) {
          var detailsViewModel = new vendorDetailsViewModel(item || { CompanyId: company.Id, SiteId: (company.sites[0] || {}).Id }, company.sites);
          detailsViewModel.on('save', function() {
            self.reload();
          });
          detailsViewModel.on('delete', function(params) {
            self.reload();
          });
          modalService.showWindow('components/membership/customer/vendors/vendor-details.html', detailsViewModel);
        };

        self.delete = function(item) {
          Customer.deleteVendor(item).then(function() {
            self.reload();
          });
        };

        if (!company.sites) {
          Customer.sites(company.Id).then(function (resp) {
            company.sites = resp;
          });
        }
      };

      return viewModel;

    }
  ]);
