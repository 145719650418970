/**
 * Created on 10/2/2015.
 */
angular.module('common')
  .directive('leanDatatables', [
    '$compile', 'AjaxService', '$translate', '$q',
    function ($compile, AjaxService, $translate, $q) {
      return {
        scope: {
          configuration: "=leanDatatables",
          hideSearchBox: "="
        },
        link: function (scope, element) {
          var sDom = "<'row'" +
            "<'col-xs-12 col-sm-4'" + (scope.hideSearchBox ? ">" : "<'filter-left'f>>") +
            "<'col-xs-12 col-sm-4'r>" +
            "<'col-sm-4 col-xs-12 hidden-xs'<'pull-right'>>>" +
            "t" +
            "<'m-t-5 clearfix'>" +
            "<'row'<'col-sm-1'l><'col-sm-5 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>";
          var options = {
            "bProcessing": true,
            "bServerSide": true,
            "bSortCellsTop": true,
            "sDom": sDom,
            "bFilter": true,
            "language": {
              "search": "",
              "searchPlaceholder": "Search",
              //"lengthMenu": "Display _MENU_ Records",
              "lengthMenu": "_MENU_",
            },
            "ajax": ajaxImpl,
            drawCallback: function (oSettings) {
              var cellsWithDynamicHtml = element.find("td.dynamic-html");
              for (var index = 0; index < cellsWithDynamicHtml.length; index++) {
                var cell = angular.element(cellsWithDynamicHtml[index]);
                var originalHtml = cell.html();

                var compiledHtml = $compile('<div>' + originalHtml + '</div>')(scope.$parent);
                cell.html("");
                cell.append(compiledHtml);
              }

              if (dataTableConfig.fnDrawCallback) {
                dataTableConfig.fnDrawCallback(oSettings);
              }
            },
            "fnRowCallback": function (nRow, aData, iDisplayIndex) {
              if (dataTableConfig.fnRowCallback) {
                dataTableConfig.fnRowCallback(nRow, aData, iDisplayIndex);
              }
              return nRow;
            }
          };

          var dataTableConfig = scope.configuration;

          var promises = [];

          if (dataTableConfig.columns) {
            options.columns = dataTableConfig.columns;
            _.each(options.columns, function (column) {
              if (!column.title && !column.i18title)
                column.title = column.data;

              if (column.render) {
                column.sClass = column.sClass || "" + "actions-col dynamic-html";
              }

              if (column.i18title) {
                promises.push($translate(column.i18title).then(function (r) {
                  column.title = r;
                }));
              }
            });
          }

          if (dataTableConfig.hiddenColumns) {
            for (var i = 0; i < dataTableConfig.hiddenColumns.length; i++) {
              options.columns[dataTableConfig.hiddenColumns[i]].visible = false;
            }
          }

          if (dataTableConfig.columnDefs) {
            options.columnDefs = dataTableConfig.columnDefs;
          }

          if (dataTableConfig.fnServerParams) {
            options.fnServerParams = dataTableConfig.fnServerParams;
          }

          if (dataTableConfig.aaSorting) {
            options.aaSorting = dataTableConfig.aaSorting;

            options.aaSorting.forEach(function (pair) {
              if (pair[0] < 0) {
                pair[0] = options.columns.length + pair[0];
              }
            });
          }

          $q.all(promises).finally(function () {
            var dataTable = element.DataTable(options);

            dataTable.reload = function () {
              dataTable.draw(true);
            };

            scope.configuration.table = dataTable;
          });

          var emptyData = {
            "draw": 0,
            "data": [],
            "recordsTotal": 0,
            "recordsFiltered": 0
          };

          function ajaxImpl(data, callback, settings) {
            if (dataTableConfig.overrideAjaxImpl) {
              dataTableConfig.overrideAjaxImpl(data, callback, settings).then(function (responseData) {
                callback(responseData);
                dataTableConfig.onAjaxSuccess && dataTableConfig.onAjaxSuccess(responseData);
              }, function (error) {
                callback(emptyData);
                dataTableConfig.onAjaxError && dataTableConfig.onAjaxError(error);
              });
            } else {
              AjaxService.post(dataTableConfig.datasourceUrl, data).then(function (responseData) {
                callback(responseData);
                dataTableConfig.onAjaxSuccess && dataTableConfig.onAjaxSuccess(responseData);
              }, function (error) {
                callback(emptyData);
                dataTableConfig.onAjaxError && dataTableConfig.onAjaxError(error);
              });
            }
          }
        }
      };
    }]);
