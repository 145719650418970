'use strict';

angular.module('membership')
  .service('securityViewModel', ['$http', 'User', function ($http, userDomain) {
    var viewModel = {
    user: {},
    isUserAllowedToChangePassword: false,
    newPassword: '',
    newPasswordRepeat: '',
    errorMessages: [],
    passwordComplexity: 0,

    isValid: false,

    change: function () {
      if (this.isValid) {
        var model = {
          UserId: viewModel.user.Id,
          NewPassword: this.newPassword,
          NewPasswordRepeat: this.newPasswordRepeat
        };
        $http.post('/account/SecureChangePassword', model).success(function (res) {
          if (res.success) {
            viewModel.errorMessages = [];
            viewModel.successMessages = ['Password changed successfully'];
          } else {
            viewModel.successMessages = [];
            viewModel.errorMessages = res.errorMessages;
          }
        });
      }
    },

    init: function (userId) {
      viewModel.userId = userId;
      userDomain.get(userId, function (user) {
        viewModel.user = user;
      });
      $http.get('/account/IsUserAllowedToChangePassword/' + userId).success(function (res) {
        viewModel.isUserAllowedToChangePassword = res.isAllowed;
        $http.get('/account/ChangePasswordRequirements').success(function (requirements) {
          _.extend(viewModel, requirements);
        });
      });
    }
  };

  return viewModel;
    }]);