'use strict';

angular.module('membership')
  .service('subscriptionService', [
    '$q', 'membership.config', 'AjaxService',
    function ($q, config, ajaxService) {
      var instance = {
        getPlans: function () {
          return ajaxService.get(config.apiUrls.base + '/subscription/plans');
        },

        getActiveSubscription: function () {
          return ajaxService.get(config.apiUrls.base + '/subscription/activeSubscription');
        },
        getPaymentOptions: function () {
          return ajaxService.get(config.apiUrls.base + '/subscription/getPaymentOptions');
        },
        chargeByStripe: function(planId, stripeToken) {
          return ajaxService.post(config.apiUrls.base + '/subscription/stripeProcess', { planId: planId, stripeToken: stripeToken });
        }
      };

      return instance;
    }
  ]);
