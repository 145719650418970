/**
 * Created on 12/7/2015.
 */

'use strict';

angular.module('siteSettings')
  .factory('SiteSettingService', [
    'SiteSettingInstances',
    'AjaxService',
    '$rootScope',
    'CacheStorageService',
    'PromiseService',
    function (SiteSettingInstances,
              AjaxService,
              $rootScope,
              CacheStorageService,
              PromiseService) {

      function SiteSettingService(settingKey) {
        var self = this;

        var instance = SiteSettingService.getSettingRegistration(settingKey);

        self.instance = instance;
        self.data = {};

        self.getSetting = function () {
          var setting = CacheStorageService.retrieve(settingKey);
          if (setting) {
            self.data = setting;
            return PromiseService.resolve(self.data);
          }

          return AjaxService.singleGet(instance.apiSettingEndPoint, null, 5).then(function (data) {
            self.data = data;
            CacheStorageService.store(settingKey, self.data);
            return data;
          });
        };

        self.saveSetting = function (data) {
          self.data = data;
          CacheStorageService.remove(settingKey);
          return AjaxService.post(instance.apiSettingEndPoint, data).finally(function () {
            $rootScope.$broadcast('siteSettingUpdated', settingKey);
          });
        };

        self.getSetting();
      }

      SiteSettingService.getInstance = function (settingKey) {
        var instance = SiteSettingService.getSettingRegistration(settingKey);

        if (!instance.settingObject) {
          instance.settingObject = new SiteSettingService(settingKey);
        }

        return instance.settingObject;
      };

      SiteSettingService.getSettingRegistration = function (settingKey) {
        var instance = SiteSettingInstances[settingKey];

        if (!instance)
          throw new Error("Setting with provided key '" + settingKey + "' cannot be found in registry");

        return instance;
      };

      SiteSettingService.getAvailableSettingRegistration = function () {
        var result = [];

        _.each(SiteSettingInstances, function (value, key) {
          if (key == 'registerSetting') return;
          result.push({
            key: key,
            name: value.name,
            order: value.order || 100
          });
        });

        return _.sortBy(result, 'order');
      };


      return SiteSettingService;
    }
  ]);
