angular.module('membership')
  .factory('userCreationViewModel', [
    'User', 'validation', 'membership.config', '$translate',
    function (userDomain, validation, membershipConfig, $translate) {

      function userCreationViewModel(modalInstance, role, extendedData) {
        var self = this;
        var translationId = 'membership.customRoleName.' + role;

        $translate(translationId).then(function (r) {
          self.translatedRoleName = r;
        });
        self.modalInstance = modalInstance;

        this.extendedData = extendedData;

        this.firstName = '';
        this.lastName = '';
        this.phone = '';
        this.email = '';
        this.password = '';
        this.passwordRepeat = '';
        this.comment = '';
        this.showValidationMessages = false;
        this.passwordValid = false;
        this.passwordComplexity = 0;
        this.minPwdComplexPoint = membershipConfig.minPwdComplexPoint || 10;
        this.roles = [];

        if (role && role !== '')
          this.roles.push(role);
      }

      userCreationViewModel.prototype = {
        passwordRepeatValidate: function () {
          if (this.extendedData.IsNeedInvite)
            return true;

          return this.password === this.passwordRepeat;
        },

        showValidation: function (field) {
          return validation.validate(field, 'required') && this.showValidationMessages;
        },

        //validatePassword: function () {
        //  this.passwordComplexity = Complexify(this.password);
        //  return this.passwordComplexity.valid;
        //},

        isModelValid: function () {
          return this.passwordRepeatValidate()
              //&& this.validatePassword()
            && validation.validate(this.firstName, 'required')
            && validation.validate(this.email, 'required')
              //&& validation.validate(this.ssn, 'required')
            && validation.validate(this.lastName, 'required');
        },

        save: function () {
          this.showValidationMessages = true;
          var self = this;
          if (this.isModelValid()) {
            this.showValidationMessages = false;

            var user = _.merge(_.cloneDeep(self), this.extendedData);

            delete user.modalInstance;

            userDomain.insert(user, function (u) {
              self.modalInstance.close();
            });
          }
        }
      };

      return userCreationViewModel;
    }]);
