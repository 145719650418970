/**
 * Created on 9/30/2015.
 */
angular.module('membership')
  .provider('userUiService', function () {
    var options = {
      showDialogInsteadOfPage: false
    };

    this.showDialogInsteadOfPage = function (value) {
      options.showDialogInsteadOfPage = value;
    };

    this.$get = [
      'modalDialogService', 'userCreationViewModel', '$state', '$q',
      function (modalSvc, userCreationViewModel, $state, $q) {
        var userUIService = {
          proceedUserCreation: function (userRole, $$extendedData) {
            if (!options.showDialogInsteadOfPage) {
              var defer = $q.defer();
              $state.go('membership_usermanager_details', {filter: userRole, action: "create"});

              return defer.resolve();
            } else {
              return modalSvc.showWindow('components/membership/user/creationModal/userCreation.tpl.html', 'UserCreationController', {
                userRole: userRole || '',
                $$extendedData: $$extendedData
              });
            }
          }
        };

        return userUIService;
      }];

    return this;
  });
