'use strict';

angular.module('membership')
  .directive('membershipContacts', [
    'contactsViewModel',
    function(contactsViewModel) {
      return {
        restrict: 'E',
        scope: {},
        templateUrl: 'components/membership/interfaces/user_contacts_select/contacts.html',
        require: 'ngModel',
        link: function(scope, elm, attrs, ngModel) {
          scope.vm = new contactsViewModel();
          scope.$watch('vm.selectedUsers',
            function() {
              ngModel.$setViewValue(scope.vm.selectedUsers);
            }
          );
        }
      }
    }
  ]);