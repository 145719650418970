/**
 * Created on 10/12/2015.
 */
angular.module('common')
  .directive('imageCarouselControl', [
    function () {
      return {
        restrict: "EA",
        link: function (scope, element, attr) {
          $(element).css({
            cursor: 'pointer'
          });

          $(element).click(function (e) {
            e.preventDefault();
            $(attr.carouselControl).trigger(attr.carouselCommand);
          });
        }
      }
    }
  ])
  .directive('imageCarousel', [
    '$timeout',
    function ($timeout) {
      return {
        restrict: "EA",
        scope: {
          items: '=',
          link: '@',
          trigger: '&'
        },
        link: function (scope, element, attr) {
          $timeout(function () {
            $(element).owlCarousel({
              navigation: false, // Show next and prev buttons
              slideSpeed: 300,
              paginationSpeed: 400,
              items: scope.items,
              pagination: false,
              autoPlay: true
            });

            $(element).find("a").click(function (e) {
              e.preventDefault();
              owl_goTo($(this).data('slide'));
            });
          }, 100);

          function owl_goTo(x) {
            if (!scope.link) return;

            var owl = $(scope.link).data('owlCarousel');
            owl.goTo(x);
          }
        }
      }
    }]);
