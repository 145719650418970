/**
 * Created on 10/6/2015.
 */
angular.module('membership')
  .directive('selectRole', [
    'AjaxService',
    'Role',
    'membership.config',
    '$q',
    'translationSvc',
    function (AjaxService, Role, config, $q, translationSvc) {
      return {
        restrict: "EA",
        templateUrl: function (element, attrs) {
          return attrs.templateUrl || "components/common/templates/commonSelectionWithAutoComplete.html";
        },
        require: 'ngModel',
        scope: {
          bindingProperty: "@bindingProperty",
        },
        link: function (scope, element, attr, ngModel) {

          var self = scope;
          self.optionsList = [];
          var defer = $q.defer();
          self.ensureDataLoaded = defer.promise;

          self.selectedValue = {};

          self.getDisplayValue = function (item) {
            return item ? item.displayName : "";
          };

          if (!scope.optionalDisplayText)
            scope.optionalDisplayText = translationSvc.t('membership.lbl.typeToSearchUser');

          Role.reload().then(function () {
            self.optionsList = Role.list.map(function (item) {
              item.displayName = item.Description;
              return item;
            });
          }).finally(function () {
            defer.resolve();
          });

          scope.$watch('selectedValue.selected', function () {
            if (scope.selectedValue.selected)
              ngModel.$setViewValue(scope.selectedValue.selected[scope.bindingProperty]);
          }, true);


          scope.$watch(function () {
            return ngModel.$modelValue;
          }, function (newValue) {
            scope.ensureDataLoaded.then(function () {
              scope.selectedValue.selected = _.find(self.optionsList, function (user) {
                return user[scope.bindingProperty] == newValue;
              });

              if (!scope.showOptionalValue) {
                scope.optionalDisplayText = self.getDisplayValue(scope.selectedValue.selected);
              }
            });
          });
        }
      };
    }
  ]);
