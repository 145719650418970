'use strict';

angular.module('magtac')
    .controller('MagTacCustomersWorkflowsCtrl', [
        '$scope', 'MagTacCustomersWorkflowsViewModel',
    function ($scope, viewModel) {
      $scope.model = new viewModel();
      $scope.toggleOpen = function () {
        $scope.model.filterOpen = !$scope.model.filterOpen;
      };


    }
  ]);