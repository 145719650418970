'use strict';

angular.module('ticketAssignment')
    .controller('alertConfigurationCtrl', [
        '$scope', 'alertConfigurationViewModel',
        function ($scope, viewModel) {
            $scope.model = new viewModel();

            $scope.configuration = $scope.model.configuration;

            $scope.error = {
                hasError: false,
                message: "",
            };

            $scope.selected = 'CustomerWaitingWarning';

            $scope.SetSelectedAlert = function (newSelection) {
                $scope.selected = newSelection;
            }

            $scope.pendingChanges = {};
            $scope.addUser = function (username) {
                if (username === undefined) {
                    return;
                }
                if (username.endsWith('@magview.com')) {
                    username = username.substring(0, username.indexOf('@'));
                }
                var hasAlreadyBeenAdded = $scope.configuration[$scope.selected].initRecipients.includes(username) || Object.keys($scope.configuration[$scope.selected].recipients).includes(username);

                if (!hasAlreadyBeenAdded && usernameIsValid(username)) {
                    var totalNumRecipients = Object.keys($scope.configuration[$scope.selected].recipients).length;

                    $scope.configuration[$scope.selected].recipients[username] = {
                        id: totalNumRecipients,
                        name: username,
                        email: username + '@magview.com',
                        initial: hasAlreadyBeenAdded,
                        toBeRemoved: false,
                        toBeAdded: true
                    };
                }
                else {
                    console.log('denied because hasAlreadyBeenAdded = true');
                }
            }
            $scope.removeUser = function (username) {
                var pendingRemoval = $scope.configuration[$scope.selected].recipients[username].toBeRemoved;

                if ($scope.configuration[$scope.selected].initRecipients.includes(username)) {
                    console.log('un-doing removal of initially configured user: \'' + username + '\'');
                    $scope.configuration[$scope.selected].recipients[username].toBeRemoved = !pendingRemoval;
                }
                else {
                    console.log('removing custom-added user \'' + username + '\'');
                    delete $scope.configuration[$scope.selected].recipients[username];
                }
            }

            $scope.RegisterChange = function () {
                var configuration = $scope.configuration[$scope.selected];
                
                $scope.error = { hasError : false, message : '' };

                var S = configuration.execution.start;
                var E = configuration.execution.end;

                if (Number.isInteger(S.hour) && Number.isInteger(S.minute) && Number.isInteger(E.minute) && Number.isInteger(E.hour)) {
                    if (60 * S.hour + S.minute >= 60 * E.hour + E.minute) {
                        $scope.error = { hasError: true, message: "Start Time must preceed End Time" }; return;
                    }
                    else {
                        console.log('60*s.hour + s.minute: ' + (60 * S.hour + S.minute));
                        console.log('60*e.hour + e.minute: ' + (60 * S.hour + S.minute));
                    }
                }
                else {
                    $scope.error = { hasError: true, message: "Invalid start or end time - enter whole number values" }; return;
                }


                if ( ! (isWithinRange(S.hour, 0, 23) && isWithinRange(S.minute, 0, 59))) {
                    $scope.error = { hasError: true, message: "Start Time is invalid. " }; return;
                }
                else if (!(isWithinRange(E.hour, 0, 23) && isWithinRange(E.minute, 0, 59))) {
                    $scope.error = { hasError: true, message: "End Time is invalid. " }; return;
                }

                if ( configuration.threshold.value === undefined) {
                    $scope.error = { hasError: true, message: "Invalid threshold value" }; return;
                }
                if (configuration.reset === undefined) {
                    $scope.error = { hasError: true, message: "Invalid reset time value" }; return;
                }

                if ( ! $scope.model.DAYS_OF_WEEK.some(function f(v) { return configuration.execution.days[v].value == true; })) {
                    if ( ! configuration.disabled) { 
                        $scope.error = { hasError: true, message: "No days are selected. If this is intended, hit the Disable checkbox." }
                        return;
                    }
                }


            }

            $scope.getPendingChanges = function () {
                var requestObj = { requestedBy: 'cesenwa', request: [], };

                console.log('configurationNames');
                console.log($scope.model.configurationNames);

                for (var alert in $scope.configuration) {
                    if ( ! $scope.model.configurationNames.includes(alert)) {
                        console.log(alert+' is not in configurationNames so continue; in forloop');
                        continue;
                    }

                    var config = $scope.configuration[alert];

                    var recipients = Object.values(config.recipients);

                    var _toAdd = recipients.filter(function f(v, i, arr) { return arr[i].toBeAdded; });
                    var _toRemove = recipients.filter(function f(v, i, arr) { return arr[i].toBeRemoved; });
                    var toAdd = [];
                    var toRemove = [];
                    for (var i = 0; i < _toAdd.length; i++) {
                        toAdd.push(_toAdd[i].name);
                    }

                    for (var i = 0; i < _toRemove.length; i++) {
                        toRemove.push(_toRemove[i].name);
                    }

                    var executeDays = $scope.model.DAYS_OF_WEEK.filter(function f(v) { return config.execution.days[v].value });



                    requestObj.request.push(
                    {
                        BaseConfigurationName: alert,

                        RecipientsToAdd: toAdd,

                        RecipientsToRemove: toRemove,

                        Threshold: config.threshold.value,

                        IsDisabled: config.disabled,

                        ResetTime: config.reset,

                        StartTime: 
                        {
                            hour: config.execution.start.hour,
                            minute: config.execution.start.minute
                        },

                        EndTime: 
                        {
                            hour: config.execution.end.hour,
                            minute: config.execution.end.minute
                        },

                        OnDays: executeDays
                    });


                }

                console.log('$scope.getPendingChanges() evaluates to:');
                console.log(requestObj);
                return requestObj;
            };



            $scope.save = function () {
                var request = $scope.getPendingChanges();

                console.log('request:'); console.log(request);

                $scope.model.save(request);
            }

            $scope.FormatTime = function (timeObj) {
                if (timeObj === undefined) {
                    return '---';
                }
                else {
                    return new Date(2020, 9, 1, timeObj.hour, timeObj.minute, 0, 0).toLocaleTimeString().replace(':00', '');
                }
            };

            $scope.pendingConfigurationString = function () {
                if ($scope.error.hasError) {
                    return '---';
                }
                else {
                    var config = $scope.configuration[$scope.selected];
                    var abbreviatedDays = $scope.model.DAYS_OF_WEEK
                        .filter(function f(v) { return config.execution.days[v].value })
                        .map(function f(v) { return v.substring(0, 3) })
                        .join(', ');
                    return $scope.FormatTime(config.execution.start) + ' to ' + $scope.FormatTime(config.execution.end) + ' - ' + abbreviatedDays;
                }
            };

            $scope.configurationDescription = function (config) {
                console.log('$scope.configurationDescription for config = \'' + config + '\'');

                var c = $scope.configuration[$scope.selected];

                if (config === 'original') {
                    console.log('$scope.model.originalConfigurationValues = ');
                    console.log($scope.model.originalConfigurationValues);
                    c = $scope.model.originalConfigurationValues[$scope.selected];
                }

                if (c === undefined) {
                    console.log('var c is undefined');
                }
                else {
                    console.log('var c is not undefined. var c = ');
                    console.log(c);
                }
                var start = new Date(2020, 9, 1, c.execution.start.hour, c.execution.start.minute, 0, 0)
                    .toLocaleTimeString().replace(':00', '');
                var end = new Date(2020, 9, 1, c.execution.end.hour, c.execution.end.minute, 0, 0)
                    .toLocaleTimeString().replace(':00', '');
                var days = $scope.model.DAYS_OF_WEEK.filter(function f(v) { return c.execution.days[v].value }).map(function f(v) { return v.substring(0, 3) });

                console.log('var days = ');
                console.log(days.join(', '));

                return ['Threshold: ' + c.threshold.value + ' ' + c.threshold.units,
                    'From: ' + start + ' To: ' + end,
                    'Execute on days:' + days.join(', '),
                    'Reset Every: ' + c.reset + ' minutes',
                    'This alert is: ' + (c.disabled ? 'disabled and not running' : 'enabled and is running')];
            };

            // ng-style functions:
            //  *   *   *   *   *   *   *   *   *   *   *   *   *   *   *   *
            $scope.recipientName = function (status) {
                var style = {
                    'font-weight': 'lighter',
                    'text-decoration': 'unset',
                    'color': 'rgb(50, 50, 50)'
                };

                if (status.toBeAdded) {
                    style['font-weight'] = 'normal';
                }
                if (status.toBeRemoved) {
                    style['text-decoration'] = 'line-through';
                    style['color'] = 'rgb(153, 1, 1)';
                    style['font-weight'] = 'normal';
                }

                return style;
            }
            $scope.recipientRowStyle = function (status) {
                var style = {
                    'font-weight': 'normal',
                    'text-decoration': 'unset'
                };
                var rowAlternatingColorIntensity = 15;
                var backgroundcolor = 'rgba(255, 255, 255, 1.0)';

                if (status.toBeAdded) {
                    backgroundcolor = 'rgba(181, 219, 237, 0.46)';
                }
                else if (status.toBeRemoved) {
                    backgroundcolor = 'rgb(255, 185, 185)';
                    rowAlternatingColorIntensity = 0;
                }

                var recipientIndex = Object.keys($scope.configuration[$scope.selected].recipients).indexOf(status.name);

                if (recipientIndex % 2 === 1) {
                    backgroundcolor = darker(backgroundcolor, rowAlternatingColorIntensity);
                }

                style['background-color'] = backgroundcolor;
                return style;
            }
            $scope.userFieldStyle = function (username) {
                var style = {
                    'border-color': 'unset',
                    'border-width': '2px'
                };
                if (username == undefined || username.length == 0) {
                    return style;
                }

                style['border-color'] = usernameIsValid(username) ? 'rgb(12, 161, 77)' : 'rgb(217, 83, 79)';

                return style;
            }
            //  *   *   *   *   *   *   *   *   *   *   *   *   *   *   *   *




            var forbiddenCharacters = '* @#%[]{}():;"\',\\!?~`|'.split('');
            var darker = function (rgbColor, intensity) {
                var i = rgbColor.indexOf('(') + 1;
                var j = rgbColor.indexOf(')');

                var values = rgbColor.substring(i, j).split(',');

                var alpha = values.length > 3 ? values[3] : '1.0';
                console.log();

                var darkened = values
                    .splice(0, 3)
                    .map(function f(v, index, arr) {
                        return Math.max(v - intensity);
                    }).join(',');

                return 'rgba(' + darkened + ', ' + alpha + ')';
            };
            var usernameIsValid = function (username) {
                if (username === undefined || username.length == 0) {
                    return false;
                }
                if (username.endsWith('@magview.com')) {
                    username = username.substring(0, username.indexOf('@'));
                }
                for (var i in forbiddenCharacters) {
                    if (username.indexOf(forbiddenCharacters[i]) != -1) {
                        return false;
                    }
                }
                return true;
            }

            var ngstyleObject = function (cssStyleString) {
                var declarations = cssStyleString.split(';').filter(function (val, i, arr) {
                    return val.length > 0;
                });

                var cssObj = {};

                for (var i in declarations) {
                    var declaration = declarations[i].split(':');
                    var property = declaration[0].trim();
                    var value = declaration[1].trim();
                    cssObj[property] = value;
                }

                return cssObj;
            };

            var isWithinRange = function (value, min, max) { return value >= min && value <= max; };

            var isNumber = function (val) { return ! (val === undefined && Number.isInteger(val)) };

        }
    ]);