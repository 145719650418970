

angular.module('common')
  .config(function ($httpProvider) {
    $httpProvider.interceptors.push('onCompleteInterceptor');
  })
  .run(function ($http, onStartInterceptor) {
    $http.defaults.transformRequest.push(onStartInterceptor);
  })
  .factory('onCompleteInterceptor', [
    '$q', 'loadingService', '$rootScope',
    function ($q, loadingService, $rootScope) {

      var decrementRequestCount = function (response) {

        loadingService.requestCount--;

        if (loadingService.requestCount < 0) {
          loadingService.requestCount = 0;
        }

        if (loadingService.requestCount == 0) {
          HidePageLoadSpinner(true);
          $rootScope.$broadcast('common.page.loaded');
        }

        return response;
      };

      return {

        'response': function (response) {
          decrementRequestCount();
          return response;
        },

        'responseError': function (rejection) {
          decrementRequestCount();
          return $q.reject(rejection);
          //return rejection;
        }
      };
    }])
  .factory('onStartInterceptor', [
    '$q', 'loadingService', '$rootScope',
    function ($q, loadingService, $rootScope) {
      return function (data, headersGetter) {
        loadingService.requestCount++;
        $rootScope.$broadcast('common.page.loading');
        if (!headersGetter('IsBackground')) {
          ShowPageLoadSpinner();
        }
        return data;
      };
    }])
  .factory('loadingService', function () {
    var service = {
      requestCount: 0
    };
    return service;
  });

function ShowPageLoadSpinner() {

  if ($('.page-loading-spinner').length > 0 && !$('.page-loading-spinner').is(':visible')) {
    $('.page-loading-spinner').show();
    $('#page-wrapper > *').css('opacity', 0);
  }
  /*
  if($('#pageLoadSpinner')){
    var operations = parseInt($('#pageLoadSpinner').attr('operations'));
    if (isNaN(operations)) {
      $('#pageLoadSpinner').attr('operations', 1);
      if (!$('.page-loading-spinner').is(':visible')) {
        $('.page-loading-spinner').show();
        $('#page-wrapper > *').css('opacity', 0);
      }
    } else {
      $('#pageLoadSpinner').attr('operations', operations + 1);
    }
  }*/
}

function HidePageLoadSpinner() {

  if ($('.page-loading-spinner').is(':visible')) {
    $('.page-loading-spinner').hide();
    $('#page-wrapper > *').css('opacity', 1);
  }
  /*
  var operations = parseInt($('#pageLoadSpinner').attr('operations'));
  if (!isNaN(operations)) {
    $('#pageLoadSpinner').removeAttr('operations');
    if ($('.page-loading-spinner').is(':visible')) {
      $('.page-loading-spinner').hide();
      $('#page-wrapper > *').css('opacity', 1);
    }
  }*/
}
