'use strict';

angular.module('common')
  .directive('selectElementValue', function() {
   return function(scope, elem, attr) {
        elem.on('click', function () {
            if(this.selectionStart === this.selectionEnd && this.selectionStart === this.value.length){
                this.select();
            }            
        });
   };
});