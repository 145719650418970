/**
 * Created on 10/1/2015.
 */

angular.module('membership')
  .controller('ClientCompanyCreationController', [
    '$scope', 'clientCompanyCreationViewModel', '$modalInstance',
    function($scope, userCreationViewModel, modalInstance) {

      $scope.model = new userCreationViewModel(modalInstance);
    }
  ]);
