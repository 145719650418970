'use strict';

angular.module('projectsManagement')
  .service('newProjectViewModel', [
    'Projects', 'Customer', 'modalDialogService', '$stateParams', '$state',
    function(Projects, Customer, modalDialogService, $stateParams, $state) {

      var viewModel = function(modalInstance, parent, companyId, project) {

        var self = this;

        self.init = function() {
          self.projectEditModel = {
            Specific: {},
            Value: 0
          };          

          if(project){
            self.projectEditModel = project;
            self.isEditExistingProject = true;
          }

          if (companyId) {
            self.projectEditModel.CompanyId = companyId;
            self.companySelectionDisabled = true;
            self.companyUpdated();
          }
          self.statuses = [
            'New', 'Assigned', 'In Progress', 'Live', 'On Hold', 'Canceled'
          ];
          self.projectsTypes = [{id: 'IMP', label: 'Improvement'}, {id: 'ADDON', label: 'Add-on'}];

          Projects.descriptions().then(function(descriptionsList) {
            self.descriptionsList = descriptionsList;            
          });
        
          Customer.namesList().then(function (resp) {
            self.customers = resp;
          });

          self.isEditMode = true;
        };
        
        self.companyUpdated = function() {
          if (self.projectEditModel.CompanyId != companyId || self.projectEditModel.ParentId != (parent || {}).Id) {
            self.projectEditModel.ParentId = null;
          }

          if (!self.projectEditModel.CompanyId) {
            self.projectsList = [];
            return;
          }

          var includeChild = false;
          var fullDetails = true;
          Projects.byCustomer(self.projectEditModel.CompanyId, includeChild, fullDetails).then(function(projectsList) {
            self.projectsList = projectsList;
            if (parent) {
              self.FillParentDescription();
            }
          });
        }

        self.FillParentDescription = function(){          
          self.projectEditModel.ParentId = parent.Id;
          if(parent.Description){
            self.projectEditModel.ParentName = parent.Description;
          }
          else{
            var parentModel = self.projectsList.find(function(value, index){
              return value.Id == self.projectEditModel.ParentId;
            });
            if(parentModel){
              self.projectEditModel.ParentName = parentModel.Description;
            }              
          }
        }

        self.save = function() {
          if(!self.isEditExistingProject){
            self.projectEditModel.Value = +self.projectEditModel.Value;
            Projects.create(self.projectEditModel).then(function(resp){
              modalInstance.close(resp);
            }, function() {            
              modalDialogService.showAlert('Failed to create project');
            });
          }else{
            Projects.update(self.projectEditModel).then(function(resp){
              resp.ParentId = self.projectEditModel.ParentId;
              modalInstance.close(resp);
            }, function(error){
              modalDialogService.showAlert(error.errorMessage || error.message || error, 'Failed to update a project');
            })
          }
        };

        self.parentSelected = function(){
          if(!self.projectEditModel.ParentId){
            self.projectEditModel.Specific.project_type = '';
            self.projectEditModel.PmId = '';
            self.projectEditModel.TechId = '';
            self.projectEditModel.Specific.active = false;
            self.projectEditModel.Status = '';
            self.projectEditModel.Specific.start_date = null;
            self.projectEditModel.Specific.sos_date = null;
            self.projectEditModel.Specific.estimated_golive = null;
            self.projectEditModel.Specific.golive = null;
            self.projectEditModel.Specific.end_date = null;
            self.projectEditModel.Specific.handoff = null;
            self.projectEditModel.Specific.sleep_until = null;
          }else{
            var parent = self.projectsList.find(function(value, index){
              return value.Id == self.projectEditModel.ParentId;
            });
            self.projectEditModel.Specific.project_type = parent.Specific.project_type;
            self.projectEditModel.PmId = parent.PmId;
            self.projectEditModel.TechId = parent.TechId;
            self.projectEditModel.Specific.active = parent.Specific.active;
            self.projectEditModel.Status = parent.Status;
            self.projectEditModel.Specific.start_date = parent.Specific.start_date;
            self.projectEditModel.Specific.sos_date = parent.Specific.start_date;
            self.projectEditModel.Specific.estimated_golive = parent.Specific.estimated_golive;
            self.projectEditModel.Specific.golive = parent.Specific.golive;
            self.projectEditModel.Specific.end_date = parent.Specific.end_date;
            self.projectEditModel.Specific.handoff = parent.Specific.handoff;
            self.projectEditModel.Specific.sleep_until = parent.Specific.sleep_until;
          }
        }

        self.currencyValueChanged = function(){
          if(!self.projectEditModel.Value){
            self.projectEditModel.Value = 0;
          }
        }

        self.currencyNumberAdded = function(value){
          if(self.projectEditModel.Value == '0.00'){
            self.projectEditModel.Value = value;
          }
        }

        self.init();
      };

      return viewModel;

    }
  ]);