/**
 * Created on 10/24/2015.
 */

'use strict';

angular.module('membership')
  .controller('ChangePasswordController', [
    '$scope', 'ChangePasswordViewModel', '$userId',
    function ($scope, ChangePasswordViewModel, $userId) {
      $scope.vm = new ChangePasswordViewModel($userId);
    }
  ]);
