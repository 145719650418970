/**
 * Created on 10/29/2015.
 */



angular.module('common')
  .service('AwaitService', [
    function () {
      var AwaitService = {};
      AwaitService.await = function (callback, delay) {
        if (callback.___delay === undefined)
          callback.___delay = 0;

        if (callback.___delay)
          clearTimeout(callback.___delay);

        callback.___delay = setTimeout(callback, delay || 200);
      };

      return AwaitService;
    }
  ]);
