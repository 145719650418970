/**
 * Created on 10/24/2015.
 */

'use strict';

angular.module('membership')
  .controller('profilePropertyCreateController', [
    '$scope', 'profilePropertyCreateViewModel', '$modalInstance',
    function ($scope, profilePropertyCreateViewModel, $modalInstance) {
      $scope.vm = new profilePropertyCreateViewModel($modalInstance);
    }
  ]);
