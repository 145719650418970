'use strict';

angular.module('membership')
  .service('permissionsService', [
    '$q', 'membership.config', 'AjaxService', '$rootScope',
    function ($q, config, ajaxService, $rootScope) {
      var instance = {
        permissions: [],
        initializing: false,
        initialized: false,
        initPromise: $q.defer(),
        init: function (cb, makeNewRequest) {
          var self = this;

          if (self.initializing && !makeNewRequest) {
            self.initPromise.promise.then(cb);
            return self.initPromise.promise;
          }

          self.initPromise = $q.defer();
          self.initPromise.promise.then(cb);
          self.initializing = true;

          ajaxService.get(config.apiUrls.base + '/auth/permissions').then(function (data) {
            self.permissions = data;
            self.permissions.push('[authorized]');
            self.initializing = false;
            $rootScope.$broadcast(config.events.onUserLoggedIn);
            self.initialized = true;
            self.initPromise.resolve();
          }, function () {
            self.permissions = [];
            self.initializing = false;
            self.initialized = true;
            self.initPromise.resolve();
          });
          return self.initPromise.promise;
        },
        hasPermission: function (area) {
          var self = this;
          return _.contains(self.permissions, area);
        }
      };

      //instance.init();
      return instance;
    }
  ]);
