/**
 * Created on 10/24/2015.
 */

'use strict';

angular.module('membership')
  .controller('RoleProfilePropertyCtrl', [
    '$scope', 'RoleProfilePropertyViewModel', '$stateParams',
    function ($scope, RoleProfilePropertyViewModel, $stateParams) {
      $scope.vm = new RoleProfilePropertyViewModel($stateParams.id);
    }
  ]);
