'use strict';

angular.module('magtac')
  .service('timersConfigViewModel', [
    'TimerConfig', 'timerConfigDetailsViewModel', 'modalService', 'timerFormatService', 'StatusPageLookup',
    function (TimerConfig, timerConfigDetailsViewModel, modalService, timerFormatService, lookup) {

      var viewModel = function (companyId) {

        var self = this;

        self.mode = "Monitor";

        TimerConfig.getServers(companyId).then(function(resp){
          self.servers = resp;
        });
        
        TimerConfig.getAvailableTasks(companyId).then(function(resp){
          self.availableTasks = resp;
        });

        TimerConfig.getDefaultTasksConfigurations().then(function(resp){
          self.defaultConfigurations = resp;
        });

        TimerConfig.getAdHocQueries().then(function (resp) {
          self.adHocQueries = resp;
        });

        lookup.observerNames().then(function (data) {
          self.observerNames = data;
        });

        self.getInstanceName = function(item) {
          if (!item.Parameters || !item.Parameters.instances || !self.availableTasks || !self.availableTasks.length) {
            return null;
          }

          var task = _.find(self.availableTasks, { Name: item.TaskName })

          if (!task) {
            return item.Parameters.instances;
          }

          var instance = _.find(task.Parameters.instances, { path: item.Parameters.instances });

          return instance ? instance.description + '(' + instance.path + ')' : item.Parameters.instances;
        }

        self.reload = function (custStatus) {
          TimerConfig.get(companyId).then(function (resp) {
            self.timers = resp;
            if (!!custStatus){
              custStatus.reloadData();
            }
          });
        };

        self.getTimeZone = function(offset){
          var zoneAbbrs = [];
          var serverOffset = offset * -1;
          var zoneNames = moment.tz.names().filter(function(zoneName){
            return zoneName.indexOf("America") > -1;
          });  

          zoneNames.forEach(function(zoneName){
            var zone = moment.tz(zoneName)._z;
            if(zone.offsets.some(function(offset){
              return offset == serverOffset;
            })){
              var offsetindex = zone.offsets.indexOf(serverOffset);
              if(zoneAbbrs.indexOf(zone.abbrs[offsetindex]) == -1){
                zoneAbbrs.push(zone.abbrs[offsetindex]);              
              }              
              return;
            }
          });
          return zoneAbbrs;
        }

        self.getTime = function(timer) {
          var serverTimeZone = timer.Server ? timer.Server.TimeZoneOffset : undefined; 
          var serverOffset = serverTimeZone * 60;                    
          var serverZoneAbbr = self.getTimeZone(serverOffset);

          var clientOffset = moment.tz(new Date(), moment.tz.guess())._offset;
          var clientTimeZone = clientOffset / 60;
          var clientZoneAbbr = moment.tz(new Date(), moment.tz.guess()).format('z');

          var checkForCron = true;
          var clientFormattedTime = timerFormatService.formatTime(timer.CronConfiguration, clientTimeZone, checkForCron);
          if(clientFormattedTime.isCron){
            checkForCron = false;
            var serverFormattedTime = timerFormatService.formatTime(timer.CronConfiguration, serverTimeZone, checkForCron);
            if(serverFormattedTime === clientFormattedTime.string){
              return clientFormattedTime.string;
            }
            var clientFormattedTimeString = clientFormattedTime.string;
            var clientSpaceIndex = clientFormattedTimeString.indexOf(' ');
            var clientCronString = clientFormattedTimeString.substr(0, clientSpaceIndex) + ' ' + clientZoneAbbr + clientFormattedTimeString.substr(clientSpaceIndex);
            var serverSpaceIndex = serverFormattedTime.indexOf(' ');
            var serverCronString = serverFormattedTime.substr(0, serverSpaceIndex) + ' ' + serverZoneAbbr.join('/');
            return clientCronString + ' (' + serverCronString + ' )' || timer.CronConfiguration;
          }else{
            return clientFormattedTime.string || timer.CronConfiguration;
          }
        }

        self.edit = function (custStatus, item) {
          var detailsViewModel = new timerConfigDetailsViewModel(item || { CompanyId: companyId, SubTasks: [] }, 
            self.servers, angular.copy(self.defaultConfigurations), self.observerNames, self.timers, self.adHocQueries);
          detailsViewModel.on('save', function () {
            self.reload(custStatus);
          });
          detailsViewModel.on('delete', function (params) {
            self.reload(custStatus);
          });
          modalService.showWindow('components/magtac/customerStatus/timersConfig/timerConfig-details.html', detailsViewModel);
        };

        self.delete = function(custStatus, item) {
          TimerConfig.delete(item).then(function() {
            self.reload(custStatus);
          });
        };

        self.setRunNow = function(custStatus, item, flagOn) {
          var detailsViewModel = new timerConfigDetailsViewModel(item || { CompanyId: companyId, SubTasks: [] }, 
            self.servers, angular.copy(self.defaultConfigurations), self.observerNames, self.timers, self.adHocQueries);
          detailsViewModel.item.RunNow = flagOn;
          detailsViewModel.on('save', function () {
            self.reload(custStatus);
          });
          detailsViewModel.save();
        };

        self.reload();
      };

      return viewModel;

    }
  ]);
