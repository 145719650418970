'use strict';

angular.module('membership')
  .service('timerConfigDetailsViewModel', [
      'TimerConfig', 'timerFormatService', 'modalDialogService',
      function (TimerConfig, timerFormatService, modalDialogService) {

      var viewModel = function (item, servers, defaultConfigurations, observerNames, timers, adHocQueries) {

        var self = this;

        self.listeners = {};
        self.item = angular.copy(item);
        self.isNew = !item.Id;
        self.servers = [];
        self.observerNames = observerNames;
        self.timers = timers;
        self.adHocQueries = adHocQueries;
        self.adHocDetailsExpanded = false;
        //self.notificationRecipients = [{},{}];
        self.RUN_NOW_DELAY_MINUTES = 5;

        TimerConfig.getAvailableTasks(self.item.CompanyId).then(function(resp){

          self.availableTasks = resp;

          var filtered = _.filter(self.availableTasks, function(x){
            if(x.Category == 1){
              return true;
            }
            if(self.item && self.item.TaskName == x.Name) {
              return true;
            }
            if(x.Parameters && x.Parameters.instances && x.Parameters.instances.length > 1){
              var perInstance = _.map(x.Parameters.instances, function(instance){
                return !_.findWhere(self.timers, { TaskName: x.Name, Parameters: {instances: (instance.path || instance)}});
              });
              return _.contains(perInstance, true);
            }
            else{
              return !_.findWhere(self.timers, { TaskName: x.Name });
            }
          });

          self.taskNames = _.uniq(_.pluck(filtered, 'Name'));

          if (!!self.item.TaskName) {
            self.taskChanged(true);
          }
        });

        if (!self.item.CronConfiguration) {
          self.item.CronEnabled = true;
          self.item.CronConfiguration = "0 0/2 * * * ?";
        }

        _.each([self.item].concat(self.item.SubTasks), function (x) {
          x.RunNow = !!x.RunNowCronConfiguration;
        });

        self.CronInput = self.item.CronConfiguration;
        self.CronOutput = self.item.CronConfiguration;

        // if (!self.item.Server) {
        //   self.item.Server = self.servers[0];
        // } else {
        //   self.item.Server = _.findWhere(self.servers, {Id: self.item.Server.Id}) || self.servers[0];
        // }

        self.applyCron = function() {
          self.item.CronConfiguration = self.CronOutput;
          self.item.CronInput = self.CronOutput;
        }

        self.toggleAdvanced = function() {
          self.advancedVisible = !self.advancedVisible;
          if (self.advancedVisible) {
            self.CronInput = self.item.CronConfiguration;
            self.CronOutput = self.item.CronConfiguration;
          }
        }

        self.availableTimeValues = timerFormatService.getValues();

        self.cronConfig = {
          options: {
            allowYear : false
          }
        };

        self.on = function (action, callBack) {
          self.listeners[action] = callBack;
        };

        self.notify = function (action, params) {
          if (self.listeners[action]) {
            self.listeners[action](params);
          }
        };

        self.taskChanged = function(isInitializing) {
          if (!self.item.TaskName) {
            self.item.TaskName = null;
            return;  
          }

          self.selectedTasks = _.where(self.availableTasks, { Name: self.item.TaskName });
          
          if (isInitializing) {
            self.item.Server = _.findWhere(_.pluck(self.selectedTasks, 'Server'), { ServerId: self.item.Server.ServerId}) || self.selectedTasks[0].Server; 
          } else {
            self.item.Server = self.selectedTasks[0].Server;
          }

          self.serverUpdated();

          self.isGroup = !!self.selectedTask.SubTasks;

          if(self.isNew) {

            // apply default settings

            var defaultConfig = self.getDefaultConfigurationByTaskName(self.item.TaskName);
            if (defaultConfig) {
              self.item.CronConfiguration = defaultConfig.CronConfiguration;
              self.item.CronEnabled = true;
              self.item.Parameters = defaultConfig.Parameters;

              if (self.isGroup) {
                _.each(self.selectedTask.SubTasks, function (x) {
                  var defaultSubtaskConfig = self.getDefaultConfigurationByTaskName(x.Name);
                  var subTask = self.getSubTaskByName(x.Name);
                  subTask.CronConfiguration = defaultSubtaskConfig.CronConfiguration;
                  subTask.CronEnabled = true;
                  subTask.RunNow = false;
                  subTask.Parameters = defaultSubtaskConfig.Parameters;
                });
              }
            }
          }
        };

        self.getDefaultConfigurationByTaskName = function(taskName) {
          return _.findWhere(defaultConfigurations, { TaskName: taskName });
        };

        self.serverUpdated = function() {
          self.selectedTask = _.findWhere(self.selectedTasks, {Server: self.item.Server});
          _.each(self.selectedTask.SubTasks, function(x){
            var subTask = self.getSubTaskByName(x.Name);
            if(!subTask){
              subTask = {};
              self.item.SubTasks.push(subTask);
            }
            subTask.TaskName = x.Name;
          });

          if(self.selectedTask.Parameters) {
            if(!self.item.Parameters){
              self.item.Parameters = {};
              self.item.Parameters.isSingleResult = self.selectedTask.Parameters.isSingleResult;
            }
          }
        };

        self.getSubTaskByName = function(name) {
          return _.findWhere(self.item.SubTasks, { TaskName: name });
          //return (task || {Name: name});
        };

        /*self.getParameters = function() {
          var task = _.findWhere(self.availableTasks, { Name: self.item.TaskName });
          return (task || {}).Parameters;
        };*/

        self.save = function () {
          _.each([self.item].concat(self.item.SubTasks), function (x) {
            if (!x.RunNow) {
              x.RunNowCronConfiguration = null;
            } else if (!x.RunNowCronConfiguration) {
              var minute = (new Date().getMinutes() + self.RUN_NOW_DELAY_MINUTES) % 60;
              x.RunNowCronConfiguration = "0 " + minute + " * * * ?";
            }
          });

          if (self.item.SubTasks && self.item.SubTasks.length > 0) {
            self.item.CronConfiguration = self.item.SubTasks[0].CronConfiguration;
          }

          if (self.item.Id) {

            TimerConfig.update(self.item).then(function () {
              self.notify('save');
              angular.copy(self.item, item);
            });
          } else {
            TimerConfig.create(self.item).then(function () {
              self.notify('save');
              angular.copy(self.item, item);
            });
          }
        };

        self.delete = function () {
          TimerConfig.delete(self.item.Id).then(function () {
            self.notify('delete', { Id: self.item.Id });
          });
        };


        self.oneTaskPerInstance = function(instance) {
          if(self.selectedTask.Category == 1){
            return true;
          }
          var path = instance.path || instance;
          return (self.item && self.item.Parameters && self.item.Parameters.instances == path) 
          || !_.findWhere(self.timers, { TaskName: self.selectedTask.Name, Parameters: {instances: path}});
        };

      };

      return viewModel;

    }
  ]);
