/**
 * Created on 10/24/2015.
 */

'use strict';

angular.module('membership')
  .service('ProfileDomain', [
    'membership.config', 'AjaxService',
    function (config, ajaxService) {
      var baseUrl = config.apiUrls.base;
      var ProfileDomain = {
        getMyProfile: function () {
          return ajaxService.get(baseUrl + '/api/user/myprofile');
        },
        updateMyProfile: function (data) {
          return ajaxService.post(baseUrl + '/api/user/myprofile', data);
        },
        getProfile: function (userId) {
          return ajaxService.get(baseUrl + '/api/user/' + userId + '/profile');
        },
        updateProfile: function (userId, data) {
          return ajaxService.post(baseUrl + '/api/user/' + userId + '/profile', data);
        },
        getMyPersonalInfo: function () {
          return ajaxService.get(baseUrl + '/api/user/mypersonalinfo');
        },
        updateMyPersonalInfo: function (data) {
          return ajaxService.post(baseUrl + '/api/user/mypersonalinfo', data);
        },
        getUserPersonalInfo: function (userId) {
          return ajaxService.get(baseUrl + '/api/user/' + userId + '/personalinfo');
        },
        updateUserPersonalInfo: function (userId, data) {
          return ajaxService.post(baseUrl + '/api/user/' + userId + '/personalinfo', data);
        }
      };

      return ProfileDomain;
    }
  ]);
