'use strict';

angular.module('magview')
  .service('TimeManagement', [
    'AjaxService', 'magview.config',
    function (ajaxService, config) {
      var baseUrl = config.apiUrls.base;
      var domain = {
        getAttendanceReasons: function () {
          return ajaxService.get(baseUrl + "/attendanceTracking/getAttendanceReasons");
        },
        getAttendance: function (id) {
          return ajaxService.get(baseUrl + "/attendanceTracking/getAttendance/" + id);
        },
        saveAttendance: function (data) {
          return ajaxService.post(baseUrl + "/attendanceTracking/saveAttendance", data);
        },
        deleteAttendance: function (data) {
          return ajaxService.post(baseUrl + "/attendanceTracking/deleteAttendance", data);
        },
        getUserIdUserInit: function (userInit) {
          return ajaxService.get(baseUrl + "/timeTracking/getUserIdUserInit?init=" + userInit);
        },
        getUserIdByAttendanceId: function (attendanceId) {
          return ajaxService.get(baseUrl + "/attendanceTracking/getUserIdByAttendanceId?attendanceId=" + attendanceId);
        }
      };

      return domain;
    }
  ]);