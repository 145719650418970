'use strict';

angular.module('membership')
  .service('workflowViewModel', [
    'Customer',
    function (Customer) {

      var viewModel = function (company) {

        var self = this;
        self.name = 'Josh is my hero!!!!!!'
        Customer.getWorkflow(company.Id).then(function(resp){
            self.workflow = resp;
        });
      };

      return viewModel;

    }
  ]);
