angular.module('membership')
  .directive('contactUserWidget', [
    'contactUserWidgetViewModel',
    function (contactUserWidgetViewModel) {
      return {
        restrict: "EA",
        templateUrl: function (element, attributes) {
          return attributes.templateUrl || "components/membership/interfaces/contact-user-widget/contactUser.html";
        },
        scope: {
          userId: "="
        },
        link: function (scope, element, attribute) {
          scope.$watch('userId', function () {
            init();
          }, true);

          function init() {
            scope.vm = new contactUserWidgetViewModel(scope.userId);
          }

          init();
        }
      }
    }
  ])
  .factory('contactUserWidgetViewModel', [
    'UserInformationUIService',
    function (UserInformationUIService) {
      function contactUserWidgetViewModel(userId) {
        var self = this;
        self.userId = userId;

        self.showUserInfo = function () {
          UserInformationUIService.showUserInfo(self.userId);
        };
      }

      return contactUserWidgetViewModel;
    }]);
