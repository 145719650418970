/**
 * Created on 10/24/2015.
 */

'use strict';

angular.module('membership')
  .factory('profilePropertyCreateViewModel', [
    'ProfilePropertiesDomain',
    function (profilePropertiesDomain) {
      function profilePropertyCreateViewModel($modalInstance) {
        var self = this;

        self.modalInstance = $modalInstance;
        self.data = {};

        profilePropertiesDomain.getAllPropertyType().then(function (response) {
          self.propertyTypes = response;
        });

        self.save = function () {
          profilePropertiesDomain.create(self.data).then(self.onSaveSuccess);
        };

        self.onSaveSuccess = function () {
          $modalInstance.close();
        };
      }

      return profilePropertyCreateViewModel;
    }
  ]);
