'use strict';

angular.module('membership')
  .service('roleInfoViewModel', [
    'Role', '$location', 'membership.config', function (roleDomain, $location, config) {

      return function (roleId) {

        var viewModel = {
          permissions: [],
          role: null,
          systemRoles: [], //["CA", "CS", "CC", "CCS"],
          editRole: null,
          editMode: false,

          allowToDelete: function() {
            return viewModel.role && !_.contains(viewModel.systemRoles, viewModel.role.Code);
          },

          init: function () {
            var self = this;
            roleDomain.get(roleId, function (role) {
              self.role = role;
            });
            roleDomain.getSystemRoles(function (systemRoles) {
              self.systemRoles = systemRoles;
            });
          },

          startEditing: function () {
            this.editMode = true;
            this.editRole = angular.copy(this.role);
          },

          saveChanges: function () {
            if (this.editMode) {
              this.role.Name = this.editRole.Name;
              this.role.Description = this.editRole.Description;

              roleDomain.update(this.role.Id, this.role, function() {
                viewModel.editMode = false;
              });
              //this.role.customPUT();
            }
          },

          discardChanges: function () {
            if (this.editMode) {
              this.editMode = false;
            }
          },

          removeRole: function () {
            roleDomain.remove(this.role.Id, function() {
              $location.path("/membership/role/list");
            });
          },

          //advanced show hide
          advanced: false,
          toggleAdvanced: function () {
            this.advanced = !this.advanced;
          },

          //datatables config
          datatablesConfig: {
            datasourceUrl: config.apiUrls.base + '/role/' + roleId + "/permissions/datasource",
            dtColumns: [
              {
                "bSearchable": false,
                "bVisible": false,
                "aTargets": [0]
              },
              {
                "bSearchable": false,
                "aTargets": [2]
              }
            ]
          }
        };

        viewModel.init();

        return viewModel;
      };
    }
  ]);