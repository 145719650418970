'use strict';

angular.module('membership')
  .service('rolePermissionsViewModel', [
    'Permission', 'Role', 'membership.config',
    '$q',
    //'datatablesHelper',
    function (permission, roleDomain, config, $q/*, datatablesHelper*/) {

      return function (roleId) {

        var viewModel = {
          //datatables config
          datatablesConfig: {
            datasourceUrl: config.apiUrls.base + '/role/' + roleId + "/permissions/datasource",
            dtColumns: [
              {
                "bSearchable": false,
                "bVisible": false,
                "aTargets": [0]
              },
              {
                "bSearchable": false,
                "aTargets": [2]
              }
            ]
          },

          //advanced show hide
          advanced: false,
          toggleAdvanced: function () {
            this.advanced = !this.advanced;
          },

          //roleId: 0,
          role: null,
          isLoading: false,
          rolePermissions: [],
          selectingPermissionIds: [],
          selectingUnassignedPermissionIds: [],
          permissionsSearch: '',
          init: function (/*roleId*/) {
            var self = this;
            self.roleId = roleId;

            self.isLoading = true;
            var allPromises = [roleDomain.get(roleId, function (role) {
              self.role = role;
            }),
              roleDomain.getPermissionsFromServer(roleId, function (permissions) {
                self.rolePermissions = permissions;
              })]

            $q.all(allPromises).then(function () {
              self.isLoading = false;
            })
          },

          isPermissionAssigned: function (permissionId) {
            var permission = _.find(this.rolePermissions, function (x) {
              return x.Id == permissionId;
            });
            return permission !== undefined;
          },
          getPermissions: function () {
            return permission.list;
          },
          getUnassignedPermissions: function () {
            var self = this;
            return _.filter(permission.list, function (permission) {
              return !self.isPermissionAssigned(permission.Id);
            });
          },
          assignPermissions: function () {
            var self = this;

            var selectedPermissions = _.filter(permission.list, function (permission) {
              return _.contains(self.selectingPermissionIds, permission.Id.toString());
            });

            self.isLoading = true;
            var allPromises = _.map(selectedPermissions, function (permission) {
              return assignPermission(self.role, permission);
            });

            $q.all(allPromises).then(function (results) {
              self.selectingPermissionIds = [];
              roleDomain.getPermissionsFromServer(roleId, function (permissions) {
                self.rolePermissions = permissions;
                self.datatablesConfig.reload();
                self.isLoading = false;
              });
            })
          },
          unassignPermissions: function () {
            var self = this;

            var selectedPermissions = _.filter(self.rolePermissions, function (permission) {
              return _.contains(self.selectingUnassignedPermissionIds, permission.Id.toString());
            });
            self.isLoading = true;
            var allPromises = _.map(selectedPermissions, function (permission) {
              return unassignPermission(self.role, permission);
            });

            $q.all(allPromises).then(function (results) {
              self.selectingUnassignedPermissionIds = [];
              roleDomain.getPermissionsFromServer(roleId, function (permissions) {
                self.rolePermissions = permissions;
                self.datatablesConfig.reload();
                self.isLoading = false;
              });
            })
          }
        };

        function assignPermission(role, permission) {
          var defer = $q.defer();


          roleDomain.assignPermission(role, permission, function () {
            defer.resolve(permission);
          });

          return defer.promise;
        }

        function unassignPermission(role, permission) {
          var defer = $q.defer();


          roleDomain.unassignPermission(role, permission, function () {
            defer.resolve(permission);
          });

          return defer.promise;
        }

        viewModel.init();

        return viewModel;
      };
    }]);