'use strict';

angular.module('magtac')
  .controller('CustomerStatusCtrl', [
    '$scope', 'customerStatusViewModel', 'timersConfigViewModel', '$stateParams',
    function ($scope, viewModel, timerViewModel, $stateParams) {
      $scope.timerModel = new timerViewModel(parseInt($stateParams.key));

      $scope.model = new viewModel(parseInt($stateParams.key), $scope.timerModel);

    }
  ]);
