// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-pane.without-tabs {
  border: 0;
  padding: 0;
  background: transparent;
}
.customer-details-page .big-rows input {
  margin-top: -5px;
}
.customers-table .status {
  width: 92px;
}
.customers-table .status-na {
  background: #ddd;
}
.label-expired {
  background-color: #e0b200;
}
.label-expired[href]:hover,
.label-expired[href]:focus {
  background-color: #e0b200;
}
`, "",{"version":3,"sources":["webpack://./src/components/membership/styles/customer.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,uBAAuB;AACzB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;AACb;AACA;EACE,gBAAgB;AAClB;AACA;EACE,yBAAyB;AAC3B;AACA;;EAEE,yBAAyB;AAC3B","sourcesContent":[".tab-pane.without-tabs {\n  border: 0;\n  padding: 0;\n  background: transparent;\n}\n.customer-details-page .big-rows input {\n  margin-top: -5px;\n}\n.customers-table .status {\n  width: 92px;\n}\n.customers-table .status-na {\n  background: #ddd;\n}\n.label-expired {\n  background-color: #e0b200;\n}\n.label-expired[href]:hover,\n.label-expired[href]:focus {\n  background-color: #e0b200;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
