'use strict';

angular.module('membership')
  .service('contactDetailsViewModel', [
    'Customer',
    function (Customer) {

      var viewModel = function (item) {

        var self = this;

        self.listeners = {};
        self.item = item || {};

        self.on = function (action, callBack) {
          self.listeners[action] = callBack;
        };

        self.notify = function (action, params) {
          if (self.listeners[action]) {
            self.listeners[action](params);
          }
        };

        self.save = function () {
          Customer.saveContact(self.item).then(function () {
            self.notify('save');
          });
        };

        self.delete = function () {
          Customer.deleteContact(self.item).then(function () {
            self.notify('delete', { Id: self.item.Id });
          });
        };

      };

      return viewModel;

    }
  ]);
