'use strict';

angular.module('ticketAssignment')
    .service('scoreboardViewModel', ['ticketAssignment.config', 'ticketAdmin', '$state',
        function (config, Tickets, $state) {
            var viewModel = function () {

                var self = this;



                Tickets.getSupportMetrics().then(
                    function (resp) {
                        console.log('Calling Tickets.getSupportMetrics()...');
                        self.TeamNames = resp.GroupNames;
                        self.Teams = resp.Groups;
                        self.GroupMetrics = resp.GroupMetrics;
                        self.TotalMetrics = resp.TotalMetrics;
                        self.ErrorMessages = resp.ErrorMessages;
                        self.HasErrorMessages = self.ErrorMessages.length > 0;
                        self.Members = Object.values(self.Teams)
                            .map(function f(team) { return Object.values(team.Members) })
                            .flat();

                        if (self.Members.filter(function (m) { return !m.HasPhoneExtension }).length > 0)
                        {
                            self.warning = {
                                message: 'No phone extension for: ' + self.Members.filter(function (m) { return !m.HasPhoneExtension }).map(function (m) { return m.Name; }),
                                display: true
                            };
                        }
                    }
                );



                var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                var today = new Date();
                self.dtStr = today.toLocaleDateString("en-US", options) + ", " +
                    today.toLocaleTimeString("en-US");



                self.AVERAGE_ID = 10000000001;
                self.CURRENT_USER_ID = 10000000001;


                self.metrics = {
                    "MFR3d": {tag: "MFR3d", label: "First Response",units:"m", threshold: 30, significantFigures: 1, change: "MFRChange"},
                    "AFR3d": {tag: "AFR3d", label: "First Response (A)", units: "m", threshold: 30, significantFigures: 1},
                    "ALT": {tag: "ALT", label: "Last Touch Days", threshold: 2.5, units: "d", significantFigures: 1},
                    "ALTC": {tag: "ALTC", label: "Last Customer Touch Days", threshold: 4, significantFigures: 1},
                    "CXR": {tag: "CXR", label: "Customer Experience Rating", threshold: -1, significantFigures: 1},
                    "CEDR": {tag: "CEDR", label: "CED Rate", threshold: 90, significantFigures: 1},
                    "ESCR": { tag: "ESCR", label: "Escalation Rate", units: "%", threshold: 50, significantFigures: 0, change: "ESCRChange"},
                    "ROR3d": {tag: "ROR3d", label: "Re-open Rate", units: "%", threshold: 7.50, significantFigures: 1},
                    "RT": { tag: "RT", label: "Resolution Rate", units: "%", threshold: 1, significantFigures: 1, change: "RTChange"},
                    "BA": { tag: "BA", label: "Backlog Age", units: "d", threshold: -1, significantFigures: 0, change: "BAChange"},
                    "BA7": { tag: "BA7", label: "Backlog > 7 days", units: "%", threshold: 50, significantFigures: 0, change: "BA7Change"},
                    "BA30": { tag: "BA30", label: "Backlog > 30 days", units: "%", threshold: 30, significantFigures: 0, change: "BA30Change"},
                    "BA90": { tag: "BA90", label: "Backlog > 90 days", units: "%", threshold: 12, significantFigures: 0},
                    "BA180": { tag: "BA180", label: "Backlog > 180 days", units: "%", threshold: 5, significantFigures: 1, change: "BA180Change"},
                    "OpenTicketCount": { tag: "OpenTicketCount", label: "Open Tickets", units: "", threshold: 1, significantFigures: 0, change: "OpenTicketCountChange",
                                          header: "Backlog", subheader: "Total"},
                    "ADC": { tag: "ADC", label: "Daily Closings", units: "", threshold: 0.5, significantFigures: 1, change: "ADCChange",
                             header: "Closes", subheader: "OneDay"},

                    'PLACEHOLDER': { tag: 'PLACEHOLDER', label: 'PLACEHOLDER' }
                };


                self.histogram = {
                    "MFR3d": {
                        color: "rgb(0, 148, 206)",
                        xMin: 0, xMax: 60, yMin: 0, yMax: 0,
                        step: 5, xstep: 1, ystep: 1, steps_shown: 10.0,
                        distribution: {}
                    },
                    "AFR3d": {
                        color: "rgb(0, 148, 206)",
                        xMin: 0, xMax: 180, yMin: 0, yMax: 0,
                        step: 20, xstep: 1, ystep: 1, steps_shown: 20.0,
                        distribution: {}
                    },
                    "ALT": { xMin: 0, xMax: 10, yMin: 0, yMax: 0, step: 0.1, distribution: {} },
                    "ALTC": { xMin: 0, xMax: 10, yMin: 0, yMax: 0, step: 0.1, distribution: {} },
                    "CXR": { xMin: 0, xMax: 10, yMin: 0, yMax: 0, step: 0.1, distribution: {} },
                    "CEDR": { xMin: 0, xMax: 10, yMin: 0, yMax: 0, step: 0.1, distribution: {} },
                    "ESCR": {
                        color: "rgba(64, 61, 157, 0.74)",
                        xMin: 0, xMax: 125, yMin: 0, yMax: 0,
                        step: 25, xstep: 1, ystep: 1, steps_shown: 5,
                        distribution: {}
                    },
                    "ROR3d": { xMin: 0, xMax: 10, yMin: 0, yMax: 0, step: 0.1, distribution: {} },
                    "RT": { xMin: 0, xMax: 10, yMin: 0, yMax: 0, step: 0.1, color: "rgba(0, 148, 206, 0.61)", distribution: {} },
                    "BA": { xMin: 0, xMax: 10, yMin: 0, yMax: 0, step: 0.1, color: "rgba(0, 148, 206, 0.61)", distribution: {} },
                    "BA7": { xMin: 0, xMax: 10, yMin: 0, yMax: 0, step: 0.1, distribution: {} },
                    "BA30": { xMin: 0, xMax: 10, yMin: 0, yMax: 0, step: 0.1, distribution: {} },
                    "BA90": { xMin: 0, xMax: 10, yMin: 0, yMax: 0, step: 0.1, distribution: {} },
                    "BA180": { xMin: 0, xMax: 10, yMin: 0, yMax: 0, step: 0.1, distribution: {} }
                };

                self.data = {
                    "MFR3d": {},
                    "AFR3d": {},
                    "ALT": {},
                    "ALTC": {},
                    "CXR": {},
                    "CEDR": {},
                    "ESCR": {},
                    "ROR3d": {},
                    "RT": {},
                    "BA": {},
                    "BA7": {},
                    "BA30": {},
                    "BA90": {},
                    "BA180": {},
                    "OpenTicketCount": {}
                };


                //self.metricsGroupOne = [self.metrics["OpenTicketCount"], self.metrics['ADC'], self.metrics["MFR3d"], self.metrics["RT"], self.metrics["ESCR"]];
                //self.metricsGroupTwo = [self.metrics["BA"], self.metrics["BA7"], self.metrics["BA30"], self.metrics["BA180"], self.metrics['PLACEHOLDER']];

                self.rows = [
                    [self.metrics["OpenTicketCount"], self.metrics['ADC'], self.metrics["MFR3d"], self.metrics["RT"], self.metrics["ESCR"]],
                    [self.metrics["BA"], self.metrics["BA7"], self.metrics["BA30"], self.metrics["BA180"]]
                ];

                //self.metricsGroupThree = [self.metrics["ROR3d"],  self.metrics["AFR3d"]];

                self.tags = ["MFR3d", "AFR3d", "ALT", "ALTC", "CXR", "CEDR", "ESCR", "ROR3d", "RT", "BA", "BA7", "BA30", "BA90", "BA180", "OpenTicketCount", "ADC"];
                self.units = { "MFR3d": "min", "AFR3d": "min", "ALT": "days", "BA": "days", "BA7": "%", "BA30": "%", "BA90": "%", "BA180": "%", "ESCR": "%", "RT": "days", "OpenTicketCount": "", "ADC": "" };
                self.leaders = { "MFR3d": "", "AFR3d": "", "ALT": "", "BA": "", "BA7": "", "BA30": "", "BA90": "", "BA180": "", "ESCR": "", "RT": "" };

                self.displayHistogram = ["MFR3d", "AFR3d", "ESCR", "ADC"];//, "AFR3d"]; , "ALT", "ALTC", "CXR", "CEDR", "ESCR", "ROR3d", "RT", "BA", "BA7", "BA30", "BA90", "BA180"];
                self.displayLeaderboard = ["MFR3d", "AFR3d"];
                

                Tickets.getScoreboardMetrics().then(
                    function (resp) {
                        self.users = resp;
                        self.avg = resp[self.AVERAGE_ID];
                        self.user = resp[self.CURRENT_USER_ID];


                        for (var userId in resp) {
                            var agentName = self.users[userId]['AgentName'];
                            var userOnLeaderboard = self.users[userId]["OnLeaderboard"];

                            //var name = self.users[userId].AgentName;

                            for (var metric in self.metrics) {
                                if ( metric == 'IGNORE') {
                                    continue;
                                }

                                var userValue = self.users[userId][metric];
                                
                                console.log(userValue);

                                if (userOnLeaderboard) {
                                    if (self.users[userId]["IsLeadingIn"].includes(metric)) {
                                        console.log('leader found ' + agentName);

                                        self.leaders[metric] = agentName;

                                    }
                                }



                                var fit = function (uValue, histogramRange) {
                                    return parseInt(histogramRange * Math.floor(uValue / histogramRange, 10));
                                }

                                if (self.displayHistogram.includes(metric)) {
                                    var step = self.histogram[metric].step;

                                    self.data[metric][userId] = userValue;

                                    if (userValue != null) {
                                        var userHistogramValue = fit(userValue, step);
                                        if (self.histogram[metric].distribution[userHistogramValue] == null) {
                                            self.histogram[metric].distribution[userHistogramValue] = 0;
                                        }
                                        self.histogram[metric].distribution[userHistogramValue]++;

                                        self.histogram[metric].yMax = Math.max(userValue, self.histogram[metric].yMax);
                                    }
                                }


                            }
                        }

                        console.log('\t\t --- data: --- (viewModel)');

                        for (var m in self.displayHistogram) {
                            var dataStr = 'self.data[' + m + '] = {';

                            for (var id in self.data[m]) {
                                var u = self.data[m][id];
                                dataStr += id + ':' + u + ',\t';

                            }
                            console.log(dataStr + '\n}');
                        }
                        

                        for (var i = 0; i < self.displayHistogram.length; i++) {
                            var tag = self.displayHistogram[i];

                            var histogramValues = Object.values(self.histogram[tag].distribution);
                            self.histogram[tag].yMax = Math.max.apply(null, histogramValues);

                            var d = self.histogram[tag].distribution;
                            var str = tag + '.distribution = {';
                            for (var hValue in d) {
                                var hFreq = d[hValue];
                                str += '  ' + hValue + ':' + hFreq + ', ';
                            }
                            console.log(str + '}; \n');
                        }

                        return resp;
                    }

                );


                console.log('\n');
                console.log('\n-----------Loaded at: -------------');
                console.log(self.dtStr);
                console.log('\n-----------Loaded at: -------------');

                console.log('(scoreboard.vm.js) Reached the end of viewModel');
            }
            

            return viewModel;
        }
    ]);