'use strict';

angular.module('membership')
  .service('ConnectionInfo', ['AjaxService', 'membership.config',
    function (ajaxService, config) {
      var baseUrl = config.apiUrls.base;
      var domain = {
        getConnectionInfo: function (id) {
          return ajaxService.get(baseUrl + '/connectionInfo/GetConnectionInfo/' + id);
        },
        getConnectionInfoWithPin: function (id, pin) {
          return ajaxService.get(baseUrl + '/connectionInfo/GetConnectionInfoWithPin/' + id + '?pin=' + pin);
        },
        requestPin: function (id, pin) {
          return ajaxService.post(baseUrl + '/connectionInfo/RequestPin/' + id);
        },
        saveConnectionInfo: function (data) {
          return ajaxService.post(baseUrl + '/connectionInfo/SaveConnectionInfo', data);
        }
      };

      return domain;
    }
  ]);
