angular.module('membership')
  .config([
    'SiteSettingInstancesProvider',
    'membership.configProvider',
    'common.configProvider',
    function (SiteSettingInstancesProvider, config, commonConfig) {

      SiteSettingInstancesProvider.registerSetting(commonConfig.config.coreSettingKey, {
        name: 'membership.lbl.generalSiteSettings',
        templateUrl: 'components/membership/siteSettings/generalSiteSettings/generalSiteSetting.html',
        apiSettingEndPoint: config.config.apiUrls.base + '/api/GeneralSiteSetting',
        order: 10
      });

      SiteSettingInstancesProvider.registerSetting(config.config.membershipSettingKey, {
        name: 'membership.lbl.membershipSetting',
        templateUrl: 'components/membership/siteSettings/membershipSetting/membershipSetting.html',
        apiSettingEndPoint: config.config.apiUrls.base + '/api/membershipSetting',
        order: 15
      });

    }]);
