// this order doesn't actually matter. It seems like both angularjs and webpack can both handle it.

// import '../components/common/_module';
// import '../components/documentsManagement/app';
// import '../components/navigation/_navigation';
// import '../components/backend/app';
// import '../components/siteSettings/app';
// import '../components/membership/app';
// import '../components/magview/app';
// import '../components/magtac/_magtac';
// import '../components/ticketAssignment/_tickets';
// import '../components/siteSettings/app';
// import '../components/projectsManagement/app';


(function (ng) {
'use strict';
ng.module('modules', 
	[
		'common',
		'documentsManagement',
		'navigation',
		'backend',
		'siteSettings',
		'membership',
		'magview',
		'magtac',
		'ticketAssignment',
		'siteSettings',
		'projectsManagement'
	]);
})(angular);


