'use strict';

angular.module('projectsManagement')
  .service('projectDetailsViewModel', [
    'Projects', 'Customer', 'modalDialogService', '$stateParams', '$state',
    function(Projects, Customer, modalDialogService, $stateParams, $state) {

      var viewModel = function(projectId) {

        var self = this;

        self.projectId = projectId;
        self.project = {};
        self.statuses = [
          'New', 'Assigned', 'In Progress', 'Live', 'On Hold', 'Canceled'
        ];
        self.projectsTypes = [{ id: 'IMP', label: 'Improvement' }, { id: 'ADDON', label: 'Add-on' }];

        Projects.details(projectId).then(function(project) {
          angular.copy(project, self.project);

          Customer.namesList().then(function (resp) {
            self.customers = resp;
          });
        });

        // Projects.getExpenses(projectId).then(function(expenses) {
        //   self.expenses = expenses;
        // });

        Projects.childs(projectId).then(function(childs) {
          self.projectChilds = childs;
        });

        self.selectionChanged = function (data, container, escapeMarkup) {
          var selectedCompanyId = parseInt(data.id);
          console.log(selectedCompanyId);
          if (self.project.CompanyId && selectedCompanyId && selectedCompanyId != self.project.CompanyId) {
            $state.go('projects_list', { customerId: data.id });
          }
          return data ? escapeMarkup(data.text) : undefined;
        };

        self.isChildPage = function() {
          return !!$stateParams.childId;
        }

        self.getChildId = function() {
          return $stateParams.childId;
        }

        self.addProject = function() {
          modalDialogService
            .showWindow('components/projectsManagement/newProjectDialog/newProjectDialog.tpl.html', 'NewProjectCtrl', { $$parent: self.project, $$companyId: self.project.CompanyId, $$project: null })
            .then(function(resp) {
              Projects.childs(projectId).then(function(childs) {
                self.projectChilds = childs;
              });

              $state.go('project_details.child.general', { childId: resp.Id });
            });
        }
      };

      return viewModel;

    }
  ]);
