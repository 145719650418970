/**
 * Created on 10/2/2015.
 */
angular.module('membership')
  .controller('ClientCompanyInfoController', [
    '$scope', 'ClientCompanyInfoViewModel',
    function ($scope, ClientCompanyInfoViewModel) {
      $scope.vm = new ClientCompanyInfoViewModel($scope.clientCompanyId);
    }
  ]);
