/**
 * Created on 10/5/2015.
 */
angular.module('membership')
  .config([
    'appStateConfigProvider', 'membership.configProvider',
    function (appStateConfigProvider, configProvider) {

      //user
      var states = [
        {
          name: 'membership_search',
          url: "/",
          templateUrl: "components/membership/search/search.html",
          controller: 'SearchCtrl',
          data: {
            access: configProvider.config.permissions.customerView
          }
        },
        {
          name: 'register',
          url: "/register",
          templateUrl: "components/membership/register/register.html",
          controller: 'UserRegistrationController',
          data: {
            title: 'membership.lbl.register',
            specialClass: 'log-reg'
          }
        },
        {
          name: 'userActivation',
          url: "/userActivation/{token:.*}",
          templateUrl: "components/membership/userActivation/userActivation.html",
          controller: 'UserActivationController',
          data: {
            title: 'membership.lbl.activation'
          },
          params: {}
        },
        {
          name: 'login',
          url: "/login",
          templateUrl: "components/membership/login/login.html",
          controller: 'LoginCtrl'
        },
        {
          name: 'secretLogin',
          url: "/syslogin",
          templateUrl: "components/membership/login/secretlogin.html",
          controller: 'LoginCtrl'
        },

        //user
        {
          name: 'membership_user',
          url: "/membership/user/list",
          templateUrl: "components/membership/user/list.html",
          controller: 'UserListCtrl',
          data: {
            access: configProvider.config.permissions.manageUsers
          }
        },

        //role
        {
          name: 'membership_role',
          url: "/membership/role/list",
          templateUrl: "components/membership/role/list.html",
          controller: 'RoleListCtrl',
          data: {
            access: configProvider.config.permissions.manageRoles
          }
        },

        //company
        {
          name: 'membership_company',
          url: "/membership/company/list",
          templateUrl: "components/membership/company/list.html",
          controller: 'CompanyListCtrl',
          data: {
            access: configProvider.config.permissions.systemAdmin
          }
        },
        {
          name: 'membership_company_details',
          url: "/membership/company/details/:id",
          templateUrl: "components/membership/company/details.html",
          controller: 'CompanyDetailsCtrl',
          data: {

          }
        },

        //user manager
        {
          name: 'membership_usermanager',
          url: "/membership/usermanager/list/:filter",
          templateUrl: "components/membership/userManager/list/list.html",
          controller: 'UserManagerListCtrl',
          data: {
            access: configProvider.config.permissions.manageUsers
          }
        },

        // user management details
        {
          name: 'membership_usermanager_details',
          url: "/membership/usermanager/:filter/:action/:userId",
          templateUrl: "components/membership/userManager/details/details.html",
          controller: 'UserManagerDetailsCtrl',
          data: {
            access: configProvider.config.permissions.manageUsers
          }
        },

        //user manager sync
        {
          name: 'membership_usermanager_sync',
          url: "/membership/usermanager-sync/list/:filter",
          templateUrl: "components/membership/userManagerSync/list/list.html",
          controller: 'UserManagerListSyncCtrl',
          data: {
            access: configProvider.config.permissions.manageUsers
          }
        },

        // user management details
        {
          name: 'membership_usermanager_details_sync',
          url: "/membership/usermanager-sync/:filter/:action/:userId",
          templateUrl: "components/membership/userManagerSync/details/details.html",
          controller: 'UserManagerDetailsSyncCtrl',
          data: {
            access: configProvider.config.permissions.manageUsers
          }
        },

        {
          name: 'membership_userAccount',
          url: "/membership/userManagement/:userId",
          templateUrl: "components/membership/userManager/userDetails/userDetailPage.html",
          controller: 'MyAccountController',
          data: {
            access: "/myaccount"
          }
        },

        {
          name: "membership_userAccount.personalInfo",
          url: "/personalInfo",
          templateUrl: "components/membership/myaccount/personalInfo/personalInfo.html",
          controller: 'AccountInfoController',
          resolve: {
            $userId: ['$stateParams', function ($stateParams) {
              return $stateParams.userId
            }]
          },
        },
        {
          name: "membership_userAccount.changePassword",
          url: '/changePassword',
          templateUrl: "components/membership/myaccount/changePassword/changePassword.html",
          controller: 'ChangePasswordController',
          resolve: {
            $userId: ['$stateParams', function ($stateParams) {
              return $stateParams.userId;
            }]
          }
        },
        {
          name: "membership_userAccount.profile",
          url: '/profile',
          templateUrl: "components/membership/myaccount/profile/profile.html",
          controller: 'AccountProfileController',
          resolve: {
            $userId: ['$stateParams', function () {
              return $stateParams.userId;
            }]
          }
        },

        //user groups
        {
          name: 'membership_usergroup',
          url: "/membership/usergroup/list",
          templateUrl: "components/membership/userGroup/list/list.html",
          controller: 'UserGroupListCtrl'/*,
         data: {
         access: "/manageUsers"
         }*/
        },
        {
          name: 'membership_usergroup_details',
          url: "/membership/usergroup/:action/:groupId",
          templateUrl: "components/membership/userGroup/details/details.html",
          controller: 'UserGroupDetailsCtrl'/*,
         data: {
         access: "/manageUsers"
         }*/
        },

        //user detail views
        {
          name: 'membership_user_detail',
          url: "/membership/user/details/:id/:tab",
          views: {
            "": {
              templateUrl: "components/membership/user/details/details.base.html",
              controller: 'UserDetailsBaseCtrl',
            },
            "personalInfo@membership_user_detail": {
              templateUrl: "components/membership/user/details/personalInfo.html",
              controller: "PersonalInfoCtrl"
            },
            "permissions@membership_user_detail": {
              templateUrl: "components/membership/user/details/permissions.html",
              controller: 'UserPermissionsCtrl'
            }
          },
          data: {
            access: configProvider.config.permissions.manageUsers
          }
        },

        //role details views
        {
          name: 'membership_role_detail',
          url: "/membership/role/details/:id/:tab",
          views: {
            "": {
              templateUrl: "components/membership/role/details/details.base.html",
              controller: 'RoleDetailsBaseCtrl',
            },
            "roleInfo@membership_role_detail": {
              templateUrl: "components/membership/role/details/roleInfo/roleInfo.html",
              controller: 'RoleInfoCtrl',
            },
            "permissions@membership_role_detail": {
              templateUrl: "components/membership/role/details/permission/permissions.html",
              controller: 'RolePermissionsCtrl',
            },
            "profileProperties@membership_role_detail": {
              templateUrl: "components/membership/role/details/profileProperties/profileProperty.html",
              controller: 'RoleProfilePropertyCtrl',
            }
          },
          data: {
            access: configProvider.config.permissions.manageUsers
          }
        },

        // my account view
        {
          name: 'membership_user_my_account',
          url: "/myaccount",
          templateUrl: "components/membership/myaccount/myAccount.html",
          controller: 'MyAccountController',
          data: {
            access: "/myaccount",
            title: 'My Account'
          }
        },

        {
          name: "membership_user_my_account.personalInfo",
          url: "/personalInfo",
          templateUrl: "components/membership/myaccount/personalInfo/personalInfo.html",
          controller: 'AccountInfoController',
          resolve: {
            $userId: function () {
              return null;
            }
          },
          data: {
            order: 1,
            title: 'Personal information',
            tabTitle: 'Personal information'
          }
        },
        {
          name: "membership_user_my_account.changePassword",
          url: '/changePassword',
          templateUrl: "components/membership/myaccount/changePassword/changePassword.html",
          controller: 'ChangePasswordController',
          resolve: {
            $userId: ['$stateParams', function () {
              return undefined;
            }]
          },
          data: {
            order: 3,
            title: 'Change Password',
            tabTitle: 'Change Password'
          }
        },
        {
          name: "membership_user_my_account.profile",
          url: '/profile',
          templateUrl: "components/membership/myaccount/profile/profile.html",
          controller: 'AccountProfileController',
          resolve: {
            $userId: ['$stateParams', function () {
              return undefined;
            }]
          },
          data: {
            order: 2,
            title: 'User Profile',
            tabTitle: 'User Profile'
          }
        },
        {
          name: "userProfile",
          url: '/user/:userId/profile',
          templateUrl: "components/membership/userProfile/userProfile.html",
          controller: 'UserProfileController'
        },

        {
          name: 'access_denied',
          url: "/access-denied",
          template: "<div class='global-warning'><h1>Permission Error</h1><p>Access Denied. Contact your system administrator.</p><div>"
        },

        {
          name: 'clientCompany',
          abstract: true,
          url: "/manage/clientCompany",
          template: "<div ui-view></div>",
          data: {
            access: "/manageUsers",
            pageTitle: 'membership.manageCompanyClientTitle',
            pageDesc: 'membership.manageCompanyClientPageDesc',
            specialClass: 'client-company-management-page'
          }
        },

        {
          name: 'clientCompany.list',
          url: "",
          templateUrl: "components/membership/clientCompany/list.html",
          controller: 'ClientCompanyController',
          data: {
            access: "/manageUsers"
          }
        },
        {
          name: 'clientCompany.detail',
          url: '/:clientCompanyId',
          templateUrl: 'components/membership/clientCompany/detail/detail.html',
          controller: 'ClientCompanyDetailController',
          data: {
            access: "/manageUsers"
          }
        },
        {
          name: 'clientCompany.detail.managers',
          url: '/managers',
          templateUrl: 'components/membership/userManager/filterableList/list.html',
          controller: 'ClientCompanyManagersFilterableListController',
          data: {
            access: "/manageUsers",
            filter: configProvider.config.userRoles.companyClientManager,
            tabTitle: 'membership.lbl.clientCompanyStaffs'
          }
        },

        // customer
        {
          name: 'membership_customerSearch',
          url: "/customers",
          templateUrl: "components/membership/customer/customer-search.html",
          data: {
            access: configProvider.config.permissions.customerView
          }
        },
        {
          name: 'membership_customerSearch.list',
          url: "/list",
          templateUrl: "components/membership/customer/customers-list.html",
          controller: 'CustomersListCtrl',
          data: {
            access: configProvider.config.permissions.customerView
          }
        },
        //Kap code
        {
          name: 'membership_customerSearch.ownership',
          url: "/ownership",
          templateUrl: "components/membership/customer/ownership/ownership.html",
          controller: "OwnershipCtrl",
          data: {
            access: configProvider.config.permissions.customerView
          }
        },
        {
          name: 'membership_customerSearch.contacts',
          url: "/contacts-search",
          templateUrl: "components/membership/customer/contacts/contacts-search.html",
          controller: 'ContactsSearchCtrl',
          data: {
            access: configProvider.config.permissions.customerView
          }
        },
        {
          name: 'membership_customer',
          redirectTo: 'membership_customer.general',
          url: "/customer/{key:int}",
          templateUrl: "components/membership/customer/customer.html",
          controller: 'CustomerCtrl',
          data: {
            access: configProvider.config.permissions.customerView
          }
        },
        {
          name: 'membership_customer.general',
          url: "/general",
          templateUrl: "components/membership/customer/general/general.html",
          data: {
            access: configProvider.config.permissions.customerView
          }
        },
        {
          name: 'membership_customer.contacts',
          url: "/contacts",
          templateUrl: "components/membership/customer/contacts/contacts.html",
          controller: "CustomerContactsCtrl",
          data: {
            access: configProvider.config.permissions.customerContactsView
          }
        },
        {
          name: 'membership_customer.sites',
          url: "/sites",
          templateUrl: "components/membership/customer/sites/sites.html",
          controller: "SitesCtrl",
          data: {
            access: configProvider.config.permissions.customerSitesView
          }
        },
        {
          name: 'membership_customer.workflow',
          url: "/workflow",
          templateUrl: "components/membership/customer/workflow/workflow.html",
          controller: "WorkflowCtrl",
          data: {
            access: configProvider.config.permissions.customerContactsView
          }
        },
        /*{
         name: 'membership_customer.documents',
         url: "/documents",
         templateUrl: "components/membership/customer/documents/documents.html",
         controller: "DocumentsCtrl"
         },*/
        {
          name: 'membership_customer.documents',
          url: "/documents",
          templateUrl: "components/documentsManagement/list/documents.html",
          controller: 'DocumentsListCtrl',
          data: {
            access: configProvider.config.permissions.customerDocumentsView
          },
          resolve: {
            masterId: ['$stateParams', function ($stateParams) {
              return $stateParams.key;
            }],
            documentsApiUrl: ['membership.config', function (config) {
              return config.apiUrls.base + '/CustomerDocuments';
            }],
            editPermission: ['membership.config', function (config) {
              return config.permissions.customerDocumentsEdit;
            }]
          }
        },


        // {
        //   name: 'membership_customer.projects',
        //   url: "/projects",
        //   templateUrl: "components/projectsManagement/embeddedList/embeddedList.html",
        //   controller: 'ProjectsEmbeddedListCtrl',
        //   resolve: {
        //     masterId: function ($stateParams) { return $stateParams.key; }
        //   }
        // },

        {
          name: 'membership_customer.vendors',
          url: "/vendors",
          templateUrl: "components/membership/customer/vendors/vendors.html",
          controller: "VendorsCtrl",
          data: {
            access: configProvider.config.permissions.customerVendorsView
          }
        },
        {
          name: 'membership_customer.SMS',
          url: "/SMS",
          templateUrl: "components/membership/customer/SMS/SMS.html",
          controller: "smsCtrl",
          data: {
            access: configProvider.config.permissions.customerVendorsView
          }
        },
        {
          name: 'membership_customer.partnerships',
          url: "/partnerships",
          templateUrl: "components/membership/customer/partnerships/partnerships.html",
          controller: "PartnershipsCtrl",
          data: {
            access: configProvider.config.permissions.customerPartnershipsView
          }
        },
        // {
        //   name: 'membership_customer.status',
        //   url: "/status",
        //   templateUrl: "components/statusPage/statusHistory/customerStatus/customer-status.html",
        //   data: {
        //     access: configProvider.config.permissions.customerView
        //   }
        // },
        {
          name: 'membership_customer.users',
          url: "/users",
          templateUrl: "components/membership/customer/users/customer-user-list.html",
          controller: "CustomerUserListCtrl",
          data: {
            access: configProvider.config.permissions.userView
          }
        },

        // user
        {
          name: 'membership_customerUserList',
          url: "/users",
          templateUrl: "components/membership/customer/users/customer-user-list.html",
          controller: 'CustomerUserListCtrl',
          data: {
            access: configProvider.config.permissions.userView
          }
          },

        {
          name: 'membership_customerUser',
          redirectTo: 'membership_customerUser.userProfile.general',
          url: "/customer-user/{key:int}",
          templateUrl: "components/membership/customer/users/customer-user.html",
          controller: 'CustomerUserCtrl',
          data: {
            access: 'authorizedUser'
          }
        },
        {
          name: 'membership_customerUser.userProfile',
          redirectTo: 'membership_customerUser.userProfile.general',
          url: "/",
          templateUrl: "components/membership/customer/users/userProfileGeneral/general.html"
        },
        {
          name: 'membership_customerUser.userProfile.general',
          url: "general",
          templateUrl: "components/membership/myaccount/personalInfo/personalInfo.html",
          controller: 'AccountInfoController',
          resolve: {
            $userId: ['$stateParams', function ($stateParams) {
              return $stateParams.key
            }]
          },
          data: {
            tabTitle: 'General',
            title: 'General',
            order: 1
          }
        },
        {
          name: 'membership_customerUser.userProfile.profile',
          url: "profile",
          templateUrl: "components/membership/myaccount/profile/profile.html",
          controller: 'AccountProfileController',
          resolve: {
            $userId: ['$stateParams', function ($stateParams) {
              return $stateParams.key
            }]
          },
          data: {
            tabTitle: 'User Profile',
            title: 'User Profile',
            order: 2
          }
        },
        {
          name: 'membership_customerUser.userProfile.changePassword',
          url: "changePassword",
          templateUrl: "components/membership/myaccount/changePassword/changePassword.html",
          controller: 'ChangePasswordController',
          resolve: {
            $userId: ['$stateParams', function ($stateParams) {
              return $stateParams.key
            }]
          },
          data: {
            tabTitle: 'Change Password',
            title: 'Change Password',
            order: 3
          }
        },
        {
          name: 'membership_customerUser.timeManagement',
          url: "/time-management",
          templateUrl: "components/myMagview/timeManagement/time-management.html",
          data: {
            access: 'MyMagview.TimeManagement'
          }
        },
        {
          name: 'membership_customerUser.timeManagement.workTime',
          url: "/work-time",
          templateUrl: "components/myMagview/timeManagement/timeTracking/time-tracking.html",
          data: {
            access: 'MyMagview.TimeManagement'
          }
        },
        {
          name: 'membership_customerUser.timeManagement.timeOff',
          url: "/time-off",
          templateUrl: "components/myMagview/timeManagement/attendanceTracking/attendance-tracking.html",
          data: {
            access: 'MyMagview.TimeManagement'
          }
          },
          {
              name: 'supportMetricsSettings',
              url: "/metrics/settings",
              templateUrl: "components/ticketAssignment/supportMetrics/metricsSettings/metricsSettings.html",
              controller: 'supportMetricsSettingsCtrl',
              data: {
                  access: configProvider.config.permissions.manageUsers
              }
          },
        /*{
         name: 'membership_connectionInfo',
         url: "/customer-info/:id",
         templateUrl: "components/membership/connectionInfo/connection-info.html",
         controller: 'ConnectionInfoCtrl',
         data: {
         access: 'authorizedUser'
         }
         },*/
      ];

      states.forEach(appStateConfigProvider.addState);
    }
  ]);
