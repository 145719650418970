'use strict';

angular.module('projectsManagement')
  .service('projectsEmbeddedListViewModel', ['projectsManagement.config', 'modalDialogService', '$state',
    function (config, modalDialogService, $state) {

      var viewModel = function (masterId) {

        var self = this;

        self.filter = { customerId: masterId };

        self.datatablesConfig = {
          datasourceUrl: config.apiUrls.base + '/projects/datasource',
          defaultSortingColumn: "0",
          defaultSortingOrder: "desc",
          iDisplayLength: 25,
          dtColumns: [
            {
              "aTargets": 1,
              "sWidth": 200,
              "sClass": "dynamic-html",
              "render": function (data, type, full, meta) {
                return "<a class='' ui-sref='project_details.general({key:" + full[0] + "})'>" + data +"</a>";                
              }
            },
            // {
            //   "aTargets": 2,
            //   //visible: false              
            // },
            {
              "aTargets": 5,
              "render": function (data, type, full, meta) {
                var labelType = 'default';
                switch (data) {
                  case 'Live': labelType = 'success';
                    break;
                  case 'New': labelType = 'info';
                    break;
                  case 'In Progress': labelType = 'warning';
                    break;
                  case 'On Hold': labelType = 'default';
                    break;
                  case 'Canceled': labelType = 'danger';
                    break;
                  case 'Assigned': labelType = 'primary';
                    break;
                }

                return "<span class='label label-" + labelType + "'>" + data + "</span>";
              }
            },
            {
              "aTargets": -1,
              "bSearchable": false,
              "bSortable": false,
              "data": null,
              "sWidth": 90,
              "sClass": "actions-col dynamic-html",
              "render": function (data, type, full, meta) {
                var editBtn = "<a class='btn btn-success' disabled='disabled' ui-sref='project_details.general({key:" + data[0] + "})' title='Details' ><i class='fa fa-usd'></i></a>";                
                return editBtn;
              }
            }
          ],
          customFilter: self.filter,
          dom: "<'row'<'col-xs-12 col-sm-12'r>>" + 
            "t" +
            "<'row'<'col-sm-2 col-xs-12 hidden-xs'l><'col-sm-4 col-xs-12 hidden-xs'<'pull-right'i>><'col-xs-12 col-sm-6'p>>"
            
        };

        self.create = function() {
          modalDialogService
            .showWindow('components/projectsManagement/newProjectDialog/newProjectDialog.tpl.html', 'NewProjectCtrl', { $$parent: null, $$companyId: null, $$project: null})
            .then(function(resp){
              $state.go('project_details.general', {key: resp.Id});
            });
        };

        self.reloadData = function () {
          self.datatablesConfig.reload();
        };
      };

      return viewModel;

    }
  ]);
