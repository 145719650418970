/**
 * Created on 10/12/2015.
 */
angular.module('common')
  .directive('layoutZone', [
    'layoutZoneService', '$compile',
    function (layoutZoneService, $compile) {
      return {
        restrict: "EA",
        scope: {
          zoneName: "@",
          zoneClasses: "@"
        },
        link: function (scope, element, attr) {
          scope.widgets = layoutZoneService.getWidgets(scope.zoneName);
          scope._parent = scope.$parent;
          scope.vm = scope._parent.vm;

          var parent = scope;
          while (true) {
            parent = parent.$parent;
            if (parent == null) break;
            scope.vm = parent.vm;
            if (scope.vm !== undefined && scope.vm !== null)
              break;
          }

          var html = "<div class='{{zoneClasses}}' id='zone-{{zoneName}}'>";

          scope.widgets.forEach(function (widget) {
            html += widget.template
          });

          html += "</div>";

          $(element).append($compile(html)(scope));
        }
      }
    }
  ]);
