'use strict';

angular.module('common')
  .directive('datatables', [
    '$compile', 'AjaxService',
    function ($compile, AjaxService) {
      return {
        scope: {
          settings: '=datatables',
          noFilter: "=",
          noSearch: "="
        },
        link: function (scope, elm, attrs) {

          //var settings = scope.$eval(attrs.datatables);
          var settings = scope.settings;
          // TODO: (Justin) Possible wrong when use logged out
          var headers = AjaxService.tokenHeaderValue;

          var disableFilter = attrs.noFilter;

          var config = {
            "dom": settings.dom || "<'row'" +
            "<'col-xs-12 col-sm-4'" + ( disableFilter ? "" : "<'filter-left'f>") + ">" +
            "<'col-xs-12 col-sm-4'r>" +

            ( disableFilter ? ">" : "<'col-sm-4 col-xs-12 hidden-xs'<'pull-right'l>>>") +
            
            "t" +
            "<'row'<'col-sm-5 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-7'p>>",
            "language": {
              "search": "",
              "searchPlaceholder": "Search",
              //"lengthMenu": "Show _MENU_",
              "lengthMenu": "_MENU_",
            },
            "serverSide": true,
            "processing": true,
            "bFilter": !scope.noSearch ? !scope.noSearch : false,
            "ajax": {
              "type": "POST",
              "url": settings.datasourceUrl,
              headers: headers
            },
            "drawCallback": function (oSettings) {
              var cellsWithDynamicHtml = elm.find("td.dynamic-html");
              for (var index = 0; index < cellsWithDynamicHtml.length; index++) {
                var cell = angular.element(cellsWithDynamicHtml[index]);
                var rightScope = scope.$parent;
                while (!rightScope.model && rightScope.$parent) {
                  rightScope = rightScope.$parent;
                }
                var compiledHtml = $compile(cell.html())(rightScope);
                cell.html("");
                cell.append(compiledHtml);
              }

              if (settings.fnDrawCallback) {
                settings.fnDrawCallback(oSettings);
              }
            },
            createdRow: function (row, data) {
              if (settings.createdRow) settings.createdRow(row, data);
            },
            fnServerParams: function (aoData) {
              if (settings.fnServerParams) settings.fnServerParams(aoData);
            },
            /*fnDrawCallback: settings.fnDrawCallback || function () {
             }*/
          };

          if (settings.hiddenColumns) {
            for (var i = 0; i < settings.hiddenColumns.length; i++) {
              settings.dtColumns.push({
                "bSearchable": false,
                "bVisible": false,
                "aTargets": [settings.hiddenColumns[i]]
              });
            }
          }

          if (settings.dtColumns) {
            config.columnDefs = settings.dtColumns;
          }

          if (settings.iDisplayLength) {
            config.iDisplayLength = settings.iDisplayLength;
          }

          if (settings.defaultSortingColumn && settings.defaultSortingOrder) {
            config.aaSorting = [[settings.defaultSortingColumn, settings.defaultSortingOrder]];
          }

          if(settings.sPaginationType){
            config.sPaginationType = settings.sPaginationType;
          }

          config.ajax.data = function (data) {
            if (settings.customFilter) {
              data.customFilter = JSON.stringify(settings.customFilter);
            }
            if(settings.skipCounting){
              data.skipCounting = true;
            }
          };

          // store filter row in memory before render (to calculate hidden columns too)

          var columnFilterHeaders = elm.find("tr.columns-filter th");

          var table = elm.DataTable(config);

          columnFilterHeaders.each(function (index, value) {

            if (table.column(index).visible()) {

              $(value).find('input.filter').on('keyup change', function () {

                table
                  .column(index)
                  .search(this.value)
                  .draw();
              });
            }
          });

          scope.$watch('settings.filterQuery', _.debounce(function (newValue, oldValue) {
            if (newValue !== oldValue) {
              table.search(newValue).draw();
            }
          }, 500));

          scope.$watch('settings.customFilter', _.debounce(function (newValue, oldValue) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
              settings.reload();
            }
          }, 500), true);

          scope.$watch('settings.datasourceUrl', function (newValue, oldValue) {
            if (newValue !== oldValue) {
              table.settings().ajax.url(newValue /*.datasourceUrl*/);
              settings.reload();
            }
          }, true);

          //scope.$watch(function () {
          //    return scope.$eval(attrs.datatables);
          //  },
          //  function (newValue, oldValue) {
          //    if (newValue.datasourceUrl !== oldValue.datasourceUrl) {
          //      table.settings().ajax.url(newValue.datasourceUrl);
          //      table.ajax.reload();
          //    }
          //    else if (newValue.customFilter !== oldValue.customFilter) {
          //      table.ajax.reload();
          //    }
          //    //...
          //    // handle other dynamic changes
          //  }, true);
          settings.table = table;
          settings.reload = function () {
            table.draw(true);
          };
        }
      };
    }
  ]);
