/**
 * Created on 10/6/2015.
 */
angular.module('membership')
  .directive('selectUser', [
    'AjaxService', 'User', 'membership.config', '$q', 'translationSvc',
    function (AjaxService, User, config, $q, translationSvc) {
      return {
        restrict: "EA",
        templateUrl: function (element, attrs) {
          return attrs.templateUrl || "components/common/templates/commonSelectionWithAutoComplete.html";
        },
        require: 'ngModel',
        scope: {
          bindingProperty: "@bindingProperty",
          roleFilter: "@roleFilter",
          optionalDisplayText: "@optionalDisplayText",
          showOptionalValue: "="
        },
        link: function (scope, element, attr, ngModel) {

          var self = scope;
          self.optionsList = [];
          var defer = $q.defer();
          self.ensureDataLoaded = defer.promise;

          self.selectedValue = {};

          self.getDisplayValue = function (item) {
            return item ? item.displayName : "";
          };

          if (!scope.optionalDisplayText)
            scope.optionalDisplayText = translationSvc.t('membership.lbl.typeToSearchUser');

          self.reload = function (filter) {
            if (!self.originalList) {
              return;
            }

            self.optionsList = self.originalList.map(function (r) {
              r.displayName = r.FirstName + " " + r.LastName;
              return r;
            }).filter(function(r){
              return !!r.displayName && !!r.displayName.trim() &&
                      r.displayName.toLowerCase().indexOf((filter || '').toLowerCase()) >= 0; 
            });
            if (scope.showOptionalValue) {
              self.optionsList.splice(0, 0, {
                Id: '',
                displayName: scope.optionalDisplayText || translationSvc.t('membership.lbl.typeToSearchUser')
              });
            }
          };

          self.reloadUsers = function (filter) {
            return AjaxService.get(config.apiUrls.base + "/user/autocomplete?q=" + filter + "&roleFilter=" + (scope.roleFilter || '') + "&page_limit=1100&page=1&_=" + new Date().getTime())
              .then(function (response) {
                self.originalList = response;
                self.reload();
              })
              .finally(function () {
                defer.resolve();
              });
          };

          scope.$watch('selectedValue.selected', function () {
            if (scope.selectedValue.selected)
              ngModel.$setViewValue(scope.selectedValue.selected ? scope.selectedValue.selected[scope.bindingProperty] : null);
          }, true);

          scope.$watch('bindingProperty', function () {
              if (scope.selectedValue.selected)
              ngModel.$setViewValue(scope.selectedValue.selected ? scope.selectedValue.selected[scope.bindingProperty] : null);
          }, true);

          scope.$watch(function () {
            return ngModel.$modelValue;
          }, function (newValue) {
            scope.ensureDataLoaded.then(function () {
              scope.selectedValue.selected = _.find(self.optionsList, function (user) {
                return user[scope.bindingProperty] == newValue;
              });

              if (!scope.showOptionalValue) {
                scope.optionalDisplayText = self.getDisplayValue(scope.selectedValue.selected);
              }
            });
          });

          scope.$watch('roleFilter', function () {
            scope.reloadUsers('');
          }, true)
        }
      };
    }
  ]);
