/**
 * Created on 2/23/2016.
 */
angular.module('common')
  .factory('googleMapLoader', [
    '$q',
    function ($q) {
      var deferred = $q.defer();
      if (typeof window.google !== 'undefined' && typeof window.google.maps !== 'undefined') {
        deferred.resolve(window.google.maps);
        return deferred.promise;
      }

      var randomizedFunctionName = 'onGoogleMapsReady' + Math.round(Math.random() * 1000);
      window[randomizedFunctionName] = function () {
        deferred.resolve(window.google.maps);
        delete window[randomizedFunctionName];
      };

      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://maps.googleapis.com/maps/api/js?libraries=places&callback=' + randomizedFunctionName;
      document.body.appendChild(script);

      return deferred.promise;
    }
  ]);
