'use strict';

angular.module('magtac')
  .controller('DashboardCtrl', [
    '$scope', 'dashboardViewModel', '$stateParams',
    function ($scope, viewModel, $stateParams) {
      $scope.model = new viewModel();

      $scope.$on('$destroy', function() {
          $scope.model.destroy();
      });
    }
  ]);
