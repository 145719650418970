'use strict';

angular.module('membership')
  .controller('CompanyListCtrl', [
    '$scope', 'membership.config', function ($scope, config) {

      /*$scope.dtColumns = [
      {
        "bSearchable": false,
        "bVisible": false,
        "aTargets": [0]
      }, {
        "sType": "html",
        "fnRender": function (o, val) {
          return $('<div>').append($("<a>").html(o.aData[1] || 'empty').attr('href', '/#/admin/company-management/' + o.aData[0])).html();
        },
        "aTargets": [1]
      }
    ];*/

      $scope.datatablesConfig = {
        datasourceUrl: config.apiUrls.base + '/datasource/manageCompanies',
        dtColumns: [
          {
            "aTargets": -1,
            "bSearchable": false,
            "bSortable": false,
            "data": null,
            "sWidth": 30,
            "sClass": "actions-col dynamic-html",
            "render": function (data, type, full, meta) {

              return "<a class='btn btn-success' ui-sref='membership_company_details({id:" + data[0] + "})' title='Edit' ><i class='fa fa-edit'></i></a>";
            }
          }
        ],
        hiddenColumns: [0]
      };

    }
  ]);