'use strict';

angular.module('membership')
  .directive('membershipAuthorizedOnly', [
    'authenticationService', 'permissionsService',
    function (authenticationService, permissionsService) {
      return {
        link: function (scope, elm, attrs) {
          scope.$watch(function(){ return scope.isFeatureAllowed('authorizedUser'); }, function (newValue) {
            if (!newValue) {
              elm.hide();
            } else {
              elm.show();
            }
          });

        }
      };
    }
  ]);