'use strict';
angular.module('ticketAssignment')
    .service('customerBoardViewModelOld', ['ticketAssignment.config', 'ticketAdmin', '$state',
        function (config, Tickets, $state) {
            var viewModel = function () {
                console.log('\n-----Loaded:-----');
                console.log('Loaded customerboard view model');

                var self = this;

                self.headers = [
                    {
                        name: 'First Response Agents',
                        width: '11.11%',
                        display: 'FRT'
                    },
                    {
                        name: 'Team1',
                        width: '11.11%',
                        display: 'Team 1'
                    },
                    {
                        name: 'Team2',
                        width: '11.11%',
                        display: 'Team 2'
                    },
                    {
                        name: 'Team3',
                        width: '11.11%',
                        display: 'Team 3'
                    },
                    {
                        name: 'Team4',
                        width: '11.11%',
                        display: 'Team 4'
                    },
                    {
                        name: 'Team5',
                        width: '11.11%',
                        display: 'Team 5'
                    },
                    {
                        name: 'VIP',
                        width: '11.11%',
                        display: 'VIP'
                    }
                ];

                var border = '1px solid rgb(221, 221, 221)';

                self.agentHeaders = [
                    {
                        name: 'name',
                        width: '70%',
                        borderRight: border,
                        text_align: 'left',
                        alt_colspan: '2'
                    },
                    {
                        name: 'tickets',
                        width: '15%',
                        borderRight: border,
                        text_align: 'right',
                        alt_colspan: '1'
                    },
                    {
                        name: 'score',
                        width: '15%',
                        borderRight: 'none',
                        text_align: 'right',
                        alt_colspan: '1'
                    }
                ];

                self.companyHeaders = [
                    {
                        name: 'priority',
                        width: '15%',
                        borderRight: border,
                        display: 'Priority'
                    },
                    {
                        name: 'name',
                        width: '55%',
                        borderRight: border,
                        display: 'Name'
                    },
                    {
                        name: 'tickets',
                        width: '15%',
                        borderRight: border,
                        display: 'Tickets'
                    },
                    {
                        name: 'score',
                        width: '15%',
                        borderRight: 'none',
                        display: 'Score'
                    }
                ];

                self.sorts = [
                    {
                        name: 'nameAsc',
                        display: 'Name, ascending'
                    },
                    {
                        name: 'ticketsDesc',
                        display: 'Tickets, descending'
                    },
                    {
                        name: 'scoreAsc',
                        display: 'Score, ascending'
                    },
                    {
                        name: 'scoreDesc',
                        display: 'Score, descending'
                    }
                ];

                Tickets.getTicketStatusesOld().then(
                    function (response) {
                        console.log('Getting ticket statuses');
                        self.freshdeskStatuses = response;
                    }
                );

                Tickets.getTicketTypesOld().then(
                    function (response) {
                        console.log('Getting ticket types');
                        self.ticketTypes = response;
                    }
                );

                self.priorities = [
                    {
                        name: 'All Priorities'
                    },
                    {
                        name: 'Low'
                    },
                    {
                        name: 'Medium'
                    },
                    {
                        name: 'High'
                    },
                    {
                        name: 'Urgent'
                    },
                    {
                        name: 'High & Urgent'
                    }
                ];

                self.showTypes = [
                    {
                        name: 'Ticket Score'
                    },
                    {
                        name: 'Customer Question'
                    },
                    {
                        name: 'Support Question'
                    },
                    {
                        name: 'Owned by Other'
                    },
                    {
                        name: 'Owned by FRT'
                    },
                    {
                        name: 'Opened by Account Manager'
                    }
                ];

                //filters as set on the webpage
                self.filter = {
                    SortBy: 'ticketsDesc',
                    FreshdeskStatus: 'All Unresolved',
                    TicketType: 'All Types',
                    Priority: 'All Priorities',
                    Days: '',
                    Touch: '',
                    Tags: 'Unimplemented',
                    Text: '',
                    ShowType: 'Ticket Score',
                    FilterWithShow: false
                };

                //filters applied to current data; JSON conversion for deep copy
                self.dataFilter = JSON.parse(JSON.stringify(self.filter));

                self.SetSortBy = function (sortByType) {
                    self.filter.SortBy = sortByType;

                    if (sortByType == 'nameAsc') {
                        for (var team in self.teams) {
                            self.teams[team].teamCompanies.sort(function (a, b) {
                                if (a.name < b.name) {
                                    return -1;
                                } else if (a.name > b.name) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            });
                        }
                    } else if (sortByType == 'ticketsDesc') {
                        for (var team in self.teams) {
                            self.teams[team].teamCompanies.sort(function (a, b) {
                                var compare = b.tickets - a.tickets;

                                if (compare != 0) {
                                    return compare;
                                } else {
                                    if (a.name < b.name) {
                                        return -1;
                                    } else if (a.name > b.name) {
                                        return 1;
                                    }
                                }
                            });
                        };
                    } else if (sortByType == 'scoreAsc') {
                        for (var team in self.teams) {
                            self.teams[team].teamCompanies.sort(function (a, b) {
                                // If company has no tickets, push the company's ticket score to the bottom of list using a high score
                                var score_a = ! (self.filter.ShowType == 'Ticket Score' && a.tickets == 0) ? a.score : 99;
                                var score_b = ! (self.filter.ShowType == 'Ticket Score' && b.tickets == 0) ? b.score : 99;

                                var compare = score_a - score_b;

                                if (compare != 0) {
                                    return compare;
                                } else {
                                    if (self.filter.ShowType == 'Ticket Score' && (a.tickets == 0 ^ b.tickets == 0) ) {
                                        return a.tickets - b.tickets                // sort by the number of tickets
                                    }
                                    return a.name < b.name ? -1 : 1;                //sort by name asc
                                }
                            });
                        };
                    } else if (sortByType == 'scoreDesc') {
                        for (var team in self.teams) {
                            self.teams[team].teamCompanies.sort(function (a, b) {
                                var score_a = ! (self.filter.ShowType == 'Ticket Score' && a.tickets == 0) ? a.score : -99;
                                var score_b = ! (self.filter.ShowType == 'Ticket Score' && b.tickets == 0) ? b.score : -99;

                                var compare = score_b - score_a;

                                if (compare != 0) {
                                    return compare;
                                } else {
                                    if (self.filter.ShowType == 'Ticket Score' && (a.tickets == 0 ^ b.tickets == 0)) {
                                        return a.tickets - b.tickets                // sort by the number of tickets
                                    }
                                    return a.name < b.name ? -1 : 1;                //sort by name asc
                                }
                            });
                        };
                    }
                }

                self.SetFreshdeskStatus = function (status) {
                    self.filter.FreshdeskStatus = status;
                }

                self.SetTicketType = function (type) {
                    self.filter.TicketType = type;
                }

                self.SetPriority = function (priority) {
                    self.filter.Priority = priority;
                }

                self.SetShowType = function (show) {
                    self.filter.ShowType = show;

                    if (show == 'Ticket Score') {
                        self.filter.FilterWithShow = false;
                    } else {
                        self.filter.FilterWithShow = true;
                    }
                }

                self.sortDropdownStyle = function (sortName) {
                    var backgroundColor = 'unset';
                    var fontWeight = 'unset';

                    if (sortName == self.filter.SortBy || sortName == self.filter.FreshdeskStatus || sortName == self.filter.TicketType || sortName == self.filter.Priority || sortName == self.filter.ShowType) {
                        backgroundColor = 'rgba(75, 25, 140, 0.35)';
                        fontWeight = 'bold';
                    }

                    var styleObj = {
                        "background-color": backgroundColor,
                        "font-weight": fontWeight
                    };
                    return styleObj;
                }

                self.GetSortName = function () {
                    if (self.filter.SortBy == 'nameAsc') {
                        return 'Name, ascending';
                    } else if (self.filter.SortBy == 'ticketsDesc') {
                        return 'Tickets, descending';
                    } else if (self.filter.SortBy == 'scoreAsc') {
                        return 'Score, ascending';
                    } else if (self.filter.SortBy == 'scoreDesc') {
                        return 'Score, descending';
                    }
                    return '';
                }

                Tickets.getTeamsTicketsOld(self.filter).then(
                    function (response) {
                        console.log('Getting teams');
                        self.teams = response;
                        console.log(self.teams);
                        self.unassignedAgents = self.teams['Unassigned'];
                        self.swatAgents = self.teams['.SWAT'];
                        self.extraAgents = self.unassignedAgents.agentTickets.concat(self.swatAgents.agentTickets);

                        var unassignedCount = self.unassignedAgents.agentTickets.length;
                        var swatCount = self.swatAgents.agentTickets.length;
                        self.otherScore = (unassignedCount * self.unassignedAgents.score + swatCount * self.swatAgents.score) / (unassignedCount + swatCount);

                        self.unassignedCompany = self.unassignedAgents.teamCompanies[0];
                        self.dataFilter = JSON.parse(JSON.stringify(self.filter));
                        self.setCookies();
                        self.getTotal();
                    }
                );

                self.applyFilters = function () {
                    Tickets.getTeamsTicketsOld(self.filter).then(
                        function (response) {
                            console.log('Updating teams');
                            self.teams = response;
                            console.log(self.teams);
                            self.SetSortBy(self.filter.SortBy);
                            self.dataFilter = JSON.parse(JSON.stringify(self.filter));
                            self.setCookies();
                            self.getTotal();
                        }
                    );
                }

                self.resetFilters = function () {
                    self.SetSortBy('ticketsDesc');
                    self.filter.FreshdeskStatus = 'All Unresolved';
                    self.filter.TicketType = 'All Types';
                    self.filter.Priority = 'All Priorities';
                    self.filter.Days = '';
                    self.filter.Touch = '';
                    self.filter.Tags = 'Unimplemented';
                    self.filter.Text = '';
                    self.filter.ShowType = 'Ticket Score';
                    self.filter.FilterWithShow = false;
                }

                self.setCookies = function () {
                    localStorage.setItem('CustomerboardFilters', JSON.stringify(self.dataFilter));
                }

                self.getTotal = function () {
                    self.TotalOpenTickets = Object.values(self.teams)[0].TotalOpenTickets;
                    self.TotalOpenTicketScore = Object.values(self.teams)[0].TotalOpenTicketScore;
                }
            }

            return viewModel;
        }
    ]);