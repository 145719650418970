"use strict";

angular
  .module("wrapper")
  .config([
    "$urlRouterProvider",
    "navigationServiceProvider",
    "appStateConfigProvider",
    "$translateProvider",
    function(
      $urlRouterProvider,
      navigationServiceProvider,
      appStateConfigProvider,
      $translateProvider
    ) {
      $translateProvider.useSanitizeValueStrategy("sanitize");
      $urlRouterProvider.otherwise("/");

      navigationServiceProvider.unregister(
        "membership_user_my_account.personalInfo"
      );
      navigationServiceProvider.unregister("membership_usermanager");
      navigationServiceProvider.unregister("magview_TimeManagement.workTime");

      navigationServiceProvider.register({
        priority: 4,
        state: "magtacDashboard",
        name: "Status"
      });
      navigationServiceProvider.register({
        priority: 5,
        state: "membership_user",
        name: "Admin",
        children: [
          {
            state: "membership_user",
            name: "membership.sysAdminMenu.users"
          },
          {
            state: "membership_role",
            name: "membership.sysAdminMenu.roles"
          },
          {
            state: "membership_customerUserList",
            name: "Employees"
          },
          {
            state: "server_deletion",
            name: "Server Deletion"
          },
          {
            state: "magview_TimeManagement.workTime",
            name: "Time Management"
          },
          {
            state: "magtacLibraries",
            name: "MagTac Libraries"
          },
          {
            state: "magTacBulkTasksManagement",
            name: "MagTac default tasks configuration"
          },
          {
            state: "tickets_admin",
            name: "Ticket Assignment Audit"
          }
        ]
      });
    }
  ])
  .run([
    "common.config",
    function(commonConfig) {
      commonConfig.AnnouncementsBoardId = "56d98b1140b8b8a668f353c5";
    }
  ]);
