'use strict';

angular.module('common')
  .directive('onBlur', [function () {
    return {
      restrict : 'A',
      scope: {
        onBlur: '&'
      },
      link: function (scope, element, attrs) {
        element.on('blur', function (e) {
          scope.onBlur()();
        });

      }
    };
  }
  ]);