'use strict';

angular.module('magtac')
  .controller('IgnoreRuleDialogCtrl', [
    '$scope', '$modalInstance',
    function ($scope, $modalInstance) {
      $scope.model = {
        notes: ''
      };
      
      $scope.cancel = function() {
        $modalInstance.destroy();
      }

      $scope.ok = function() {
        $modalInstance.close($scope.model.notes);
      }
    }
  ]);
