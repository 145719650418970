'use strict';

angular.module('magview')
    .service('PrefsViewModel', ['magview.config', 'Prefs', 'modalDialogService', '$window',
        function (config, Prefs, modalDialogService, $window) {

            var escapeHtml = function (unsafe) {
                if (!unsafe) {
                    return "";
                }
                return unsafe
                    .replace(/&/g, "&amp;")
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;")
                    .replace(/"/g, "&quot;")
                    .replace(/'/g, "&#039;");
            };

            var viewModel = function () {

                var self = this;

                self.syncPrefs = async function () {
                    console.log('syncing prefs');

                    const result = await modalDialogService.showConfirmation(`
                            Please confirm that you would like to sync prefs. This will not delete existing prefs, only add and modify.
                            \n The sync requires that an up-to-date prefs.csv can be found in modules\\magview\\server\\MagTac.Api\\file_drop
                            \n This action may take several minutes.`, 'Confirm Pref Sync', 'dialog - danger'
                    );

                    if (result) {
                        console.log(result);

                        await Prefs.syncPrefs();
                        modalDialogService.showAlert(`
                            Prefs synced successfully.`, 'Pref Sync Confirmation', 'dialog - success'
                        ).then(() => {
                            $window.location.reload();
                        });
                    } else {
                        console.log("Confirmation rejected");
                    }
                };

                Prefs.getPrefData().then(
                    function (response) {
                        console.log('Getting test data');
                        self.dataSet = response;
                    }
                ).then(
                    function () {
                        var table = $('#prefs').DataTable({
                            data: self.dataSet,
                            columns: [
                                {
                                    title: "Name",
                                    render: function (name, type, data) {
                                        return "<a target='_blank' href='/prefs/" + name.trim() + "/general'>" + name + "</div>";
                                    }
                                },
                                {
                                    title: 'Default Value',
                                    "width": "20%",
                                    render: function (name, type, data) {
                                        return "<div style='word-break: break-all;'>" + data[1] + "</div>";
                                    }
                                },
                                {
                                    title: "Date Added",
                                    render: function (name, type, data) {
                                        return data[2];
                                    }
                                },
                                {
                                    title: "Description",
                                    render: function (name, type, data) {
                                        let descriptionSansSpecialChars = escapeHtml(data[3]);
                                        let description = descriptionSansSpecialChars.replace(/!br!/g, '<br>');
                                        return description;
                                    }
                                },
                            ],
                            searching: false
                        });

                        $('#customSearchBox').on('keyup', function () {
                            // this is custom search logic. It overrides the default datatables behavior.

                            let searchTerm = $(this).val().toLowerCase();
                            
                            let wordsToSearch = searchTerm.split(' ').filter(function (term) {
                                return !term.startsWith('-');
                            });

                            let termsToExclude = searchTerm.split(' ').filter(function (term) {
                                return term.startsWith('-');
                            }).map(function (term) {
                                return term.substring(1); // we remove the first character because it is always a hyphen
                            });

                            let filteredData = self.dataSet.filter(function (row) {
                                let rowData = row.join(' ').toLowerCase();

                                let containsAllTerms = wordsToSearch.every(function (term) {
                                    return rowData.includes(term);
                                });
                                
                                let containsExcludedWord = termsToExclude.some(function (term) {
                                    return rowData.includes(term);
                                });
                               
                                return containsAllTerms && !containsExcludedWord;
                            });
                            
                          
                            // Clear the table and add the filtered data
                            table.clear();
                            table.rows.add(filteredData);
                            table.draw();
                        });
                    }
                );
            };

            return viewModel;
        }
    ]);
