'use strict';

angular.module('membership')
  .service('SearchViewModel', [
    '$state', /*'Status',*/ 'Customer', 'modalService', 'focus', 'membership.config', 'connectionInfoViewModel', 'version',
    function ($state, /*Status,*/ Customer, modalService, focus, config, connectionInfoViewModel, version) {

      var viewModel = function () {

        var self = this;

        self.closedFilter = 3;
        self.openFilter = 1;

        self.version = version;

        self.selectedType = 'customers';
        self.customers = [];
        self.customerId = null;
        Customer.namesList().then(function (resp) {
          self.customers = resp;
        });

        self.showConnectioninfo = function () {
          modalService.showWindow('components/membership/customer/connectionInfo/connection-info-window.html', new connectionInfoViewModel(self.selectedCustomer.Id));
        };

        /*self.customersAutocompleteOptions = {
          searchInputPlaceholder: 'Type Customer Name',
          allowClear: true,
          placeholderOption: function () {
            return angular.element('<option value="__"></option>');
          },
          formatSelection: function (data, container, escapeMarkup) {

            self.statusInfo = null;

            if (self.customerId) {
              focus('goBtnFocus');

              Status.latest(self.customerId).then(function (resp) {
                self.statusInfo = resp;
              });
            }
            return data ? escapeMarkup(data.text) : undefined;
          },
          dropdownCssClass: 'select2-big home-page-custom'
        };*/

        var globalTermVar;

        self.customersAutocompleteOptions = {
          placeholder: "Type Customer Name",
          dropdownCssClass: 'select2-big home-page-custom',
          allowClear: true,
          ajax: {
            url: config.apiUrls.base + "/customers/autocompleteWithStatus",
            quietMillis: 100,
            data: function (term, page) {
              globalTermVar = term;
              return {
                q: term,
                page_limit: 20 + 1,
                page: page
              };
            },
            results: function (data, page, a, b) {
              var more = false;
              if (data.length > 20) {
                data.pop();
                more = true;
              } else if (!globalTermVar && page == 1) {
                more = true;
              }
              return { results: data, more: more };
            }
          },
          initSelection: function (element, callback) {
          },
          formatResult: function (item) {
            return item.Value;
          },
          formatSelection: function (item) {
            if (self.selectedCustomer) {
              self.statusInfo = null;
              self.statusInfoUpdateTime = null;
              self.isStatusExpired = null;
              if (self.selectedCustomer.Id == -1) {
                $state.go('membership_customerList');
              } else {
                focus('goCustomerBtnFocus');
                if (self.selectedCustomer.Status) {
                  self.statusInfo = self.selectedCustomer.Status;
                  self.statusInfoUpdateTime = new Date(self.selectedCustomer.StatusLastUpdated);
                  self.isStatusExpired = self.selectedCustomer.IsStatusExpired;
                }
              }
            }
            return item.Value;
          },
          id: function (item) {
            return item.Id;
          }
        };

        self.usersAutocompleteOptions = {
          placeholder: "Type User Name",
          dropdownCssClass: 'select2-big home-page-custom',
          allowClear: true,
          ajax: {
            url: config.apiUrls.base + "/user/autocomplete",
            quietMillis: 100,
            data: function (term, page) {
              return {
                q: term,
                page_limit: 20 + 1,
                page: page
              };
            },
            results: function (data, page) {
              var more = false;
              if (data.length > 20) {
                data.pop();
                more = true;
              }
              return { results: data, more: more };
            }
          },
          initSelection: function (element, callback) {
          },
          formatResult: function (item) {
            return item.FirstName + ' ' + item.LastName;
          },
          formatSelection: function (item) {
            if (self.selectedUser) {
              if (self.selectedUser.Id == -1) {
                $state.go('membership_customerUserList');
              } else {
                focus('goUserBtnFocus');
              }
            }
            return item.FirstName + ' ' + item.LastName;
          },
          id: function (item) {
            return item.Id;
          }
        };
      };

      return viewModel;

    }
  ]);
