'use strict';

angular.module('projectsManagement')
  .controller('ProjectsListCtrl', [
    '$scope', 'projectsListViewModel', '$state',
    function ($scope, viewModel, $state) {
      $scope.model = new viewModel($state.params.customerId);
      $scope.toggleOpen = function() {
        $scope.model.filterOpen = !$scope.model.filterOpen;
      }
    }
  ]);
