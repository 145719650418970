'use strict';

angular.module('magview')
  .service('Prefs', [
    'AjaxService', 'magview.config',
    function (ajaxService, config) {
      var baseUrl = config.apiUrls.base;
      var domain = {
        getPrefData: function () {
            return ajaxService.get(baseUrl + "/prefs/GetFormattedPrefs");
          },
        getPrefDetails: function (prefName) {
            return ajaxService.get(baseUrl + "/prefs/" + prefName);
          },
        notes: function (prefName) {
            return ajaxService.get(baseUrl + '/prefs/' + prefName + '/notes');
        },
        saveNote: function (item) {
              return ajaxService.post(baseUrl + '/saveNote', item);
          },
        saveDescription: function (dto) {
            return ajaxService.post(baseUrl + '/saveDescription', dto);
        },
        deleteNote: function (item) {
              return ajaxService.post(baseUrl + '/deleteNote', item);
          },
        syncPrefs: function (item) {
            return ajaxService.post(baseUrl + '/prefs/syncPrefs');
        },
      };

      return domain;
    }
  ]);