/**
 * Created on 10/2/2015.
 */
angular.module('membership')
  .factory('ClientCompanyInfoViewModel', [
    'ClientCompanyDomain',
    'ClientCompanyUiService',
    'toastr',
    'translationSvc',
    function (ClientCompanyDomain, membershipClientCompanyUiService, toastr, translationSvc) {
      function ClientCompanyInfoViewModel(clientCompanyId) {
        var self = this;
        self.clientCompanyId = clientCompanyId;
        self.data = {};
        self.isLoading = false;

        self.loadData = function () {
          self.isLoading = true;
          ClientCompanyDomain.getById(self.clientCompanyId).then(self.onDataLoaded.bind(self), self.onDataLoadError.bind(self));
        };

        self.editClientCompanyInfo = function () {
          membershipClientCompanyUiService.editClientCompany(self.clientCompanyId).then(self.loadData);
        };

        self.onDataLoaded = function (data) {
          self.data = data;
          self.isLoading = false;
        };

        self.onDataLoadError = function (error) {
          toastr.error(translationSvc.t('membership.msg.errorLoadingClientCompany', 'membership.lbl.loadingClientCompany'));
          self.isLoading = false;
        };

        self.loadData();
      }

      return ClientCompanyInfoViewModel;
    }
  ]);
