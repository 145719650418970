'use strict';

angular.module('membership')
  .service('customerContactsViewModel', [
    'Customer', 'modalService', 'contactDetailsViewModel',
    function (Customer, modalService, contactDetailsViewModel) {

      var viewModel = function (company) {

        var self = this;

        self.reload = function () {
          Customer.contacts(company.Id).then(function (resp) {
            company.contacts = resp;
            self.contacts = resp;
          });
        };

        if (!company.contacts) {
          self.reload();
        } else {
          self.contacts = company.contacts;
        }

        self.edit = function (item) {
          var detailsViewModel = new contactDetailsViewModel(item || { CompanyId: company.Id });
          detailsViewModel.on('save', function () {
            self.reload();
          });
          detailsViewModel.on('delete', function (params) {
            self.reload();
          });
          modalService.showWindow('components/membership/customer/contacts/contact-details.html', detailsViewModel);
        };

        self.delete = function (item) {
          Customer.deleteContact(item).then(function () {
            self.reload();
          });
        };
      };

      return viewModel;

    }
  ]);
