'use strict';

angular.module('projectsManagement')
  .service('projectDetailsGeneralViewModel', [
    'Projects', 'modalDialogService', '$stateParams', '$state',
    function(Projects, modalDialogService, $stateParams, $state) {

      var viewModel = function(project) {

        var self = this;

        self.projectId = project.Id;
        self.project = project;
        self.initialized = false;
        self.statuses = [
          'New', 'Assigned', 'In Progress', 'Live', 'On Hold', 'Canceled'
        ];
        self.projectsTypes = [{id: 'IMP', label: 'Improvement'}, {id: 'ADDON', label: 'Add-on'}];

        Projects.descriptions().then(function(descriptionsList) {
          self.descriptionsList = descriptionsList;
        });

        self.init = function() {
          if (self.initialized) {
            return;
          }

          self.initialized = true;
          Projects.byCustomer(self.project.CompanyId).then(function(projectsList) {
            self.projectsList = projectsList;
          });
        }
              
        self.isChildPage = function() {
          return !!$stateParams.childId;
        }     

        self.getChildId = function() {
          return $stateParams.childId;
        }

        self.getProjectType = function() {
          if (!self.project || !self.project.Specific) {
            return null;
          }
          
          return (_.findWhere(self.projectsTypes, { id: self.project.Specific.project_type }) || {}).label;          
        }

        self.startEdit = function() {
          // self.isEditMode = true;
          // self.projectEditModel = angular.copy(self.project);
          // if(!self.projectEditModel.Value){
          //   self.projectEditModel.Value = 0;
          // }
          self.projectEditModel = angular.copy(self.project);
          self.parentObject = {Id: self.projectEditModel.ParentId};
          modalDialogService
              .showWindow('components/projectsManagement/newProjectDialog/newProjectDialog.tpl.html', 
                          'NewProjectCtrl', 
                          { $$parent: self.parentObject, $$companyId: self.projectEditModel.CompanyId, $$project: self.projectEditModel })
              .then(function(resp){
                $state.reload();
              });
        };

        self.save = function() {
          Projects.update(self.projectEditModel).then(function(){
            self.isEditMode = false;
            angular.copy(self.projectEditModel, self.project);
            self.project.Specific.lastupdate = new Date();
          }, function() {
            modalDialogService.showAlert('Failed to update project');
          });
          
        };

        self.cancel = function() {
          self.isEditMode = false;
        };

        self.deleteProject = function(projectId) {
          modalDialogService.showConfirmation('Are you sure you want to delete this project?', 'Projects Module')
            .then(function(res) {
              if (res) {
                Projects.delete(projectId).then(function() {
                  if (!self.isChildPage()) {
                    $state.go('projects_list');
                    return;
                  }
                  
                    $state.go('projects_list');
                }, function(err) {
                  var message = "Unable to delete project";
                  if (err.status == 400) {
                    message = err.data.Message;
                  }
                  modalDialogService.showAlert(message, 'Projects Module');
                });
              }
            });
        };

        self.currencyValueChanged = function(){
          if(!self.projectEditModel.Value){
            self.projectEditModel.Value = 0;
          }
        };

        self.currencyNumberAdded = function(value){
          if(self.projectEditModel.Value == '0.00'){
            self.projectEditModel.Value = value;
          }
        }
      };

      return viewModel;

    }
  ]);
