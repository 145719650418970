'use strict';

angular.module('magview')
    .service('prefNotesDetailsViewModel', ['Prefs',
        function (Prefs) {

            var viewModel = function (item) {

                var self = this;

                self.listeners = {};
                self.item = item || {};

                self.on = function (action, callBack) {
                    self.listeners[action] = callBack;
                };

                self.notify = function (action, params) {
                    if (self.listeners[action]) {
                        self.listeners[action](params);
                    }
                };

                self.save = function () {
                    Prefs.saveNote(self.item).then(function () {
                        self.notify('save');
                    });
                };

                self.delete = function () {
                    Prefs.deleteNote(self.item).then(function () {
                        self.notify('delete', { Id: self.item.Id });
                    });
                };

            };

            return viewModel;

        }
    ]);
