'use strict';

angular.module('magtac')
  .controller('StatusConfigCtrl', [
    '$scope', 'Status', '$stateParams',
    function ($scope, Status, $stateParams) {

      Status.magtacSettings($stateParams.key).then(function(resp) {
        $scope.settings = resp;
      });
    }
  ]);
