'use strict';

angular.module('membership')
  .service('companyDetailsViewModel', [
    'ManageCompanies',
    'modalDialogService',
    '$state',
    function (manageCompanies,
              modalSvc,
              $state) {

      var viewModel = {
        editMode: false,
        isNew: false,
        previousState: 'membership_company',
        init: function (id) {
          var self = this;
          if (id == "create") {
            self.editMode = true,
              self.isNew = true,
              self.company = {};
          } else {
            self.editMode = false,
              self.isNew = false,
              manageCompanies.get(id, function (company) {
                self.company = company;
              });
          }
          /*AppSettings.getThemes(function (data) {
           self.themes = data;
           });*/
        },

        startEditing: function () {
          this.editMode = true;
          this.backup = angular.copy(this.company);
        },

        saveChanges: function () {
          var self = this;
          if (this.editMode) {
            this.editMode = false;
            manageCompanies.saveCompany(self.company, function (data) {
              self.editMode = false;
              modalSvc.showAlert({
                headerText: "Edit Company", bodyText: "Company successfully updated"
              });
            });
            /*manageCompanies.setCompanyName(self.company.CompanyName, self.company.Id, function () {
             manageCompanies.setCompanyLogo(self.company.LogoUrl, self.company.Id, function () {
             self.init(self.company.Id);
             });
             });*/
          }
        },

        discardChanges: function () {
          if (this.editMode) {
            this.editMode = false;
            this.company = this.backup;
          }
        },

        removeCompany: function () {

        },

        goBack: function () {
          var self = this;
          $state.go(self.previousPage, self.previousParams);
        },

        createCompany: function () {
          var self = this;
          manageCompanies.createCompany(self.company, function (data) {
            modalSvc.showAlert("Company successfully created", "Company Creation").then(function () {
              self.goBack();
            });
          });
        }
      };

      return viewModel;
    }]);
