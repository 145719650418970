"use strict";

angular.module("ticketAssignment").service("assignmentListViewModel", [
  "ticketAssignment.config", "$filter",
  "ticketAdmin",
  function(config, $filter, Tickets) {
    var assignmentListViewModel = function() {
      var self = this;

      Tickets.getAssignedAgentLookups().then(function (resp) {
        self.users = resp;
      })

      Tickets.getAssignedCompanyLookups().then(function (resp) {
        self.customers = resp;
      })

      self.filter = {};

      self.datatablesConfig = {
        datasourceUrl: config.apiUrls.base + "/datasource/assignment",
        defaultSortingColumn: "0",
        defaultSortingOrder: "desc",
        dtColumns: [
          {
            // Id
            bSearchable: false,
            bVisible: true,
            aTargets: [0]
          },
          {
            // External Id
            bSearchable: false,
            bVisible: false,
            aTargets: [1]
          },
          {
            // Support Ticket Id
            bSearchable: true,
            bVisible: false,
            aTargets: [2]
          },
          {
            // Event Type
            aTargets: [3],
            sClass: "dynamic-html no-wrap",
            render: function(data, type, full, meta) {
              return (
                "<a ui-sref='tickets_audit_details({id: " +
                full[0] +
                "})' >" +
                data +
                "</a>"
              );
            }
          },
          {
            // Event Datetime
            bSearchable: false,
            aTargets: [4],
            "render": function (data, type, full, meta) {
              var date = new Date(data);
              if (!date) {
                return data;
              }
              return $filter('date')(date, 'M/d/yyyy h:mm:ss a');
            }
          },
          {
            // Ticket Title
            bSearchable: true,
            aTargets: [5],
            render: function(data, type, full, meta) {
              return (
                '<a href="https://magview.freshdesk.com/a/tickets/' +
                full[1] +
                '" >' +
                data +
                " </a>"
              );
            }
          },
          {
            // Customer Name
            bSearchable: false,
            aTargets: [6]
          },
          {
            // Assignee
            bSearchable: true,
            aTargets: [7]
          }

          // ,
          // {
          //     aTargets: [3],
          //     "sClass": "dynamic-html no-wrap",
          //     render: function (data, type, full, meta) {
          //         return "<a ui-sref='membership_customerUser.userProfile.general({key: " + full[0] + "})' >" + data + "</a>";
          //     }
          // },
          // {
          //     "bSearchable": false,
          //     "bVisible": false,
          //     "aTargets": [4]
          // },
          // {
          //     "bSearchable": false,
          //     "bVisible": false,
          //     "aTargets": [5]
          // },
          // {
          //     "bSearchable": false,
          //     "bVisible": false,
          //     "aTargets": [6]
          // }

          // ,
          // {
          //     "aTargets": -1,
          //     "bSearchable": false,
          //     "bSortable": false,
          //     "data": null,
          //     "sWidth": 50,
          //     "sClass": "actions-col dynamic-html no-wrap",
          //     "render": function (data, type, full, meta) {
          //         var buttons = [
          //             //"<a class='btn btn-success' ui-sref='membership_customerUser.userProfile.general({key: " + data[0] + "})' title='Details' ><i class='fa fa-eye'></i></a>"
          //         ];

          //         // if (authorizeService.isFeatureAllowed(config.permissions.activateDeactivateUsers)) {
          //         //   if (data[8] === "True") {
          //         //     buttons.push("<a class='btn btn-danger' ng-click='model.deactivateUser(" + data[0] + ")' title='Deactivate User' ><i class='fa fa-ban'></i></a>");
          //         //   } else {
          //         //     buttons.push("<a class='btn btn-info' ng-click='model.reactivateUser(" + data[0] + ")' title='Reactivate User' ><i class='fa fa-check'></i></a>");
          //         //   }
          //         // }
          //         // if (authorizeService.isFeatureAllowed('/sysadmin') || authorizeService.isFeatureAllowed(config.permissions.impersonateUsers)) {
          //         //   buttons.push("<a class='btn btn-danger' ng-click='$root.impersonateUser(" + data[0] + ")' title='Impersonate User' ><i class='fa fa-exchange'></i></a>");
          //         // }

          //         return buttons.join('&nbsp;');
          //     }
          // }
        ],
        customFilter: self.filter,
        dom:
          "<'row'<'col-xs-12 col-sm-12'r>>" +
          "t" +
          "<'row'<'col-sm-2 col-xs-12 hidden-xs'l><'col-sm-4 col-xs-12 hidden-xs'<'pull-right'i>><'col-xs-12 col-sm-6'p>>"
      };

      self.userChanged = function () {
        console.log(self.filter)
      }

    };



    return assignmentListViewModel;
  }
]);
