/**
 * Created on 10/8/2015.
 */
angular.module('common')
  .service('translationSvc', [
    '$translate',
    function ($translate) {
      $translate.instant('common.lbl'); // hook to translate service to make sure it always has data
      return {
        t: function (key, option) {
          return $translate.instant(key, option);
        },
        t_async: function (key, option) {
          return $translate(key, option);
        },
        useLanguage: function (language) {
          $translate.use(language);
        }
      }
    }
  ]);
