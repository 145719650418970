'use strict';

angular.module('membership')
  .service('modifyAgentViewModel', [
    'Ownership', 'modalDialogService', '$stateParams', '$state',
    function(ownership, modalDialogService, $stateParams, $state) {

      var viewModel = function(modalInstance, selectedId, selectedName) {

        var self = this;
        self.displayedName = selectedName
        self.modifyData = {};
        self.modifyData.Id = selectedId
      
        
        self.modify = function () {
          ownership.modifyOwnership(self.modifyData, function (data) {
            modalInstance.close();
          });
        }
      };

      return viewModel;

    }
  ]);
