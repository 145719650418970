angular.module('common')
  .directive('leanPagination', [
    '$state', '$location',
    function ($state, $location) {
      return {
        restrict: "EA",
        templateUrl: function (element, attributes) {
          return attributes.templateUrl || "components/common/directives/pagination/pagination.tpl.html";
        },
        scope: {
          pageSize: "=",
          pageIndex: "=",
          totalItems: "=",
          pageChanged: "&"
        },
        link: function (scope, element, attribute) {
          if (isNaN(scope.pageIndex)) {
            scope.pageIndex = 0;
          }

          scope.$watch('pageSize', update, true);
          scope.$watch('pageIndex', update, true);
          scope.$watch('totalItems', update, true);

          function update() {
            scope.startPage = 0;

            scope.endPage = parseInt(scope.totalItems / scope.pageSize);

            if (scope.totalItems % scope.pageSize != 0) scope.endPage += 1;
          }

          scope.goBack = function () {
            if (scope.canGoBack()) {
              scope.pageIndex--;
              scope.goToPage(scope.pageIndex);
            }
          };

          scope.canGoBack = function () {
            return scope.pageIndex > 0;
          };

          scope.goNext = function () {
            if (scope.canGoNext()) {
              scope.pageIndex++;
              scope.goToPage(scope.pageIndex);
            }
          };

          scope.goToPage = function (page) {
            scope.pageIndex = page;
            var params = $state.params;
            params.pageIndex = scope.pageIndex;

            $location.search('pageIndex', scope.pageIndex);
            scope.pageChanged({$pageIndex: scope.pageIndex});
          };

          scope.canGoNext = function () {
            return scope.pageIndex < scope.endPage - 1;
          };
        }
      }
    }
  ]);
