/**
 * Created on 12/12/2015.
 */

'use strict';

angular.module('membership')
  .service('InvoiceService', [
    'AjaxService',
    'membership.config',
    function (ajaxService, membershipConfig) {
      var baseUrl = membershipConfig.apiUrls.base;
      var InvoiceService = {
        getForCurrentUser: function () {
          return ajaxService.singleGet(baseUrl + '/api/invoice/myinvoices');
        }
      };

      return InvoiceService;
    }
  ]);
