/**
 * Created on 10/10/2015.
 */
angular.module('common')
  .provider('appStateConfig', function () {
    var self = this;

    self.statesList = [];
    self.defaultPath = '/';

    self.addState = function (value) {
      self.statesList.push(value);
    };

    self.deleteState = function (stateName) {
      self.statesList = _.filter(self.statesList, function (state) {
        return state.name !== stateName;
      });
    };

    self.overrideStates = function (overrideDefinitions) {
      _.each(overrideDefinitions, function (overrideDefinition) {
        var page = _.find(self.statesList, function (item) {
          return item.name === overrideDefinition.name;
        });

        if (page) {
          _.each(overrideDefinition, function (value, key) {
            if (key !== 'name')
              page[key] = value;
          });
        }
      });
    };

    this.$get = function () {
      return {
        statesList: self.statesList,
        overrideStates: self.overrideStates,
        defaultPath: self.defaultPath
      };
    };

    return this;
  });
