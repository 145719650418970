/**
 * Created on 10/24/2015.
 */

'use strict';

angular.module('membership')
  .controller('AccountProfileController', [
    '$scope', 'accountProfileViewModel', '$userId',
    function ($scope, accountProfileViewModel, $userId) {
      $scope.vm = new accountProfileViewModel($userId);
    }
  ]);
