'use strict';

angular.module('navigation', [])
  .provider('navigationService', function () {
    var self = this;
    var items = [];
    self.register = function (stateItem) {
      items.push(stateItem);
      items = _.sortBy(items, 'priority');
    };
    self.unregister = function (stateToErase) {
      items = _.filter(items, function (x) { return x.state != stateToErase; });
    };

    self.$get = function () {
      return {
        register: self.register,
        unregister: self.unregister,
        items: items
      };
    };
    return self;
  });
