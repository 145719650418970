'use strict';

angular.module('magtac')
  .controller('CurrentStatusCtrl', [
    '$scope',
    function ($scope) {
      $scope.checkIfHtml = function(string){
        return /<[a-z][\s\S]*>/i.test(string);
      }
      $scope.getLocalTime = function(date){
        var timeZone = moment.tz(date, moment.tz.guess()).format('Z z');
        //var timeZoneCity = moment.tz.guess();
        return timeZone;
      }
    }
  ]);
