'use strict';

angular.module('magview')
    .service('prefNotesViewModel', ['Prefs' , 'modalService', 'prefNotesDetailsViewModel',
      function (Prefs, modalService, prefNotesDetailsViewModel) {

      var viewModel = function (pref) {

        var self = this;

        self.reload = function () {
            Prefs.notes(pref.Name).then(function (result) {

                result.forEach(function (n) {
                    n.DateAdded = n.DateAdded.split('T')[0];
                });
                pref.notes = result;
                self.notes = result;
          });
        };

          if (!pref.notes) {
          self.reload();
        } else {
          self.notes = pref.notes;
        }

        self.edit = function (item) {
            var detailsViewModel = new prefNotesDetailsViewModel(item || { PrefName: pref.Name });
          detailsViewModel.on('save', function () {
            self.reload();
          });
          detailsViewModel.on('delete', function (params) {
            self.reload();
          });
            modalService.showWindow('components/magview/prefs/prefDetails/notes/prefNotes-details.html', detailsViewModel);
        };

        self.delete = function (item) {
            Prefs.deleteNote(item).then(function () {
            self.reload();
          });
        };
      };

      return viewModel;

    }
  ]);
