'use strict';

angular.module('membership')
  .factory('userGroupDetailsViewModel', [
    '$state', 'UserGroup', 'membership.config', function ($state, UserGroup, config) {

      var viewModel = function (mode, groupId) {
        var self = this;
        
        self.action = mode;
        self.isAddMode = mode === 'create';
        self.isEditMode = mode === 'update';
        self.isNameInputDisabled = true;

        self.groupId = groupId;
        self.group = { Users: [] };

        switch (mode) {
          case 'create':
            break;
          case 'update':
            UserGroup.get(self.groupId, function (group) {
              self.group = group;
            });
            break;
        };

        self.insert = function () {
          UserGroup.insert(self.group, function (u) {
            $state.transitionTo('membership_usergroup');
          });
        };

        self.update = function () {
          UserGroup.update(self.groupId, self.group, function (u) {
            $state.transitionTo('membership_usergroup');
          });
        };

        self.save = function () {
          self.addUser();
          if (self.groupId) {
            self.update();
          } else {
            self.insert();
          }
        };

        self.remove = function (userId) {
          self.group.Users = _.filter(self.group.Users, function(x) {
            return x.Id != userId;
          });
        };

        self.addUser = function () {
          if (self.selectedUser) {
            var existingUser = _.find(self.group.Users, function (x) {
              return x.Id == self.selectedUser.Id;
            });
            if (!existingUser) {
              self.group.Users.push(self.selectedUser);
            }
            self.selectedUser = null;
          }
        };

        self.toggleNameInput = function(){
          self.isNameInputDisabled = !self.isNameInputDisabled;
        };

        var showPerPage = 10;
        self.autocompleteOptions = {
          width: 'element',
          placeholder: "Search for a user",
          minimumInputLength: 1,
          ajax: {
            url: config.apiUrls.base + "/user/autocomplete",
            quietMillis: 100,
            data: function (term, page) {
              return {
                q: term,
                page_limit: showPerPage + 1,
                page: page
              };
            },
            results: function (data, page) {
              var more = false;
              if (data.length > showPerPage) {
                data.pop();
                more = true;
              }
              return { results: data, more: more };
            }
          },
          initSelection: function (element, callback) {
            /*if (element.select2('data') === null) {
              callback(self.event.customer);
            }*/
          },
          formatResult: function (item) {

            if (!item.CompanyName || item.CompanyName == null || item.CompanyName == "null") {
              return item.LastName + ' ' + item.FirstName + ' / ' + item.Email + ' / ' + item.CellPhoneNumber;
            } else {
              return item.CompanyName + ' / ' + item.LastName + ' ' + item.FirstName + ' / ' + item.Email + ' / ' + item.CellPhoneNumber;
            }
          },
          formatSelection: function (item) {
            if (!item.CompanyName || item.CompanyName == null || item.CompanyName == "null") {
              return item.LastName + ' ' + item.FirstName + ' / ' + item.Email + ' / ' + item.CellPhoneNumber;
            } else {
              return item.CompanyName + ' / ' + item.LastName + ' ' + item.FirstName + ' / ' + item.Email + ' / ' + item.CellPhoneNumber;
            }
          },
          id: function (item) { return item.Id; },
          escapeMarkup: function (m) { return m; }
        };
      };

      return viewModel;
    }
  ]);