'use strict';

angular.module('projectsManagement')
  .factory('noteEditingViewModel', [
    'AjaxService',
    'notifySvc',
    'projectsManagement.config',
    function (AjaxService,
              notifySvc,
              config) {

      function noteEditingViewModel(masterId, noteId, $modalInstance) {
        var baseUrl = config.apiUrls.base + '/projectNotes';

        var self = this;

        self.noteId = noteId;

        self.data = {
          Summary: '',
          Details: ''
        };

        self.loadData = function () {
          AjaxService.get(baseUrl + '/' + self.noteId).then(function (data) {
            self.data = data;
          });
        };

        self.uploadUrl = config.apiUrls.base + '/image/upload';

        self.editorConfig = { 
          height: 280,
          toolbar: [
            ['Styles','Format','Font','FontSize'],
            '/',
            ['Bold','Italic','Underline','StrikeThrough','-','Undo','Redo','-','Cut','Copy','Paste','Find','Replace','-','Outdent','Indent','-','Print'],
            '/',
            ['NumberedList','BulletedList','-','JustifyLeft','JustifyCenter','JustifyRight','JustifyBlock'],
            ['Image','Table','-','Link','Flash','Smiley','TextColor','BGColor','Source']
          ] 
        };


        self.save = function () {
           if (self.noteId) {
            self.updateDocumentData();
            return;
          }

          AjaxService.post(baseUrl + '/?masterId=' + masterId, self.data).then(function (data) {
            self.onDataSavedSuccessfully();
          }, self.onError.bind(self, 'Error while saving note'));

        };

        self.updateDocumentData = function () {
          AjaxService.put(baseUrl, self.data).then(function (data) {
            self.onDataSavedSuccessfully();
          }, self.onError.bind(self, 'Error while saving note'));
        };

        self.onDataSavedSuccessfully = function () {
          notifySvc.success({
            msg: "Note saved successfully"
          });
          $modalInstance.close();
        };

        self.onError = function (title, error) {
          notifySvc.error({
            msg: error.data && error.data.ExceptionMessage ? error.data.ExceptionMessage : error.data || error,
            title: title
          });
        };

        if (self.noteId) {
          self.loadData();
          self.data = null;
        }
      }

      return noteEditingViewModel;
    }
  ])
;