'use strict';

angular.module('magtac')
    .service('MagTacCustomersWorkflowsViewModel', [
        'magtac.config', 'WorkflowRoutes',
        function (config, WorkflowRoutes) {

            var viewModel = function () {
                // todo
                //  make the ui of tabs dry in the html

                var self = this;

                self.selectedArea;
                self.selectedSection;
                self.selectedElement;
                self.selectedDetail;
                self.searchResultsPresent = false;

                self.areas = [
                    'Profile',
                    'Radiologist',
                    'Letters',
                    'Technologist',
                    'Tracking',
                    'Components',
                    'Environment',
                    'Status',
                    'Alerts',
                ]; 
                self.sections = []; 
                self.elements = []; 

                self.placeHolderText = "Search for workflow content";

                self.searchLevel = "All";
                self.searchCategory = "Profile";
                self.searchTerm = "";

                self.numericSearch = false;
                self.partialMatchSearch = false;

                self.populateDropdowns = function () {
                    WorkflowRoutes.getDropdownOptions().then(
                        function (response) {
                            self.groupedSections = response[0].Options; // sections grouped by which area they belong to
                            self.groupedElements = response[1].Options; // elements grouped by which section they belong to
                            self.groupedDetails = response[2].Options; // details grouped by which element they belong to
                        });
                }

                self.populateDropdowns();


                self.selectArea = function () {
                    if (!self.selectedArea) {
                        self.searchLevel = "All";
                        self.searchCategory = "Areas";

                        self.selectedSection = "";
                        self.selectedElement = "";
                        self.selectedDetail = "";
                    } else {
                        self.searchLevel = "Area";
                        self.searchCategory = self.selectedArea;
                    }
                    // find all the sections that belong to the selected Areas
                    self.sections = self.groupedSections[self.selectedArea];
                }

                self.selectSection = function () {
                    if (!self.selectedSection) {
                        self.searchLevel = "Area";
                        self.searchCategory = self.selectedArea;

                        self.selectedElement = "";
                        self.selectedDetail = "";
                    } else {
                        self.searchLevel = "Section";
                        self.searchCategory = self.selectedSection;
                    }
                    // find all the elements that belong to the selected sections
                    self.elements = self.groupedElements[self.selectedSection];
                }

                self.selectElement = function () {
                    if (!self.selectedElement) {
                        console.log('ALL: ' + self.selectedElement);
                        self.searchLevel = "Section";
                        self.searchCategory = self.selectedSection;

                        self.selectedDetail = "";
                    } else {
                        self.searchLevel = "Element";
                        self.searchCategory = self.selectedElement;
                    }

                    // find all the elements that belong to the selected sections
                    self.details = self.groupedDetails[self.selectedElement];
                }

                self.selectDetail = function () {
                    if (!self.selectedDetail) {
                        self.searchLevel = "Element";
                        self.searchCategory = self.selectedElement;
                    } else {
                        self.searchLevel = "Detail";
                        self.searchCategory = self.selectedDetail;
                    }

                }

                self.updateSearchPlaceholderText = function () {
                    if (self.placeHolderText == "Search for workflow content") {
                        self.placeHolderText = "Search using the format '>5 and < 10' or just '<100'";
                        $('.partial-match-search-checkbox').prop('disabled', true);
                        $('.partial-match-search').addClass('greyed-out');
                    } else {
                        self.placeHolderText = "Search for workflow content";
                        $('.partial-match-search-checkbox').prop('disabled', false);
                        $('.partial-match-search').removeClass('greyed-out');
                    }
                }


                self.exportToCsv = function () {
                    const headers = ["Company", "Content", "Version"];

                    if (!self.dataSet || self.dataSet.length === 0) {
                        alert('No data available to export.');
                        return;
                    }

                    const csvData = self.dataSet.map(item => {
                        const context = item.SearchResultMatch ? item.SearchResultMatch.replace(/<\/?[^>]+(>|$)/g, "") : "";
                        return [
                            `"${item.CompanyName}"`,
                            `"${item.SearchResultContext} ${context}"`,
                            `"${item.MagViewVersion}"`
                        ];
                    });

                    // Add headers
                    csvData.unshift(headers.map(header => `"${header}"`));
                    const csvContent = csvData.map(e => e.join(",")).join("\n");

                    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'workflow_search_results.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                };


                self.search = function () {
                    let searchTerm = self.searchTerm;
                    if (!searchTerm) {
                        searchTerm = "!123!NoSearchTermProvided!123!"; // this represents an unfindable string. Passing it in will return 0 results
                    }

                    // we need to check for special characters and replace them. Our server setup does not allow searches for "/", ">", or "<"
                    searchTerm = searchTerm.replace(/\//g, "!slash!");
                    searchTerm = searchTerm.replace(">", "!g!");
                    searchTerm = searchTerm.replace("<", "!l!");

                    WorkflowRoutes.getCustomerWorkflows(searchTerm, self.searchLevel, self.searchCategory, self.partialMatchSearch, self.numericSearch).then(
                        function (response) {
                            console.log('in view model --');
                            console.log(response);

                            if (typeof response === 'string') {
                                alert(response);
                            } else {
                                self.dataSet = response;
                                if (response.length > 0) {
                                    self.searchResultsPresent = true;
                                } else {
                                    self.searchResultsPresent = false;
                                }
                            }
                            

                            // Destroy the existing datatable. If we don't do this, it won't update, even though we retrieve the data
                            if ($.fn.DataTable.isDataTable('#workflowSearchResults')) {
                                $('#workflowSearchResults').DataTable().destroy();
                            }
                        }
                    ).then(
                        function () {
                            if (!$.fn.DataTable.isDataTable('#workflowSearchResults')) {
                                $('#workflowSearchResults').DataTable({
                                    data: self.dataSet,
                                    searching: false,
                                    lengthChange: false,
                                    pageLength: 20,
                                    columns: [
                                        {
                                            title: "Company",
                                            render: function (data, type, full, meta) {
                                                return "<a target='_blank' href='/customer/" + full.CompanyId + "/workflow'>" + full.CompanyName + "</div>";
                                            }  
                                        }, 
                                        {
                                            title: "Content",
                                            render: function (data, type, full, meta) {
                                                let context = full.SearchResultMatch;
                                                if (searchTerm && searchTerm !== "!123!NoSearchTermProvided!123!" && !self.numericSearch) {
                                                    const regex = new RegExp(`(${searchTerm})`, 'gi');
                                                    context = context.replace(regex, '<strong>$1</strong>');
                                                }
                                                return full.SearchResultContext + " " + context;
                                            }
                                        },
                                        {
                                            title: "Magview Version",
                                            render: function (data, type, full, meta) {
                                                return full.MagViewVersion;
                                            }
                                        }, 
                                    ],
                                });
                            }
                        }
                    );


                };

                self.dataSet = [];


            };

            return viewModel;

        }
    ]);
