/**
 * Created on 9/28/2015.
 */
angular.module('siteSettings')
  .provider('SiteSettingInstances', function () {
    this.siteSettings = {};
    var self = this;

    this.registerSetting = function (settingKey, setting) {
      if (self.siteSettings[settingKey])
        throw new Error("Setting with the provided name already exists");

      self.siteSettings[settingKey] = setting;
    };


    this.$get = [function () {
      self.siteSettings.registerSetting = function (settingKey, setting) {
        self.registerSetting(settingKey, setting);
      };
      return self.siteSettings;
    }];

    return this;
  });
