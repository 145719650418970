/**
 * Created on 12/7/2015.
 */

'use strict';

angular.module('siteSettings')
  .factory('SiteSettingsListViewModel', [
    'SiteSettingService',
    function (SiteSettingService) {
      function SiteSettingsListViewModel() {
        var self = this;

        self.availableSettings = SiteSettingService.getAvailableSettingRegistration();
      }

      return SiteSettingsListViewModel;
    }
  ]);
