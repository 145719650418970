'use strict';

angular.module('membership')
  .service('personalInfoViewModel', ['User', function (userDomain) {
    var viewModel = {
      permissions: [],
      user: null,
      userRoles: [],
      init: function(userId) {
        var self = this;
        userDomain.get(userId, function(user) {
          self.user = user;
        });
        userDomain.getRoles(userId, function(roles) {
          self.userRoles = roles;
        });
      },

      //advanced show hide
      advanced: false,
      toggleAdvanced: function() {
        this.advanced = !this.advanced;
      },
    };

    //datatables config 
    viewModel.dtColumns = [
      {
        "bSearchable": false,
        "bVisible": false,
        "aTargets": [0]
      },
      {
        "bSearchable": false,
        "aTargets": [2]
      }
    ];


    return viewModel;
  }]);