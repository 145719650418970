'use strict';

angular.module('membership')
  .controller('WorkflowCtrl', [
    '$scope', '$sce','workflowViewModel',
    function ($scope, $sce, viewModel) {
      $scope.model = new viewModel($scope.company);
      //console.log($scope);
      //initialize();
      $scope.renderHtml = function(html_code){
        return $sce.trustAsHtml(html_code);
      };

      //$scope.BindHtml = $sce.trustAsHtml($scope.model.workflow);
      console.log($scope);
    }
  ]);
