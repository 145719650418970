'use strict';

angular.module('ticketAssignment')
    .controller('companyTicketsControlModel', ['$scope', '$stateParams', 'companyTicketsViewModel',

        function ($scope, $stateParams, viewModel) {
            $scope.model = new viewModel($stateParams.id);

            $scope.get_ticket_link = function (TicketId) {
                return 'https://magview.freshdesk.com/a/tickets/' + TicketId;
            }

            $scope.get_team_link = function (teamName) {
                return '/team/' + teamName + '/tickets';
            }

            $scope.get_search_link = function (internalCustomerId) {
                return '/customer/' + internalCustomerId + '/general';
            }

            $scope.get_freshdesk_link = function (agentId) {
                if (agentId !== undefined && agentId !== null) {
                    return "https://magview.freshdesk.com/a/admin/agents/" + agentId;
                } else {
                    return "/";
                }
            }

            $scope.showDetails = function (agentId) {
                toggleDisplay(document.querySelectorAll(".note"));
            }

            $scope.shorten_note = function (noteText) {
                if (noteText != null) {
                    return noteText.substring(0, 120);
                }
                return noteText;
            }

            function toggleDisplay(notes) {

                for (var i = 0; i < notes.length; i++) {
                    if (notes[i].style.display === "none" || notes[i].style.display == null || notes[i].style.display == "") {
                        notes[i].style.display = "table-row";
                    } else {
                        notes[i].style.display = "none";
                    }
                }
            }
        }
    ]);