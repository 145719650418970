'use strict';

angular.module('ticketAssignment')
    .controller('AssignmentListCtrl', [
        '$scope', 'assignmentListViewModel',
        function ($scope, viewModel) {
            $scope.model = new viewModel();
            $scope.toggleOpen = function () {
                $scope.model.filterOpen = !$scope.model.filterOpen;
            };
            console.log($scope.model);
        }
    ]);