'use strict';

angular.module('membership')
  .service('customersListViewModel', [
    'membership.config', '$state', 'modalDialogService', 'Customer', /*'Status',*/
    function (config, $state, modalDialogService, Customer/*, Status*/) {

      var viewModel = function () {

        var self = this;

        self.filterOpen = false;
        self.filter = {};
        //self.customersStatuses = {};

        self.datatablesConfig = {
          datasourceUrl: config.apiUrls.base + '/customers/datasource',
          dtColumns: [
            {
              "bSearchable": false,
              "bVisible": false,
              "aTargets": [0]
            },
            {
              aTargets: [1],
              "sClass": "dynamic-html no-wrap",
              render: function (data, type, full, meta) {
                if (full[5] === 'True') {
                  return "<span>" + full[1] + "</span>";
                }

                return "<a ui-sref='membership_customer.general({key: " + full[0] + "})' >" + full[1] + "</a>";
              }
            },
            {
              "aTargets": [5],
              "render": function (data, type, full, meta) {
                var status = data;
                var statusClass = '';
                if (!status) {
                  statusClass = 'status-na';
                  status = 'N/A';
                } else if (status.indexOf('Success') >= 0) {
                  statusClass = 'btn-success'; 
                } else if (status.indexOf('Expired') >= 0) {
                  statusClass = 'btn-expired'; 
                } else if (status.indexOf('Warning') >= 0) {
                  statusClass = 'btn-warning';
                } else if (status.indexOf('Error') >= 0) {
                  statusClass = 'btn-danger';
                } else {
                  statusClass = 'btn-info';
                }
                return "<span class='btn status btn-xs " + statusClass + "'>" + status + "</span>";
              }
            },
            {
              "aTargets": -1,
              "bSearchable": false,
              "bSortable": false,
              "data": null,
              "sWidth": 50,
              "sClass": "actions-col dynamic-html no-wrap",
              "render": function (data, type, full, meta) {

                var buttons = [                  
                ];

                if (data[5] === 'True') {
                  buttons.push("<a class='btn btn-info' ng-click='model.revertCustomer(" + data[0] + ")' title='Restore' ><i class='fa fa-undo'></i></a>")
                }

                return buttons.join('&nbsp;');
              }
            }
          ],
          customFilter: self.filter,
          /*fnDrawCallback: function(settings) {
            var customers = _.map(settings.json.data, function(r){ return parseInt(r[0]); });

            Status.getStatuses(customers).then(function(resp){
              console.log(resp);
              self.customersStatuses = resp;
            });
          },*/
          dom: "<'row'<'col-xs-12 col-sm-12'r>>" +
          "t" +
          "<'row'<'col-sm-2 col-xs-12 hidden-xs'l><'col-sm-4 col-xs-12 hidden-xs'<'pull-right'i>><'col-xs-12 col-sm-6'p>>"

        };

        /*self.getCustomerStatusLabel = function(id) {
          return self.customersStatuses[id.toString()] || 'N/A';
        }*/

        /*self.getCustomerStatusCssClass = function(id) {
          var status = self.customersStatuses[id.toString()];
          if (!status) {
            return 'status-na';
          }

          return status == 'Error' ? 'btn-danger' : 'btn-success';
        }*/
        self.createCustomer = function () {
          modalDialogService
            .showWindow('components/membership/customer/newCustomerDialog/newCustomerDialog.tpl.html', 'NewCustomerCtrl');
        };

        self.revertCustomer = function (id) {
           modalDialogService.showConfirmation('Are you sure you want to revert this customer?', 'Customers Module')
            .then(function(res) {
              if (res) {
                Customer.revertCustomer(id).then(function() {
                  self.datatablesConfig.reload();
                });  
              }
            });
        };

      };


      return viewModel;

    }
  ]);
