'use strict';
angular.module('ticketAssignment')
    .service('activityReportViewModel', ['ticketAssignment.config', 'ticketAdmin', '$state',
        function (config, Tickets, $state) {
            var viewModel = function () {
                $(function () {
                    $('.dropdown').hover(function () {
                        $(this).addClass('open');
                    },
                        function () {
                            $(this).removeClass('open');
                        });
                });

                console.log('Loading activityReport view model');
                var self = this;
                self.agentList = [];

                self.headers = [
                    {
                        name: 'Date',
                        width: '7vw',
                        display: 'Date'
                    },
                    {
                        name: 'Time',
                        width: '7vw',
                        display: 'Time'
                    },
                    {
                        name: 'Duration',
                        width: '7vw',
                        display: 'Time Between'
                    },
                    {
                        name: 'ActivityType',
                        width: '7vw',
                        display: 'Type'
                    },
                    {
                        name: 'ActivityContent',
                        width: '40vw',
                        display: 'Content'
                    },
                    {
                        name: 'TicketLogicalId',
                        width: '11vw',
                        display: 'Ticket ID'
                    }
                ];
                self.sorts = [
                    {
                        name: 'TotalScore',
                        display: 'Score'
                    },
                    {
                        name: 'NumOfCloses',
                        display: 'Closes'
                    },
                    {
                        name: 'CallDurationHours',
                        display: 'Call Hours'
                    },
                    {
                        name: 'TicketsTouched',
                        display: 'Tickets Touched'
                    },
                    {
                        name: 'TotalDuration',
                        display: 'Duration (Total)'
                    },
                    {
                        name: 'LongestDuration',
                        display: 'Duration (Longest)'
                    },
                    {
                        name: 'NumOfTouches',
                        display: 'Touches'
                    }
                ];
                self.groupReportHeaders = [
                    {
                        name: 'Agent',
                        width: '7vw',
                        display: 'Agent'
                    },
                    {
                        name: 'NumOfCloses',
                        width: '7vw',
                        display: 'Closes'
                    },
                    {
                        name: 'CallDurationHours',
                        width: '7vw',
                        display: 'Call Hours'
                    },
                    {
                        name: 'NumOfTouches',
                        width: '7vw',
                        display: 'Touches'
                    },
                    {
                        name: 'TicketsTouched',
                        width: '7vw',
                        display: 'Tickets Touched'
                    },
                    {
                        name: 'TotalDuration',
                        width: '7vw',
                        display: 'Duration Total'
                    },
                    {
                        name: 'LongestDuration',
                        width: '7vw',
                        display: 'Longest Duration'
                    },
                    {
                        name: 'TotalScore',
                        width: '7vw',
                        display: 'Score'
                    }
                ];


                Tickets.getActivityReportAgents().then(
                    function (response) {
                        var agentsArray = Object.values(response);

                        // sort alphabetically, but make sure that 'All Agents' is always the default option
                        agentsArray.sort((a, b) => {
                            if (a.AgentName === 'All Agents') return -1;
                            if (b.AgentName === 'All Agents') return 1;
                            return a.AgentName.localeCompare(b.AgentName);
                        });
        
                        self.agentList = agentsArray;
                        console.log(self.agentList);

                        var today = new Date();
                        today.setHours(0, 0, 0, 0);
                        document.getElementById('start').valueAsDate = today;
                        document.getElementById('end').valueAsDate = today;
                    }
                );

                self.getActivityReport = function (start, end, id) {
                    if (id == null) {
                        id = 10000000001;
                    }

                    var parameters = start + ' ' + end + ' ' + id;
                    console.log('calling tickets api: self.getActivityReport(' + parameters + ')');
                    self.reports = [];
					var idParam = id;

                    return Tickets.getActivityReport(parameters).then(
                        function (response) {
                            console.log("--!response recieved!--")
                            self.reports = response;
                            self.sortedReports = {};

                            for (var i in self.sorts) {
                                var sortBy = self.sorts[i].name;
                                var sortedIds = [];
                                for (var id in self.reports) {
                                    if (self.reports[id].AgentScore != null) {
                                        var value = self.reports[id].AgentScore[sortBy];
                                        var touches = self.reports[id].AgentScore['NumOfTouches'];
                                        sortedIds.push([id, value, touches]);
                                    }
                                }
                                sortedIds.sort(function (valueOne, valueTwo) {
                                    if (valueTwo[1] == valueOne[1]) {
                                        return valueTwo[2] - valueOne[2];
                                    }
                                    return valueTwo[1] - valueOne[1];
                                });
                                self.sortedReports[sortBy] = sortedIds;
                            }
							
							var allAgentsId = 10000000001;
							if (idParam == allAgentsId && response[allAgentsId].ErrorMessage != null && response[allAgentsId].ErrorMessage === "SQL Timeout Error") {
								timeout.style = "";
							}
                        }
                    );
                    
                }

                console.log('\n-----------Loaded : -------------');
                console.log('Loaded activityReport view model');
            }

            return viewModel;
        }
    ]);