'use strict';

angular.module('membership')
  .service('partnershipsViewModel', [
    'Customer', 'modalService', 'partnershipDetailsViewModel',
    function (Customer, modalService, partnershipDetailsViewModel) {

      var viewModel = function (company) {

        var self = this;

        self.reload = function () {
          Customer.partnerships(company.Id).then(function (resp) {
            company.partnerships = resp;
            self.partnerships = resp;
          });
        };

        if (!company.partnerships) {
          self.reload();
        } else {
          self.partnerships = company.partnerships;
        }

        self.edit = function (item) {
          var detailsViewModel = new partnershipDetailsViewModel(item || { CompanyId: company.Id });
          detailsViewModel.on('save', function () {
            self.reload();
          });
          detailsViewModel.on('delete', function (params) {
            self.reload();
          });
          modalService.showWindow('components/membership/customer/partnerships/partnership-details.html', detailsViewModel);
        };

        self.delete = function(item) {
          Customer.deletePartnership(item).then(function() {
            self.reload();
          });
        };

      };

      return viewModel;

    }
  ]);
