'use strict';

angular.module('magtac')
  .service('dashboardViewModel', [
    'Dashboard', 'StatusPageLookup', '$q', '$filter', 'magtacSignalrService', '$interval', '$timeout', 'StorageService',
    function (Dashboard, lookup, $q, $filter, signalrService, $interval, $timeout, StorageService) {

      var viewModel = function () {

        var self = this;

        self.filteredCustomers = [];
        self.magTacTasks = [];
        self.selectedTasks = [];
        self.expirationTimeouts = {};
        self.currentTime = new Date();
        
        var selectedStatusesValues = StorageService.retrieve('MagTAC_Dashboard_Statuses') || ['Error', 'Expired', 'Warning', 'Success'];
          self.statuses = [
              { id: 'Error', label: 'Error' },
              { id: 'Expired', label: 'Expired' },
              { id: 'Warning', label: 'Warning' },
              { id: 'Success', label: 'Success' },
              { id: 'Waiting', label: 'Waiting' },
              { id: 'Skipped', label: 'Skipped' },
          ];

        //console.log(self.statuses);

        self.selectedStatuses =  _.filter(self.statuses, function(s) {
          return _.contains(selectedStatusesValues, s.id);
        })        

        self.saveStatuses = function() {
          var statuses = _.pluck(self.selectedStatuses, 'id');
          StorageService.store('MagTAC_Dashboard_Statuses', statuses);
          self.filterData();
        }

        self.filterData = function() {
          
          if (!self.customers || !self.customers.length || !self.selectedStatuses.length) {
            self.filteredCustomers = [];
            return;
          }

          var statuses = _.pluck(self.selectedStatuses, 'id');

          self.filteredCustomers = _.sortBy(_.filter(self.customers, function(c) {
            return _.contains(statuses, c.Status) && (c.IsImportant || !self.isImportant);
          }), function(c){ return self.statusWeight(c)});
        }

        self.reloadData = function() {
          Dashboard.getData().then(function(resp) {
            self.customers = [];
            _.forEach(resp, function(c) {
              self.statusChanged(c);
            });            
          })
        }
        self.loadTaskFilter = function() {
          var selectedTaskValues = [];
          for(var key in self.observerNames)
          {
            self.magTacTasks.push({
              id: key,
              label: self.observerNames[key].Description
            });
            selectedTaskValues.push(key);
          }
          selectedTaskValues = StorageService.retrieve('MagTAC_Dashboard_Tasks') || selectedTaskValues;
          //console.log(selectedTaskValues);
          self.selectedTasks = _.filter(self.magTacTasks, function(t) {
            return _.contains(selectedTaskValues, t.id);
          })
        }

        self.saveSelectedTask = function(){
          var tasks = _.pluck(self.selectedTasks, 'id');
          StorageService.store('MagTAC_Dashboard_Tasks', tasks);
          self.filterTasks();
        }
        
        self.filterTasks = function(){
          
          if (!self.customers || !self.customers.length || !self.selectedTasks.length) {
            self.filteredCustomers = [];
            return;
          }
          if (self.magTacTasks.length == self.selectedTasks.length)
          {
            self.filteredCustomers = _.sortBy(self.customers, function(c){ return self.statusWeight(c)});
            return ;
          }

          var tasks = _.pluck(self.selectedTasks, 'id');

          self.filteredCustomers = _.sortBy(_.filter(self.customers, function(c) {
            var hasTask = false;
            if ( c.FailedApps)
            {
              c.FailedApps.split(/\s*,\s*/).forEach(function(t){
                if (_.contains(tasks, t))
                {
                  hasTask = true;
                }
              });
            }
            return hasTask && (c.IsImportant || !self.isImportant);
          }), function(c){ return self.statusWeight(c)});
        }

        self.statusWeight = function(c){
          var weight;
          switch(c.Status){
            case "Error":
              weight = 1 - self.failedInMinutes(c.FirstFailedDatetime);
              break;
            case "Warning":
              weight = 2;
              break;
            case "Expired":
              weight = 3;
              break;
            case "Success":
              weight = 4;
              break;
            default:
              weight = 5;
              break;
          }
          return weight;
          //return (c.Status == "Error" ? 1 - self.failedInMinutes(c.FirstFailedDatetime): (c.Status == 'Warning' ? 2 : (c.Status == 'Expired' ? 3 : (c.Status == 'Success'? 4 :5)))) ;
        }

        self.init = function() {
          lookup.observerNames().then(function(data) {
            self.observerNames = data;
            self.loadTaskFilter();
          });
          self.reloadData();
          // signalrService.connect();          
          // self.intervalPromise = $interval(function() {
          //   // refresh state
          //   self.currentTime = new Date();
          // }, 10000);
          // self.statusChangedRef = Dashboard.onEvent('statusChanged', self.statusChanged)
          // self.statusDisabledRef = Dashboard.onEvent('statusDisabled', self.statusDisabled)
        }

        self.getErrorsCount = function() {
          return _.filter(self.customers, function(c) { return c.Status == 'Error'}).length;
        }

        self.getWarningCount = function() {
          return _.filter(self.customers, function(c) { return c.Status == 'Warning'}).length;
        }

        self.getExpiredCount = function() {
          return _.filter(self.customers, function(c) { return c.Status == 'Expired'}).length;
        }

        self.getWaitingCount = function() {
          return _.filter(self.customers, function(c) { return c.Status == 'Waiting'}).length;
        }

        self.getSkippedCount = function () {
            return _.filter(self.customers, function (c) { return c.Status == 'Skipped' }).length;
        }

        self.statusDisabled = function(customerId) {
          $timeout(function(){
            _.remove(self.customers, function(c) {
              return c.CustomerId == customerId;
            });
            self.filterData();
          });
        };

        self.statusChanged = function(updatedCustomer) {
          $timeout(function(){
             _.remove(self.customers, function(c) {
              return c.CustomerId == updatedCustomer.CustomerId;
            });

            if(self.expirationTimeouts[updatedCustomer.CustomerId]) {
              $timeout.cancel(self.expirationTimeouts[updatedCustomer.CustomerId]);
            }

            updatedCustomer.Priority = parseInt(updatedCustomer.Priority) || 1;
            updatedCustomer.IsExpired = updatedCustomer.ExpirationInterval <= 0;
            if (updatedCustomer.IsExpired && updatedCustomer.Status != 'Error' && updatedCustomer.Status != 'Waiting') {
              updatedCustomer.Status = 'Expired';
            // } else if (updatedCustomer.ExpirationInterval < 2147483647) { // check value to avoid type overflow
            //   self.expirationTimeouts[updatedCustomer.CustomerId] = $timeout(function() {self.expireCustomer(updatedCustomer);}, updatedCustomer.ExpirationInterval);
            }

            self.customers.push(updatedCustomer);
            self.filterData();
          })
        }

        self.expireCustomer = function(customer) {
          customer.IsExpired = true;
          customer.Status = "Expired";
          self.filterData();
        }

        self.getTaskDescriptions = function(tasks){
          var desc = '';
          var taskArray = [];
          var taskDescArray = [];
          //split by comman and remove white space
          if (tasks){
            tasks.split(/\s*,\s*/).forEach(function(t){
              taskDescArray.push(self.observerNames[t].Description);
            });
            
            desc = taskDescArray.slice(0,6).join(', ');
          }
          return desc;
        }

        self.getFirstFailedDatetime = function(tasks, failedDatetime){
          var firstFailedDatetime  = '';
          if (failedDatetime){
            if (failedDatetime){
              firstFailedDatetime = "Failed " + self.formattedDate(failedDatetime) + " ago";
            }
          }
          return firstFailedDatetime;
        }

        self.formattedDate = function(date) {
          var time = moment.duration(moment(date).diff(moment())).humanize();
          return time == 'a few seconds' ? 'a minute' : time;
        }

        self.failedInMinutes = function(date){
          var diff = 0;
          if (date){
            diff = moment.duration(moment().diff(moment(date))).asMinutes();
          }
          return diff;
        }

        self.toggleFullscreen = function() {
          if (!document.fullscreenElement &&    // alternative standard method
            !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
            if (document.documentElement.requestFullscreen) {
              document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
              document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
              document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
          } else {
            if (document.cancelFullScreen) {
              document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
              document.webkitCancelFullScreen();
            }
          }

        }

        self.onIsImportantChange = function(){
           self.filterData();
        }
        
        self.reload = function() {
          location.reload(); 
        }

        self.destroy = function() {
          self.statusChangedRef.unsubscribe();
          self.statusDisabledRef.unsubscribe();
          signalrService.disconnect();
          $interval.cancel(self.intervalPromise);
          _.forEach(self.expirationTimeouts, function(t) {
            $timeout.cancel(t);  
          })
        }

        self.init();

      };

      return viewModel;

    }
  ]);
