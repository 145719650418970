angular.module('common')
  .directive('countrySelection', [
    function () {
      return {
        restrict: "A",
        templateUrl: function (element, attributes) {
          return attributes.templateUrl || "";
        },
        scope: {},
        link: function (scope, element, attribute) {

        }
      }
    }
  ]);
