'use strict';

angular.module('magview', [
  'magview-config',
  'ngCookies',
  'ngSanitize',
  'ngAnimate',
  'mgcrea.ngStrap',
  'navigation',
  'ui.router',
  'common'
]);
