/**
 * Created on 11/11/2015.
 */

'use strict';

angular.module('common')
  .service('EventBroadcastingService', [
    function () {
      var EventBroadcastingService = {
        registerEvent: function (obj, eventName) {
          obj.___eventsList___ = obj.___eventsList___ || {};
          obj.___eventsList___[eventName] = new signals.Signal();
        },
        addListener: function (eventObj, eventName, listener) {
          if (!eventObj.___eventsList___ || !eventObj.___eventsList___[eventName]) {
            return;
          }
          eventObj.___eventsList___[eventName].add(listener);
        },
        removeListener: function (eventObj, eventName, listener) {
          if (!eventObj.___eventsList___ || !eventObj.___eventsList___[eventName]) {
            return;
          }
          eventObj.___eventsList___[eventName].remove(listener);
        },
        removeAll: function (eventObj, eventName) {
          if (!eventObj.___eventsList___ || !eventObj.___eventsList___[eventName]) {
            return;
          }
          eventObj.___eventsList___[eventName].removeAll();
        },
        dispatchEvent: function (eventObj, eventName) {
          if (!eventObj.___eventsList___ || !eventObj.___eventsList___[eventName]) {
            return;
          }
          var args = [];

          for (var i = 2; i < arguments.length; i++) {
            args.push(arguments[i]);
          }

          eventObj.___eventsList___[eventName].dispatch.apply(eventObj, args);
        }
      };

      return EventBroadcastingService;
    }
  ]);
