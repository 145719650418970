/**
 * Created on 10/29/2015.
 */

'use strict';

angular.module('membership')
  .factory('UserProfileViewModel', [
    '$rootScope',
    'User', 'notifySvc', 'common.config',
    'membership.config',
    function ($rootScope,
              User, notifySvc, commonConfig,
              membershipConfig) {
      function UserProfileViewModel(userId) {
        var self = this;

        self.data = null;
        self.userId = userId;
        self.commonConfig = commonConfig;

        User.getProfile(self.userId).then(function (response) {
          self.data = response;
          self.data.Birthday = self.data.Birthday ? moment(self.data.Birthday).format(commonConfig.momentDateOnlyFormat) : "";
        }, function (error) {
          notifySvc.error({
            msg: error.data ? error.data.ExceptionMessage || error.data : error.statusText
          });
        }).finally(function () {
          $rootScope.$broadcast(membershipConfig.events.userProfilePageLoaded);
        });
      }

      return UserProfileViewModel;
    }
  ]);
