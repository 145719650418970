'use strict';

angular.module('membership')
  .factory('manageUsersListViewModel', [
    'membership.config', 'BizUsers', '$state', 'userUiService', 'translationSvc', 'modalDialogService',
    'authorizeService', 'UserRegisterDomain',
    '$rootScope',
    function (config, BizUsers, $state, userUiService, translationSvc, modalDialogService,
              authorizeService, UserRegisterDomain,
              $rootScope) {

      var viewModel = function (filter) {
        var self = this;
        self.role = filter;
        var translationId = 'membership.customRoleName.' + (filter || 'user');
        self.filterSearchName = "";

        self.canBeInvited = config.canBeInvited[filter] == true;

        self.showDeletedOnly = false;
        self.ShowApprovalPendingOnly = false;

        translationSvc.t_async(translationId).then(function (r) {
          self.translatedRoleName = r;

          self.pageTitle = translationSvc.t('membership.manageAction') + ' ' + self.translatedRoleName
        });

        var defaultHiddenColumns = config.defaultHiddenColumns || {};

        self.datatablesConfig = {
          datasourceUrl: config.apiUrls.base + '/bizUsers/datatables',
          columns: [
            {
              "data": "Id",
              "title": "Id",
              searchable: false
            },
            {
              "data": "CompanyName",
              "title": "Company Name"
            },
            {
              "data": "FirstName",
              "title": "First Name"
            },
            {
              "data": "LastName",
              "title": "Last Name"
            },
            {
              "data": "Email",
              "title": "Email"
            },
            {
              "data": "CellPhoneNumber",
              "title": "Phone Number",
              searchable: false
            },
            {
              "data": "RolesString",
              "title": "Roles",
              searchable: false
            },
            {
              "data": "IsApproved",
              "title": "Is Approved",
              searchable: false,
              "render": function (data, type, row) {
                if (data) {
                  return '<label class="label label-success">YES</label>';
                }
                return '<label class="label label-warning">NO</label>';
              }
            },
            {
              data: "Id",
              title: " ",
              width: 120,
              searchable: false,
              sortable: false,
              render: function (data, type, row) {

                //var addApptBtn = "<a class='btn btn-success' href='#/scheduler/appointments/customer/" + data + "' rel='tooltip' title='Add Appointment'><i class='fa fa-calendar'></i></a>";

                var eventContext = {
                  user: row,
                  handler: self,
                  actionButtons: []
                };

                $rootScope.$broadcast(config.events.renderingUserActions, eventContext);

                var templates = _.pluck(_.sortBy(eventContext.actionButtons, 'order'), 'template');
                return templates.join('  ');
              }
            }
          ],
          hiddenColumns: defaultHiddenColumns[self.role] || [0],
          fnServerParams: function (ajaxData) {
            ajaxData.customFilter = ajaxData.customFilter || {};
            ajaxData.Role = self.role;
            ajaxData.ShowDeletedOnly = self.showDeletedOnly;
            ajaxData.ShowApprovalPendingOnly = self.ShowApprovalPendingOnly;
          }
        };

        self.triggerSearch = function () {
          self.datatablesConfig.table.reload();
        };

        self.addUser = function () {
          userUiService.proceedUserCreation(self.role).finally(function () {
            self.triggerSearch();
          });
        };

        self.inviteUser = function () {
          userUiService.proceedUserCreation(self.role, {
            IsNeedInvite: true
          }).finally(function () {
            self.triggerSearch();
          });
        };
      };

      return viewModel;
    }]);
