/**
 * Created on 10/24/2015.
 */

'use strict';

angular.module('membership')
  .controller('AccountInfoController', [
    '$scope', 'AccountInfoViewModel', '$userId',
    function ($scope, accountInfoViewModel, $userId) {
      $scope.vm = new accountInfoViewModel($userId);
    }
  ]);
