/**
 * Created on 11/20/2015.
 */

'use strict';

angular.module('common')
  .service('stateExtensions', [
    '$state',
    function ($state) {
      var stateExtensions = {
        getChildrenState: function (parentName) {
          if (!_.endsWith(parentName, '.'))
            parentName += '.';

          var allState = $state.get();

          var childrenStates = _.filter(allState, function (state) {
            return _.startsWith(state.name, parentName) &&
              state.name.indexOf('.', parentName.length + 1) == -1 && state.abstract !== true;
          });

          var abstractChildren = _.filter(allState, function (state) {
            return _.startsWith(state.name, parentName) &&
              state.name.indexOf('.', parentName.length + 1) == -1 && state.abstract === true;
          });

          _.each(abstractChildren, function (state) {
            var childStates = stateExtensions.getChildrenState(state.name);
            childStates = _.sortBy(childStates, 'data.order');
            childrenStates.push(childStates[0]);
          });

          return _.sortBy(childrenStates, 'data.order');
        },
        goToFirstSubchild: function (parentName, params) {
          var childrenStates = this.getChildrenState(parentName);
          $state.go(childrenStates[0].name, params);
        }
      };

      return stateExtensions;
    }
  ]);