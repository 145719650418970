/**
 * Created on 10/24/2015.
 */

'use strict';

angular.module('membership')
  .factory('ChangePasswordViewModel', [
    'authenticationService', 'notifySvc', 'User',
    function (authenticationService, notifySvc, User) {
      function ChangePasswordViewModel(userId) {
        var self = this;

        self.userId = userId;
        self.data = null;

        self.init = function () {
          self.data = {
            newPassword: "",
            confirmNewPassword: ""
          };

          if (!self.userId) self.data.oldPassword = "";
        };

        self.save = function () {
          var changePasswordPromise = self.userId ? User.changePasswordForUser(self.userId, self.data) : authenticationService.changePassword(self.data);
          changePasswordPromise.then(self.onChangePasswordSuccess, self.onChangePasswordError);
        };

        self.formValid = function () {
          var valid = true;

          if ((!self.userId && !self.data.oldPassword) || !self.data.newPassword)
            return false;

          if (self.data.newPassword !== self.data.confirmNewPassword)
            return false;

          return valid;
        };

        self.onChangePasswordSuccess = function () {
          notifySvc.success({
            i18msg: "membership.msg.changePasswordSuccess"
          });
          self.init();
        };

        self.onChangePasswordError = function (response) {
          notifySvc.error({
            msg: response.data.Message || response.data || response.statusText,
            i18title: 'membership.msg.changePasswordError'
          });
        };

        self.init();
      }

      return ChangePasswordViewModel;
    }
  ]);
