'use strict';

angular.module('membership')
  .directive('membershipLogoutMenu', [
    'authenticationService', 'AjaxService', 'membership.config',
    function (authenticationService, ajaxService, config) {
      return {
        restrict: 'E',
        replace: true,
        //scope: {},
        templateUrl: function(element, attrs){
          return attrs.templateUrl || 'components/membership/interfaces/logout_menu/logoutMenu.html';
        },
        link: function (scope, elm, attrs) {
          scope.signOut = function () {
            authenticationService.signOut();
          };
          scope.cancelImpersonation = function () {
            ajaxService.get(config.apiUrls.base + "/auth/CancelImpersonation").then(function() { 
              window.location = '/'; 
            });
          };
        }
      };
    }
  ]);
