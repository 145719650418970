'use strict';
angular.module('ticketAssignment')
    .service('teamTicketsViewModel', ['ticketAssignment.config', 'ticketAdmin', '$state',

        function (config, Tickets, $stateParams) {
            var viewModel = function (teamName) {

				var self = this;
                self.teamName = teamName;
                self.version = "new";

				self.headers = [
					{
						name: 'Opened',
						style: 'width: 4.5%',
						display: 'Opened'
					},
					{
						name: 'TicketId',
						style: 'width: 4.5%',
						display: 'Ticket'
					},
					{
						name: 'Subject',
						style: 'width: 32%',
						display: 'Subject'
					},
					{
						name: 'Contact',
						style: 'width: 9%',
						display: 'Contact'
					},
					{
						name: 'CompanyName',
						style: 'width: 16%',
						display: 'Company'
					},
					{
						name: 'Priority',
						style: 'width: 4%',
						display: 'Priority'
					},
					{
						name: 'Status',
						style: 'width: 9%',
						display: 'Status'
					},
					{
						name: 'DaysOld',
						style: 'width: 3%',
						display: 'Days Old'
					},
					{
						name: 'LastContact',
						style: 'width: 4%',
						display: 'Last Contact'
					},
					{
						name: 'LastTouchedBy',
						style: 'width: 4%',
						display: 'Last Touch'
					},
					{
						name: 'TicketScore',
						style: 'width: 3%',
						display: 'Ticket Score'
                    },
                    {
                        name: 'Agent',
                        style: 'width: 7%',
                        display: 'Agent'
                    }
				];

				self.filter = JSON.parse(localStorage.getItem('CustomerboardFilters'));
				self.filter['FilterWithShow'] = false;


                self.getData = function () {
                    if (self.version == "new") {
                        Tickets.getAgentsInTeam(teamName, self.filter).then(
                            function (response) {
                                console.log('Getting agents in team')
                                self.team = response;
                                console.log(self.team);
                            }
                        );
                    } else {
                        Tickets.getAgentsInTeamOld(teamName, self.filter).then(
                            function (response) {
                                console.log('Getting agents in team')
                                self.team = response;
                                console.log(self.team);
                            }
                        );
                    }
                }

				self.currentOpenSort = '';
				self.sortOpenTicketsBy = function (sortBy) {       // Sort open ticket list by attribute, 'sortBy' ie: "DaysOld"
					if (self.currentOpenSort == sortBy) {
						self.team.OpenedTickets.sort(function (a, b) { return a[sortBy] < b[sortBy] ? -1 : 1; });
					}
					else {
						self.team.OpenedTickets.sort(function (a, b) { return a[sortBy] > b[sortBy] ? -1 : 1; });
					}
					self.currentOpenSort = (self.currentOpenSort == sortBy) ? '' : sortBy;
				};

				self.currentClosedSort = '';
				self.sortClosedTicketsBy = function (sortBy) {      // Sort recently closed ticket list by attribute, 'sortBy'
					if (self.currentClosedSort == sortBy) {
						self.team.RecentlyClosedTickets.sort(function (a, b) { return a[sortBy] < b[sortBy] ? -1 : 1; });
					}
					else {
						self.team.RecentlyClosedTickets.sort(function (a, b) { return a[sortBy] > b[sortBy] ? -1 : 1; });
					}
					self.currentClosedSort = (self.currentClosedSort == sortBy) ? '' : sortBy;
                };


                self.getAgentIdFromName = function (agentName) {
                    return self.team.agentTickets.find(obj => obj.name === agentName).id;
                };

                self.abbreviateName = function (name) {
                    let parts = name.split(' ');
                    let firstName = parts[0];
                    let lastName = parts[1];
                    return `${firstName.charAt(0)}. ${lastName}`;
                }


                self.toggleVersion = function () {
                    if (self.version == "new") {
                        self.version = "old";
                        console.log("Switching to old version");
                    } else {
                        self.version = "new";
                        console.log("Switching to new version");
                    }

                    self.getData();
                }

                self.getData();

            }

            return viewModel;
        }
    ]);
