'use strict';
angular.module('ticketAssignment')
    .service('alertConfigurationViewModel', ['ticketAssignment.config', 'ticketAdmin', '$state',
        function (config, Tickets, $state) {
            var viewModel = function () {
                console.log('Loading alertConfiguration view model');


                var self = this;

                self.DAYS_OF_WEEK = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

                self.alerts = '';
                self.configurationNames = [];

                self.configuration = {
                    'NotEnoughAgentsLoggedIn':
                    {
                        name: 'NotEnoughAgentsLoggedIn',
                        display: 'Agents Logged into Support Queue',
                        hasPendingChanges: false,
                        reset: 0,
                        severity: 0,
                        initRecipients: [],
                        recipients: {},
                        execution: {},
                        pending: {
                            addition: [],
                            removal: []
                        },
                        threshold: {
                            description: 'The minimum number of allowed agents'
                        },
                        description: [
                            'This alert warning is sent out when there are fewer than the specified configurable number of agents available and logged into the support queue. ',
                            'The unavailable agents busy on support queue calls will be listed',
                        ]
                    },
                    'CustomerWaitingWarning':
                    {
                        name: 'CustomerWaitingWarning',
                        display: 'Customer Waiting (Warning)',
                        hasPendingChanges: false,
                        reset: 0,
                        severity: 0,
                        initRecipients: [],
                        recipients: {},
                        execution: {},
                        threshold: {
                            description: 'The maximum allowed customer waiting time'
                        },
                        pending: {
                            addition: [],
                            removal: []
                        },
                        description: [
                            'This alert warning is sent when there is a customer waiting in the support queue for longer than the specified threshold wait time. ',
                        ]
                    },
                    'CustomerWaitingAlert':
                    {
                        name: 'CustomerWaitingAlert',
                        display: 'Customer Waiting (Alert)',
                        hasPendingChanges: false,
                        reset: 0,
                        severity: 0,
                        initRecipients: [],
                        recipients: {},
                        execution: {},
                        threshold: {
                            description: 'The maximum allowed customer waiting time'
                        },
                        pending: {
                            addition: [],
                            removal: []
                        },
                        description: [
                            'This alert is sent when there is a customer waiting in the support queue for longer than the specified threshold wait time. ',
                        ]
                    }
                };

                self.originalConfigurationValues = {};
                //self.originalConfigurationValues[name] = {
                //    start: { hour: -1, minute: -1 },
                //    end: { hour: -1, minute: -1 },
                //    threshold: { value: -1, units: -1 },
                //    reset: -1,
                //    disabled: true,
                //};

                Tickets.getAllAlerts().then(
                    function (response) {
                        self.alerts = response;
                        console.log('self.alerts: ');
                        console.log(self.alerts);
                        for (var i = 0; i < self.alerts.length; i++) {
                            var a = self.alerts[i];
                            var name = a.BaseConfigurationName;

                            var emails = a.SendTo.split(',');
                            var usernames = emails.sort()
                                .map(function f(email) { return email.substring(0, email.indexOf('@')); })
                                .filter(function f(username) { return username.length > 0; });

                            for (var j = 0; j < usernames.length; j++) {
                                self.configuration[name].recipients[usernames[j]] =
                                    {
                                        id: j,
                                        name: usernames[j],
                                        email: usernames[j] + '@magview.com',
                                        status: '',
                                        initial: true,
                                        toBeRemoved: false,
                                        toBeAdded: false,
                                    };
                            }

                            var executeDayMap = {};

                            self.DAYS_OF_WEEK.forEach(function f(dayOfWeek) {
                                executeDayMap[dayOfWeek] = {
                                    day: dayOfWeek,
                                    value: a.ExecuteOnDays.includes(dayOfWeek),
                                };
                            });

                            self.configurationNames.push(a.BaseConfigurationName);

                            self.configuration[name].disabled = a.IsDisabled;
                            self.configuration[name].reset = a.ResetTimeMins;
                            self.configuration[name].severity = a.Severity;
                            self.configuration[name].threshold = {
                                value : a.Threshold,
                                units: a.ThresholdUnits,
                                description: self.configuration[name].threshold.description,
                            }
                            self.configuration[name].initRecipients = usernames;
                            self.configuration[name].hasPendingChanges = true;
                            self.configuration[name].execution = {
                                start: {
                                    hour: a.StartHour,
                                    minute: a.StartMinute,
                                },
                                end: {
                                    hour: a.EndHour,
                                    minute: a.EndMinute,
                                },
                                days: executeDayMap,
                            };

                            self.originalConfigurationValues[name] = {
                                execution: {
                                    start: {
                                        hour: a.StartHour,
                                        minute: a.StartMinute
                                    },
                                    end: {
                                        hour: a.EndHour,
                                        minute: a.EndMinute
                                    },
                                    days: executeDayMap,
                                },
                                threshold: {
                                    value: a.Threshold,
                                    units: a.ThresholdUnits
                                },
                                reset: a.ResetTimeMins,
                                disabled: a.IsDisabled
                            };
                            console.log('self.originalConfiguration (init. inside .vm.js)');
                            console.log(self.originalConfigurationValues);
                        }
                    }
                );

                self.save = function (request) {
                    self.saveSuccessful = true;
                    self.saveResultsMessage = '';
                    console.log('calling Tickets api');
                    Tickets.modifyAlertConfiguration(request).then(
                        function (response) {
                            self.saveResultsMessage = 'inside Tickets api';
                        }
                    );
                    console.log('save results message: ' + self.saveResultsMessage);
                    return self.reports;
                };

                console.log('\n-----------Loaded : -------------');
                console.log('Loaded activityReport view model');
            };

            return viewModel;
        }
    ]);