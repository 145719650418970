'use strict';

angular.module('magtac')
  .service('MagTacCustomersViewModel', [
    'magtac.config', 'modalDialogService', 'Customer',
    function (config, modalDialogService, Customer) {

      var viewModel = function() {

        var self = this;

        self.filterOpen = false;
        self.filter = {};

        self.datatablesConfig = {
          datasourceUrl: config.apiUrls.base + '/customers/datasource',
          dtColumns: [
            {
              "bSearchable": false,
              "bVisible": false,
              "aTargets": [0]
            },
            {
              aTargets: [1],
              "sClass": "dynamic-html no-wrap",
              render: function (data, type, full, meta) {
                if (full[5] === 'True') {
                  return "<span>" + full[1] + "</span>";
                }

                return "<a ui-sref='membership_customer.general({key: " + full[0] + "})' >" + full[1] + "</a>";
              }
            },
            // {
            //   "aTargets": [5],
            //   "render": function (data, type, full, meta) {
            //     var status = data;
            //     var statusClass = '';
            //     if (!status) {
            //       statusClass = 'status-na';
            //       status = 'N/A';
            //     } else if (status.indexOf('Success') >= 0) {
            //       statusClass = 'btn-success'; 
            //     } else if (status.indexOf('Expired') >= 0) {
            //       statusClass = 'btn-expired'; 
            //     } else if (status.indexOf('Warning') >= 0) {
            //       statusClass = 'btn-warning';
            //     } else if (status.indexOf('Error') >= 0) {
            //       statusClass = 'btn-danger';
            //     } else {
            //       statusClass = 'btn-info';
            //     }
            //     return "<span class='btn status btn-xs " + statusClass + "'>" + status + "</span>";
            //   }
            // },
            {
              "aTargets": -1,
              "bSearchable": false,
              "bSortable": false,
              "data": null,
              "sWidth": 50,
              "sClass": "actions-col dynamic-html no-wrap",
              "render": function (data, type, full, meta) {

                var buttons = [                  
                ];

                if (data[5] === 'True') {
                  buttons.push("<a class='btn btn-info' ng-click='model.revertCustomer(" + data[0] + ")' title='Restore' ><i class='fa fa-undo'></i></a>")
                }

                return buttons.join('&nbsp;');
              }
            }
          ],
          customFilter: self.filter,
          dom: "<'row'<'col-xs-12 col-sm-12'r>>" +
          "t" +
          "<'row'<'col-sm-2 col-xs-12 hidden-xs'l><'col-sm-4 col-xs-12 hidden-xs'<'pull-right'i>><'col-xs-12 col-sm-6'p>>"
        };

        self.createCustomer = function () {
          modalDialogService
            .showWindow('components/membership/customer/newCustomerDialog/newCustomerDialog.tpl.html', 'NewCustomerCtrl');
        };

        self.revertCustomer = function (id) {
           modalDialogService.showConfirmation('Are you sure you want to revert this customer?', 'Customers Module')
            .then(function(res) {
              if (res) {
                Customer.revertCustomer(id).then(function() {
                  self.datatablesConfig.reload();
                });  
              }
            });
        };

        self.clearVersionFilters = function(){
          self.filter.versionValue = '';
          self.filter.subversionValue = '';
          self.versionValue = '';
          self.subversionValue = '';
        }

        self.versionChanged = function(){
          self.filter.versionValue = self.versionValue.replace(/[^0-9.]/g, '');
          if(self.filter.versionValue.charAt(0) == '.'){
            self.filter.versionValue = self.filter.versionValue.substring(1,self.filter.versionValue.length);
            self.versionValue = self.versionValue.substring(1,self.versionValue.length);
          }
        }

        self.subversionChanged = function(){
          self.filter.subversionValue = self.subversionValue.replace(/[^0-9.]/g, '');
          if(self.filter.subversionValue.charAt(0) == '.'){
            self.filter.subversionValue = self.filter.subversionValue.substring(1,self.filter.subversionValue.length);
            self.subversionValue = self.subversionValue.substring(1,self.subversionValue.length);
          }
        }
      };

      return viewModel;
    }
  ]);