/**
 * Created on 9/28/2015.
 */

angular.module('membership')
  .factory('clientCompanyListModel', [
    'ClientCompanyDomain',
    'ClientCompanyUiService',
    'modalDialogService',
    'translationSvc',
    'membership.config',
    '$state',
    'toastr',
    'common.config',
    function (ClientCompanyDomain, ClientCompanyUiService, modalDialogService, $translate, config, $state, toastr, commonConfig) {

      function clientCompanyListModel() {
        var self = this;

        self.datatablesConfig = {
          "datasourceUrl": config.apiUrls.base + "/clientCompany/datatables",
          "columns": [
            {
              "data": "Name",
              "i18title": 'membership.lbl.clientCompanyName',
              render: function (data, type, row) {
                return '<a ng-click="vm.showJobSitesForBuilder(\'' + row.Id + '\')">' + data + '</a>';
              }
            },
            {
              "data": "LastModifiedBy",
              "i18title": 'common.lbl.lastModifiedBy',
              "searchable": false
            },
            {
              "data": "ModifiedDate",
              "i18title": 'common.lbl.lastModified',
              "searchable": false,
              "render": function (data, type, row) {
                return moment(data).format(commonConfig.momentDateFormat);
              }
            },
            {
              "data": "Id",
              "i18title": 'common.lbl.action',
              "sClass": "actions-col dynamic-html",
              "searchable": false,
              "sortable": false,
              render: function (data, type, row) {
                return '<button ng-click="vm.editClientCompany(' + data + ')" class="btn btn-primary btn-xs">' +
                  '<i class="glyphicon glyphicon-pencil"></i>' +
                  '</button>&nbsp;' +
                  '<button ng-click=\'vm.remove(' + data + ', \"' + row.Name + '\")\'  class="btn btn-danger btn-xs">' +
                  '<i class="glyphicon glyphicon-remove"></i>' +
                  '</button>';
              }
            }
          ]
        };

        var stateList = _.filter($state.get(), function (state) {
          if (_.startsWith)
            return _.startsWith(state.name, 'clientCompany.detail.');
          else {
            return state.name.indexOf('clientCompany.detail.') > -1;
          }
        });

        var tabsList = [];

        _.each(stateList, function (state) {
          if (!state.data.order) {
            state.data.order = 100;
          }
          tabsList.push(state);
        });

        tabsList = _.sortBy(tabsList, 'data.order');

        self.showJobSitesForBuilder = function (clientCompanyId) {
          $state.go(tabsList[0].name, {clientCompanyId: clientCompanyId});
        };

        self.remove = function (userId, companyName) {
          var message = $translate.t('membership.msg.confirmDeleteClientCompany', {companyName: companyName});
          var title = $translate.t('membership.title.deleteClientCompanyCfmDlg');

          modalDialogService.showConfirmation(message, title, "dialog-warning").then(function (result) {
            if (result) {
              ClientCompanyDomain.delete(userId).then(function () {
                toastr.success($translate.t('membership.msg.deleteClientCompanySuccessfully'), $translate.t('membership.title.deleteClientCompanyDlg'));
              }, function () {
                toastr.error($translate.t('membership.msg.deleteClientCompanyError'), $translate.t('membership.title.deleteClientCompanyDlg'), "dialog-warning");
              }).finally(function () {
                self.reloadTable();
              });
            }
          });
        };

        self.triggerSearch = function () {
          self.datatablesConfig.table.search(self.filterSearchName).draw();
        };

        self.addNewClientCompany = function () {
          ClientCompanyUiService.newClientCompany().then(self.reloadTable);
        };

        self.editClientCompany = function (clientCompanyId) {
          ClientCompanyUiService.editClientCompany(clientCompanyId).then(self.reloadTable);
        };

        self.reloadTable = function () {
          self.datatablesConfig.table.reload();
        };
      }

      return clientCompanyListModel;
    }
  ]);
