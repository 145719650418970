'use strict';

angular.module('ticketAssignment')
    .controller('customerBoardControlModel', [
        '$scope', 'customerBoardViewModel',
        function ($scope, viewModel) {
            $scope.model = new viewModel();

            $scope.get_company_link = function (companyId, filterWithShow) {
                return '/company/' + companyId + '/tickets?show=' + filterWithShow;
            }

            $scope.get_agent_link = function (agentId) {
                return '/agent/' + agentId + '/tickets';
            }

            $scope.get_team_link = function (teamId) {
                return '/team/' + teamId + '/tickets';
            }

            $scope.TeamCompanyStyle = function (header, company, filter) {
                var obj = {
                    'border-right': header.borderRight,
                    'background-color': (header.name == 'name' && company.redStatus) ? 'darkred' : 'inherit',
                    'color': (header.name == 'name' && company.redStatus) ? 'white' : 'black',
                    'font-weight': (header.name == 'name' && company.redStatus) ? 'bold' : 'inherit',
                    'text-align': (header.name == 'tickets' || header.name == 'priority' || header.name == 'score') ? 'right' : 'left',
                    'text-overflow': (header.name == 'name') ? 'ellipsis' : 'unset',
                };

                if (header.name == 'score' && !filter.FilterWithShow) {
                    obj['background-color'] = (company.tickets > 0 && company.score < 1.0) ? 'rgba(255, 0, 0, 0.075)' : 'inherit';
                }

                return obj;
            }

            $scope.TicketScoreStyle = function (score) {
                return {
                    'font-weight': 'normal',
                    'font-size': '11px;',
                    'color': GetScoreColor(score),
                };
            }

            var round = function (n, digits) { return Math.round(n * Math.pow(10, digits)) / Math.pow(10, digits) };
            var GetScoreColor = function (score) { return ScoreColors[round(score / 0.5, 0) * 0.5]; };

            var ScoreColors = {
                4: 'rgb(0, 152, 0)',
                3.5: 'rgb(0, 152, 0)',
                3: 'rgb(121, 165, 0)',
                2.5: 'rgb(169, 157, 0)',
                2: 'rgb(207, 156, 0)',
                1.5: 'rgb(218, 82, 0)',
                1: 'rgb(195, 0, 0)',
                0.5: 'darkred',
                0: 'rgb(148, 0, 0)',
            }
        }
    ]);