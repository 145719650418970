'use strict';

angular.module('common')
  .directive('uiSelect2Events', [function () {
    return {
      link: function (scope, element, attrs) {

        var callBacks = scope.$eval(attrs.uiSelect2Events);
        var eventsParent = scope.$eval(attrs.eventsParent);

        element.on('change', function (e) {
          for (var callBack in callBacks) {
            if (e[callBack]) {
              callBacks[callBack](e[callBack], eventsParent);
            }
          }
        });

      }
    };
  }
  ]);