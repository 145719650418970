'use strict';

angular.module('membership-config', [])
  .provider('membership.config', function () {

    this.config = {
      defaultTokenKey: 'AuthToken',
      apiUrls: {
        base: 'membership.api'
      },
      userRoles: {
        clients: "BizClient",
        staff: "BizStaff",
        companyClientManager: "BizClientCompanyStaff"
      },
      permissions: {
        systemAdmin: '/sysadmin',
        manageUsers: '/admin/users',
        manageRoles: '/admin/roles',
        cancelImpersonation: '/cancelImpersonation',
        manageSubscription: "Membership.Permissions.ManageSubscription",
        accessUserInformation: "Membership.Permissions.AccessUserInformation",
        approveUserRegistration: "Membership.Permissions.ApproveUserRegistration",
        activateDeactivateUsers: "Membership.Permissions.ActivateDeactivateUsers",
        impersonateUsers: "Membership.Permissions.Impersonation",

        customerView: "Membership.Customers.CustomerView",
        customerEdit: "Membership.Customers.CustomerEdit",

        userView: "Membership.Users.UserView",
        userEdit: "Membership.Users.UserEdit",

        customerContactsView: "Membership.Customers.Contacts.ContactsView",
        customerContactsEdit: "Membership.Customers.Contacts.ContactsEdit",
        customerSitesView: "Membership.Customers.Sites.SitesView",
        customerSitesEdit: "Membership.Customers.Sites.SitesEdit",
        customerDocumentsView: "Membership.Customers.Documents.DocumentsView",
        customerDocumentsEdit: "Membership.Customers.Documents.DocumentsEdit",
        customerVendorsView: "Membership.Customers.Vendors.VendorsView",
        customerVendorsEdit: "Membership.Customers.Vendors.VendorsEdit",
        customerPartnershipsView: "Membership.Customers.Partnerships.PartnershipsView",
        customerPartnershipsEdit: "Membership.Customers.Partnerships.PartnershipsEdit",
        customerSMSView: "Membership.Customers.SMS.SMSView",
        customerSMSEdit: "Membership.Customers.SMS.SMSEdit"

      },
      defaultHiddenColumns: {
        BizClient: [0, 6],
        BizStaff: [0, 1, 5, 6],
        BizClientCompanyStaff: [0, 1, 5, 6]
      },
      canBeInvited: {
        BizClient: true
      },
      enableJiraLogin: false,
      alwaysRequireLogin: true,
      membershipSettingKey: 'Membership.MembershipSetting',
      events: {
        userProfilePageLoaded: 'membership.events.userProfileLoaded',
        userProfileSaved: 'membership.events.userProfileSaved',
        onDisplayingUserProfileInfo: 'membership.events.onDisplayingUserProfileInfo',
        renderingUserActions: 'membership.events.renderingUserActions',
        onUserLoggedIn: 'membership.events.onUserLoggedIn',
        onUserLoggedOut: 'membership.events.onUserLoggedOut'
      }
    };

    var self = this;

    this.$get = function () {
      return self.config;
    };
    return this;
  });
