/**
 * Created on 11/28/2015.
 */

'use strict';

angular.module('membership')
  .controller('UserActivationController', [
    '$scope', 'UserActivationViewModel', '$stateParams',
    function (scope, UserActivationViewModel, $stateParams) {
      scope.vm = new UserActivationViewModel($stateParams.token);
    }
  ]);
