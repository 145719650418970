angular.module('common')
  .directive('listPage', [
    '$rootScope', '$timeout',
    function ($rootScope, $timeout) {
      return {
        restrict: 'E',
        transclude: {
          'filters': '?listFilters',
          'table': 'table'
        },
        scope: {
          configuration: "=",
          createRecordAction: '=',
          hideFilters: '=',
          createRecordPermission: '=',
          filterInitiallyOpen: "=",
          noSearch: "="
        },
        templateUrl: function (element, attributes) {
          return attributes.templateUrl || 'components/common/templates/listPage.tpl.html';
        },

        link: function (scope, element, attrs) {
          var vm = {
            createRecordLabel: attrs.createRecordLabel,
            createRecord: function () {
              scope.createRecordAction();
            },
            datatablesConfig: scope.configuration,
            reloadData: function () {
              vm.datatablesConfig.reload();
            },
            filterInitiallyOpen: scope.filterInitiallyOpen,
            filterOpen: scope.filterInitiallyOpen,
            hideFilters: scope.hideFilters,
            noSearch: scope.noSearch ? scope.noSearch : false,
            filterPlaceholder: attrs.filterPlaceholder,
            toggleOpen: function () {
              $timeout(function(){
                vm.filterOpen = !vm.filterOpen;
              });              
            },
            canShowCreateAction: function () {
              return vm.createRecordLabel && (!scope.createRecordPermission || $rootScope.isFeatureAllowed(scope.createRecordPermission));
            }
          };

          scope.dir = vm;
        }
      };
    }
  ]);
