'use strict';

angular.module('membership')
  .service('roleCreationViewModel', [
    'Role', '$location', function (roleDomain, $location) {
      var viewModel = {
        name: null,
        description: null,

        init: function () {
          this.name = '';
          this.description = '';
        },

        save: function () {
          var role = {
            name: this.name,
            description: this.description
          };
          roleDomain.insert(role, function (r) {
            $location.path("/membership/role/details/" + r.Id + "/roleInfo");
          });
        }
      };

      return viewModel;
    }
  ]);